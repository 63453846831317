import {
  UnitTemplate,
  EvaluationModuleCommonProps,
  EvaluationHelperProduct,
  EvaluationHelperProps,
} from "../EvaluationTypes";
import { countUnitWidth } from "../evaluationValues";

/*
  - ヘッダーの units を作成
  - 評価フェーズを設定
  - 標準のビヘイビアを設定
*/
const seedDataTypes = {
  headerList: {
    description: "各行の構成",
    data: {
      type: "array",
      schema: {
        factors: {
          type: "text[]",
          label: "項目名",
        },
        axis: {
          type: "text",
          label: "分析用タグ",
        },
        relatedPhase: {
          type: "phase",
          label: "関係する評価フェーズ",
        },
        type: {
          type: "valueType",
          label: "種類",
        },
        helper: {
          type: "query",
          label: "Query",
        },
      },
    },
  },
};
const AnswerHeaderRowModule = (
  common: EvaluationModuleCommonProps,
  props: {
    origin: {
      row: number;
      column: number;
    };
    headerList: EvaluationHelperProps["iteration"];
  }
): EvaluationHelperProduct => {
  const units = [] as UnitTemplate[];
  const current = {
    row: props.origin.row,
    column: props.origin.column,
  };

  const CSSBundleUsageMap = {
    DEFAULT: ["header_cell", "flex_centering"],
  };

  const maxRowSpan = Math.max(...props.headerList.map(({ factors }) => (factors ?? [""]).length));

  props.headerList.forEach(({ factors = [""] }, index) => {
    for (let i = 0, gridRow = current.row; i < factors.length; i++) {
      const gridColumn = current.column;
      const id = `${common.unitGroupId}__${common.subRoutineId}__${gridRow}__${gridColumn}`;
      const gridColumnSpan = (() => {
        if (props.headerList[index - 1]?.factors?.[i] && props.headerList[index - 1].factors?.[i] === factors[i]) {
          // 一つ左と同じ場合、すでにこのセル分は確保されているのでスキップする
          return 0;
        } else {
          let result = 1;
          for (let j = index + 1; j < props.headerList.length; j++) {
            if (props.headerList[j].factors?.[i] === factors[i]) {
              result++;
            } else {
              break;
            }
          }
          return result;
        }
      })();
      if (!gridColumnSpan) {
        if (i === factors.length - 1) {
          gridRow = current.row;
        } else {
          gridRow++;
        }
        continue;
      }
      const width = countUnitWidth(Math.max(...factors.map((f) => f.length)));

      units.push({
        type: "static",
        id,
        description: [...(factors || [])].join(" - "),
        gridRow,
        gridColumn,
        gridRowSpan: i === factors.length - 1 ? maxRowSpan - factors.length + 1 : 1,
        gridColumnSpan,
        value: factors[i],
        factor: factors[i],
        unitGroupId: common.unitGroupId,
        subRoutineId: common.subRoutineId,
        style: CSSBundleUsageMap["DEFAULT"].reduce((prev, current) => {
          return {
            ...prev,
            ...common.context.cssTraits[current]?.styles,
          };
        }, {}),
        width,
      });
      if (i === factors.length - 1) {
        gridRow = current.row;
      } else {
        gridRow++;
      }
    }
    current.column++;
  });

  return {
    units,
    origin: { row: props.origin.row, column: props.origin.column },
    occupied: { row: current.row + maxRowSpan - 1, column: current.column },
    context: common.context,
    meta: {},
  };
};

const sampleArgv = { headerList: [] };

export default {
  label: "回答欄ヘッダー",
  run: AnswerHeaderRowModule,
  seedDataTypes,
  sampleArgv,
};
