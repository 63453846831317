import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sidebar_Evaluation from "../../component/Sidebar_Evaluation";
import Table from "../../component/Table";
import { Container, Row, Col, Button, Alert, Form } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { getPhases, getTodos, selectESState } from "./evaluationSystemSlice";
import { ESProcessMethod, ESProcess } from "./ESTypes";
import RatioBar from "../../component/RatioBar";
import ESTodoCard from "./ESTodoCard";
import { Link } from "react-router-dom";
import Icon from "../../component/Icon";

function App() {
  const dispatch = useAppDispatch();
  const { phaseGroups, processes, methods, todos } = useAppSelector(selectESState);
  const [selectedProcess, $selectedProcess] = useState<ESProcess | null>(null);
  const [selectedMethod, $selectedMethod] = useState<ESProcessMethod | null>(null);
  const [expandedSections, $expandedSections] = useState<string[]>([]);
  const [dateRange, $dateRange] = useState<{ start: string; end: string }>({ start: "", end: "" });
  const [searchKeyword, $searchKeyword] = useState<string>("");
  useEffect(() => {
    dispatch(getPhases());
    dispatch(getTodos());
  }, []);

  const getExpander = useCallback(
    (section: string) => {
      return (
        <a
          className="--inline-link"
          onClick={() =>
            $expandedSections(
              expandedSections.includes(section)
                ? expandedSections.filter((_) => _ !== section)
                : [...expandedSections, section]
            )
          }
        >
          {expandedSections.includes(section) ? "最新分だけ表示する" : "すべて表示する"}
        </a>
      );
    },
    [expandedSections]
  );

  const methodOptions = useMemo(() => {
    return methods.map((m, i) => {
      return { value: m.method_id, label: m.method_name };
    });
  }, [methods]);

  const roleOptions = useMemo(() => {
    return [
      { value: "評価者", label: "評価者" },
      { value: "被評価者", label: "被評価者" },
    ];
  }, []);

  const phaseOptions = useMemo(() => {
    return phaseGroups.map((pg, i) => {
      return { value: pg.id, label: pg.label };
    });
  }, [phaseGroups]);

  return (
    <div className="Ev-container --editor">
      <Sidebar_Evaluation current="dashboard" />
      <div className="Ev-edit-content">
        <h2 className="Headline--page">マイダッシュボード</h2>
        <Container className="bg-white mt-3 p-4 px-2 px-md-4">
          <Row>
            <Col>
              <div className="d-flex flex-row-reverse">
                <Button variant="outline-primary" className="mx-1 --flex --align-items-center">
                  <div className="mx-1">評価シート一覧へ</div>
                  <Icon type="arrow-right-circle" width={18} height={18} />
                </Button>
              </div>
              <Alert className="mt-4" variant="secondary">
                新しい通知はありません。
              </Alert>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="mb-1 --font-s">プロセスを選択</div>
              <Form.Select
                value={selectedProcess?.process_id ?? ""}
                onChange={(e) => {
                  $selectedProcess(processes.find((p) => p.process_id === e.target.value) ?? null);
                }}
              >
                {processes.map((p, i) => {
                  return (
                    <option key={`option_${i}`} value={p.process_id}>
                      {p.process_name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
          <Row className="mt-4 mb-4">
            <Col md={8}>
              <h3 className="Headline--section">TODO</h3>
            </Col>
            <Col md={4} className="--text-align-right">
              {getExpander("todo")}
            </Col>
          </Row>
          <Row>
            <Col>
              {expandedSections.includes("todo") && (
                <div className="Process-progress-card --grouping w-100">
                  <Row>
                    <Col>
                      <div className="--flex --align-items-center">
                        <div className="--shrink-0 mx-2">評価手法</div>
                        <Form.Select
                          value={selectedMethod?.method_id ?? ""}
                          onChange={(e) => {
                            $selectedMethod(methods.find((m) => m.method_id === e.target.value) ?? null);
                          }}
                        >
                          <option value={""}>評価手法を選択してください</option>
                          {methodOptions.map((m) => (
                            <option value={m.value} key={m.value}>
                              {m.label}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col md={4}>
                      <div className="--shrink-0 --font-s">フェーズ</div>
                      <Form.Select
                        value={""}
                        onChange={(e) => {
                          return;
                        }}
                      >
                        <option value={""}>---</option>
                        {phaseOptions.map((phase, i) => {
                          return (
                            <option key={`option_${i}`} value={phase.value}>
                              {phase.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                    <Col md={4}>
                      <div className="--shrink-0 --font-s">ステップ</div>
                      <Form.Select
                        value={""}
                        onChange={(e) => {
                          return;
                        }}
                      >
                        <option value={""}>---</option>
                      </Form.Select>
                    </Col>
                    <Col md={4}>
                      <div className="--shrink-0 --font-s">ロール</div>
                      <Form.Select
                        value={""}
                        onChange={(e) => {
                          return;
                        }}
                      >
                        <option value={""}>---</option>
                        {roleOptions.map((role, i) => {
                          return (
                            <option key={`option_${i}`} value={role.value}>
                              {role.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col>
                      <div className="--flex --align-items-center">
                        <div className="--shrink-0 mx-2">検索条件</div>
                        <Form.Control
                          value={searchKeyword}
                          onChange={(e) => {
                            $searchKeyword(e.target.value);
                          }}
                        ></Form.Control>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              <div className="Todo-card__list mt-2">
                {todos.map((todo, i) => {
                  return <ESTodoCard todo={todo} key={`todo${i}`}></ESTodoCard>;
                })}
              </div>
            </Col>
          </Row>
          <Row className="mt-5 mb-4">
            <Col md={8}>
              <h3 className="Headline--section">プロセスの進捗</h3>
            </Col>
            <Col md={4} className="--text-align-right">
              {getExpander("progress")}
            </Col>
          </Row>
          <Row>
            <Col>
              {expandedSections.includes("progress") && (
                <div className="Process-progress-card --flex --align-items-center --grouping w-100">
                  <Icon className="--shrink-0" type="filter-left" width={24} height={24} />
                  <div className="--shrink-0 mx-2">期間</div>
                  <Form.Select
                    value={dateRange.start}
                    onChange={(e) => {
                      $dateRange({ ...dateRange, start: e.target.value });
                    }}
                  >
                    <option value={""}>---</option>
                    {["2025", "2024", "2023", "2022"].map((value, i) => {
                      return (
                        <option key={`option_${i}`} value={`${value}-01-01`}>
                          {value}年
                        </option>
                      );
                    })}
                  </Form.Select>
                  <div className="mx-1">～</div>
                  <Form.Select
                    value={dateRange.end}
                    onChange={(e) => {
                      $dateRange({ ...dateRange, end: e.target.value });
                    }}
                  >
                    <option value={""}>---</option>
                    {["2025", "2024", "2023", "2022"].map((value, i) => {
                      return (
                        <option key={`option_${i}`} value={`${value}-01-01`}>
                          {value}年
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
              )}
              <div className="Process-progress-card__list mt-2">
                {processes.map((process, i) => {
                  return (
                    <article key={i} className="Process-progress-card">
                      <h3 className="Process-progress-card__title">{process.process_name}</h3>
                      <div className="mt-3">
                        {process.workflow_phases.map((phase, i2) => {
                          return (
                            <div className="Process-progress-card__step mb-1" key={`process${i}_phase${i2}`}>
                              <div className="w-50">
                                {phase.phase_name}
                                <br />({phase.completed_todos}/{phase.total_todos})
                              </div>
                              <div className="w-50">
                                <RatioBar
                                  width={"100%"}
                                  steps={[
                                    {
                                      value: phase.progress_rate,
                                      valueSuffix: "%",
                                      backgroundColor: "#0D6EFD",
                                      color: "#fff",
                                    },
                                    {
                                      value: 100 - phase.progress_rate,
                                      backgroundColor: "#E9ECEF",
                                      color: "transparent",
                                      showValue: false,
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </article>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row className="mt-5 mb-4">
            <Col md={8}>
              <h3 className="Headline--section">スケジュール</h3>
            </Col>
            <Col md={4} className="--text-align-right">
              {getExpander("schedule")}
            </Col>
          </Row>
          {expandedSections.includes("schedule") && (
            <Row className="mb-2">
              <Col>
                <div className="Process-progress-card --flex --align-items-center --grouping w-100">
                  <Icon className="--shrink-0" type="filter-left" width={24} height={24} />
                  <div className="--shrink-0 mx-2">期間</div>
                  <Form.Select
                    value={dateRange.start}
                    onChange={(e) => {
                      $dateRange({ ...dateRange, start: e.target.value });
                    }}
                  >
                    <option value={""}>---</option>
                    {["2025", "2024", "2023", "2022"].map((value, i) => {
                      return (
                        <option key={`option_${i}`} value={`${value}-01-01`}>
                          {value}年
                        </option>
                      );
                    })}
                  </Form.Select>
                  <div className="mx-1">～</div>
                  <Form.Select
                    value={dateRange.end}
                    onChange={(e) => {
                      $dateRange({ ...dateRange, end: e.target.value });
                    }}
                  >
                    <option value={""}>---</option>
                    {["2025", "2024", "2023", "2022"].map((value, i) => {
                      return (
                        <option key={`option_${i}`} value={`${value}-01-01`}>
                          {value}年
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className="Process-progress-card__list">
                {processes.reduce((prev, process) => {
                  return [
                    ...prev,
                    ...process.workflow_phases.map((_, pi) => {
                      return (
                        <article key={`process${process.process_id}_phase${pi}`} className="Process-progress-card">
                          <div className="--font-s mb-1">{process.project_name}</div>
                          <div className="Process-progress-card__step">
                            <div className="w-50">
                              <Link to={"/"}>{_.phase_name}</Link>
                            </div>
                            <div className="w-50">
                              {_.start_date} ~ {_.end_date}
                            </div>
                          </div>
                        </article>
                      );
                    }),
                  ];
                }, [] as React.ReactNode[])}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
