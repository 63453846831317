import { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import Sidebar_Evaluation from "../../component/Sidebar_Evaluation";
import { Container, Row, Tab, Nav } from "react-bootstrap";
import EvaluationProjectList from "./EvaluationProjectList";
import EvaluationSystemList from "./EvaluationSystemList";
import EvaluationProjectGroupList from "./EvaluationProjectGroupList";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { setEditTarget } from "./evaluationSheetSlice";
import { useAppDispatch } from "../../app/store";

type TAB = {
  label: string;
  eventKey: string;
};

const TABS: TAB[] = [
  {
    label: "プロジェクト",
    eventKey: "projects",
  },
  {
    label: "評価タイプ",
    eventKey: "types",
  },
  {
    label: "評価年度",
    eventKey: "groups",
  },
];

function App() {
  const { listType } = useParams();
  const dispatch = useAppDispatch();
  const [activeTabKey, $activeTabKey] = useState(listType || "projects");
  const tabContent = useMemo(() => {
    if (activeTabKey === "types") {
      return <EvaluationSystemList />;
    } else if (activeTabKey === "projects") {
      return <EvaluationProjectList />;
    } else if (activeTabKey === "groups") {
      return <EvaluationProjectGroupList />;
    } else {
      return <div>{activeTabKey}</div>;
    }
  }, [activeTabKey]);

  useEffect(() => {
    dispatch(setEditTarget({ systemId: "", projectId: "", patternId: "" }));
  }, []);

  return (
    <div className="Ev-container --editor">
      <Sidebar_Evaluation current="list" />
      <div className="Ev-edit-content bg-white">
        <Container className="pb-4">
          <Row>
            <Tab.Container
              onSelect={(key: string | null) => {
                $activeTabKey(key ?? "projects");
                if (key && key !== "projects") {
                  window.history.replaceState({}, "", `/_/evaluation/list/${key}`);
                } else {
                  window.history.replaceState({}, "", `/_/evaluation/`);
                }
              }}
              activeKey={activeTabKey}
            >
              <Nav variant="tabs">
                {TABS.map((t) => (
                  <Nav.Item key={t.eventKey}>
                    <Nav.Link eventKey={t.eventKey}>{t.label}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content>
                <div className="mt-4">{tabContent}</div>
              </Tab.Content>
            </Tab.Container>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
