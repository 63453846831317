import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import Table from "../../component/Table";
import { getEvaluationSystems, selectEvaluationState } from "./evaluationSheetSlice";
import { Row, Col, Alert, Container, Button } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

function App() {
  const dispatch = useAppDispatch();
  const { systems } = useAppSelector(selectEvaluationState);
  useEffect(() => {
    dispatch(getEvaluationSystems());
  }, []);
  return (
    <Container>
      <Row>
        <Col>
          <Link to="/_/evaluation/create/system">
            <Button variant="outline-primary">新規評価タイプ</Button>
          </Link>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          {systems.length > 0 ? (
            <Table
              col={[
                {
                  name: "評価タイプ名",
                  colName: "label",
                },
              ]}
              row={systems.map((s) => {
                return {
                  key: s.id,
                  data: [s.label],
                  link: `/_/evaluation/system/${s.id}/`,
                };
              })}
              usePagenation={true}
            />
          ) : (
            <Alert variant={"info"}>該当する評価タイプがありません。</Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
