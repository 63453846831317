import { useState, useEffect, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button, Form, Badge, OverlayTrigger, Tooltip, Modal, Card } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../app/store";
import Table from "../../component/Table";
import ModalDialog from "../../component/ModalDialog";
import {
  commitApplicationStatus,
  copyApplicationConfig,
  deleteApplicationConfig,
  getApplicationStatus,
  getApplyTemplateSummaries,
  getEditingApplicationConfig,
  getNotificationTarget,
  commitNotificationTarget,
  selectApplyState,
  getApplicantConfig,
  commitApplicantConfig,
} from "./applySlice";
import MultipleMemberSelector from "../profile/MultipleMemberSelector";
import MultiSelectItem from "../profile/MultiSelectItem";
import { getAllTerms } from "../../app/translate";
import SectionSelector from "../section/SectionSelector";
import { APPLICATION_CATEGORIES, APPLICATION_USAGE_SATUSES, ApplicationUsageStatusType } from "./applyValues";
import { setLoading } from "../notification/notificationSlice";
import classNames from "classnames";
function ApplicationStatus() {
  const dispatch = useAppDispatch();
  const TERMS = getAllTerms();
  const { templateSummaries, notificationTarget, applicantConfig } = useAppSelector(selectApplyState);
  const [state, $state] = useState({
    activeModal: "",
    targetApplicationType: "",
    keyword: "",
    selectedCategories: [] as string[],
  });

  const [notificationTargetModal, $notificationTargetModal] = useState({
    isOpen: false,
    applicationTypes: [] as string[],
    selectedAccountIds: [] as number[],
    mode: "single" as "single" | "multiple",
  });

  const [applicantConfigModal, $applicantConfigModal] = useState({
    isOpen: false,
    applicationType: "",
    type: "",
    sections: [] as string[],
  });

  const [statusChangeModal, $statusChangeModal] = useState({
    isOpen: false,
    applicationType: "",
  });

  useEffect(() => {
    dispatch(getApplicationStatus());
    dispatch(getApplyTemplateSummaries());
    dispatch(getNotificationTarget());
    dispatch(getApplicantConfig());
  }, []);

  const filteredSummaries = useMemo(() => {
    return templateSummaries
      .filter((_) => _.template_status !== "obsolete")
      .filter((summary) => summary.name.includes(state.keyword))
      .filter(
        (summary) =>
          state.selectedCategories.length === 0 ||
          summary.categories.some((category) => state.selectedCategories.includes(category))
      )
      .map((summary) => {
        const _notificationTarget = notificationTarget[summary.application_type] ?? [];
        return { ...summary, notificationTarget: _notificationTarget };
      });
  }, [templateSummaries, notificationTarget, state.keyword, state.selectedCategories]);

  const closeNotificationTargetModal = () => {
    $notificationTargetModal({
      ...notificationTargetModal,
      isOpen: false,
      applicationTypes: [],
      selectedAccountIds: [],
    });
  };

  const closeApplicantConfigModal = () => {
    $applicantConfigModal({
      isOpen: false,
      applicationType: "",
      type: "",
      sections: [],
    });
  };

  const changeApplicationStatus = useCallback(
    async (applicationType: string, status: ApplicationUsageStatusType) => {
      dispatch(setLoading(true));
      await dispatch(commitApplicationStatus({ [applicationType]: status }));
      await dispatch(getApplyTemplateSummaries());
      $statusChangeModal({ isOpen: false, applicationType: "" });
      dispatch(setLoading(false));
    },
    [dispatch]
  );

  // 完了時通知先の一括設定で選択可能な申請書種別
  const selectableApplicationTypes = useMemo(() => {
    if (notificationTargetModal.mode !== "multiple" || !notificationTargetModal.isOpen) return [];
    return filteredSummaries
      .filter(({ application_type }) => application_type !== "application_template")
      .map(({ application_type, name }) => {
        return {
          label: name,
          value: application_type,
          checked: notificationTargetModal.applicationTypes.includes(application_type),
        };
      });
  }, [filteredSummaries, notificationTargetModal.applicationTypes, notificationTargetModal.isOpen]);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <p>
            使用する申請書を設定してください。
            <br />
            申請書設定のコピーをする場合は「コピー」を押下してください。リリース済の最新設定がコピーされます。
            <br />
            注釈表示・追加項目・承認フローの設定は「編集」を押下してください。
          </p>
          <Card className="mb-2">
            <Card.Body>
              <Row>
                <span className="--bold">表示条件</span>
              </Row>
              <Row className="--align-items-center mb-1">
                <Col md={2}>申請書種別：</Col>
                <Col md={10}>
                  {APPLICATION_CATEGORIES.map((category) => (
                    <Form.Check
                      inline
                      type="switch"
                      key={`option_c_${category}`}
                      id={`option_c_${category}`}
                      label={TERMS[`APPLICATION_CATEGORY_${category}`]}
                      checked={state.selectedCategories.includes(category)}
                      onChange={(e) => {
                        const nextCategories = e.target.checked
                          ? [...state.selectedCategories, category]
                          : state.selectedCategories.filter((c) => c !== category);
                        $state({ ...state, selectedCategories: nextCategories });
                      }}
                    />
                  ))}
                </Col>
              </Row>
              <Row className="--align-items-center mb-1">
                <Col md={2}>申請書名：</Col>
                <Col md={10}>
                  <Form.Control
                    type="text"
                    id={`filter`}
                    className="mb-1"
                    value={state.keyword}
                    onChange={(e) => $state({ ...state, keyword: e.target.value })}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Table
            col={[
              { name: "申請書名", width: "15%" },
              { name: "ステータス", width: "10%" },
              { name: "申請権限", width: "13%" },
              { name: "アクション", width: "15%" },
              {
                name: (
                  <>
                    完了時通知先
                    <Button
                      size="sm"
                      className="ms-1"
                      variant="outline-secondary"
                      onClick={() => {
                        $notificationTargetModal({ ...notificationTargetModal, mode: "multiple", isOpen: true });
                      }}
                    >
                      一括設定
                    </Button>
                  </>
                ),
                width: "13%",
              },
              { name: "説明", width: "34%" },
            ]}
            row={filteredSummaries.map(
              ({
                usage_status,
                application_type,
                name,
                version,
                description,
                inactive_copy_targets,
                notificationTarget,
              }) => {
                return {
                  id: application_type,
                  data: [
                    name,
                    application_type !== "application_template" && version !== null && (
                      <>
                        <Form.Select
                          value={usage_status}
                          className={classNames({
                            "bg-success": usage_status === "in_use",
                            "bg-secondary": usage_status === "not_in_use",
                            "bg-info": usage_status === "testing",
                            "--color-white": true,
                          })}
                          onChange={(e) => {
                            if (e.target.value === "in_use") {
                              // 利用中に変更する場合のみ確認ダイアログを表示
                              $statusChangeModal({ isOpen: true, applicationType: application_type });
                            } else {
                              // それ以外は即時更新
                              changeApplicationStatus(application_type, e.target.value as ApplicationUsageStatusType);
                            }
                          }}
                        >
                          {APPLICATION_USAGE_SATUSES.map((status) => (
                            <option
                              key={status}
                              value={status}
                              className={classNames({
                                "bg-success": status === "in_use",
                                "bg-secondary": status === "not_in_use",
                                "bg-info": status === "testing",
                                "--color-white": true,
                              })}
                            >
                              {TERMS[`APPLICATION_USAGE_STATUS_${status}`]}
                            </option>
                          ))}
                        </Form.Select>
                        {usage_status !== "not_in_use" && inactive_copy_targets.length > 0 && (
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 50, hide: 50 }}
                            overlay={(props) => (
                              <Tooltip id={`tooltip_${application_type}`} {...props}>
                                データ反映先に管理対象外のテーブルが含まれています
                              </Tooltip>
                            )}
                          >
                            <Badge pill bg="warning" text="dark" className="mx-1">
                              !
                            </Badge>
                          </OverlayTrigger>
                        )}
                      </>
                    ),
                    application_type !== "application_template" && (
                      <Button
                        variant="link"
                        onClick={() =>
                          $applicantConfigModal({
                            isOpen: true,
                            applicationType: application_type,
                            type: applicantConfig?.[application_type]?.type,
                            sections: applicantConfig?.[application_type]?.sections ?? [],
                          })
                        }
                      >
                        {TERMS[`APPLICANT_CONFIG_TYPE_${applicantConfig?.[application_type]?.type ?? "self"}`]}
                      </Button>
                    ),
                    <>
                      {version === null ? (
                        <Button
                          variant="link"
                          className="ps-1"
                          onClick={() =>
                            $state({ ...state, activeModal: "before_delete", targetApplicationType: application_type })
                          }
                        >
                          削除
                        </Button>
                      ) : (
                        <Button
                          variant="link"
                          className="ps-1"
                          onClick={() =>
                            $state({ ...state, activeModal: "before_copy", targetApplicationType: application_type })
                          }
                        >
                          コピー
                        </Button>
                      )}
                      {application_type !== "application_template" && (
                        <Link className="ps-1" to={`/_/masters/application/${application_type}`}>
                          編集
                        </Link>
                      )}
                    </>,
                    application_type !== "application_template" ? (
                      <Button
                        variant="link"
                        onClick={() =>
                          $notificationTargetModal({
                            isOpen: true,
                            applicationTypes: [application_type],
                            selectedAccountIds: notificationTarget,
                            mode: "single",
                          })
                        }
                      >
                        {notificationTarget.length}件
                      </Button>
                    ) : null,
                    <div>{description}</div>,
                  ],
                };
              }
            )}
          />
        </Col>
      </Row>
      <ModalDialog
        show={statusChangeModal.isOpen}
        onConfirm={() => {
          changeApplicationStatus(statusChangeModal.applicationType, "in_use");
        }}
        onCancel={() => $statusChangeModal({ isOpen: false, applicationType: "" })}
        message="申請書が使用中になり、申請書利用可能なアカウント全員に表示されます。よろしいですか？"
      />
      <ModalDialog
        show={state.activeModal === "before_copy"}
        onConfirm={() => {
          dispatch(copyApplicationConfig({ application_type: state.targetApplicationType })).then(() => {
            dispatch(getApplyTemplateSummaries());
          });
          $state({ ...state, activeModal: "", targetApplicationType: "" });
        }}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message={"最新のリリース済バージョンの内容をコピーします。よろしいですか？"}
      />
      <ModalDialog
        show={state.activeModal === "before_delete"}
        onConfirm={() => {
          dispatch(getEditingApplicationConfig({ application_type: state.targetApplicationType })).then((res) => {
            dispatch(deleteApplicationConfig({ id: res.payload.id })).then(() => {
              dispatch(getApplyTemplateSummaries());
            });
            $state({ ...state, activeModal: "", targetApplicationType: "" });
          });
        }}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message={"コピーした設定を削除します。よろしいですか？"}
        type="destructiveConfirm"
        confirmButtonName="削除"
      />
      <ModalDialog
        show={state.activeModal === "before_save_notification_target"}
        onConfirm={async () => {
          const { applicationTypes, selectedAccountIds } = notificationTargetModal;
          await dispatch(
            commitNotificationTarget(
              applicationTypes.reduce((prev, current) => ({ ...prev, [current]: selectedAccountIds }), {})
            )
          );
          closeNotificationTargetModal();
          $state({ ...state, activeModal: "" });
        }}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message={"完了時通知先を保存します。よろしいですか？"}
      />
      <ModalDialog
        show={state.activeModal === "before_save_applicant_config"}
        onConfirm={async () => {
          const { applicationType, type, sections } = applicantConfigModal;
          await dispatch(
            commitApplicantConfig({ [applicationType]: { type, sections: type === "self" ? undefined : sections } })
          );
          closeApplicantConfigModal();
          $state({ ...state, activeModal: "" });
        }}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message={"設定します。よろしいですか？"}
      />
      <Modal show={notificationTargetModal.isOpen} onHide={closeNotificationTargetModal} size="lg" centered>
        <Modal.Body>
          <div className="--bold">通知先</div>
          <div className="--font-s">※申請書が完了時に通知されます。申請書の権限がなければ閲覧はできません。</div>
          {notificationTargetModal.mode === "multiple" && (
            <div className="--font-s">※現在の設定がある場合も、上書きされます。</div>
          )}
          <MultipleMemberSelector
            selectedAccountIds={notificationTargetModal.selectedAccountIds}
            onChange={(selectedAccountIds: number[]) =>
              $notificationTargetModal({ ...notificationTargetModal, selectedAccountIds })
            }
          />
          <div className="--bold mt-2">申請書種別</div>
          {notificationTargetModal.mode === "multiple" ? (
            <Card>
              <Card.Body>
                <MultiSelectItem
                  choices={selectableApplicationTypes}
                  onChange={(selected) =>
                    $notificationTargetModal({ ...notificationTargetModal, applicationTypes: selected })
                  }
                />
                {notificationTargetModal.applicationTypes.length === 0 && (
                  <span className="--font-s --text-annotation">一つ以上申請書種別を選択してください</span>
                )}
              </Card.Body>
            </Card>
          ) : (
            filteredSummaries.find(
              ({ application_type }) => application_type === notificationTargetModal.applicationTypes[0]
            )?.name
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeNotificationTargetModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button
            disabled={notificationTargetModal.applicationTypes.length === 0}
            onClick={() => $state({ ...state, activeModal: "before_save_notification_target" })}
          >
            設定
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={applicantConfigModal.isOpen} onHide={closeApplicantConfigModal} size="lg" centered>
        <Modal.Body>
          <div className="--bold mt-2">申請書種別</div>
          {
            filteredSummaries.find(({ application_type }) => application_type === applicantConfigModal.applicationType)
              ?.name
          }
          <div className="--bold mt-2 --required-label">申請権限</div>
          <Form.Select
            value={applicantConfigModal.type}
            onChange={(e) => $applicantConfigModal({ ...applicantConfigModal, type: e.target.value })}
          >
            {["self", "representative", "all"].map((type) => (
              <option key={type} value={type}>
                {TERMS[`APPLICANT_CONFIG_TYPE_${type}`]}
              </option>
            ))}
          </Form.Select>
          {["representative", "all"].includes(applicantConfigModal.type) && (
            <>
              <div className="--bold mt-2 --required-label">代理申請可能な部署</div>
              <SectionSelector
                selectedSectionCodes={applicantConfigModal.sections}
                onChange={(sections) => {
                  $applicantConfigModal({ ...applicantConfigModal, sections });
                }}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeApplicantConfigModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button
            disabled={
              applicantConfigModal.type === "" ||
              (["representative", "all"].includes(applicantConfigModal.type) &&
                applicantConfigModal.sections.length === 0)
            }
            onClick={() => $state({ ...state, activeModal: "before_save_applicant_config" })}
          >
            設定
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ApplicationStatus;
