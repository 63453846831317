import { useEffect, useMemo } from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getFileProjectsAdmin, unselectFileProjects, selectFileState } from "./fileSlice";
import { selectUserState } from "../login/userSlice";
import Table from "../../component/Table";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from "react-router-dom";
import { FILE_PROJECT_DISPLAY_TYPES } from "./fileValues";
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { fileProjects, fileProjectHasMore, fileProjectTotalCount, fileProjectFetchedPage } =
    useAppSelector(selectFileState);
  const { user } = useAppSelector(selectUserState);
  useEffect(() => {
    dispatch(getFileProjectsAdmin({}));
    return () => {
      dispatch(unselectFileProjects());
    };
  }, []);

  const userTimeZone = useMemo(() => {
    return user.current_company.timezone ?? "Asia/Tokyo";
  }, [user]);

  return (
    <Container>
      <Row>
        <Col>
          <Button variant="outline-primary" className="my-1" onClick={() => navigate("/_/files/file_admin/create")}>
            新規作成
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {fileProjects.length === 0 ? (
            <Alert variant="info">ファイルプロジェクトがありません。</Alert>
          ) : (
            <>
              <Table
                col={[
                  {
                    name: "名前",
                    width: "25%",
                  },
                  {
                    name: "公開タイプ",
                    width: "20%",
                  },
                  {
                    name: "通知",
                    width: "10%",
                  },
                  {
                    name: "公開期間",
                    width: "35%",
                  },
                  {
                    name: "ファイル数",
                    width: "10%",
                  },
                ]}
                row={fileProjects.map(({ id, name, start_time, end_time, files, display_type, is_notify }) => {
                  const displyStartTime = dayjs(start_time).tz(userTimeZone).format("YYYY/MM/DD HH:mm");
                  const displyEndTime = dayjs(end_time).tz(userTimeZone).format("YYYY/MM/DD HH:mm");
                  return {
                    data: [
                      name,
                      FILE_PROJECT_DISPLAY_TYPES.find((type) => type.value === display_type)?.label,
                      is_notify ? "する" : "しない",
                      `${displyStartTime} ～ ${displyEndTime}`,
                      files.length,
                    ],
                    link: `/_/files/file_admin/${id}`,
                  };
                })}
              />
              <Button
                variant="outline-secondary"
                className="mt-2 float-end"
                disabled={!fileProjectHasMore}
                onClick={() => dispatch(getFileProjectsAdmin({ page: fileProjectFetchedPage + 1 }))}
              >
                さらに表示（全 {fileProjectTotalCount} 件中 {fileProjects.length} 件表示中）
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
