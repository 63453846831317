import { ESProcessMethod, ESProcess, ESScale, ESSheetLayout, ESWorkflow, ESMethodTag, ESProject } from "../ESTypes";

/*
  評価制度（workspace と呼ぶとして）のダミー
*/
export const dummyMethodTags: ESMethodTag[] = [
  {
    id: "tag_1",
    name: "人事評価（2025年度以降）",
  },
  {
    id: "tag_2",
    name: "人事評価（2024年度以前）",
  },
];

export const dummyESProjects: ESProject[] = [
  {
    project_id: "project_200",
    project_name: "プロジェクト ver1",
  },
];

export const dummyProcesses: ESProcess[] = [
  {
    process_id: "process_100",
    process_name: "2025年度人事評価プロセス",
    project_id: "project_200",
    project_name: "プロジェクト ver1",
    publication_status: true,
    target_group_id: "target_group_1",
    description: "Quarterly performance review for all employees.",
    last_updated_date: "2025-02-27",
    total_todos: 45,
    completed_todos: 17,
    overall_progress_rate: 37.8,
    workflow_phases: [
      {
        phase_name: "目標設定期間",
        total_todos: 15,
        completed_todos: 12,
        progress_rate: 80,
        start_date: "2024-08-01",
        end_date: "2024-08-31",
      },
      {
        phase_name: "中間評価期間",
        total_todos: 10,
        completed_todos: 5,
        progress_rate: 50.0,
        start_date: "2024-10-01",
        end_date: "2024-10-31",
      },
      {
        phase_name: "期末評価期間",
        total_todos: 12,
        completed_todos: 0,
        progress_rate: 0.0,
        start_date: "2025-09-01",
        end_date: "2025-09-30",
      },
      {
        phase_name: "フィードバック期間",
        total_todos: 8,
        completed_todos: 0,
        progress_rate: 0.0,
        start_date: "2025-10-01",
        end_date: "2025-10-31",
      },
    ],
  },
  {
    process_id: "process_101",
    process_name: "2024年度人事評価プロセス",
    project_id: "project_200",
    project_name: "プロジェクト ver1",
    publication_status: true,
    target_group_id: "target_group_1",
    description: "Evaluation for the sales department.",
    last_updated_date: "2025-02-15",
    total_todos: 38,
    completed_todos: 33,
    overall_progress_rate: 86.8,
    workflow_phases: [
      {
        phase_name: "上期評価期間",
        total_todos: 18,
        completed_todos: 18,
        progress_rate: 100.0,
        start_date: "2024-10-01",
        end_date: "2024-10-31",
      },
      {
        phase_name: "下期評価期間",
        total_todos: 20,
        completed_todos: 20,
        progress_rate: 100.0,
        start_date: "2025-03-01",
        end_date: "2025-03-31",
      },
    ],
  },
];

export const dummyProcessMethods: ESProcessMethod[] = [
  {
    method_id: "project_200",
    method_name: "目標管理",
    description:
      "目標管理は、設定した目標の達成度を基準に評価する制度です。期初に目標を設定し、進捗確認を経て期末に評価を行います。成果だけでなく、プロセスや行動も重視されることが多く、適切なフィードバックが従業員の成長を促します。公正な評価により、モチベーション向上や組織の生産性向上につながるのが特徴です。",
    cycle: "6か月",
    configuration_status: "active",
    last_updated: "2025-01-20",
    method_tags: ["tag_1"],
  },
  {
    method_id: "project_201",
    method_name: "行動評価",
    description: "行動評価の説明です。",
    cycle: "年間",
    configuration_status: "active",
    last_updated: "2025-02-25",
    method_tags: ["tag_1", "tag_2"],
  },
];

export const dummyPhaseGroups = [
  {
    id: "1000",
    label: "目標設定期間",
    methodId: "method_200",
    processId: "process_100",
    periodStart: "2025-04-01",
    periodEnd: "2025-05-31",
    status: "active",
    totalTasks: 100,
    doneTasks: 25,
  },
  {
    id: "1001",
    label: "中間評価期間",
    methodId: "method_201",
    processId: "process_100",
    periodStart: "2025-09-01",
    periodEnd: "2025-10-31",
    status: "inactive",
    totalTasks: 100,
    doneTasks: 0,
  },
  {
    id: "1002",
    label: "前期評価期間",
    methodId: "method_201",
    processId: "process_100",
    periodStart: "2025-09-01",
    periodEnd: "2025-10-31",
    status: "inactive",
    totalTasks: 100,
    doneTasks: 0,
  },
];

export const dummyESTodos = [
  {
    id: "todo_1",
    assignee_account_id: 3,
    assignee_name: "大野 麻衣子",
    assignee_image_path: "/_/logo192.png",
    process_id: "process_100",
    process_name: "2025年度人事評価プロセス",
    due_date: "2025-03-15",
    workflow_phase: "phase_0",
    workflow_phase_label: "目標設定期間",
    workflow_step: "step_0",
    workflow_step_label: "目標設定",
    workflow_role: "role_0",
    workflow_role_label: "被評価者",
    status: "in_progress",
    status_label: "進行中",
  },
];

export const dummySheetLayouts: ESSheetLayout[] = [
  {
    sheet_layout_id: "sheet_layout_1",
    sheet_layout_name: "レイアウトA",
    description: "レイアウトAの説明です。",
    last_updated: "2025-03-13",
  },
  {
    sheet_layout_id: "sheet_layout_2",
    sheet_layout_name: "レイアウトB",
    description: "レイアウトBの説明です。",
    last_updated: "2025-03-07",
  },
  {
    sheet_layout_id: "sheet_layout_3",
    sheet_layout_name: "レイアウトC",
    description: "レイアウトCの説明です。",
    last_updated: "2025-03-04",
  },
];

export const dummyScales: ESScale[] = [
  {
    scale_id: "scale_1",
    scale_name: "評価スケールA",
    last_updated: "2025-03-13",
    condition_groups: [
      {
        condition_group_id: "condition_group_10",
        condition_group_name: "職種",
        conditions: [
          {
            attribute_name: "営業職",
            attribute_value: "A1",
          },
          {
            attribute_name: "属性B",
            attribute_value: "B1",
          },
        ],
      },
      {
        condition_group_id: "condition_group_11",
        condition_group_name: "条件グループ2",
        conditions: [
          {
            attribute_name: "属性A",
            attribute_value: "値A2",
          },
          {
            attribute_name: "属性B",
            attribute_value: "値B2",
          },
        ],
      },
    ],
  },
];

export const dummyWorkflows: ESWorkflow[] = [
  {
    workflow_id: "workflow_1",
    workflow_name: "人事評価ワークフロー",
    last_updated: "2025-03-31",
    description:
      "ワークフローとは、業務の手順や流れを定めたプロセスのことです。業務の効率化や標準化を目的とし、タスクの順序や担当者を明確にすることでスムーズな進行を促します。紙やデジタルツールを活用した承認フローや自動化システムなどが一般的です。適切なワークフローの設計により、業務のミス削減や生産性向上が期待できます。",
    phases: [
      {
        phase_id: "phase_10",
        phase_name: "フェーズA",
        phase_order: 1,
        steps: [
          {
            step_id: "step_100",
            step_name: "ステップA-1",
            step_order: 1,
            roles: [
              {
                role_id: "role_1000",
                role_name: "ロールX",
                role_order: 1,
              },
              {
                role_id: "role_1001",
                role_name: "ロールY",
                role_order: 2,
              },
            ],
          },
          {
            step_id: "step_101",
            step_name: "ステップA-2",
            step_order: 1,
            roles: [
              {
                role_id: "role_1002",
                role_name: "ロールZ",
                role_order: 1,
              },
            ],
          },
        ],
      },
      {
        phase_id: "phase_11",
        phase_name: "フェーズB",
        phase_order: 2,
        steps: [
          {
            step_id: "step_102",
            step_name: "ステップB-1",
            step_order: 1,
            roles: [
              {
                role_id: "role_1003",
                role_name: "ロールW",
                role_order: 1,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const dummyESMethods = [
  {
    method: "method_1",
    method_name: "評価方法A",
    workflow_id: "workflow_1",
    sheet_layout_id: "sheet_layout_1",
    description: "評価方法Aの説明です。",
    cycle: "年間",
    configuration_status: "有効",
    last_updated: "2025-03-13",
    included_systems: [
      {
        system_id: "system_10",
        system_name: "制度X",
      },
      {
        system_id: "system_11",
        system_name: "制度Y",
      },
    ],
  },
];
