import { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { testResponse, trimSpaces } from "../../app/util";
import { useAppSelector, useAppDispatch, ASSET_PATH } from "../../app/store";
import { sendPasswordResetMail, selectUserState } from "../../features/login/userSlice";
import { Alert, Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function InitPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loginState = useAppSelector(selectUserState);
  const [state, $state] = useState({
    companyCode: "",
    loginCode: "",
    processing: false,
    errorMessage: "",
    isMailSent: false,
  });

  const isReady = useMemo(() => {
    return !!(state.companyCode && state.loginCode);
  }, [state]);

  const submit = useCallback(async () => {
    if (state.processing) return;
    $state({ ...state, processing: true });
    const reject = () => $state({ ...state, errorMessage: "処理中にエラーが発生しました。" });
    const result = testResponse(
      await dispatch(
        sendPasswordResetMail({
          companyCode: state.companyCode,
          loginCode: state.loginCode,
          language: navigator.language.indexOf("ja") !== 0 ? "en" : "ja",
        })
      )
    );
    if (!result) return reject();

    $state({ ...state, isMailSent: true, processing: false, errorMessage: "" });
  }, [dispatch, state, loginState.processing, navigate]);

  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && isReady && !state.processing) submit();
    };
    window.addEventListener("keydown", keydown);
    return () => {
      window.removeEventListener("keydown", keydown);
    };
  }, [state, loginState, isReady, submit]); // [] に渡さないと、次回関数コンポーネントが実行されたとき古いほうが参照されてしまう。

  return (
    <div className="Centered">
      <div className="Centered__content">
        <figure className="Centered__logo">
          <img src={`${ASSET_PATH}/login-logo-common.png`} alt="SHRPA" />
        </figure>
        <Container fluid="md" className="Grouping px-4">
          <Row className="mb-4">
            <Col>
              <h2 className="Centered__header">パスワード再設定</h2>
            </Col>
          </Row>
          <Row>
            <Form>
              <Form.Group className="mb-3" controlId="companyCode">
                <Form.Control
                  value={state.companyCode}
                  onChange={(e) => $state({ ...state, companyCode: e.target.value })}
                  type="text"
                  placeholder="企業コード"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="loginCode">
                <Form.Control
                  value={state.loginCode}
                  onChange={(e) => $state({ ...state, loginCode: trimSpaces(e.target.value) })}
                  type="text"
                  autoComplete="username"
                  placeholder="ログインID"
                />
              </Form.Group>

              {state.errorMessage && (
                <Alert variant="danger" className="my-3">
                  {state.errorMessage}
                </Alert>
              )}
              {state.isMailSent && (
                <Alert variant="info" className="my-3">
                  パスワード再設定用のリンクをご登録中のメールアドレスにお送りしました。メールに記載されたリンクをクリックしてパスワードを再設定してください。
                </Alert>
              )}

              <div className="d-grid gap-2">
                {!state.isMailSent && (
                  <Button className="mt-4" variant="primary" disabled={!isReady} onClick={submit}>
                    {loginState.processing && (
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    )}
                    <span className="pl-2">パスワード再設定用メールを送る</span>
                  </Button>
                )}
                <Link to="/" className="Anchor-link__item--label">
                  ログイン画面へ戻る
                </Link>
              </div>
            </Form>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default InitPassword;
