import { EvaluationProject } from "../EvaluationTypes";
import dayjs from "dayjs";

const hyoka_project_nakagawa: EvaluationProject = {
  id: "hyoka_project_nakagawa",
  systemId: "hyoka_nakagawa",
  groupId: "group_2",
  label: "2024年度中川さんのプロジェクト",
  status: "waiting",
  created_at: dayjs().add(-12, "day").unix(),
  updated_at: dayjs().add(-8, "day").unix(),
};

export const dummyProjects = [hyoka_project_nakagawa];
