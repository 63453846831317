import { useState, useEffect, useMemo } from "react";
import { Row, Col, Accordion, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Account, ACCOUNT_SEARCH_LIMIT, Member } from "./profileValues";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { clearFilteredAccounts, searchAccounts, selectProfileState } from "./profileSlice";

function MemberSelector({
  accounts,
  selectedAccountId,
  onSelectedAccountChange,
  className,
}: {
  accounts: Account[];
  selectedAccountId?: number;
  onSelectedAccountChange: (id: number) => any;
  className?: string;
}) {
  const dispatch = useAppDispatch();
  const [state, $state] = useState({
    keyword: "",
    checkedsectionCodes: [] as string[],
    timeoutId: null as number | null,
  });

  const [candidates, $candidates] = useState(accounts.filter((_, i) => i < ACCOUNT_SEARCH_LIMIT));
  const checkedAccount = useMemo(() => {
    return accounts.find((t) => selectedAccountId === t.id);
  }, [selectedAccountId, accounts]);
  /*
  const selectSection = (id: string) => {
    let next = [...state.checkedsectionCodes];
    if (next.includes(id)) {
      next = next.filter((_) => _ !== id);
    } else {
      next = [...next, id];
    }
    $state({ ...state, checkedsectionCodes: next });
  };

  */
  const onChangeKeyWord = (keyword: string) => {
    // 打ち終わって500ms後に検索のリクエストをする
    if (state.timeoutId) {
      window.clearTimeout(state.timeoutId);
    }
    const timeoutId = window.setTimeout(() => {
      $candidates(accounts.filter((m) => m.name.includes(keyword)).filter((_, i) => i < ACCOUNT_SEARCH_LIMIT));
      $state({ ...state, timeoutId: null, keyword });
    }, 500);
    $state({ ...state, timeoutId, keyword });
  };

  //   const checkedAccount = useMemo(() => {
  //     return filteredAccounts.find((t) => selectedAccountId === t.id);
  //   }, [filteredAccounts, selectedAccountId]);

  //   useEffect(() => {
  //     if (selectedAccountId && filteredAccounts.find((a) => a.id === selectedAccountId)) {
  //       onSelectedAccountChange(selectedAccountId);
  //     }
  //   }, [selectedAccountId, filteredAccounts]);

  return (
    <Accordion className={className ?? ""}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{checkedAccount ? checkedAccount.name : "（未選択）"}</Accordion.Header>
        <Accordion.Body>
          <Row className="mb-2">
            {/* <Col md="4">
              <div className="--bold mb-2">部署</div>
              {sections.map((s) => (
                <Form.Check
                  key={s.id}
                  checked={state.checkedsectionCodes.includes(`${s.id}`)}
                  id={`${s.id}`}
                  type="checkbox"
                  label={s.name}
                  onChange={() => selectSection(`${s.id}`)}
                />
              ))}
            </Col> */}
            <Col md="12" className="mt-4 mt-md-0">
              <Form.Control
                type="text"
                id="search"
                value={state.keyword}
                className="mb-2"
                placeholder="キーワード（名前・ログインID）で絞り込む"
                onChange={(e) => {
                  onChangeKeyWord(e.target.value);
                }}
              />
              <Row>
                {candidates.map((t) => (
                  <Col md="6" key={t.id}>
                    <Form.Check
                      checked={selectedAccountId === t.id}
                      id={`${t.id}`}
                      type="radio"
                      label={`${t.name}（${t.loginCode}）${!t.isActive ? "（無効）" : ""}`}
                      onChange={() => onSelectedAccountChange(t.id)}
                    />
                  </Col>
                ))}
                {candidates.length === ACCOUNT_SEARCH_LIMIT && (
                  <Col>{`${ACCOUNT_SEARCH_LIMIT}名以上存在します。選択したいアカウントが表示されていない場合はキーワードを変更してください。`}</Col>
                )}
                {candidates.length === 0 && <Col>該当するアカウントが存在しません。キーワードを変更してください。</Col>}
              </Row>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default MemberSelector;
