import dayjs from "dayjs";
import { EvaluationPhaseGroupTemplate, EvaluationPhaseTemplate } from "../EvaluationTypes";

export const headerList_input1 = [
  { factors: ["目標項目（テーマ）"], axis: "theme", relatedPhase: "phase_key_0", type: "text", isAnswer: true },
  {
    factors: ["ウェイト"],
    axis: "weight",
    relatedPhase: "phase_key_0",
    type: "select",
    helper: "stepValues?min=0&max=100&step=5",
  }, // 10段階の選択式の回答を必要とする
  {
    factors: ["目標及びプロセス等【「何を」「どのレベルまで（定量・定性）」「どのようなやり方で」】"],
    axis: "process",
    relatedPhase: "phase_key_0",
    type: "text",
    isAnswer: true,
  },
  {
    factors: ["期末レビュー", "本人コメント"],
    axis: "self_comment",
    relatedPhase: "phase_key_1",
    type: "text",
    isAnswer: true,
  },
  {
    factors: ["期末レビュー", "一次評価者コメント"],
    axis: "phase1_comment",
    relatedPhase: "phase_key_2",
    type: "text",
    isAnswer: true,
  },
  {
    factors: ["評価", "自己評価"],
    axis: "self_score",
    relatedPhase: "phase_key_1",
    type: "select",
    helper: "stepValues?min=1&max=5",
  },
  {
    factors: ["評価", "一次評価"],
    axis: "phase1_score",
    relatedPhase: "phase_key_2",
    type: "select",
    helper: "stepValues?min=1&max=5",
  },
  // weight を%にして評価・一次評価とかける
  {
    factors: ["評価", "評点"],
    axis: "phase2_score",
    relatedPhase: "phase_key_2",
    type: "computed",
    helper: "computingRule?model=A&weight=ウェイト&value=評価,一次評価",
    isAnswer: true,
  },
];

export const headerList_input3 = [
  {
    factors: ["評価", "評価点"],
    axis: "raw_score",
    relatedPhase: "phase_key_1",
    type: "select",
    helper: "stepValues?min=1&max=5",
  },
  {
    factors: ["評価", "平均"],
    axis: "average",
    relatedPhase: "phase_key_1",
    type: "computed",
    helper: "computingRule?model=B&value=評価,評価点",
  },
  {
    factors: ["評価", "ウェイト"],
    axis: "weight",
    relatedPhase: "phase_key_1",
    type: "select",
    helper: "stepValues?min=0&max=100&step=5",
  },
  {
    factors: ["評価", "評点"],
    axis: "score",
    relatedPhase: "phase_key_1",
    type: "computed",
    helper: "computingRule?model=A&weight=評価,ウェイト&value=評価,平均",
    isAnswer: true,
  },
];

export const headerList_input4 = [
  {
    factors: ["評価", "評価点"],
    axis: "raw_score",
    relatedPhase: "phase_key_1",
    type: "select",
    helper: "stepValues?min=1&max=3",
  },
  {
    factors: ["評価", "平均"],
    axis: "average",
    relatedPhase: "phase_key_1",
    type: "computed",
    helper: "computingRule?model=B&value=評価,評価点",
  },
  {
    factors: ["評価", "評点"],
    axis: "score",
    relatedPhase: "phase_key_1",
    type: "computed",
    helper: "computingRule?model=A&value=評価,平均",
    isAnswer: true,
  },
];

export const behaviorMapTemplate_nakagawa = {
  phase_key_0_input: {
    label: "目標設定期間 - 目標設定で使うセル",
    rules: {
      phase_key_0: "default",
      phase_key_1: "locked",
      phase_key_2: "locked",
      phase_key_3: "locked",
    },
  },
  phase_key_1_input: {
    label: "目標設定期間 - 目標承認で使うセル",
    rules: {
      phase_key_0: "veiled",
      phase_key_1: "default",
      phase_key_2: "locked",
      phase_key_3: "locked",
    },
  },
  phase_key_2_input: {
    label: "中間評価期間 - 中間評価（本人）で使うセル",
    rules: {
      phase_key_0: "veiled",
      phase_key_1: "veiled",
      phase_key_2: "default",
      phase_key_3: "locked",
    },
  },
  phase_key_3_input: {
    label: "中間評価期間 - 中間評価（一次評価）で使うセル",
    rules: {
      phase_key_0: "veiled",
      phase_key_1: "veiled",
      phase_key_2: "veiled",
      phase_key_3: "default",
    },
  },
  hidden: {
    label: "常に非表示",
    rules: {
      phase_key_0: "hidden",
      phase_key_1: "hidden",
      phase_key_2: "hidden",
      phase_key_3: "hidden",
    },
  },
  DEFAULT_BEHAVIOR: {
    label: "デフォルト",
    rules: {
      phase_key_0: "default",
      phase_key_1: "default",
      phase_key_2: "default",
      phase_key_3: "default",
    },
  },
};

export const phaseTemplates = [
  {
    key: "phase_key_0",
    label: "目標設定",
    phaseGroupKey: "phase_group_key_0",
  },
  {
    key: "phase_key_1",
    label: "目標承認",
    phaseGroupKey: "phase_group_key_0",
  },
  {
    key: "phase_key_2",
    label: "中間評価（本人）",
    phaseGroupKey: "phase_group_key_1",
  },
  {
    key: "phase_key_3",
    label: "中間評価（一次評価）",
    phaseGroupKey: "phase_group_key_1",
  },
] as EvaluationPhaseTemplate[];

export const textList = [
  "姿勢・執務態度,創造,チャレンジ精神,新しい仕事、未経験の仕事でも“まずはやってみよう”とする",
  "姿勢・執務態度,創造,チャレンジ精神,２つめの項目",
  "姿勢・執務態度,創造,主体性,自分で考え、積極的に行動している",
  "姿勢・執務態度,和,多様性の許容,自分と異なる価値観、性格、考え、意見であっても相手を尊重する",
  "姿勢・執務態度,和,協調性,チームの和を乱すような言動、行動をとらない",
  "姿勢・執務態度,誠実,信頼,言動・行動が上司、同僚、顧客から信頼を得ている",
  "姿勢・執務態度,誠実,真摯,素直さ、謙虚さを忘れず、上司・先輩・顧客の話しを聴く",
  "業務遂行力,上司の補佐,報告・連絡・相談,上司への報連相を適切に実施している",
  "業務遂行力,上司の補佐,状況判断,状況を的確に把握し、タイムリーに上司や同僚に相談している",
  "業務遂行力,組織運営,方針および目標,方針及び目標を理解し、それに沿って仕事をしている",
  "業務遂行力,組織運営,リーダーシップ,整理整頓（５S）、自己管理ができている",
  "業務遂行力,組織運営,課題解決,担当業務に関わる課題を考え、課長（次長）へ提言し、解決に向けて工夫している",
  "業務遂行力,組織運営,部門間連携,わからないことは部門を超えた相手でも質問・相談している",
  "業務遂行力,人材育成,人材育成,能動的な自己啓発、能力開発に努めている",
  "業務遂行力,人材育成,後進指導・動機付け,後進の指導、相談対応に取り組んでいる",
  "業務遂行力,ステークホルダーの対応,実施,ステークホルダーに関わる方針を実行している",
  "成果,目標成果管理（上半期）",
  "成果,目標成果管理（下半期）",
].map((value) => ({ value }));
