import { EvaluationComputingRuleSet, UnitTemplate } from "../EvaluationTypes";
import { readHelperQuery } from "../evaluationValues";

const __computingRule_A = ({
  query,
  units,
  computedUnit,
}: {
  query: string;
  units: UnitTemplate[];
  computedUnit: UnitTemplate;
}): { computedKey: string; data: EvaluationComputingRuleSet; unit: UnitTemplate } => {
  const { method, params } = readHelperQuery(query);
  if (method !== "computingRule" && params.model !== "A") {
    throw new Error("uncorresponding method or model");
  } else if (!units?.length) {
    throw new Error("units are required");
  }
  const factors1 = params.value.split(",").filter((_) => _);
  const factors2 = params.weight?.split(",").filter((_) => _) ?? [];
  const order = params.order ? +params.order : 0;
  const operandUnit1 = units.filter(
    (unit) => unit.factorUnitIds && factors1.every((_) => unit.factorUnitIds?.includes(_))
  )[order];
  const operandUnit2 = units.filter(
    (unit) => unit.factorUnitIds && factors2.every((_) => unit.factorUnitIds?.includes(_))
  )[order];
  const computedKey = `${computedUnit.id}`;
  const updatedUnit = {
    ...computedUnit,
    conditions: [
      {
        computedKey,
        value: "$$RESULT",
      },
      {
        computedKey: "",
        value: "",
      },
    ],
  };

  return {
    computedKey,
    unit: updatedUnit,
    data: {
      label: `computed - ${units[0].id}`,
      rules:
        operandUnit1 && operandUnit2
          ? [
              {
                operands: [(operandUnit1.type === "computed" ? "COMPUTED$$" : "") + (operandUnit1.id ?? "")],
                method: "ASSIGN",
              },
              {
                operands: [(operandUnit2.type === "computed" ? "COMPUTED$$" : "") + (operandUnit2.id ?? "")],
                method: "ASSIGN",
              },
              {
                operands: ["$$0"],
                method: "PERCENT",
                subOperands: ["$$1"],
              },
            ]
          : [],
    },
  };
};
export default __computingRule_A;
