import { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button, Form, Alert, Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  getSectors,
  getRegularColumns,
  getDefaultLabelColumns,
  getRenamedColumns,
  getColumnStatus,
  commitRenamedColumns,
  commitColumnStatus,
  selectClientState,
  uploadRenamedColumns,
  downloadRenamedColumns,
} from "./clientSlice";
import ModalDialog from "../../component/ModalDialog";
import { useParams } from "react-router-dom";
import Uploader, { DecodedFileData } from "../../component/Uploader";

function RenameColumnList() {
  const { target } = useParams();
  const { sectors, sectorRegularColumns, renamedColumns, defaultLabelColumns, columnStatus } =
    useAppSelector(selectClientState);
  const dispatch = useAppDispatch();
  const [state, $state] = useState({
    sectorId: target ?? "",
    inputtedColumns: {} as { [column: string]: string },
    inputtedColumnStatus: {} as { [column: string]: boolean },
    switchingSectorId: "",
    activeModal: "",
    isUploading: false,
  });

  const targetSector = useMemo(() => {
    if (!state.sectorId) return [];
    return sectors.filter((s) => s.sector_id === state.sectorId);
  }, [state.sectorId, sectors]);

  const renameTargetSectors = useMemo(() => {
    return sectors.filter(({ has_rename_targets }) => has_rename_targets);
  }, [sectors]);

  useEffect(() => {
    dispatch(getRegularColumns({ sectorId: state.sectorId }));
    dispatch(getRenamedColumns());
    dispatch(getColumnStatus());
    dispatch(getSectors());
  }, []);

  useEffect(() => {
    const isValidSectorId = sectors?.some((s) => s.sector_id === state.sectorId);
    if (isValidSectorId) {
      window.history.replaceState({}, "", `/_/masters/fields/edit/${state.sectorId}`);
      if (!defaultLabelColumns[state.sectorId]) {
        dispatch(getDefaultLabelColumns({ sectorId: state.sectorId }));
      }
    } else {
      window.history.replaceState({}, "", `/_/masters/fields`);
    }
  }, [state.sectorId, sectors]);

  const onFileLoad = async (decodedFileData: DecodedFileData) => {
    $state({ ...state, isUploading: true });
    try {
      await dispatch(
        uploadRenamedColumns({
          sector: state.sectorId,
          file: decodedFileData.dataURI,
          name: decodedFileData.name,
        })
      );
    } catch (error) {
    } finally {
      $state({ ...state, isUploading: false });
    }
  };
  const download = async () => {
    await dispatch(downloadRenamedColumns({ sector: state.sectorId }));
  };

  const defaultIdLabels = useMemo(() => {
    return defaultLabelColumns[state.sectorId]?.reduce(
      (prev, { id, label }) => ({ ...prev, [id]: label }),
      {} as { [column: string]: string }
    );
  }, [state.sectorId, defaultLabelColumns]);

  const duplicatedValues = useMemo(() => {
    const currentIdLabels = {
      ...defaultIdLabels,
      ...renamedColumns[state.sectorId],
      ...Object.keys(state.inputtedColumns).reduce((prev, current) => {
        if (state.inputtedColumns[current] === "") return { ...prev, [current]: defaultIdLabels[current] };
        return { ...prev, [current]: state.inputtedColumns[current] };
      }, {}),
    };
    return Object.values(currentIdLabels).filter((label, i, array) => label !== "" && !(array.indexOf(label) === i));
  }, [state, defaultIdLabels]);

  useEffect(() => {
    if (!state.sectorId) return;
    if (!defaultIdLabels) return;
    // defaultIdLabelsがsectorRegularColumnsよりも先に取得されると、
    // updatedStatus[column]が必ずfalseになり、初期表示が全てfalseになってしまう。
    // sectorRegularColumnsが未取得の場合は抜けるように修正
    if (!sectorRegularColumns[state.sectorId]) return;
    $state({
      ...state,
      inputtedColumnStatus: Object.keys(defaultIdLabels).reduce((updatedStatus: { [key: string]: boolean }, column) => {
        updatedStatus[column] =
          state.inputtedColumnStatus?.[column] !== undefined
            ? state.inputtedColumnStatus[column] // 画面上で変更があればその値をセット
            : sectorRegularColumns[state.sectorId]?.some((c) => c.id === column) // 画面上で変更がなければ、現在の利用状況をセット
            ? true
            : false;
        return updatedStatus;
      }, {}),
    });
  }, [state.sectorId, defaultIdLabels, sectorRegularColumns]);

  const matchedSector: any = useMemo(() => {
    const matchedSector = renameTargetSectors.find(({ sector_id }) => sector_id === state.sectorId);
    if (matchedSector && matchedSector.sector_id === "profile_u_attendance") {
      return {
        ...matchedSector,
        categoryTypes: [{ label: "日数・時間", categoryType: "days_times" }],
      };
    } else if (
      matchedSector &&
      (matchedSector.sector_id === "profile_u_salary" || matchedSector?.sector_id === "profile_u_bonus")
    ) {
      return {
        ...matchedSector,
        categoryTypes: [
          { label: "支給", categoryType: "payments" },
          { label: "控除", categoryType: "deductions" },
          { label: "その他", categoryType: "others" },
          { label: "備考", categoryType: "remarks" },
        ],
      };
    }
    return matchedSector;
  }, [renameTargetSectors, state.sectorId]);

  if (!targetSector[0]?.logical_name) {
    return <Container className="mt-4"></Container>;
  }

  return (
    <Container>
      {state.sectorId !== "" && (
        <div className="pt-4">
          <h2 className="Headline--section mb-2">{targetSector[0]?.logical_name}の項目設定</h2>
          <div className="my-2">
            {state.isUploading ? (
              <Alert variant={"info"}>リクエストを受け付けました。処理完了までしばらくお待ちください。</Alert>
            ) : (
              <Uploader
                onFileLoad={onFileLoad}
                accepts={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
              />
            )}
          </div>
          <Button className="mb-2 mx-2" variant="outline-secondary" onClick={download}>
            ダウンロード
          </Button>
          {matchedSector && matchedSector.categoryTypes?.length > 0 ? (
            <>
              {(() => {
                if (matchedSector.sector_id === "profile_u_attendance") {
                  return (
                    <Alert variant="info">
                      項目名や表示設定を変更した場合、給与明細にも変更が反映されます。
                      <br />
                      ※ただし、出力対象でもデータが空欄の場合は出力されません。
                    </Alert>
                  );
                } else if (matchedSector.sector_id === "profile_u_salary") {
                  return (
                    <Alert variant="info">
                      項目名を変更した場合、給与明細にも変更が反映されます。
                      <br />
                      ※ただし、出力対象でもデータが空欄の場合は出力されません。
                    </Alert>
                  );
                } else if (matchedSector.sector_id === "profile_u_bonus") {
                  return (
                    <Alert variant="info">
                      項目名を変更した場合、賞与明細にも変更が反映されます。
                      <br />
                      ※ただし、出力対象でもデータが空欄の場合は出力されません。
                    </Alert>
                  );
                }
              })()}
              <Accordion defaultActiveKey="" className="mb-3">
                {matchedSector.categoryTypes.map((c: any) => {
                  return (
                    <Accordion.Item eventKey={c.categoryType} key={c.categoryType}>
                      <Accordion.Header>{c.label}</Accordion.Header>
                      <Accordion.Body>
                        {defaultLabelColumns[state.sectorId]
                          ?.filter((column) => column.rename_target && c.categoryType.includes(column.category_type))
                          ?.map(({ id, label }, i) => {
                            const inputted = state.inputtedColumns[id]; // いま入力している値
                            const renamed = renamedColumns[state.sectorId]?.[id]; // DBに設定している値
                            const value = inputted ?? renamed ?? "";
                            const isActive = state.inputtedColumnStatus[id] ?? false;
                            return (
                              <Row key={`${i}`} className="mb-2">
                                <Col md={10}>
                                  <Form.Label className="--bold">{label}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder={label}
                                    value={value}
                                    disabled={!isActive}
                                    onChange={(e) => {
                                      $state({
                                        ...state,
                                        inputtedColumns: { ...state.inputtedColumns, [id]: e.target.value },
                                      });
                                    }}
                                  />
                                  {duplicatedValues.includes(value) ? (
                                    <span className="--text-annotation mt-1 --font-s">
                                      他の項目名と重複するため設定できません。
                                    </span>
                                  ) : null}
                                </Col>
                                <Col md={1}>
                                  <Form.Label className="--bold">表示</Form.Label>
                                  <Form.Check
                                    type="switch"
                                    checked={isActive}
                                    disabled={false} // 他テーブルに展開する際に制御を追加する
                                    onChange={(e) => {
                                      $state({
                                        ...state,
                                        inputtedColumnStatus: {
                                          ...state.inputtedColumnStatus,
                                          [id]: e.target.checked,
                                        },
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </>
          ) : (
            <>
              {defaultLabelColumns[state.sectorId]
                ?.filter(({ id }) => !["valid_from", "valid_to", "login_code", "name"].includes(id))
                ?.map(({ id, label, rename_target }, i) => {
                  const inputted = state.inputtedColumns[id]; // いま入力している値
                  const renamed = renamedColumns[state.sectorId]?.[id]; // DBに設定している値
                  const value = inputted ?? renamed ?? "";
                  const isActive = state.inputtedColumnStatus[id] ?? false;
                  const displayEditable = defaultLabelColumns[state.sectorId].some(
                    (c) => c.id === id && c.display_editable
                  );
                  return (
                    <Row key={`${i}`} className="mb-2">
                      <Col md={10}>
                        <Form.Label className="--bold">{label}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={label}
                          value={value}
                          disabled={!isActive || !rename_target}
                          onChange={(e) => {
                            $state({
                              ...state,
                              inputtedColumns: { ...state.inputtedColumns, [id]: e.target.value },
                            });
                          }}
                        />
                        {duplicatedValues.includes(value) ? (
                          <span className="--text-annotation mt-1 --font-s">
                            他の項目名と重複するため設定できません。
                          </span>
                        ) : null}
                      </Col>
                      <Col md={1}>
                        <Form.Label className="--bold">表示</Form.Label>
                        <Form.Check
                          type="switch"
                          checked={isActive}
                          disabled={!displayEditable}
                          onChange={(e) => {
                            $state({
                              ...state,
                              inputtedColumnStatus: { ...state.inputtedColumnStatus, [id]: e.target.checked },
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  );
                })}
            </>
          )}
          <Button onClick={() => $state({ ...state, activeModal: "commit" })} disabled={duplicatedValues.length > 0}>
            保存
          </Button>
        </div>
      )}

      <ModalDialog
        show={state.activeModal === "commit"}
        message="保存します。よろしいですか？"
        onConfirm={() => {
          const renameColumns = { ...renamedColumns[state.sectorId], ...state.inputtedColumns };
          const status = { ...columnStatus[state.sectorId], ...state.inputtedColumnStatus };
          const actions = [];
          if (matchedSector) {
            actions.push(
              dispatch(
                commitRenamedColumns({
                  sectorId: state.sectorId,
                  renameColumns: {
                    ...Object.keys(renameColumns).reduce((prev, current) => {
                      return { ...prev, [current]: renameColumns[current] };
                    }, {}),
                  },
                })
              )
            );
          }
          actions.push(
            dispatch(
              commitColumnStatus({
                sectorId: state.sectorId,
                columnStatus: {
                  ...Object.keys(status).reduce((prev, current) => {
                    return { ...prev, [current]: status[current] };
                  }, {}),
                },
              })
            )
          );
          Promise.all(actions).then(() => {
            dispatch(getRegularColumns({ sectorId: state.sectorId }));
          });
          $state({ ...state, activeModal: "", inputtedColumns: {} });
        }}
        onCancel={() => {
          $state({ ...state, activeModal: "" });
        }}
      />
      <ModalDialog
        show={state.activeModal === "switch"}
        message="入力済の内容は破棄されますが、切り替えてよろしいですか？"
        onConfirm={() => {
          $state({
            ...state,
            sectorId: state.switchingSectorId,
            inputtedColumns: {},
            activeModal: "",
            switchingSectorId: "",
          });
        }}
        onCancel={() => {
          $state({ ...state, switchingSectorId: "", activeModal: "" });
        }}
        type="destructiveConfirm"
        confirmButtonName="破棄して切替"
      />
    </Container>
  );
}

export default RenameColumnList;
