import { useAppDispatch, useAppSelector, ASSET_PATH } from "../../app/store";
import { Link } from "react-router-dom";
import {
  selectHasPostMasterPermissions,
  selectHasPostUserPermissions,
  selectHasPostTemplatePermissions,
  selectHasPostAcountAdminPermissions,
  selectHasGetUserPermissions,
} from "../../features/login/userSlice";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../component/Sidebar";
import dayjs from "dayjs";
import { selectTodoState, getTodos } from "../todo/todoSlice";
import { selectUserState } from "../../features/login/userSlice";
import { getUserReportTemplates, selectReportState } from "../report/reportSlice";
import { selectApplyState, getTodoFormCounts } from "../../features/apply/applySlice";
import { MY_NUMBER_SERVICE_ID } from "../../features/myAccount/myAccountValues";
import { clearMyNumberTodoCount, getMyNumberTodoCount, selectMyNumberState } from "../myNumber/myNumberSlice";

function App() {
  const dispatch = useAppDispatch();
  const hasPostMasterPermissions = useAppSelector(selectHasPostMasterPermissions);
  const hasPostTemplatePermissiont = useAppSelector(selectHasPostTemplatePermissions);
  const hasPostAccountAdminPermissions = useAppSelector(selectHasPostAcountAdminPermissions);
  const hasPostUserPermissions = useAppSelector(selectHasPostUserPermissions);
  const hasGetUserPermissions = useAppSelector(selectHasGetUserPermissions);
  const { todoCount } = useAppSelector(selectTodoState);
  const { policies, user } = useAppSelector(selectUserState);
  const { userInitialized, userReportTemplates } = useAppSelector(selectReportState);
  const { reviewingFormsLength } = useAppSelector(selectApplyState);
  const { myNumberTodoCount } = useAppSelector(selectMyNumberState);
  const news = useMemo(() => {
    const today = dayjs();
    const recent25th = dayjs().date(25);
    if (today.date() < 25) {
      recent25th.subtract(1, "month");
    }
    return hasPostMasterPermissions
      ? [
          {
            label: "メンテナンス情報・お知らせ",
            tag: "system",
            date: dayjs(),
            isNew: false,
            link: "https://fosterlink.atlassian.net/wiki/spaces/SHRPAPROFILE/pages/3480649902",
          },
          {
            label: "管理者機能ガイド",
            tag: "system",
            date: dayjs(),
            isNew: false,
            link: "https://fosterlink.atlassian.net/wiki/spaces/SHRPAPROFILE/pages/3480748242/SHRPA",
          },
          // {
          //   label: "新しい機能が追加されました",
          //   tag: "system",
          //   date: dayjs(),
          //   isNew: true,
          //   link: "https://fosterlink.atlassian.net/wiki/spaces/GUIDES/pages/2612428801/HR-Platform+SHRPA",
          // },
          // {
          //   label: `${recent25th.format("YYYY年")}${recent25th.format("MM月DD日")}支給分 給与明細が発行されました`,
          //   tag: "event",
          //   date: recent25th,
          //   isNew: false,
          //   link: "/_/report/",
          // },
        ]
      : [
          {
            label: "メンテナンス情報・お知らせ",
            tag: "system",
            date: dayjs(),
            isNew: false,
            link: "https://fosterlink.atlassian.net/wiki/spaces/SHRPAPROFILE/pages/3480649902",
          },
          {
            label: "ユーザ機能ガイド",
            tag: "system",
            date: dayjs(),
            isNew: false,
            link: "https://fosterlink.atlassian.net/wiki/spaces/SHRPAPROFILE/pages/3480944845/SHRPA",
          },
        ];
  }, [hasPostMasterPermissions]);
  const dailyTasks = useMemo(() => {
    return hasPostMasterPermissions
      ? [
          {
            label: "入社",
            to: "/_/dashboard/detail/#entry_to_company",
          },
          // 2024/1～：ステージング環境で検証していただくため非表示
          // {
          //   label: "退職",
          // },
          // {
          //   label: "転居",
          // },
          {
            label: "異動・昇進",
            to: "/_/dashboard/detail/#job_transfer",
          },
          {
            label: "退職",
            to: "/_/dashboard/detail/#resign_from_company",
          },
          // {
          //   label: "扶養家族変更",
          // },
          // {
          //   label: "書類出力",
          // },
        ]
      : [
          // {
          //   label: "住所変更申請",
          //   to: "/_/apply/create/address/",
          // },
          // {
          //   label: "書類出力",
          // },
        ];
  }, [hasPostMasterPermissions]);
  const monthlyTasks = useMemo(() => {
    return hasPostMasterPermissions
      ? [
          // 2024/1～：ステージング環境で検証していただくため非表示
          // {
          //   label: "住民税額の更新",
          //   description: "7/10 までに従業員情報を更新しましょう",
          // },
        ]
      : [];
  }, [hasPostMasterPermissions]);
  const applicationTasks = useMemo(() => {
    return hasPostMasterPermissions
      ? []
      : [
          {
            label: "申請書",
            to: "/_/apply/",
          },
        ];
  }, [hasPostMasterPermissions]);
  const systemTasks = useMemo(() => {
    const tasks = [];
    hasPostAccountAdminPermissions &&
      tasks.push({
        label: "アカウント",
        to: "/_/account/",
      });
    hasPostTemplatePermissiont &&
      tasks.push({
        label: "システム設定",
        to: "/_/masters/",
      });
    // 2024/1～：ステージング環境で検証していただくため非表示
    // tasks.push({
    //   label: "ご契約中のプラン",
    // });
    return tasks;
  }, [hasPostAccountAdminPermissions, hasPostTemplatePermissiont]);
  const profileTasks = useMemo(() => {
    return hasGetUserPermissions
      ? [
          {
            label: "従業員情報（一覧）",
            to: "/_/profiles/",
          },
          {
            label: "従業員情報（個別）",
            to: "/_/profile/",
          },
          // 2024/1～：ステージング環境で検証していただくため非表示
          // {
          //   label: "統計データ",
          // },
        ]
      : [];
  }, [hasGetUserPermissions]);
  const reportTasks = useMemo(() => {
    return hasPostMasterPermissions
      ? []
      : userReportTemplates?.some((t) => t.id === "salary_slip_single_download")
      ? [
          {
            label: "給与・賞与明細",
            to: "/_/file/list/salary/",
          },
        ]
      : [];
  }, [hasPostMasterPermissions, userReportTemplates]);

  useEffect(() => {
    // 件数がほしいだけなので、limit:1でリクエスト
    dispatch(getTodos({ limit: 1, page: 1, done: false }));
    dispatch(getTodoFormCounts());
  }, []);

  useEffect(() => {
    if (userInitialized || !user.id || Object.keys(policies).length === 0 || hasPostMasterPermissions) return;
    // "給与・賞与明細"にはsectorStatusは使っていないので空で渡す
    dispatch(getUserReportTemplates({ policies, sectorUserStatus: [] }));
  }, [user, policies, userInitialized, hasPostMasterPermissions]);

  useEffect(() => {
    if (!user.services.some((s) => s === MY_NUMBER_SERVICE_ID)) return;
    dispatch(getMyNumberTodoCount({ role: user.role as "admin" | "user" }));
    return () => {
      dispatch(clearMyNumberTodoCount());
    };
  }, [user]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"dashboard"} />
      </div>
      <div className="Layout__main">
        <main>
          <Container>
            {news.length > 0 && (
              <Row className="mb-4">
                <Col>
                  <section className="News-card">
                    <h3 className="News-card__title">お知らせ</h3>
                    <div className="News-card__items">
                      {news.map((n, ni) => {
                        if (n.link) {
                          return (
                            <div className="News-card__item" key={`news${ni}`}>
                              {/* <div className="News-card__item-date">{n.date.format("YYYY/MM/DD")}</div> */}
                              {n.isNew && <div className="News-card__item-icon-new">New</div>}
                              <a href={n.link} target="_blank" className="News-card__item-label">
                                {n.label}
                              </a>
                            </div>
                          );
                        } else {
                          return (
                            <div className="News-card__item" key={`news${ni}`}>
                              {n.label}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </section>
                </Col>
              </Row>
            )}
            <div className="Dashboard-card__row">
              <section className="Dashboard-card my-1">
                <h2 className="Dashboard-card__title">ToDo</h2>
                <div className="Dashboard-card__buttons">
                  <Link to="/_/todo" className="Dashboard-card__button">
                    <div className="Dashboard-card__button-label">未完了のToDo（{todoCount}件）</div>
                  </Link>
                  {reviewingFormsLength > 0 && (
                    <Link to="/_/apply/processor/" className="Dashboard-card__button">
                      <div className="Dashboard-card__button-label">承認待ちの申請書（{reviewingFormsLength}件）</div>
                    </Link>
                  )}
                  {myNumberTodoCount > 0 && (
                    <Link to="/_/my_number/" className="Dashboard-card__button">
                      <div className="Dashboard-card__button-label">
                        {user.role === "admin" ? "確認待ちのマイナンバー" : "マイナンバー提出依頼"}（{myNumberTodoCount}
                        件）
                      </div>
                    </Link>
                  )}
                </div>
              </section>
              {applicationTasks.length > 0 && (
                <section className="Dashboard-card my-1">
                  <h2 className="Dashboard-card__title">各種手続き</h2>
                  <div className="Dashboard-card__buttons">
                    {applicationTasks.map((t, ti) => (
                      <Link to={t.to ?? ""} className="Dashboard-card__button" key={`task${ti}`}>
                        <div className="Dashboard-card__button-label">{t.label}</div>
                      </Link>
                    ))}
                  </div>
                </section>
              )}
              {dailyTasks.length > 0 && (
                <section className="Dashboard-card my-1">
                  <h2 className="Dashboard-card__title">通常業務</h2>
                  <div className="Dashboard-card__buttons">
                    {dailyTasks.map((t, ti) => (
                      <Link to={t.to ?? ""} className="Dashboard-card__button" key={`task${ti}`}>
                        <div className="Dashboard-card__button-label">{t.label}</div>
                      </Link>
                    ))}
                  </div>
                </section>
              )}
              {/* 2024/10/25リリースにて、一旦コメントアウト
              <section className="Dashboard-card my-1">
                <h2 className="Dashboard-card__title">月例業務</h2>
                <div className="Dashboard-card__buttons">
                  {monthlyTasks.length > 0 ? (
                    monthlyTasks.map(() => (
                      <div></div>
                      // <Link to={t.to ?? ""} className="Dashboard-card__button" key={`task${ti}`}>
                      //   <div className="Dashboard-card__button-label">{t.label}</div>
                      // </Link>
                    ))
                  ) : (
                    <div>現在残っている業務はありません。</div>
                  )}
                </div>
              </section> */}
              {profileTasks.length > 0 && (
                <section className="Dashboard-card my-1">
                  <h2 className="Dashboard-card__title">従業員情報</h2>
                  <div className="Dashboard-card__buttons">
                    {profileTasks.map((t, ti) => (
                      <Link to={t.to ?? ""} className="Dashboard-card__button" key={`task${ti}`}>
                        <div className="Dashboard-card__button-label">{t.label}</div>
                      </Link>
                    ))}
                  </div>
                </section>
              )}
              {reportTasks.length > 0 && (
                <section className="Dashboard-card my-1">
                  <h2 className="Dashboard-card__title">給与・賞与明細</h2>
                  <div className="Dashboard-card__buttons">
                    {reportTasks.map((t, ti) => (
                      <Link to={t.to ?? ""} className="Dashboard-card__button" key={`task${ti}`}>
                        <div className="Dashboard-card__button-label">{t.label}</div>
                      </Link>
                    ))}
                  </div>
                </section>
              )}
              {systemTasks.length > 0 && (
                <section className="Dashboard-card my-1">
                  <h2 className="Dashboard-card__title">組織情報</h2>
                  <div className="Dashboard-card__buttons">
                    {systemTasks.map((t, ti) => (
                      <Link to={t.to ?? ""} className="Dashboard-card__button" key={`task${ti}`}>
                        <div className="Dashboard-card__button-label">{t.label}</div>
                      </Link>
                    ))}
                  </div>
                </section>
              )}
            </div>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default App;
