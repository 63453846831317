import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import Table from "../../component/Table";
import { getEvaluationProjectGroups, getEvaluationSystems, selectEvaluationState } from "./evaluationSheetSlice";
import { Row, Col, Alert, Container, Button } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { getESProjects, selectESState } from "../evaluationSystem/evaluationSystemSlice";

function App() {
  const dispatch = useAppDispatch();
  const { systems } = useAppSelector(selectEvaluationState);
  const { projects } = useAppSelector(selectESState);
  useEffect(() => {
    dispatch(getESProjects());
    dispatch(getEvaluationProjectGroups());
    dispatch(getEvaluationSystems());
  }, []);
  return (
    <Container>
      <Row>
        <Col>
          <Button variant="outline-primary">新規プロジェクト</Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          {projects.length > 0 ? (
            <Table
              col={[
                {
                  name: "プロジェクト名",
                  colName: "title",
                },
                {
                  name: "評価タイプ",
                  colName: "system",
                },
                {
                  name: "評価年度",
                  colName: "group",
                },
              ]}
              row={projects.map((p) => {
                const systemLabel = systems.find((sys) => sys.id === p.project_id)?.label ?? "";
                // const groupLabel = projectGroups.find((g) => g.id === p.groupId)?.label ?? "";
                return {
                  key: p.project_id,
                  data: [p.project_name, systemLabel, ""],
                  link: `/_/evaluation/project/${p.project_name}/`,
                };
              })}
              usePagenation={true}
            />
          ) : (
            <Alert variant={"info"}>該当する制度プロジェクトがありません。</Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
