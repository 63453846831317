import { useEffect, useMemo, useState } from "react";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { EvaluationCSSBundles } from "./EvaluationTypes";
import { Accordion, Row, Col, Button, Form, Alert } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { apply, selectEvaluationState } from "./evaluationSheetSlice";

let timer = 0;

function EvaluationEditorStyleSnippets({ target }: { target: string }) {
  const dispatch = useAppDispatch();
  const { system, layout, pattern, todo, individualMetaData, variables } = useAppSelector(selectEvaluationState);
  const [stage, $stage] = useState<EvaluationCSSBundles>({});

  useEffect(() => {
    if (target === "system" && system?.cssTraits) {
      $stage({ ...system.cssTraits });
    } else if (target === "layout" && layout?.cssTraits) {
      $stage({ ...layout.cssTraits });
    }
  }, [system, layout]);

  const bundleKeyOptions = useMemo(() => {
    if (!system) return [];
    return Object.keys(system.cssTraits).map((key) => {
      return { label: system.cssTraits[key].label, value: key };
    });
  }, [system]);

  const unselectedOnes = useMemo(() => {
    return bundleKeyOptions.filter((o) => !stage[o.value]);
  }, [bundleKeyOptions, stage]);

  const addCSSBundle = () => {
    if (unselectedOnes.length > 0) {
      $stage({
        ...stage,
        [unselectedOnes[0].value]: {
          label: unselectedOnes[0].label,
          styles: {},
        },
      });
    }
  };

  const deleteCSSBundle = (key: string) => {
    const next = Object.keys(stage).reduce((prev, current) => {
      if (current !== key) {
        return { ...prev, [current]: stage[current] };
      }
      return prev;
    }, {});
    $stage(next);
  };

  const reapply = (field: string, updated: any) => {
    if (!system) return;
    const next = { ...stage };
    if (field === "styles") {
      try {
        JSON.parse(updated.styles);
      } catch (e) {
        // 入力途中のため中断
        return;
      }
      next[updated.key] = { ...next[updated.key], styles: JSON.parse(updated.styles) };
      $stage({ ...stage, [updated.key]: { ...next[updated.key], styles: JSON.parse(updated.styles) } });
    } else if (field === "key") {
      if (!updated.nextKey) return;
      next[updated.nextKey] = { ...next[updated.key] };
      delete next[updated.key];
      $stage({ ...next });
    } else if (field === "label") {
      if (!updated.label) return;
      next[updated.key] = { ...next[updated.key], label: updated.label };
      $stage({ ...next });
    }

    if (timer > 0) {
      clearTimeout(timer);
    }
    timer = +setTimeout(() => {
      if (!pattern || !layout) return;
      dispatch(
        apply({
          system:
            target === "system"
              ? {
                  ...system,
                  cssTraits: next,
                }
              : system,
          layoutUsage:
            target === "layout"
              ? {
                  ...layout,
                  cssTraits: next,
                }
              : layout,
          pattern,
          todo,
          individualMetaData,
          variables,
          answers: {},
          useSeedTemplateDefault: false,
        })
      );
    }, 500);
  };

  if (!system) return <div></div>;

  return (
    <section>
      {target === "layout" && (
        <div>
          <Alert variant="info">プロジェクト共通の設定がこちらの設定で上書きされます。</Alert>
          <div className="mb-2">
            <Button onClick={addCSSBundle} disabled={unselectedOnes.length === 0} variant="outline-primary">
              上書き設定を追加
            </Button>
          </div>
        </div>
      )}
      <Accordion>
        {Object.keys(stage).map((key, i) => {
          const s = stage[key];
          return (
            <Accordion.Item key={`snippet_${i}`} eventKey={`snippet_${i}`}>
              <Accordion.Header>{s.label}</Accordion.Header>
              <Accordion.Body>
                <Row key={`phase${i}`}>
                  <Col md={6}>
                    <div className="--font-s">{target === "system" ? "Key" : "CSSバンドル"}</div>
                    {target === "system" ? (
                      <Form.Control
                        type="string"
                        defaultValue={key}
                        disabled={true}
                        onChange={(e) => {
                          reapply("key", { key, nextKey: e.target.value });
                          return false;
                        }}
                      />
                    ) : (
                      <Form.Select
                        value={key}
                        onChange={(e) => {
                          reapply("key", { key, nextKey: e.target.value });
                          return false;
                        }}
                      >
                        {[{ value: key, label: s.label }, ...unselectedOnes].map((o) => (
                          <option key={o.value} value={o.value}>
                            {o.label}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  </Col>
                  {target === "system" ? (
                    <Col md={6}>
                      <div className="--font-s">説明</div>
                      <Form.Control
                        type="string"
                        defaultValue={s.label}
                        onChange={(e) => {
                          reapply("label", { key, label: e.target.value });
                          return false;
                        }}
                      />
                    </Col>
                  ) : (
                    <Col md={6}>
                      <Button variant="danger" onClick={() => deleteCSSBundle(key)}>
                        削除
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row className="mt-2">
                  <Col>
                    <div className="--font-s">Styles</div>
                    <Form.Control
                      type="string"
                      as="textarea"
                      className="--font-s"
                      style={{ minHeight: 150 }}
                      defaultValue={JSON.stringify(s.styles, null, 2)}
                      onChange={(e) => {
                        reapply("styles", { key, styles: e.target.value });
                        return false;
                      }}
                    />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </section>
  );
}

export default EvaluationEditorStyleSnippets;
