import { EvaluationComputingRuleSet, UnitTemplate } from "../EvaluationTypes";
import { readHelperQuery } from "../evaluationValues";

const __computingRule_B = ({
  query,
  units,
  computedUnit,
}: {
  query: string;
  units: UnitTemplate[];
  computedUnit: UnitTemplate;
}): { computedKey: string; data: EvaluationComputingRuleSet; unit: UnitTemplate } => {
  const { method, params } = readHelperQuery(query);
  if (method !== "computingRule" && params.model !== "B") {
    throw new Error("uncorresponding method or model");
  } else if (!units?.length) {
    throw new Error("units are required");
  }
  const factors1 = params.value.split(",").filter((_) => _);
  const operandUnits = units.filter(
    (unit) => unit.factorUnitIds && factors1.every((_) => unit.factorUnitIds?.includes(_))
  );
  const computedKey = `${computedUnit.id}`;
  const updatedUnit = {
    ...computedUnit,
    conditions: [
      {
        computedKey,
        value: "$$RESULT",
      },
      {
        computedKey: "",
        value: "",
      },
    ],
  };
  return {
    computedKey,
    unit: updatedUnit,
    data: {
      label: `computed - ${computedUnit.id}`,
      rules:
        operandUnits.length > 0
          ? [
              ...operandUnits.map((unit) => {
                return {
                  operands: [unit.type === "computed" ? "COMPUTED$$" : "" + unit.id],
                  method: "ASSIGN",
                };
              }),
              {
                operands: operandUnits.map((_, i) => `$$${i}`),
                method: "AVERAGE",
              },
            ]
          : [],
    },
  };
};
export default __computingRule_B;
