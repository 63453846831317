import { useAppDispatch, useAppSelector } from "../../app/store";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Alert, Form, Button } from "react-bootstrap";
import RatioBar from "../../component/RatioBar";
import { getStatistics, selectStatisticsState, StatsItem } from "./statisticsSlice";
import { setLoading } from "../../features/notification/notificationSlice";
import { useEffect, useMemo, useState } from "react";
import { selectUserState } from "../login/userSlice";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

const widthMap = {
  legend: 200,
  size: 80,
  gender: 140,
  ages: 200,
  averageAge: 120,
  averageJoinedYears: 120,
} as {
  [key: string]: number;
};

const configMap = {
  gender: {
    legends: ["男性", "女性"],
    backgroundColor: ["#aae0ff", "#ffaad4"],
  },
  ages: {
    legends: ["~29", "30~39", "40~49", "50~59", "60~"],
    backgroundColor: ["#97e9ee", "#7bdea5", "#4bda88", "#8ed31f", "#d2d433"],
  },
};

function App() {
  const navigate = useNavigate();
  const { initialized, insufficientData, statsMap, sectionStories } = useAppSelector(selectStatisticsState);
  const { policies } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();
  const [showRecessMember, $showRecessMember] = useState(false);
  const [showSecondedMember, $showSecondedMember] = useState(false);
  const load = () => {
    dispatch(setLoading(true));
    dispatch(getStatistics({ showRecessMember, showSecondedMember })).then(() => {
      dispatch(setLoading(false));
    });
  };
  useEffect(() => {
    if (initialized) return;
    load();
  }, []);
  const viewWidth = useMemo(() => {
    return Object.keys(widthMap).reduce((acc, key) => {
      return acc + widthMap[key];
    }, 0);
  }, [widthMap]);
  const storiedSectionStats = useMemo(() => {
    const _s = statsMap.sectionStats.value;
    if (!_s) return [];
    const firstFloorSections = _s.filter((_: any) => sectionStories[_.subLabel].floor === 1);
    const storiedSectionStats = firstFloorSections.map((s) => {
      const children1 = _s.filter((_: any) => sectionStories[s.subLabel].childrenCodes.includes(_.subLabel));
      const children = children1.map((c) => {
        const children2 = _s.filter((_: any) => sectionStories[c.subLabel].childrenCodes.includes(_.subLabel));
        return [c, ...children2];
      });
      return {
        ...s,
        children: children.flat(),
      };
    });
    return storiedSectionStats;
  }, [statsMap, sectionStories]);
  useEffect(() => {
    if (Array.isArray(policies.access_manager) && !policies.access_manager.some((_) => _ === "POST")) {
      navigate("/_/dashboard/");
    }
  }, [policies]);

  return initialized ? (
    !insufficientData ? (
      <Container className="mt-4">
        <Row>
          <Col>
            <Alert variant="warning">
              ・この機能はベータ版となっています。
              <br />
              ・主務の部署、役職のみで集計しています。
            </Alert>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md="2" className="--bold">
            在籍状況
          </Col>
          <Col md="10">
            <Row>
              <Col md="4">
                <Form.Check
                  onChange={() => {
                    $showRecessMember(!showRecessMember);
                  }}
                  type="checkbox"
                  label={"休職者を含める"}
                  id={`show_enrollment_recess`}
                  checked={showRecessMember}
                  value={`showRecessMember`}
                />
              </Col>
              <Col md="4">
                <Form.Check
                  onChange={() => {
                    $showSecondedMember(!showSecondedMember);
                  }}
                  type="checkbox"
                  label={"出向者を含める"}
                  id={`show_enrollment_seconded`}
                  checked={showSecondedMember}
                  value={`showSecondedMember`}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Button onClick={load} variant="outline-primary">
              更新
            </Button>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <section className="Stats">
              <div className="Stats__view" style={{ width: `${viewWidth}px` }}>
                <div className="Stats__header">
                  <div className="Stats__header-cell" style={{ width: `${widthMap.legend}px` }}></div>
                  <div className="Stats__header-cell" style={{ width: `${widthMap.size}px` }}>
                    人数
                  </div>
                  <div className="Stats__header-cell" style={{ width: `${widthMap.gender}px` }}>
                    <div>性別の構成</div>
                    <div className="Stats__legends mt-2">
                      {configMap.gender.legends.map((v, i) => {
                        return (
                          <div key={`legend${i}`} className="Stats__legend-item">
                            <div
                              className="Stats__legend-item-color"
                              style={{ backgroundColor: configMap.gender.backgroundColor[i] }}
                            ></div>
                            <div className="Stats__legend-item-value">{configMap.gender.legends[i]}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="Stats__header-cell" style={{ width: `${widthMap.averageAge}px` }}>
                    平均年齢
                  </div>
                  <div className="Stats__header-cell" style={{ width: `${widthMap.ages}px` }}>
                    <div>年齢の構成</div>
                    <div className="Stats__legends mt-2">
                      {configMap.ages.legends.map((v, i) => {
                        return (
                          <div key={`legend${i}`} className="Stats__legend-item">
                            <div
                              className="Stats__legend-item-color"
                              style={{ backgroundColor: configMap.ages.backgroundColor[i] }}
                            ></div>
                            <div className="Stats__legend-item-value">{configMap.ages.legends[i]}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="Stats__header-cell" style={{ width: `${widthMap.averageJoinedYears}px` }}>
                    平均勤続年数
                  </div>
                </div>
                {Object.keys(statsMap).map((type) => {
                  const stats =
                    type !== "sectionStats" ? statsMap[type] : { ...statsMap[type], value: storiedSectionStats };
                  return (
                    <section key={type} className="Stats__block">
                      <h2 className="Stats__block-label">{stats.label}</h2>
                      <div className="Stats__block-contents">
                        {stats.value.map((row: StatsItem & { children?: StatsItem[] }, i) => {
                          return (
                            <div key={`row${i}`} className="Stats__block-content">
                              {[row, ...(row.children ?? [])].map((c, ci) => {
                                return (
                                  <section
                                    key={`row-content_${i}_${ci}`}
                                    className={classNames({
                                      "Stats__block-content-row": true,
                                      "--thin": ci > 0,
                                      "--group-start": sectionStories[c.subLabel]?.floor === 2,
                                    })}
                                  >
                                    <div
                                      className="Stats__block-cell --legend text-end"
                                      style={{ width: `${widthMap.legend}px` }}
                                    >
                                      <div
                                        className={classNames({
                                          "Stats__sub-value": ci > 0,
                                          "--font-s": ci > 0,
                                          "--bold": type !== "sectionStats" || sectionStories[c.subLabel]?.floor <= 2,
                                        })}
                                      >
                                        {c.label}
                                      </div>
                                    </div>
                                    <div className="Stats__block-cell" style={{ width: `${widthMap.size}px` }}>
                                      <div className={classNames({ "Stats__sub-value": ci > 0 })}>
                                        {c.stats.size ? `${c.stats.size}名` : "- -"}
                                      </div>
                                    </div>
                                    <div
                                      className="Stats__block-cell --compact"
                                      style={{ width: `${widthMap.gender}px` }}
                                    >
                                      {type !== "genderStats" && c.stats.size > 0 ? (
                                        <RatioBar
                                          width={widthMap.gender - 10}
                                          steps={c.stats.genderMap.map((value, i) => {
                                            return {
                                              value,
                                              backgroundColor: configMap.gender.backgroundColor[i],
                                            };
                                          })}
                                        />
                                      ) : (
                                        "- -"
                                      )}
                                    </div>
                                    <div className="Stats__block-cell" style={{ width: `${widthMap.averageAge}px` }}>
                                      <div className={classNames({ "Stats__sub-value": ci > 0 })}>
                                        {c.stats.averageAge ? `${c.stats.averageAge}歳` : "- -"}
                                      </div>
                                    </div>
                                    <div
                                      className="Stats__block-cell --compact"
                                      style={{ width: `${widthMap.ages}px` }}
                                    >
                                      {c.stats.size ? (
                                        <RatioBar
                                          width={widthMap.ages - 10}
                                          steps={c.stats.ageMap.map((value, i) => {
                                            return { value, backgroundColor: configMap.ages.backgroundColor[i] };
                                          })}
                                        />
                                      ) : (
                                        "- -"
                                      )}
                                    </div>
                                    <div
                                      className="Stats__block-cell"
                                      style={{ width: `${widthMap.averageJoinedYears}px` }}
                                    >
                                      <div className={classNames({ "Stats__sub-value": ci > 0 })}>
                                        {c.stats.averageJoinedYears ? `${c.stats.averageJoinedYears}年` : "- -"}
                                      </div>
                                    </div>
                                  </section>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </section>
                  );
                })}
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    ) : (
      <Container className="mt-4">
        <Row>
          <Col>
            <Alert variant="info" className="mb-0">
              データが十分登録されると分析ビューが表示されます。
            </Alert>
          </Col>
        </Row>
      </Container>
    )
  ) : (
    <Container className="mt-4">
      <Row>
        <Col>データのダウンロード中・・・</Col>
      </Row>
    </Container>
  );
}

export default App;
