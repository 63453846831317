import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { Row, Col, Button } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { selectEvaluationState, setEditTarget } from "./evaluationSheetSlice";
import Sidebar_Evaluation from "../../component/Sidebar_Evaluation";
import { useParams } from "react-router-dom";

function App() {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const { system } = useAppSelector(selectEvaluationState);

  useEffect(() => {
    dispatch(setEditTarget({ systemId: projectId }));
  }, [projectId]);

  if (!system) return <div />;
  return (
    <div className="Ev-container --editor">
      <Sidebar_Evaluation current="test" />
      <main className="Ev-edit-content bg-white">
        <Row className="mb-2 --align-items-center">
          <Col md={3} className="--font-s">
            評価年度
          </Col>
          <Col md={5}>{system?.label ?? ""}</Col>
          <Col md={4}>
            <Button variant="outline-primary">編集</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            - テスト用データ
            <br />
            - テスト内容
            <br />- テスト結果
          </Col>
        </Row>
      </main>
    </div>
  );
}

export default App;
