function NoData(props: any) {
  return (
    <aside className="No-data">
      <div className="No-data__icon">
        <img src="/_/no_data.png" />
      </div>
      <div className="No-data__children">{props.children}</div>
    </aside>
  );
}

export default NoData;
