import { yieldId } from "../../app/util";
import {
  Unit,
  UnitTemplate,
  UnitBehaviorMap,
  EvaluationValue,
  EvaluationTodo,
  EvaluationScopedVariables,
  UnitAppearance,
  EvaluationAnswers,
  UnitGroupTemplate,
  EvaluationPersonalData,
  UnitGroup,
  EvaluationPhase,
  EvaluationAttribute,
  EvaluationSelectOptions,
  UnitComputingCondition,
  EvaluationComputingRuleSet,
  Evaluation,
  EvaluationTransformProps,
  EvaluationPattern,
  EvaluationLayoutUsage,
  EvaluationHelperContext,
  Story,
} from "./EvaluationTypes";
import calculate from "./calculate";
import { getLayoutRows } from "./procedure";

// ========================================

/*
  behaviors に phase, variables を照らし合わせて
  Unit.appearance を設定する
*/
export const setUnitAppearance = ({
  unit,
  behaviorMap,
  phaseKey,
  variables,
}: {
  unit: Unit;
  behaviorMap: UnitBehaviorMap;
  phaseKey: string;
  variables: EvaluationScopedVariables;
}): Unit => {
  const behavior = behaviorMap[unit.behaviorId ?? "DEFAULT_BEHAVIOR"];
  if (!behavior) return unit;

  const currentBehavior = behavior.rules[phaseKey];
  if (!currentBehavior?.length) return unit;
  let nextAppearance = "";
  currentBehavior.forEach(({ variable, expection, appearance }) => {
    if (nextAppearance) return;
    if (variable && variables[variable] === expection) {
      nextAppearance = appearance;
    }
  });
  if (!nextAppearance) nextAppearance = currentBehavior[currentBehavior.length - 1].appearance;
  return {
    ...unit,
    id: unit.id ?? `unit-${yieldId()}`,
    value: unit.value ?? "",
    appearance: nextAppearance as UnitAppearance,
    isEmpty: !!unit.isEmpty,
  };
};

export const getUnitGroup = ({
  unitGroupTemplate,
  answers,
  todo,
  behaviorMap,
  variables,
  attributes,
  commonSelectOptions,
}: {
  unitGroupTemplate: UnitGroupTemplate;
  answers: EvaluationAnswers;
  todo: EvaluationTodo;
  behaviorMap: UnitBehaviorMap;
  variables: EvaluationScopedVariables;
  attributes: EvaluationAttribute[];
  commonSelectOptions?: EvaluationSelectOptions;
}): UnitGroup => {
  const attributesMap = attributes.reduce((prev, current) => {
    return {
      ...prev,
      [current.id]: current,
    };
  }, {} as { [id: string]: { id: string; label: string; value?: EvaluationValue } });
  return {
    ...unitGroupTemplate,
    units: unitGroupTemplate.units.map((u) => {
      if (["select", "text"].includes(u.type) && !u.value) {
        return setUnitAppearance({
          unit: {
            ...u,
            value: answers[u.id] ?? todo.values[u.id] ?? "",
            options: u.optionsKey ? commonSelectOptions?.[u.optionsKey]?.options : undefined,
            appearance: "preview",
            isEmpty: !!u.isEmpty,
          },
          phaseKey: todo.phase.key ?? "default",
          behaviorMap,
          variables,
        });
      } else if (["static"].includes(u.type)) {
        return setUnitAppearance({
          unit: {
            ...u,
            value: (() => {
              if (!u.value || `${u.value}`.indexOf("ATTR$$") !== 0) {
                return u.value ?? "";
              } else {
                const attributeId = `${u.value}`.replace("ATTR$$", "");
                return attributesMap[attributeId]?.value ?? `[[${attributeId}]]`;
              }
            })(),
            appearance: "preview",
            isEmpty: !!u.isEmpty,
          },
          phaseKey: todo.phase.key ?? "default",
          behaviorMap,
          variables: { ...variables },
        });
      } else {
        return setUnitAppearance({
          unit: {
            ...u,
            appearance: "preview",
            isEmpty: !!u.isEmpty,
          },
          phaseKey: todo.phase.key ?? "default",
          behaviorMap,
          variables: { ...variables },
        });
      }
    }),
  } as UnitGroup;
};

/*
  - column, row のサイズを計算
  - 定義されていないセルを埋める
*/
export const setUnitGroupTemplateSize = (unitGroup: UnitGroupTemplate): UnitGroupTemplate | null => {
  try {
    if (!unitGroup) return null;
    // column ごとの幅を計算する
    const widthRawData = [] as number[][];
    const heightRawData = [] as number[][];
    const occupied = [] as boolean[][];
    const _units = unitGroup.units.map((unit) => {
      // セルの幅、高さの情報
      if (unit.gridColumnSpan && unit.gridColumnSpan >= 1) {
        for (let i = unit.gridColumn; i < unit.gridColumn + (unit.gridColumnSpan || 1); i++) {
          if (!widthRawData[i - 1]) {
            widthRawData[i - 1] = [] as number[];
          }
        }
      }
      if (!unit.gridColumnSpan || unit.gridColumnSpan === 1) {
        if (unit.width) {
          widthRawData[unit.gridColumn - 1] = widthRawData[unit.gridColumn - 1] || [];
          widthRawData[unit.gridColumn - 1] = [...widthRawData[unit.gridColumn - 1], unit.width];
        }
      }
      if (!unit.gridRowSpan || unit.gridRowSpan === 1) {
        if (!heightRawData[unit.gridRow - 1]) {
          heightRawData[unit.gridRow - 1] = [] as number[];
        }
        if (unit.height) {
          heightRawData[unit.gridRow - 1] = heightRawData[unit.gridRow - 1] || [];
          heightRawData[unit.gridRow - 1] = [...heightRawData[unit.gridRow - 1], unit.height];
        }
      }
      // セルが占有する領域の情報
      for (let i = unit.gridRow; i < unit.gridRow + unit.gridRowSpan; i++) {
        if (!occupied[i - 1]) {
          occupied[i - 1] = [] as boolean[];
        }
        for (let j = unit.gridColumn; j < unit.gridColumn + unit.gridColumnSpan; j++) {
          occupied[i - 1][j - 1] = true;
        }
      }
      return {
        ...unit,
        id: unit.id ?? `unit-${yieldId()}`,
      };
    });
    // この段階で空の場合があるので考慮する
    for (let i = 0; i < widthRawData.length; i++) {
      if (!widthRawData[i]) widthRawData[i] = [];
    }
    for (let i = 0; i < heightRawData.length; i++) {
      if (!heightRawData[i]) heightRawData[i] = [];
    }

    const columnWidthList = [] as number[];
    for (let i = 0; i < widthRawData.length; i++) {
      if (widthRawData[i].length === 0) {
        columnWidthList[i] = 100;
      } else {
        columnWidthList[i] = Math.max(...widthRawData[i]);
      }
    }
    const rowHeightList = [] as number[];
    for (let i = 0; i < heightRawData.length; i++) {
      if (heightRawData[i].length === 0) {
        rowHeightList[i] = -1;
      } else {
        rowHeightList[i] = Math.max(...heightRawData[i]);
      }
    }

    const maxColumns = Math.max(...occupied.map((row) => row.length));
    // カバーされていないセルを埋める
    const additionalUnits = [] as UnitTemplate[];
    for (let rowIndex = 0; rowIndex < occupied.length; rowIndex++) {
      for (let colIndex = 0; colIndex < maxColumns; colIndex++) {
        if (!occupied[rowIndex][colIndex]) {
          additionalUnits.push({
            id: `unit_${yieldId()}`,
            type: "static",
            gridRow: rowIndex + 1,
            gridRowSpan: 1,
            gridColumn: colIndex + 1,
            gridColumnSpan: 1,
            value: "",
            style: {},
            unitGroupId: unitGroup.id,
            isEmpty: true,
          });
        }
      }
    }

    return {
      ...unitGroup,
      units: [..._units, ...additionalUnits],
      columnWidthList,
      rowHeightList,
    };
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const apply = ({
  system,
  layoutUsage,
  pattern,
  todo,
  individualMetaData,
  variables,
  answers: _cachedAnswers,
  useSeedTemplateDefault,
}: EvaluationTransformProps) => {
  const behaviorMap = Object.keys(layoutUsage.behaviorMapTemplate).reduce((prev, presetId) => {
    return {
      ...prev,
      [presetId]: {
        label: layoutUsage.behaviorMapTemplate[presetId].label,
        rules: Object.keys(layoutUsage.behaviorMapTemplate[presetId].rules).reduce((prev2, phaseKey) => {
          const definition = layoutUsage.behaviorDefinition[layoutUsage.behaviorMapTemplate[presetId].rules[phaseKey]];
          return {
            ...prev2,
            [phaseKey]: definition ? definition.rules.map((_) => _).sort((a, b) => b.priority - a.priority) : [],
          };
        }, {}),
      },
    };
  }, {} as UnitBehaviorMap);

  const answers = _cachedAnswers ? { ..._cachedAnswers } : ({} as { [unitId: string]: EvaluationValue | undefined });
  let modifiedVariables = {
    ...variables,
  };
  const { unitLayoutRows, context } = getLayoutRows({
    pattern,
    layoutUsage,
    system,
    useSeedTemplateDefault,
  });

  const commonSelectOptions = {
    ...layoutUsage.commonSelectOptions,
    ...context.commonSelectOptions,
  };
  const computingRuleSet = {
    ...layoutUsage.computingRuleSet,
    ...context.computingRuleSet,
  };
  const layoutRows = unitLayoutRows.map((row) => {
    return {
      ...row,
      unitGroups: row.unitGroups
        .map((unitGroup) => {
          return {
            ...unitGroup,
            templateId: unitGroup.templateId ?? "",
            units: unitGroup.units.map((unit) => {
              const _unit = { ...unit };
              // variable
              if (!modifiedVariables[unit.id]) {
                if (todo.values[unit.id]) {
                  modifiedVariables[unit.id] = todo.values[unit.id];
                } else if (unit.type !== "static" && unit.value !== undefined) {
                  modifiedVariables[unit.id] = unit.value;
                } else if (answers[unit.id]) {
                  modifiedVariables[unit.id] = `${answers[unit.id]}`;
                }
              }
              // answers
              if (["text", "select", "checkbox"].includes(unit.type) && !_cachedAnswers?.[unit.id]) {
                answers[unit.id] = todo.values[unit.id];
              }
              // cssBundleKeys から style へ
              const _style = {
                ...(_unit.style || {}),
                ..._unit.cssBundleKeys?.reduce((prev, key) => {
                  return {
                    ...prev,
                    ...(context.cssTraits[key]?.styles ?? {}),
                  };
                }, {}),
              };
              return {
                ..._unit,
                style: _style,
              };
            }),
          };
        })
        .map((unitGroupTemplate) => {
          // ユニットにアピアランスを設定する
          return getUnitGroup({
            unitGroupTemplate,
            answers,
            todo,
            behaviorMap,
            variables: modifiedVariables,
            commonSelectOptions,
            attributes: [...pattern.attributes, ...pattern.user_attribute_mappings].map((_) => {
              const individualValue = [...pattern.attributes, ...individualMetaData.attributes].find(
                (a) => a.id === _.id
              );
              return {
                ..._,
                value: individualValue?.value,
              };
            }),
          });
        }),
    };
  });

  // computed の初期値を求める
  const computedValues = getComputedValues(computingRuleSet, answers, modifiedVariables);

  modifiedVariables = {
    ...modifiedVariables,
    ...computedValues,
  };

  const evaluation: Evaluation = {
    title: system.label,
    layoutRows,
    phases: system.phaseTemplates.map((t) => {
      const correspondingEvaluator = individualMetaData.evaluators.find((e) => e.phaseKey === t.key);
      return {
        ...t,
        id: correspondingEvaluator?.phaseId ?? "",
      } as EvaluationPhase;
    }),
    behaviorMap,
    commonSelectOptions,
    computingRuleSet,
    hooks: system.hooks ?? [],
  };
  return {
    system,
    evaluation,
    todo,
    variables: modifiedVariables,
    answers,
    computed: computedValues,
    layoutUsage,
    pattern,
  };
};

export const validateUnitGroupTemplate = (jsonLikeString: string): [boolean, string] => {
  // JSON 形式がかどうかをチェック
  let valid = true;
  let message = "正しい形式です";
  let parsed;
  try {
    parsed = JSON.parse(jsonLikeString);
    if (typeof parsed !== "object") {
      valid = false;
      message = "構文エラーがあります";
    }
  } catch (e) {
    // JSON 形式でない場合
    valid = false;
    message = "構文にエラーがあります";
  }
  return [valid, message];
};

export const validateUnitTemplate = (jsonLikeString: string): [boolean, string] => {
  // JSON 形式がかどうかをチェック
  let valid = true;
  let message = "正しい形式です";
  let parsed;
  try {
    parsed = JSON.parse(jsonLikeString);
    if (typeof parsed !== "object") {
      valid = false;
      message = "構文エラーがあります";
    }
  } catch (e) {
    // JSON 形式でない場合
    valid = false;
    message = "構文エラーがあります";
  }
  return [valid, message];
};

export const getComputedValues = (
  computingRuleSet: {
    [computedName: string]: EvaluationComputingRuleSet;
  },
  answers: EvaluationAnswers,
  variables: EvaluationScopedVariables
): { [computedName: string]: EvaluationValue } => {
  try {
    // ======= computed の参照の深さでクラス分けする
    const relations = Object.keys(computingRuleSet).reduce((prev, computedName) => {
      const fields = computingRuleSet[computedName].rules
        .map((r) => {
          return [...r.operands, ...(r.subOperands || [])].filter(
            (_) => _ && typeof _ === "string" && _.indexOf("COMPUTED$$") === 0
          );
        })
        .map((_) => `${_}`.replace("COMPUTED$$", ""))
        .filter((_) => _)
        .flat();
      return {
        ...prev,
        [computedName]: fields,
      };
    }, {} as { [computedName: string]: string[] });

    const dig = (computedName: string): number => {
      const r =
        relations[computedName]?.length > 0 && relations[computedName].every((n) => n !== computedName)
          ? 1 + Math.max(...relations[computedName].map(dig))
          : 0;
      return r;
    };

    const depthMap = Object.keys(relations).reduce((prev, computedName) => {
      return {
        ...prev,
        [computedName]: dig(computedName),
      };
    }, {} as { [computedName: string]: number });

    const classes = depthMap.length > 0 ? new Array(Math.max(...Object.values(depthMap))) : [];
    for (const computedName in depthMap) {
      const depth = depthMap[computedName];
      if (!classes[depth]) classes[depth] = [];
      classes[depth].push(computedName);
    }
    // =======

    const computedValues = {} as { [computedName: string]: EvaluationValue };
    for (let i = 0; i < classes.length; i++) {
      Object.keys(computingRuleSet).forEach((computedName) => {
        if (!classes[i].includes(computedName)) return;
        const { rules } = computingRuleSet[computedName];
        const calculatedResults = rules.reduce((prev, current) => {
          return [
            ...prev,
            calculate({
              rule: current,
              answers,
              variables: {
                ...variables,
                ...computedValues,
              },
              accumulated: prev,
            }),
          ];
        }, [] as (EvaluationValue | EvaluationValue[])[]);
        const computedValue = calculatedResults[calculatedResults.length - 1];
        computedValues[computedName] = Array.isArray(computedValue) ? computedValue[0] : computedValue;
      });
    }
    return computedValues;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const getComputedUnitValue = ({
  conditions,
  computed,
}: {
  conditions: UnitComputingCondition[];
  computed: { [variable: string]: EvaluationValue };
}) => {
  let computedValue: any;
  conditions.forEach(({ computedKey, value }) => {
    if (computedValue !== undefined) return;
    if (computed[computedKey] || !computedKey) {
      if (value === "$$RESULT") {
        computedValue = computed[computedKey];
      } else {
        computedValue = value;
      }
    }
  });
  return computedValue;
};

export const readHelperQuery = (
  query: string
): {
  method: string;
  params: {
    [key: string]: string;
  };
} => {
  const [method, ...v] = query.split("?");
  const params = {} as {
    [key: string]: string;
  };
  v.forEach((param) => {
    const pairs = param.split("&");
    pairs.forEach((pair) => {
      const [key, value] = pair.split("=");
      params[key] = value;
    });
  });
  return {
    method,
    params,
  };
};

export const mergeContext = (
  prev: EvaluationHelperContext,
  newContext: EvaluationHelperContext
): EvaluationHelperContext => {
  return {
    commonSelectOptions: { ...prev.commonSelectOptions, ...newContext.commonSelectOptions },
    behaviorMapTemplate: { ...prev.behaviorMapTemplate, ...newContext.behaviorMapTemplate },
    behaviorDefinition: { ...prev.behaviorDefinition, ...newContext.behaviorDefinition },
    phaseTemplates: [...prev.phaseTemplates, ...newContext.phaseTemplates].filter(
      (v, i, self) => self.findIndex((_) => _.key === v.key) === i
    ),
    computingRuleSet: { ...prev.computingRuleSet, ...newContext.computingRuleSet },
    cssTraits: { ...prev.cssTraits, ...newContext.cssTraits },
  };
};

export const countUnitWidth = (count: number): number => {
  return Math.floor(Math.sqrt(count > 40 ? 40 : count) * 50);
};

const countRow = (s: Story, isTopLevel: boolean, useCategoryTotal: boolean): Story => {
  if (s.sub.length === 0) {
    return { ...s, occupiedRow: 1 };
  }
  s.sub = s.sub.map((s) => countRow(s, false, useCategoryTotal));
  let occupiedRow = s.sub.reduce((acc, cur) => acc + (cur.occupiedRow ?? 0), 0);
  if (useCategoryTotal && isTopLevel) {
    occupiedRow++;
  }
  return { ...s, occupiedRow };
};

export const toStories = (textList: { value: string }[], useCategoryTotal: boolean) => {
  const rows = textList.map((row) =>
    row.value
      .trim()
      .split(",")
      .filter((_) => _)
  );
  const result = { label: "root", sub: [], path: [] } as Story;
  const maxStories = Math.max(...rows.map((row) => row.length));
  for (let i = 0; i < maxStories; i++) {
    rows.forEach((row) => {
      let target = result;
      for (let colIndex = 0; colIndex <= i; colIndex++) {
        if (colIndex >= row.length) break;
        let matched: Story | null = target.sub.find((_) => _.label === row[colIndex]) ?? null;
        if (!matched) {
          const newStory = { label: row[colIndex], sub: [] as Story[], path: [...target.path, target.sub.length] };
          target.sub.push(newStory);
          target = newStory;
        } else {
          target = matched;
        }
      }
    });
  }

  result.sub = result.sub.map((s) => {
    return countRow(s, true, useCategoryTotal);
  });
  return {
    stories: result,
    maxStories,
  };
};

export const emptyEvaluationTodo: EvaluationTodo = {
  id: "empty",
  status: "waiting",
  phase: {
    id: "",
    key: "",
    label: "",
    phaseGroupId: "",
  },
  evaluator: {
    accountId: -1,
    displayName: "empty",
    phaseId: "empty",
    phaseKey: "empty",
  },
  evaluatee: {
    accountId: -1,
    displayName: "empty",
  },
  values: {},
  patternId: "",
};

export const emptyEvaluationPersonalData: EvaluationPersonalData = {
  id: "empty",
  evaluators: [],
  attributes: [],
  approvedPhaseIndex: -1,
};

export const emptyUnitTemplate: UnitTemplate = {
  id: "",
  type: "static",
  gridRow: 1,
  gridRowSpan: 1,
  gridColumn: 1,
  gridColumnSpan: 1,
  isEmpty: true,
};

export const emptyLayout: EvaluationLayoutUsage = {
  behaviorDefinition: {},
  behaviorMapTemplate: {},
  commonSelectOptions: {},
  computingRuleSet: {},
  id: "",
  description: "",
  projectId: "",
  title: "",
  rows: [],
  cssTraits: {},
  updated_at: 0,
};

export const emptyPattern: EvaluationPattern = {
  id: "",
  projectId: "",
  title: "",
  description: "",
  layoutId: "",
  assignConditions: [],
  attributes: [],
  user_attribute_mappings: [],
  seedData: {},
  updated_at: 0,
};

export const evaluationContexts = [
  {
    value: "answering",
    label: "回答中",
  },
  {
    value: "confirming",
    label: "回答確認中",
  },
  {
    value: "editing",
    label: "編集中",
  },
];

export const evaluationUnitTypeList = [
  {
    value: "text",
    label: "記述式の回答",
  },
  {
    value: "select",
    label: "選択式の回答",
  },
  {
    value: "checkbox",
    label: "チェックボックス",
  },
  {
    value: "computed",
    label: "算出変数",
  },
  {
    value: "static",
    label: "固定テキスト",
  },
];

export const evaluationUnitTypeListForUser = [
  {
    value: "text",
    label: "記述式の回答",
  },
  {
    value: "select",
    label: "選択式の回答",
  },
  {
    value: "checkbox",
    label: "チェックボックス",
  },
  {
    value: "static",
    label: "固定テキスト",
  },
];

export const unitAppearanceOptions: {
  value: UnitAppearance;
  label: string;
}[] = [
  {
    value: "input",
    label: "入力可能",
  },
  {
    value: "preview",
    label: "プレビュー（値を表示・入力不可）",
  },
  {
    value: "locked",
    label: "控えめに表示（値を表示・入力不可）",
  },
  {
    value: "veiled",
    label: "非表示（欄は表示）",
  },
  {
    value: "hidden",
    label: "非表示（欄を非表示）",
  },
];

export const procedureOptions = [
  {
    value: "advance_per_evaluatee",
    label: "すぐに開始する",
  },
  {
    value: "advance_per_group",
    label: "同じ評価フェーズの全員が完了したら開始する",
  },
];
