import { EvaluationComputingRuleSet, UnitTemplate } from "../EvaluationTypes";
import { readHelperQuery } from "../evaluationValues";
import __computingRule_A from "./computingRule_A";
import __computingRule_B from "./computingRule_B";

const __computingRule = ({
  query,
  units,
  computedUnit,
}: {
  query: string;
  units: UnitTemplate[];
  computedUnit: UnitTemplate;
}): { computedKey: string; data?: EvaluationComputingRuleSet; unit: UnitTemplate } => {
  const { method, params } = readHelperQuery(query);
  if (method !== "computingRule") {
    throw new Error("uncorresponding method");
  } else if (!units?.length) {
    throw new Error("units are required");
  }
  switch (params.model) {
    case "A":
      return __computingRule_A({ query, units, computedUnit });
    case "B":
      return __computingRule_B({ query, units, computedUnit });
    default:
      // どれかの model に基本的に該当するので通常到達しない
      return {
        computedKey: "",
        unit: computedUnit,
      };
  }
};
export default __computingRule;
