import { useEffect, useState } from "react";
import "../css/style.scss";
import { selectEvaluationState } from "../features/evaluationSheet/evaluationSheetSlice";
import { useAppSelector } from "../app/store";

export interface ContextMenuItem {
  label: string;
  key: string;
  sub?: ContextMenuItem[];
}

export type ContextMenuProps = {
  items: ContextMenuItem[];
  onClose: () => any;
  onSelect: (item: ContextMenuItem) => any;
};

function App({ items, onClose, onSelect }: ContextMenuProps) {
  const [location, $location] = useState<{
    x: number;
    y: number;
    facing: "left" | "right";
  }>({ x: 0, y: 0, facing: "right" });
  const { contextMenu } = useAppSelector(selectEvaluationState);

  useEffect(() => {
    if (contextMenu.active) {
      const w = window.innerWidth;
      if (w / 2 < contextMenu.x) {
        $location({ x: contextMenu.x, y: contextMenu.y, facing: "right" });
      } else {
        $location({ x: contextMenu.x, y: contextMenu.y, facing: "left" });
      }
    }
  }, [contextMenu.active]);
  return contextMenu.active ? (
    <section className="Cx-menu">
      <nav
        className="Cx-menu__list"
        style={{
          top: location.y,
          left: location.x,
          transform: `translate(${location.facing === "right" ? "0" : "-100%"},0)`,
        }}
      >
        {items.map((item, index) => {
          return (
            <div
              key={item.key}
              className="Cx-menu__item"
              onClick={() => {
                onSelect(item);
              }}
            >
              {item.label}
            </div>
          );
        })}
      </nav>
      <div
        className="Cx-menu__background"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        }}
      ></div>
    </section>
  ) : (
    <></>
  );
}

export default App;
