import { ReactElement, useMemo } from "react";
import { useAppSelector } from "../../app/store";
import { Row, Col, Form } from "react-bootstrap";
import "../../css/style.scss";
import { selectEvaluationState } from "./evaluationSheetSlice";
import { evaluationUnitTypeListForUser } from "./evaluationValues";
import { methodOptions } from "./calculate";

function App({
  seedData,
  seedDataTypes,
  onChange,
}: {
  seedData: any;
  seedDataTypes: any;
  onChange: (seedData: any) => any;
}) {
  const { system } = useAppSelector(selectEvaluationState);
  const phaseOptions = useMemo(() => {
    return [
      ...(system?.phaseTemplates.map((phase) => {
        return {
          label: phase.label,
          value: phase.key,
        };
      }) ?? []),
      {
        label: "- - -",
        value: "",
      },
    ];
  }, [system]);

  const _onChange = (v: {
    value: string | number | boolean | string[];
    field: string;
    index?: number;
    subSerialNumber?: number;
  }) => {
    onChange && onChange(v);
  };

  const getValueInputLayout = ({
    value,
    label,
    field,
    index,
    subSerialNumber,
    valueType,
  }: {
    value: string | number | boolean;
    field: string;
    valueType: string;
    index?: number;
    subSerialNumber?: number;
    label?: string;
  }) => {
    let el: ReactElement | null = null;
    const key = `value-input_${field}${index ? `_${index}` : ""}${subSerialNumber ? `_${subSerialNumber}` : ""}`;
    if (valueType === "text" || valueType === "query" || valueType === "text[]") {
      el = (
        <Form.Control
          key={key}
          type="text"
          defaultValue={value ? `${value}` : ""}
          onChange={(e) => {
            _onChange({
              value: e.target.value,
              field,
              index,
              subSerialNumber,
            });
          }}
          className="--font-s"
        ></Form.Control>
      );
    } else if (valueType === "number") {
      el = (
        <Form.Control
          key={key}
          type="number"
          defaultValue={!Number.isNaN(+value) ? +value : 1}
          onChange={(e) => {
            _onChange({
              value: e.target.value,
              field,
              index,
              subSerialNumber,
            });
          }}
          className="--font-s"
        ></Form.Control>
      );
    } else if (valueType === "phase") {
      el = (
        <Form.Select
          key={key}
          value={`${value}`}
          onChange={(e) => {
            const nextPhase = phaseOptions.find((o) => o.value === e.target.value);
            if (!nextPhase) return;
            _onChange({
              value: nextPhase.value,
              field,
              index,
              subSerialNumber,
            });
          }}
          className="--font-s"
        >
          {phaseOptions.map(({ label, value }, i) => {
            return (
              <option key={`option_${i}`} value={value}>
                {label}
              </option>
            );
          })}
        </Form.Select>
      );
    } else if (valueType === "valueType") {
      el = (
        <Form.Select
          key={key}
          value={`${value}`}
          onChange={(e) => {
            _onChange({
              value: e.target.value,
              field,
              index,
              subSerialNumber,
            });
          }}
          className="--font-s"
        >
          {evaluationUnitTypeListForUser.map(({ label, value }, i) => {
            return (
              <option key={`option_${i}`} value={value}>
                {label}
              </option>
            );
          })}
        </Form.Select>
      );
    } else if (valueType === "calculateMethod") {
      el = (
        <Form.Select
          key={key}
          value={`${value}`}
          onChange={(e) => {
            _onChange({
              value: e.target.value,
              field,
              index,
              subSerialNumber,
            });
          }}
          className="--font-s"
        >
          {methodOptions.map(({ label, value }, i) => {
            return (
              <option key={`option_${i}`} value={value}>
                {label}
              </option>
            );
          })}
        </Form.Select>
      );
    } else if (valueType === "boolean") {
      el = (
        <Form.Check
          key={key}
          type="checkbox"
          checked={!!value}
          onChange={(e) => {
            _onChange({
              value: !value,
              field,
              index,
              subSerialNumber,
            });
          }}
          label={label}
        />
      );
    } else if (valueType === "unit" || valueType === "computed") {
      el = (
        <div key={key}>
          {Object.keys(seedData).map((prop, i) => {
            const propLabel = seedDataTypes.data.schema.unit.props[prop]?.label ?? "";
            let control = <></>;
            if (prop === "gridColumnSpan" || prop === "gridRowSpan") {
              control = (
                <Form.Control
                  type="number"
                  defaultValue={!Number.isNaN(+seedData[prop]) ? +seedData[prop] : 1}
                  onChange={(e) => {
                    if (Number.isNaN(+e.target.value) || +e.target.value <= 0) return;
                    _onChange({
                      value: +e.target.value,
                      field: prop,
                    });
                  }}
                  className="--font-s"
                ></Form.Control>
              );
            } else if (prop === "value") {
              control = (
                <Form.Control
                  type="string"
                  defaultValue={seedData[prop]}
                  onChange={(e) => {
                    _onChange({
                      value: e.target.value,
                      field: prop,
                    });
                  }}
                  className="--font-s"
                ></Form.Control>
              );
            } else if (prop === "classNames" || prop === "cssBundleKeys") {
              control = (
                <Form.Control
                  type="string"
                  defaultValue={seedData[prop].join(",")}
                  onChange={(e) => {
                    _onChange({
                      value: e.target.value.split(","),
                      field: prop,
                    });
                  }}
                  className="--font-s"
                ></Form.Control>
              );
            } else if (prop === "conditions") {
              control = (
                <div>
                  <Form.Control
                    type="string"
                    as="textarea"
                    style={{ minHeight: 200 }}
                    defaultValue={JSON.stringify(seedData[prop], null, 2)}
                    onChange={(e) => {
                      // _onChange({
                      //   value: e.target.value,
                      //   field: prop,
                      // });
                      console.log(e.target.value);
                    }}
                    className="--font-s"
                  ></Form.Control>
                </div>
              );
            }
            return (
              <Row key={`${key}_${prop}`} className="--flex --align-items-center">
                <Col md={3} className="--shrink-0 --font-s">
                  {propLabel}
                </Col>
                <Col md={9}>{control}</Col>
              </Row>
            );
          })}
        </div>
      );
    }
    return el;
  };
  const getSeedDataLayout = (options: { data: any; schema: any; inArray: boolean; index?: number }) => {
    const { data = {}, schema = {}, index } = options;
    return (
      <div>
        {Object.keys(schema).map((field, i) => {
          let el: ReactElement[] = [];
          const isArrayMember = schema[field].type === "array" || schema[field].type.indexOf("[]") !== -1;
          if (!isArrayMember) {
            const _ = getValueInputLayout({ value: data[field], field, valueType: schema[field].type, index });
            if (_) el.push(_);
          } else {
            const memberSchema = schema[field];
            const _ = data[field]
              .map((d: any, subSerialNumber: number) => {
                return getValueInputLayout({ value: d, field, index, subSerialNumber, valueType: memberSchema.type });
              })
              .filter((_: any) => _);
            if (_.length)
              el.push(
                <div className="bg-white p-2" key={`${field}_${i}_list`}>
                  {_}
                </div>
              );
          }
          if (!el) return <></>;
          return (
            <Row key={`${field}_${i}`} className="--align-items-center mb-1">
              {schema[field].type !== "unit" && (
                <Col className="--font-s" md={3}>
                  {schema[field].label}
                </Col>
              )}
              <Col md={schema[field].type !== "unit" ? 9 : 12}>{el}</Col>
            </Row>
          );
        })}
      </div>
    );
  };

  if (!seedDataTypes?.data?.type) return <></>;
  return (
    <section>
      {seedDataTypes.data.type === "object" ? (
        <Row className="mb-3">
          <Col>
            <div className="bg-light px-2 py-1">
              {getSeedDataLayout({
                data: seedData,
                schema: seedDataTypes.data.schema,
                inArray: false,
              })}
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="mb-3">
          <Col>
            <div className="bg-light px-2 py-1">
              <div>
                {seedData?.map &&
                  seedData.map((d: any, __di: number) => (
                    <Row key={`data_${__di}`} className="--align-items-center">
                      <Col md={11}>
                        <div className="border-bottom my-1 py-1">
                          {getSeedDataLayout({
                            data: d,
                            schema: seedDataTypes.data.schema,
                            index: __di,
                            inArray: true,
                          })}
                        </div>
                      </Col>
                      <Col md={1}>
                        <span className="--text-annotation --cursor-pointer --font-s" onClick={() => false}>
                          削除
                        </span>
                      </Col>
                    </Row>
                  ))}
              </div>
            </div>
          </Col>
        </Row>
      )}
    </section>
  );
}

export default App;
