import {
  UnitTemplate,
  EvaluationModuleCommonProps,
  EvaluationHelperProduct,
  EvaluationAnswerMetaData,
} from "../EvaluationTypes";
import __stepValues from "./stepValues";
import __computingRule from "./computingRule";

const AnswerRowModule = (
  common: EvaluationModuleCommonProps,
  props: {
    origin: {
      row: number;
      column: number;
    };
    iteration: {
      factors?: string[];
      meta?: EvaluationAnswerMetaData;
      type?: string;
      helper?: string;
      relatedPhase?: string;
      unitSize?: number;
      isAnswer?: boolean;
      description?: string;
    }[];
  }
): EvaluationHelperProduct => {
  const CSSBundleUsageMap = {
    DEFAULT: ["normal_cell"],
  };
  const units = [] as UnitTemplate[];
  const current = {
    row: props.origin.row,
    column: props.origin.column,
  };
  const context = { ...common.context };
  const computedUnitMap = [] as {
    helper: string;
    unitTemplate: UnitTemplate;
  }[];
  const maxUnitSize = Math.max(...props.iteration.map((_) => _.unitSize || 1));
  props.iteration.forEach(
    ({ factors, meta, type, helper, relatedPhase = "", unitSize = 1, isAnswer, description: _description }, index) => {
      const thisPhase = context.phaseTemplates.find((p) => p.key === relatedPhase);
      const behaviorId = thisPhase ? `${thisPhase.key}_input` : "";
      for (let order = 0; order < unitSize; order++) {
        const id = `${common.unitGroupId}_${current.row}_${current.column}__order${order}`;
        const factorUnitIds = [...(factors || []), `Row${index + 1}`].filter((_) => _);
        const gridRow = current.row + order;
        const gridRowSpan = order === unitSize - 1 ? maxUnitSize - order : 1;
        const description = _description || factorUnitIds.join(" - ") || "";
        const answerMetaData = isAnswer
          ? {
              axis: meta?.axis ?? "",
              label: meta?.label ? (unitSize > 1 ? `${meta.label} - ${order + 1}` : meta.label) : "",
              relatedPhase: meta?.relatedPhase ?? "",
              path: meta?.path ?? [],
            }
          : undefined;
        if (type === "text") {
          units.push({
            type: "text",
            id,
            description,
            factorUnitIds,
            answerMetaData,
            gridRow,
            gridColumn: current.column,
            gridRowSpan,
            gridColumnSpan: 1,
            unitGroupId: common.unitGroupId,
            subRoutineId: common.subRoutineId,
            behaviorId,
            style: CSSBundleUsageMap["DEFAULT"].reduce((prev, current) => {
              return {
                ...prev,
                ...common.context.cssTraits[current]?.styles,
              };
            }, {}),
          });
        } else if (type === "select") {
          if (helper) {
            context.commonSelectOptions = context.commonSelectOptions || {};
            if (!common.context.commonSelectOptions[helper]) {
              context.commonSelectOptions = {
                ...context.commonSelectOptions,
                ...__stepValues(helper),
              };
            }
          } else {
            throw new Error("select type needs helper");
          }
          units.push({
            type: "select",
            id,
            description,
            factorUnitIds,
            answerMetaData,
            optionsKey: helper,
            gridRow,
            gridColumn: current.column,
            gridRowSpan,
            gridColumnSpan: 1,
            behaviorId,
            unitGroupId: common.unitGroupId,
            subRoutineId: common.subRoutineId,
            style: CSSBundleUsageMap["DEFAULT"].reduce((prev, current) => {
              return {
                ...prev,
                ...common.context.cssTraits[current]?.styles,
              };
            }, {}),
          });
        } else if (type === "computed") {
          if (!helper) {
            throw new Error("select type needs helper");
          }
          computedUnitMap.push({
            helper: helper + `&order=${order}`,
            unitTemplate: {
              type: "computed",
              id,
              description,
              factorUnitIds,
              answerMetaData,
              conditions: [], // あとでアップデートされる
              gridRow,
              gridColumn: current.column,
              gridRowSpan,
              gridColumnSpan: 1,
              behaviorId,
              unitGroupId: common.unitGroupId,
              subRoutineId: common.subRoutineId,
              style: CSSBundleUsageMap["DEFAULT"].reduce((prev, current) => {
                return {
                  ...prev,
                  ...common.context.cssTraits[current]?.styles,
                };
              }, {}),
            },
          });
        }
      }

      current.column++;
    }
  );
  computedUnitMap.forEach(({ helper, unitTemplate }) => {
    if (helper) {
      const { computedKey, data, unit } = __computingRule({
        query: helper,
        units,
        computedUnit: unitTemplate,
      });
      if (data) context.computingRuleSet[computedKey] = data;
      units.push(unit);
    } else {
      throw new Error("computed type needs helper");
    }
  });
  return {
    units: [...units],
    origin: { row: props.origin.row, column: props.origin.column },
    occupied: { row: current.row, column: current.column },
    context,
    meta: {},
  };
};
export default AnswerRowModule;
