import { useState, useMemo, useEffect, useCallback } from "react";
import { Row, Col, Card, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { getRegularColumns, selectClientState } from "../client/clientSlice";
import {
  ProfileSubField,
  FieldValue,
  ValidDateMap,
  ProfileFile,
  isRequiredField,
  isDisplayField,
  Action,
  copyValues,
} from "../../features/profile/profileFieldValues";
import { ProfileFieldStatus, USER_TABLE_PREFIX } from "../../features/profile/profileValues";
import ProfileSubFieldValue from "../../features/profile/ProfileSubFieldValue";
import ProfileSubFieldInput from "../../features/profile/ProfileSubFieldInput";
import SnapshotTimeSelector, { onChangeOption } from "../../features/profile/SnapshotTimeSelector";
import { TableCol } from "../../component/Table";
import Table from "../../component/Table";
import classNames from "classnames";
import { profileSectorStaff } from "./profileValues";
import { checkDuplication, selectProfileState, validatePermissions } from "./profileSlice";
import { selectUserState } from "../../features/login/userSlice";
import {
  clearMasterCodeChoicesForIndividual,
  getMasterCodeChoicesForIndividual,
  checkDuplication as checkMasterDuplication,
  checkCircularReference,
} from "../../features/masterData/masterDataSlice";
import { MasterCodeChoiceForIndividual, MASTER_TABLE_PREFIX } from "../masterData/masterDataValues";
import { isPermitted } from "../permission/permissionValues";
import ModalDialog from "../../component/ModalDialog";
import { getAllTerms } from "../../app/translate";
import Icon from "../../component/Icon";
import { DecodedFileData } from "../../component/Uploader";
import { getComplementData } from "../apply/applySlice";

function ProfileField({
  fieldName,
  table = "",
  labelMap = { ja: {} },
  accountId = 0,
  subFields,
  fieldStatus,
  onChange,
  onEditOrCancel,
  onCommit,
  onDelete,
  isEditing = false,
  useControl = true,
  ignorePermission = false,
  showHeader = true,
  dataType = "user",
  pageIndex,
  onSelectRow,
  onChangeToHistory,
  onChangeFieldStatus,
  files,
  onFileAdd,
  onFileDownload,
  onFileDelete,
}: {
  fieldName: string;
  table?: string;
  labelMap?: { [lang: string]: { [raw: string]: string } };
  accountId?: number;
  subFields: ProfileSubField[];
  fieldStatus: ProfileFieldStatus;
  onChange?: (subFields: ProfileSubField[], updatedSubFieldIndex: number) => any;
  onEditOrCancel?: (subFields: ProfileSubField[], isEditing?: boolean) => any;
  onCommit?: (subFields: ProfileSubField[], validDateMap: ValidDateMap) => any;
  onDelete?: (subFields: ProfileSubField[], recordId: number) => any;
  isEditing?: boolean;
  useControl?: boolean;
  ignorePermission?: boolean;
  showHeader?: boolean;
  dataType?: "user" | "master" | "application";
  pageIndex?: number;
  onSelectRow?: (id: string | number, d: (string | string[])[], c: TableCol[]) => any;
  onChangeToHistory?: () => any;
  onChangeFieldStatus?: (fieldStatus: ProfileFieldStatus) => any;
  files?: ProfileFile[];
  onFileAdd?: (subField: ProfileSubField, decodedFileData: DecodedFileData) => any;
  onFileDownload?: (fileId: string, key: string) => void;
  onFileDelete?: (fileId: string, key: string) => any;
}) {
  const dispatch = useAppDispatch();
  const [_isEditing, $_isEditing] = useState(isEditing); // 修正 または 履歴追加 の場合は true
  const [fieldStatusInProfileField, $fieldStatusInProfileField] = useState({} as ProfileFieldStatus);
  const [standardRecordId, $standardRecordId] = useState(0);
  const [validDateMap, $validDateMap] = useState({} as ValidDateMap);
  const [initValidDateMap, $initValidDateMap] = useState({} as ValidDateMap);
  const [deletedRecordIds, $deletedRecordIds] = useState([] as number[]);
  const [activeModal, $activeModal] = useState("");
  const [timeoutId, $timeoutId] = useState(null as number | null);
  const recordIdsString = subFields.map((s) => s.record.id).join(",");
  const { sectorRegularColumns } = useAppSelector(selectClientState);
  const { policies } = useAppSelector(selectUserState);
  const { permissionsByTargetAccount } = useAppSelector(selectProfileState);
  const TERMS = getAllTerms();
  const isCreating = useMemo(() => {
    // 履歴追加 の場合は true
    return isEditing && fieldStatus[fieldName] && fieldStatus[fieldName].errorMessage === "入力してください";
  }, [isEditing]);

  useEffect(() => {
    return () => {
      dispatch(clearMasterCodeChoicesForIndividual());
    };
  }, []);

  const _fieldStatus = useMemo(() => {
    // 親コンポーネントでのバリデートの結果と、このコンポーネントでのバリデートの結果をマージして保持しておく
    return { ...fieldStatus, ...fieldStatusInProfileField };
  }, [fieldStatus, JSON.stringify(fieldStatusInProfileField)]);

  useEffect(() => {
    // 開始日の定義がまだの場合、編集中ではない場合何もしない
    if (!validDateMap[standardRecordId]?.valid_from || !_isEditing) return;
    // code系のフィールドがなければ何もしない
    if (!subFields.some((_) => _.type === "code")) return;

    (async () => {
      // code系のフィールドの選択肢を再取得する必要があるときのみ、updateSubFieldsを実行
      const needUpdateSubFields = subFields
        .filter((_) => _.type === "code")
        .some((_) => !_.labelValueOptions || _.labelValueOptions?.some((__) => __.validTo === undefined));
      let _subFields = subFields;
      if (needUpdateSubFields)
        _subFields = await updateSubFields(subFields, dayjs(validDateMap[standardRecordId].valid_from));
      validateCodeChoices({ subFields: _subFields });
    })();
  }, [validDateMap[standardRecordId]]);

  useEffect(() => {
    /*
      record に変更があった場合のみこの hook を実行したいが、
      オブジェクトを依存変数にすると上手くいかなかったので
      recordIdsString という文字列を依存変数にしている
      */
    const dateMap = {} as ValidDateMap;
    const _standardRecordId = subFields.find((_) => _.tagGroupIndex === 0 && !_.isRelational)?.record.id ?? -1;
    $standardRecordId(_standardRecordId);
    subFields.forEach((s) => {
      // 通常の SubField を抜粋し、その開始日と終了日を ProfileField の開始日・終了日とする
      if (!dateMap[_standardRecordId] && s.type !== "tagHandler" && s.type !== "tableHandler") {
        dateMap[_standardRecordId] = {
          valid_from: s.record.valid_from,
          valid_to: s.record.valid_to,
        };
      } else if ((s.type === "tagHandler" || s.type === "tableHandler") && s.isRelational) {
        const samples = subFields
          .filter((_) => _.tag === s.tag)
          .filter((_) => _.type !== "tagHandler" && _.type !== "tableHandler")
          .reduce((prev, current) => {
            if (!prev.some((p) => p.tagGroupIndex === current.tagGroupIndex && p.tag === current.tag)) {
              prev.push(current);
            }
            return prev;
          }, [] as ProfileSubField[]);
        samples.forEach((s) => {
          dateMap[`${s.record.id}`] = {
            valid_from: s.record.valid_from,
            valid_to: s.record.valid_to,
          };
        });
      }
    });
    $validDateMap({ ...dateMap });
    $initValidDateMap({ ...JSON.parse(JSON.stringify(dateMap)) });
  }, [recordIdsString]);
  useEffect(() => {
    (async () => {
      $_isEditing(isEditing);
      const _standardRecordId = subFields.find((_) => _.tagGroupIndex === 0 && !_.isRelational)?.record.id ?? -1;
      // 履歴追加の初期表示・マスタデータの初期表示時にvalidDateMapを作り直す
      if (isCreating || dataType === "master") {
        if (isCreating) {
          await validateRecord({
            id: "valid_from",
            value: dayjs().format("YYYY-MM-DD"),
            tagGroupIndex: 1,
            recordId: _standardRecordId,
          });
        }
        const dateMap = {
          [_standardRecordId]: (() => {
            if (isCreating) {
              return {
                valid_from: dayjs().format("YYYY-MM-DD"),
                valid_to: "",
              };
            } else {
              const { valid_from, valid_to } = subFields?.[0].record;
              return { valid_from, valid_to };
            }
          })(),
        };
        updateSubFields(subFields, dayjs(dateMap[_standardRecordId].valid_from)).then(
          (updatedSubFields) => onEditOrCancel && onEditOrCancel(updatedSubFields, true)
        );
        $validDateMap({ ...dateMap });
        $initValidDateMap({ ...JSON.parse(JSON.stringify(dateMap)) });
      }
    })();
  }, [isEditing]);

  const sectorId = useMemo(() => {
    return dataType === "user" ? `${USER_TABLE_PREFIX}${table}` : `${MASTER_TABLE_PREFIX}${table}`;
  }, [table, dataType]);

  const resource = useMemo(() => {
    return dataType === "user" ? `user_data_manager/${table}` : `master_data_manager/${table}`;
  }, [table, dataType]);

  const subFieldIdFiles = useMemo(() => {
    return (
      files?.reduce((prev, current) => {
        const subField = subFields.find((s) => s.value === current.id);
        if (!subField) return prev;
        return { ...prev, [subField.id]: current };
      }, {} as { [key: string]: ProfileFile }) ?? ({} as { [key: string]: ProfileFile })
    );
  }, [files]);

  useEffect(() => {
    useControl && table !== "" && !sectorRegularColumns[sectorId] && dispatch(getRegularColumns({ sectorId }));
  }, [sectorId]);

  const validateRecord = async (next: { [key: string]: any }) => {
    // この時点でsectorRegularColumnsが存在しないのは0件からの履歴追加時のため、重複チェックは不要
    if (Object.keys(sectorRegularColumns).length === 0 || !sectorRegularColumns[sectorId]) return;

    // 重複チェック用のキーを作成
    // 新規コードでのコードテーブル履歴追加の場合:valid_fromはチェックに含めない
    const isNewCodeMasterRecord =
      dataType === "master" &&
      subFields[0].record.id < 0 &&
      subFields.find((s) => s.id === `${fieldName}_code`)?.editable;
    const uniqueKeyCols = sectorRegularColumns[sectorId].filter(
      (f) => f.isKey && f.id !== "login_code" && !(isNewCodeMasterRecord && f.id === "valid_from")
    );
    const uniqueKeys = uniqueKeyCols.map((f) => f.id);
    if (!uniqueKeys.includes(next.id)) return; // キーに変更がない場合は重複チェックを行わない

    // valid_from以外のユニークキーに対して、変更された場合はその値を、変更対象ではない場合は直前の値を保持する
    const _uniqueKeyFields = subFields.filter((_) => _.id !== "valid_from" && uniqueKeys.includes(_.id));
    const _keys = _uniqueKeyFields.map((_) => _.id);
    let _validCodeMap = {} as { [key: string]: any };
    _keys.forEach((k) => {
      _validCodeMap[k] = next.id === k ? next.value : _uniqueKeyFields.find((_) => _.id === k)?.value;
    });
    // 修正時は、元データのユニークキーと修正中のユニークキーが一致する場合は、validationを空にする
    const isInitValue =
      _keys.length === 0 ? true : _keys.every((k) => k in initValueMap && _validCodeMap[k] === initValueMap[k]);
    const _validFrom = next.id === "valid_from" ? next.value : validDateMap[next.recordId].valid_from;
    if (!isCreating && initValidDateMap[next.recordId].valid_from === _validFrom && isInitValue) {
      fieldStatusInProfileField[`${fieldName}/valid_from_${next.tagGroupIndex}`] = {
        validated: true,
        errorMessage: "",
      };
      $fieldStatusInProfileField(fieldStatusInProfileField);
      return;
    }

    // 重複チェック用の条件を作成
    const conditions = {} as { [key: string]: any };
    const _subFields = subFields.some((s) => s.type === "tagHandler")
      ? subFields
          .filter((_) => next.tagGroupIndex && _.tagGroupIndex === next.tagGroupIndex)
          .map((s: any) => {
            return {
              ...s,
              id: s.id.split(/_\d+/).join(""),
            };
          })
      : subFields;
    uniqueKeys.forEach((k) => {
      if (k === "valid_from") {
        conditions[k] = _validFrom;
      } else if (k === next.id) {
        conditions[k] = next.value;
      } else {
        const _s = _subFields.filter((s: any) => s.id === k);
        conditions[k] = _s[0]?.value || _s[0]?.defaultValue;
      }
    });
    // 必須項目のキーに空/nullが含まれる場合 または 家族テーブルのシリアルがnull（新しい家族の履歴追加）の場合 重複チェックを行わない
    // （例）アクセス可能な部署の役職コードはユニークキーの一部だがnullを許容するため、値がnullであっても重複チェックを行う
    const conditionValues = Object.values(conditions);
    for (let i = 0; i < uniqueKeyCols.length; i++) {
      if (
        (uniqueKeyCols[i].required && (conditionValues[i] === "" || conditionValues[i] === null)) ||
        (uniqueKeyCols[i].id === "serial" && conditionValues[i] === null)
      ) {
        // 値あり→空となった可能性もあるので、結果をリセット
        fieldStatusInProfileField[`${fieldName}/valid_from_${next.tagGroupIndex}`] = {
          validated: true,
          errorMessage: "",
        };
        $fieldStatusInProfileField(fieldStatusInProfileField);
        return;
      }
    }

    // 重複チェック
    const res: any =
      dataType === "user"
        ? await dispatch(checkDuplication({ table, accountId, uniqueKeysConditions: conditions }))
        : await dispatch(checkMasterDuplication({ table, conditions }));
    const validated = !res.payload?.isDuplicated;
    const subFieldName = isNewCodeMasterRecord
      ? `${fieldName}/${uniqueKeyCols[0].id}`
      : `${fieldName}/valid_from_${next.tagGroupIndex}`;
    fieldStatusInProfileField[`${subFieldName}`] = {
      validated: validated,
      errorMessage: validated
        ? ""
        : isNewCodeMasterRecord
        ? `指定された${uniqueKeyCols[0].label}のレコードは既に存在しています。値を変更してください。`
        : "指定された開始日のレコードはすでに存在しています。データ有効期間を変更してください。",
    };
    $fieldStatusInProfileField(fieldStatusInProfileField);
    isNewCodeMasterRecord && onChangeFieldStatus && onChangeFieldStatus(fieldStatusInProfileField);
  };

  /**
   * 選択された終了日が、選択したコードの終了日の範囲内かチェックする
   */
  const validateCodeChoices = (next: { [key: string]: any }) => {
    const targetSubFields: ProfileSubField[] = next.subFields ?? subFields;
    const _validTo = next.id === "valid_to" ? next.value : validDateMap[standardRecordId].valid_to;
    const validTo = _validTo || "9999-12-31";
    const codeFields = targetSubFields.filter((_) => _.type === "code");
    let errorMessage = "";
    for (const key in codeFields) {
      const value = next.id === codeFields[key].id ? next.value : codeFields[key].value;
      const selectedOption = codeFields[key].labelValueOptions?.find((_) => _.value === value);
      if (selectedOption && dayjs(selectedOption.validTo) < dayjs(validTo)) {
        errorMessage += `${selectedOption.label}の有効期間は${selectedOption.validTo}までです。`;
      }
    }
    const nextStatus = {
      ...fieldStatusInProfileField,
      [`${fieldName}/valid_to`]: {
        validated: !errorMessage,
        errorMessage,
      },
    };
    $fieldStatusInProfileField(nextStatus);
  };

  /**
   * 親xxコードとxxコードの循環参照を確認する
   */
  const validateCircularReference = async (next: { [key: string]: any }, updatedSubFields?: ProfileSubField[]) => {
    const parentCodeId = `parent_${fieldName}_code`;
    const codeId = `${fieldName}_code`;
    const parentCodeField = subFields.find((_) => _.id === parentCodeId);
    // 親xxコードが存在しない場合は何もしない
    if (!parentCodeField) return;
    // 変更されたのが親xxコード/開始日でなければ何もしない
    if (![parentCodeId, "valid_from"].includes(next.id)) return;
    const parentCodeValue = next.id === parentCodeId ? next.value : subFields.find((_) => _.id === parentCodeId)?.value;
    // xxコードを取得
    const codeField = subFields.find((_) => _.id === codeId);
    const codeValue = codeField?.value;

    // いずれかの値が存在しない場合は何もしない
    if (parentCodeValue === "" || parentCodeValue === null || codeValue === "" || codeValue === null) return;
    if (updatedSubFields) {
      // 既に選択肢が存在しない場合は何もしない
      const labelValueOptions = updatedSubFields?.find((_) => _.id === parentCodeId)?.labelValueOptions;
      if (!labelValueOptions?.some(({ value }) => value === parentCodeValue)) return;
    }

    const res: any = await dispatch(
      checkCircularReference({
        sector: fieldName,
        parentCode: `${parentCodeValue}`,
        code: `${codeValue}`,
      })
    );
    const validated = !res.payload?.isCircularReference;
    fieldStatusInProfileField[`${fieldName}/${parentCodeId}`] = {
      validated: validated,
      errorMessage: validated ? "" : "循環参照になるため選択できません。",
    };
    $fieldStatusInProfileField(fieldStatusInProfileField);
    onChangeFieldStatus && onChangeFieldStatus(fieldStatusInProfileField);
  };

  const initValues = useMemo(() => {
    /*
      修正される可能性のある value, tagGroupsToUse の修正開始時の値を保持する。
      */
    return subFields.map((d) => ({
      tagGroupsToUse: d.tagGroupsToUse,
      value: d.value,
    }));
  }, [_isEditing]);

  const initValueMap = useMemo(() => {
    /*
      バリデーション用に修正前の{key: value}を保持しておく
    */
    let valueMap = {} as { [key: string]: any };
    if (!subFields.some((_) => _.type === "tableHandler" || _.type === "tagHandler")) {
      subFields.forEach((_) => {
        valueMap[_.id] = _.value;
      });
    }
    return valueMap;
  }, [_isEditing]);

  /**
   * コードの選択肢を subFieldsの labelValueOptions に反映
   */
  const updateSubFields = async (subFields: ProfileSubField[], baseDate: dayjs.Dayjs) => {
    if (subFields.some((_) => _.type === "code")) {
      let updatedSubFields = [] as ProfileSubField[];
      const sectors = subFields
        .filter((_) => _.type === "code")
        .map((_) => (dataType === "user" ? _.id.split("_code")[0] : fieldName));
      // マスターデータのコード情報取得
      await dispatch(
        getMasterCodeChoicesForIndividual({
          baseDate,
          sectors,
        })
      ).then((result: any) => {
        const masterCodeChoicesForIndividual = result.payload as MasterCodeChoiceForIndividual[];
        if (!masterCodeChoicesForIndividual || masterCodeChoicesForIndividual.length === 0) return subFields;
        else {
          // labelValueOptionsの中身を生成して subFieldsに反映
          updatedSubFields = subFields.map((subField: ProfileSubField) => {
            if (subField.type === "code") {
              const sector = dataType === "user" ? subField.id.split("_code")[0] : fieldName;
              let options = [] as any;
              for (const key in masterCodeChoicesForIndividual) {
                if (masterCodeChoicesForIndividual[key].sector === sector) {
                  options = masterCodeChoicesForIndividual[key].options.reduce((prev, current) => {
                    return [
                      ...prev,
                      {
                        label: `（${current.code}）${current.name}`,
                        value: current.code,
                        validTo: current.valid_to,
                        categoryType: current.category_type,
                        categoryOrder: current.category_order,
                      },
                    ];
                  }, [] as { label: string; value: string }[]);
                }
              }
              return {
                ...subField,
                labelValueOptions: options,
                // マスタデータで選択肢が無くなった場合、値を空にする
                value: dataType === "master" && !options?.length ? "" : subField.value,
              };
            } else return subField;
          });
        }
      });
      return updatedSubFields;
    } else {
      return subFields;
    }
  };

  const doActions = async (subFields: ProfileSubField[], actions: Action[], subFieldId: string) => {
    await Promise.all(
      actions.map(({ type, from, mapping, only_same_index }) => {
        return new Promise<ProfileSubField[]>(async (resolve, _) => {
          if (type === "get_value" && from) {
            dispatch(getComplementData({ from, mapping })).then((res) => {
              const complementData = res.payload as any;
              subFields = subFields.map((sf) => {
                if (Object.keys(complementData).includes(sf.id)) {
                  return { ...sf, value: complementData[sf.id], entered: true };
                }
                return sf;
              });
              resolve(subFields);
            });
          } else if (type === "set_value") {
            subFields = subFields.map((sf) => {
              if (Object.keys(mapping).includes(sf.id)) {
                return { ...sf, value: mapping[sf.id], entered: true };
              }
              return sf;
            });
            resolve(subFields);
          } else if (type === "copy_value") {
            // only_same_index=trueの場合、マッピング先のindexも同一のもののみとするため、indexを取得
            const currentIndex = only_same_index && subFieldId.includes("::") ? subFieldId.split("::")[1] : null;
            subFields = copyValues(mapping, subFields, currentIndex);
            resolve(subFields);
          }
        });
      })
    );
    return subFields;
  };

  const edit = async (updatedSubFieldIndex: number, value: FieldValue) => {
    let next = subFields.map((d, i) => {
      if (i !== updatedSubFieldIndex) return d;
      return {
        ...d,
        entered: true,
        value,
      };
    });

    // 編集時のイベントがある場合、実行
    const subField = next[updatedSubFieldIndex];
    const onChangeActions =
      subField.events
        ?.filter(({ value, type }) => type === "on_change" && (value === undefined || value === subField.value))
        .map(({ action }) => action) ?? [];
    if (subField.errorMessage === "" && onChangeActions.length > 0) {
      next = await doActions(next, onChangeActions, subField.id);
    }

    onChange && onChange(next, updatedSubFieldIndex);
  };

  const changeTagGroupIndex = (updatedSubFieldIndex: number, tagGroupsToUse: number[]) => {
    const next = subFields.map((d, i) => {
      if (i !== updatedSubFieldIndex) return d;
      return {
        ...d,
        tagGroupsToUse,
      };
    });
    onChange && onChange(next, updatedSubFieldIndex);
  };

  const showEditOrCancelButtom = () => {
    if (subFields[0].record.id === 0) {
      return (
        <OverlayTrigger
          placement="right"
          delay={{ show: 50, hide: 50 }}
          overlay={(props) => (
            <Tooltip id="info-tooltip" {...props}>
              コード情報の変更により表示されているレコードのため、編集することはできません。
            </Tooltip>
          )}
        >
          <span className="ms-2">
            <Icon width={16} height={16} type="info-circle-fill" />
          </span>
        </OverlayTrigger>
      );
    }
    return (
      <Button
        variant="outline-secondary"
        size="sm"
        className="ms-2 --print-none"
        onClick={() => {
          if (_isEditing) {
            $activeModal("before_canceling");
          } else if (!_isEditing && onEditOrCancel) {
            // 修正ボタン押下時
            updateSubFields(subFields, dayjs(validDateMap[standardRecordId].valid_from)).then(
              (updatedSubFields) => onEditOrCancel && onEditOrCancel(updatedSubFields, true)
            );
            $deletedRecordIds([]);
            $_isEditing(!_isEditing);
          }
        }}
      >
        {_isEditing ? "キャンセル" : "修正"}
      </Button>
    );
  };

  const showEditValidToButtom = (recordId: string) => {
    return (
      <Button
        variant="outline-primary"
        className="ms-2 --print-none"
        onClick={() => {
          // 終了日を編集できるように、valid_toに今日日付を設定
          $validDateMap({
            ...validDateMap,
            [recordId]: {
              ...validDateMap[recordId],
              valid_to: dayjs().format("YYYY-MM-DD"),
            },
          });
        }}
      >
        終了日を編集する
      </Button>
    );
  };

  const showSaveButtom = () => {
    return (
      <Button
        variant="primary"
        size="sm"
        className="ms-2 --print-none"
        disabled={Object.keys(_fieldStatus).some((f) => !_fieldStatus[f].validated)}
        onClick={() => {
          $activeModal("before_saving");
        }}
      >
        保存
      </Button>
    );
  };

  // 保存
  const save = () => {
    const validDateDiff = (() => {
      const validDateDiff = {} as ValidDateMap;
      for (const recordId in validDateMap) {
        validDateDiff[recordId] = validDateDiff[recordId] || {};
        if (validDateMap[recordId].valid_from !== initValidDateMap[recordId].valid_from) {
          validDateDiff[recordId].valid_from = validDateMap[recordId].valid_from;
          validDateDiff[recordId].valid_to = validDateMap[recordId].valid_to;
        }
        if (validDateMap[recordId].valid_to !== initValidDateMap[recordId].valid_to) {
          validDateDiff[recordId].valid_to = validDateMap[recordId].valid_to;
        }
      }
      return validDateDiff;
    })();
    $initValidDateMap({
      ...validDateMap,
    });
    onCommit && onCommit(subFields, validDateDiff);
    $deletedRecordIds([]);
    $_isEditing(false);
    $activeModal("");
  };

  // 履歴削除
  const deleteHistory = () => {
    onDelete && onDelete([], deletedRecordIds[0]);
    $deletedRecordIds([]);
    $activeModal("");
  };

  // キャンセル
  const cancel = () => {
    if (onEditOrCancel) {
      $validDateMap({
        ...initValidDateMap,
      });
      $fieldStatusInProfileField({});
      onEditOrCancel(
        subFields.map((d, i) => {
          if (d.type === "tagHandler" || d.type === "tableHandler") {
            /*
                  削除されたレコードの id を振り直して用意した ProfileSubField の場合、
                  initValues[i] に削除されたレコードの value が入っていることを考慮
                  */
            return {
              ...d,
              value: !deletedRecordIds.includes((d.record.id * -1) / 100) ? initValues[i].value : d.value,
              tagGroupsToUse: !deletedRecordIds.includes((d.record.id * -1) / 100)
                ? initValues[i].tagGroupsToUse
                : d.tagGroupsToUse,
            };
          } else {
            return {
              ...d,
              value: !deletedRecordIds.includes((d.record.id * -1) / 100) ? initValues[i].value : d.value,
            };
          }
        })
      );
      $deletedRecordIds([]);
      $_isEditing(!_isEditing);
      $activeModal("");
    }
  };

  const isRequired = useCallback(
    (subField: ProfileSubField) => {
      return isRequiredField(subField, subFields);
    },
    [subFields]
  );

  const isDisplay = useCallback(
    (subField: ProfileSubField) => {
      return isDisplayField(subField, subFields);
    },
    [subFields]
  );

  return (
    <Card className="my-1 my-md-0" border={_isEditing ? "secondary" : ""}>
      {showHeader && (
        <Card.Header id={`record_${standardRecordId}`}>
          <span className="--bold">
            {labelMap["ja"][fieldName]}
            {useControl && _isEditing && !isCreating ? "の修正" : ""}
            {useControl && _isEditing && isCreating ? "の履歴追加" : ""}
          </span>
          {/* 個別画面(useControl)　かつ　参照時(!_isEditing)は権限がある場合に編集ボタンを表示　編集時(_isEditing)は権限に関係なくキャンセルボタンを表示 */}
          {useControl &&
            subFields.some((_) => _.editable) &&
            ((dataType === "master" && isPermitted(policies, resource, "POST")) ||
              (dataType === "user" && permissionsByTargetAccount[+accountId]?.[fieldName]?.["is_edit_permitted"])) &&
            showEditOrCancelButtom()}
          {/* 個別画面(useControl)　かつ　編集時(!_isEditing)　かつ　権限がある　場合に保存ボタンを表示 */}
          {useControl &&
            _isEditing &&
            ((dataType === "master" && isPermitted(policies, resource, "POST")) ||
              (dataType === "user" && permissionsByTargetAccount[+accountId]?.[fieldName]?.["is_edit_permitted"])) &&
            showSaveButtom()}
          {/* 個別画面(useControl)　かつ　編集時(!_isEditing)　かつ　履歴追加ではない(!isCreating)　かつ　権限がある場合　に履歴削除ボタンを表示 */}
          {useControl &&
            _isEditing &&
            !isCreating &&
            ((dataType === "master" && isPermitted(policies, resource, "POST")) ||
              (dataType === "user" && permissionsByTargetAccount[+accountId]?.[fieldName]?.["is_edit_permitted"])) &&
            subFields.filter((s) => s.isRelational).length === 0 && (
              <Button
                variant="danger"
                size="sm"
                className="--print-none float-end"
                onClick={() => {
                  $deletedRecordIds([...deletedRecordIds, subFields[0].record.id]);
                  $activeModal("before_deleting");
                }}
              >
                履歴削除
              </Button>
            )}
        </Card.Header>
      )}
      <Card.Body>
        {(() => {
          const elements: JSX.Element[] = [];
          const attachedFields: string[] = [];
          subFields.forEach((d, i) => {
            if (d.pageIndex !== undefined && pageIndex !== undefined && d.pageIndex !== pageIndex) return;
            if (labelMap["ja"][d.id] === undefined) return; // 非表示設定されている場合
            if (!isDisplay(d)) return;
            if (attachedFields.includes(d.id)) return;
            if (d.type === "staticLine") {
              elements.push(
                <Col md={12} key={`editable-row-${i}`}>
                  <div className="Apply-form__row-line"></div>
                </Col>
              );
              return;
            }
            if (d.type === "staticText") {
              elements.push(
                <Col md={12} key={`editable-row-${i}`}>
                  <div className="Apply-form__row">{d.value}</div>
                </Col>
              );
              return;
            }
            if (d.type !== "tagHandler" && d.type !== "tableHandler") {
              // 詳細表示（1レコード）の場合
              // プレースホルダー・注釈の取得
              const placeholder = TERMS[`PLACEHOLDER_${d.termsKey}`];
              const editingAnnotation = TERMS[`EDITING_ANNOTATION_${d.termsKey}`];
              const detailAnnotation = TERMS[`DETAIL_ANNOTATION_${d.termsKey}`];
              // onClickイベントで、マッピング先が編集中のものがあればボタンを表示
              const onClickEvents = d.events?.filter(
                ({ type, action }) =>
                  type === "on_click" &&
                  Object.keys(action.mapping).some((k) => subFields.find((_) => _.id.split("::")[0] === k)?.editable)
              );
              const file = (() => {
                if (d.type !== "file") return undefined;
                if (isCreating) return subFieldIdFiles?.[d.id];
                return files?.find((f) => f.id === d.value);
              })();
              elements.push(
                <Col
                  md={12}
                  key={`editable-row-${i}`}
                  className={classNames({
                    "--text-annotation": !_isEditing && d.highlighted,
                    "--value-row": true,
                  })}
                >
                  <Row className="align-items-center my-1">
                    <Col md={6}>
                      <span
                        className={classNames({
                          "--bold": true,
                          "--required-label": isRequired(d),
                        })}
                      >
                        {labelMap["ja"][d.id]}
                      </span>
                      {(_isEditing && editingAnnotation) || (!_isEditing && detailAnnotation) ? (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 50, hide: 50 }}
                          overlay={(props) => (
                            <Tooltip id={`tooltip_${d.id}`} {...props}>
                              {editingAnnotation || detailAnnotation}
                            </Tooltip>
                          )}
                        >
                          <span className="ms-1">
                            <Icon width={15} height={15} type="info-circle-fill" />
                          </span>
                        </OverlayTrigger>
                      ) : null}
                      {_isEditing &&
                        d.value !== "" &&
                        d.value !== null &&
                        onClickEvents?.map(({ button, action }, i) => {
                          return (
                            <Button
                              key={`action_${i}`}
                              variant="outline-primary"
                              size="sm"
                              className="ms-2 --print-none"
                              onClick={async () => {
                                subFields = await doActions(subFields, [action], d.id);
                                onChange && onChange(subFields, i);
                              }}
                            >
                              {button}
                            </Button>
                          );
                        })}
                    </Col>
                    <Col md={6} className="my-1" data-testid={d.id}>
                      {_isEditing ? (
                        <ProfileSubFieldInput
                          subField={d}
                          entered={!!_fieldStatus[`${fieldName}/${d.id}`]}
                          errorMessage={_fieldStatus[`${fieldName}/${d.id}`]?.errorMessage}
                          onChange={async (v) => {
                            edit(i, v ?? "");
                            if (useControl) {
                              const validateRecordParam = {
                                id: d.id,
                                value: v,
                                tagGroupIndex: 1,
                                recordId: d.record.id,
                              };
                              if (["code", "options"].includes(d.type)) {
                                // 既存の変更との重複チェックを行う
                                await validateRecord(validateRecordParam);
                                // コード変更の終了日のバリデーションを行う
                                validateCodeChoices({
                                  id: d.id,
                                  value: v,
                                });
                                if (dataType === "master" && d.type === "code")
                                  await validateCircularReference(validateRecordParam);
                              } else if (d.type === "string") {
                                // テキストインプットの場合、1文字毎にリクエストが走るため0.5秒の猶予を設ける
                                if (timeoutId) window.clearTimeout(timeoutId);
                                const _timeoutId = window.setTimeout(async () => {
                                  await validateRecord(validateRecordParam);
                                  $timeoutId(null);
                                }, 500);
                                $timeoutId(_timeoutId);
                              }
                            }
                          }}
                          placeholder={placeholder}
                          file={file}
                          dataType={dataType}
                          label={labelMap["ja"][d.id]}
                          onFileAdd={onFileAdd}
                          onFileDelete={onFileDelete}
                          onFileDownload={onFileDownload}
                        />
                      ) : (
                        <ProfileSubFieldValue subField={d} file={file} onFileDownload={onFileDownload} />
                      )}
                    </Col>
                  </Row>
                </Col>
              );
              attachedFields.push(d.id);
            } else if (
              // 詳細表示（複数レコード）の場合
              d.type === "tagHandler" &&
              d.tag &&
              d.tagGroupsToUse &&
              !Number.isNaN(d.minTagGroupsLength) &&
              !Number.isNaN(d.maxTagGroupsLength)
            ) {
              // プレースホルダー・注釈の取得
              const editingAnnotation = TERMS[`EDITING_ANNOTATION_${d.termsKey}`];
              const detailAnnotation = TERMS[`DETAIL_ANNOTATION_${d.termsKey}`];
              const thisTagsElements = (
                <Col md={12} key={`editable-row-${i}`}>
                  {standardRecordId > 0 && (
                    <Row className="align-items-center my-1">
                      <Col
                        md={6}
                        className={classNames({
                          "--bold": true,
                          "--required-label": isRequired(d),
                        })}
                      >
                        {labelMap["ja"][d.id]}
                        {(_isEditing && editingAnnotation) || (!_isEditing && detailAnnotation) ? (
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 50, hide: 50 }}
                            overlay={(props) => (
                              <Tooltip id={`tooltip_${d.id}`} {...props}>
                                {editingAnnotation || detailAnnotation}
                              </Tooltip>
                            )}
                          >
                            <span className="ms-1">
                              <Icon width={15} height={15} type="info-circle-fill" />
                            </span>
                          </OverlayTrigger>
                        ) : null}
                      </Col>
                    </Row>
                  )}
                  <div className="px-2">
                    {(() => {
                      const group: JSX.Element[] = [];
                      d.tagGroupsToUse.forEach((_tagGroupIndex) => {
                        const fieldsOfThisGroup = subFields
                          .filter((_) => _.tag === d.tag)
                          .filter((_) => _.tagGroupIndex === _tagGroupIndex)
                          .filter((_) => _.type !== "tagHandler")
                          .filter((_) => labelMap["ja"][_.id.replace(/_\d+$/, "")])
                          .filter((_) => isDisplay(_))
                          .filter((_) => !attachedFields.includes(_.id));
                        fieldsOfThisGroup.length > 0 &&
                          group.length < d.maxTagGroupsLength &&
                          // 最大登録数を超えた場合は表示しない
                          group.push(
                            <Card className="my-2" key={`group_of_tag${_tagGroupIndex}_${i}`}>
                              <Card.Header id={`record_${fieldsOfThisGroup[0].record.id}`}>
                                {/*
                                  - 通勤手当の通勤経路および家族の場合は、_tagGroupIndex も表示する
                                  - ただし、家族の履歴追加の場合、このタイミングでは何件目の追加かという情報を保持していないため、必ず 1 となる _tagGroupIndex は表示しない
                                  */}
                                {labelMap["ja"][d.id]} {d.isRelational && isCreating ? "" : _tagGroupIndex}
                                {
                                  /*
                                  - 家族の履歴削除 (_isEditing && !isCreating)
                                  */
                                  d.isRelational &&
                                    _isEditing &&
                                    !isCreating &&
                                    ((dataType === "master" && isPermitted(policies, resource, "POST")) ||
                                      (dataType === "user" &&
                                        permissionsByTargetAccount[+accountId]?.[fieldName]?.[
                                          "is_edit_permitted"
                                        ])) && (
                                      <Button
                                        variant="danger"
                                        disabled={d.tagGroupsToUse.length <= d.minTagGroupsLength}
                                        size="sm"
                                        className="--print-none float-end"
                                        onClick={() => {
                                          $deletedRecordIds([...deletedRecordIds, fieldsOfThisGroup[0].record.id]);
                                          $activeModal("before_deleting");
                                        }}
                                      >
                                        履歴削除
                                      </Button>
                                    )
                                }
                                {
                                  /*
                                  - 通勤手当の通勤経路・振込口座の削除
                                  */
                                  !d.isRelational && _isEditing && (
                                    <Button
                                      variant={"outline-danger"}
                                      size="sm"
                                      className="mx-2 --print-none"
                                      onClick={() => {
                                        /*
                                        - 削除される ProfileSubField の値の初期化
                                        - tagGroupsToUse の更新
                                        */
                                        const tagGroupsToUse = d.tagGroupsToUse
                                          .filter((n) => n !== _tagGroupIndex)
                                          .map((n) => {
                                            if (n < _tagGroupIndex) return n;
                                            else return n - 1;
                                          });
                                        const next = subFields.map((s) => {
                                          // tagHandler の tagGroupsToUse を更新
                                          if (s.id === d.id) {
                                            return {
                                              ...s,
                                              tagGroupsToUse,
                                              entered: false,
                                            };
                                          } else if (s.tag === d.tag && s.tagGroupIndex >= _tagGroupIndex) {
                                            // 削除対象のグループ以降は値をずらす
                                            const nextId = s.id.replace(/\d/, (s.tagGroupIndex + 1).toString());
                                            const nextValue = subFields.find((_) => _.id === nextId)?.value;
                                            return {
                                              ...s,
                                              value: nextValue ?? s.defaultValue ?? "",
                                              entered: _tagGroupIndex <= s.maxTagGroupsLength ? true : false,
                                            };
                                          } else {
                                            return s;
                                          }
                                        });
                                        onChange && onChange(next, -1);
                                      }}
                                    >
                                      削除
                                    </Button>
                                  )
                                }
                              </Card.Header>
                              <Card.Body>
                                {fieldsOfThisGroup.map((_d) => {
                                  if (_d.type === "staticLine") {
                                    return (
                                      <Row key={`group_of_tag${_tagGroupIndex}_${i}_${_d.id}`}>
                                        <Col md={12}>
                                          <div className="Apply-form__row-line"></div>
                                        </Col>
                                      </Row>
                                    );
                                  }
                                  if (_d.type === "staticText") {
                                    return (
                                      <Row key={`group_of_tag${_tagGroupIndex}_${i}_${_d.id}`}>
                                        <Col md={12}>
                                          <div className="Apply-form__row">{_d.value}</div>
                                        </Col>
                                      </Row>
                                    );
                                  }
                                  const editingAnnotation = TERMS[`EDITING_ANNOTATION_${_d.termsKey}`];
                                  const detailAnnotation = TERMS[`DETAIL_ANNOTATION_${_d.termsKey}`];
                                  const _id = _d.id.replace(/_\d+$/, "");
                                  const placeholder = TERMS[`PLACEHOLDER_${_d.termsKey}`];
                                  const file = (() => {
                                    if (_d.type !== "file") return undefined;
                                    if (isCreating) return subFieldIdFiles?.[_d.id];
                                    return files?.find((f) => f.id === _d.value);
                                  })();
                                  return (
                                    <Row
                                      key={`group_of_tag${_tagGroupIndex}_${i}_${_d.id}`}
                                      className="--align-items-center --value-row"
                                    >
                                      <Col
                                        md={6}
                                        className={classNames({
                                          "--bold": true,
                                          "--required-label": isRequired(_d),
                                        })}
                                      >
                                        {labelMap["ja"][_id]}
                                        {(_isEditing && editingAnnotation) || (!_isEditing && detailAnnotation) ? (
                                          <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 50, hide: 50 }}
                                            overlay={(props) => (
                                              <Tooltip id={`tooltip_${d.id}`} {...props}>
                                                {editingAnnotation || detailAnnotation}
                                              </Tooltip>
                                            )}
                                          >
                                            <span className="ms-1">
                                              <Icon width={15} height={15} type="info-circle-fill" />
                                            </span>
                                          </OverlayTrigger>
                                        ) : null}
                                      </Col>
                                      <Col md={6} className="my-1" data-testid={_d.id}>
                                        {_isEditing ? (
                                          <ProfileSubFieldInput
                                            subField={_d}
                                            entered={!!_fieldStatus[`${fieldName}/${_d.id}`]}
                                            errorMessage={_fieldStatus[`${fieldName}/${_d.id}`]?.errorMessage}
                                            onChange={async (v) => {
                                              if (useControl && ["code", "options"].includes(_d.type)) {
                                                await validateRecord({
                                                  id: _d.id.split(/_\d+/).join(""),
                                                  value: v,
                                                  tagGroupIndex: _d.tagGroupIndex,
                                                  recordId: _d.record.id,
                                                });
                                              }
                                              edit(
                                                subFields.findIndex((_) => _.id === _d.id),
                                                v === null ? "" : v
                                              );
                                            }}
                                            placeholder={placeholder}
                                            file={file}
                                            dataType={dataType}
                                            label={labelMap["ja"][_id]}
                                            onFileAdd={onFileAdd}
                                            onFileDelete={onFileDelete}
                                            onFileDownload={onFileDownload}
                                          />
                                        ) : (
                                          <ProfileSubFieldValue
                                            subField={_d}
                                            file={file}
                                            onFileDownload={onFileDownload}
                                          />
                                        )}
                                      </Col>
                                    </Row>
                                  );
                                })}
                                {d.isRelational && !_isEditing && (
                                  <Card className="mx-2 mt-3">
                                    {validDateMap[`${fieldsOfThisGroup[0]?.record.id}`] && (
                                      // 詳細表示（複数レコード）の場合
                                      <Card.Header>
                                        <Col>データ有効期間</Col>
                                      </Card.Header>
                                    )}
                                    {validDateMap[`${fieldsOfThisGroup[0]?.record.id}`] && (
                                      <Row>
                                        <Col md="5" className="--text-align-center py-3">
                                          {dayjs(validDateMap[`${fieldsOfThisGroup[0].record.id}`].valid_from).format(
                                            "YYYY 年 MM 月 DD 日"
                                          )}
                                        </Col>
                                        <Col md="2" className="--text-align-center py-3">
                                          から
                                        </Col>
                                        {validDateMap[`${fieldsOfThisGroup[0].record.id}`].valid_to &&
                                        validDateMap[`${fieldsOfThisGroup[0].record.id}`].valid_to < "9999-12-31" ? (
                                          <Col md="5" className="--text-align-center py-3">
                                            {dayjs(validDateMap[`${fieldsOfThisGroup[0].record.id}`].valid_to).format(
                                              "YYYY 年 MM 月 DD 日"
                                            )}
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                      </Row>
                                    )}
                                  </Card>
                                )}
                                {d.isRelational && _isEditing ? (
                                  <div>
                                    <Row className="mt-4 mb-2">
                                      <Col className="--bold">データ有効期間</Col>
                                    </Row>
                                    {validDateMap[`${fieldsOfThisGroup[0]?.record.id}`]?.valid_from && (
                                      <Row>
                                        <Col md="5" data-testid="valid_from">
                                          <SnapshotTimeSelector
                                            selectedPointDate={dayjs(
                                              validDateMap[`${fieldsOfThisGroup[0].record.id}`].valid_from
                                            )}
                                            onChange={async ({ selectedPointDate }: onChangeOption) => {
                                              if (!selectedPointDate) return;
                                              await validateRecord({
                                                id: "valid_from",
                                                value: selectedPointDate.format("YYYY-MM-DD"),
                                                tagGroupIndex: fieldsOfThisGroup[0].tagGroupIndex,
                                                recordId: fieldsOfThisGroup[0].record.id,
                                              });
                                              $validDateMap({
                                                ...validDateMap,
                                                [`${fieldsOfThisGroup[0].record.id}`]: {
                                                  ...validDateMap[`${fieldsOfThisGroup[0].record.id}`],
                                                  valid_from: selectedPointDate.format("YYYY-MM-DD"),
                                                },
                                              });
                                            }}
                                            useFarthestDaySelector={false}
                                          />
                                        </Col>
                                        <Col md="2" className="--text-align-center py-3">
                                          から
                                        </Col>
                                        {validDateMap[`${fieldsOfThisGroup[0].record.id}`].valid_to ? (
                                          <Col md="5">
                                            <SnapshotTimeSelector
                                              selectedPointDate={dayjs(
                                                validDateMap[`${fieldsOfThisGroup[0].record.id}`].valid_to
                                              )}
                                              onChange={({ selectedPointDate }: onChangeOption) => {
                                                if (!selectedPointDate) return;
                                                $validDateMap({
                                                  ...validDateMap,
                                                  [`${fieldsOfThisGroup[0].record.id}`]: {
                                                    ...validDateMap[`${fieldsOfThisGroup[0].record.id}`],
                                                    valid_to: selectedPointDate.format("YYYY-MM-DD"),
                                                  },
                                                });
                                              }}
                                              useFarthestPastDaySelector={false}
                                            />
                                          </Col>
                                        ) : (
                                          <Col md="5" className="--text-align-center py-3">
                                            {sectorRegularColumns[sectorId].some((c) => c.id === "valid_to") &&
                                              showEditValidToButtom(`${fieldsOfThisGroup[0].record.id}`)}
                                          </Col>
                                        )}
                                      </Row>
                                    )}
                                    {_fieldStatus[`${fieldName}/valid_from_${fieldsOfThisGroup[0].tagGroupIndex}`] &&
                                      !_fieldStatus[`${fieldName}/valid_from_${fieldsOfThisGroup[0].tagGroupIndex}`]
                                        .validated && (
                                        <Row className="mt-2 mb-1">
                                          <Col className="--text-annotation">
                                            {
                                              _fieldStatus[
                                                `${fieldName}/valid_from_${fieldsOfThisGroup[0].tagGroupIndex}`
                                              ].errorMessage
                                            }
                                          </Col>
                                        </Row>
                                      )}
                                  </div>
                                ) : null}
                              </Card.Body>
                            </Card>
                          );
                        attachedFields.push(...fieldsOfThisGroup.map((_) => _.id));
                      });
                      // このタグがついているがまだ出力されていない SubField が後続処理で出力されてないようにする
                      const sameTagSubFields = subFields.filter((_) => _.tag === d.tag);
                      sameTagSubFields.forEach((_d) => {
                        if (!attachedFields.includes(_d.id)) attachedFields.push(_d.id);
                      });

                      return group;
                    })()}
                  </div>
                  {subFields.some((d) => d.type === "tagHandler" && !d.isRelational) && _isEditing && (
                    // 通勤経路を追加する場合
                    <div className="px-2">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        disabled={d.tagGroupsToUse.length >= d.maxTagGroupsLength}
                        onClick={() =>
                          changeTagGroupIndex(
                            i,
                            (() => {
                              const current = [...d.tagGroupsToUse];
                              current.sort((a, b) => a - b);

                              let toAdd = 0;
                              for (let i = 1; i <= d.maxTagGroupsLength; i++) {
                                if (current.includes(i)) continue;
                                toAdd = i;
                                break;
                              }
                              const next = [...current, toAdd];
                              next.sort((a, b) => a - b);
                              return next;
                            })()
                          )
                        }
                      >
                        {labelMap["ja"][d.id]} を追加
                      </Button>
                      {subFields.some(
                        (_d) =>
                          d.tag === _d.tag &&
                          _d.type === "tagHandler" &&
                          _d.tagGroupsToUse.length < _d.minTagGroupsLength
                      ) && (
                        <Row className="mt-2 mb-1">
                          <Col className="--text-annotation">{labelMap["ja"][d.id]} を追加してください</Col>
                        </Row>
                      )}
                    </div>
                  )}
                </Col>
              );
              elements.push(thisTagsElements);
            } else if (d.type === "tableHandler" && d.tag && d.tagGroupsToUse) {
              // 履歴表示の場合
              d.tagGroupsToUse.forEach((_tagGroupIndex) => {
                const fieldsOfThisGroup = subFields
                  .filter((_) => _.tag === d.tag)
                  .filter((_) => _.tagGroupIndex === _tagGroupIndex)
                  .filter((_) => _.type !== "tableHandler")
                  .filter((_) => !attachedFields.includes(_.id));
                attachedFields.push(...fieldsOfThisGroup.map((_) => _.id));
              });
              const tableSubFields = subFields
                .filter((_) => _.tag === d.tag)
                .filter((_) => labelMap["ja"][_.id.replace(/_\d+$/, "")] !== undefined);
              const isHistory = tableSubFields.map((_) => _.id).some((_) => _.indexOf(`_history`) !== -1);
              const thisTagsElements = (
                <Col md={12} key={`editable-row-${i}`}>
                  <Table
                    col={tableSubFields
                      .filter((_) => _.tagGroupIndex === 1)
                      .map((s, __, self) => {
                        const _id = s.id.replace(/_1$/, "");
                        return {
                          name: labelMap["ja"][_id],
                          fieldName: _id,
                          width: 120 + (self.length > 6 ? 0 : (6 - self.length) * 50),
                        };
                      })}
                    row={d.tagGroupsToUse.map((_index) => {
                      if (isHistory) {
                        // 履歴の場合は詳細画面に遷移できるようにactionを渡す
                        return {
                          data: tableSubFields
                            .filter((_) => _.tagGroupIndex === _index)
                            .map((f) => (f.value === null ? "" : `${f.value}`)),
                          action: {
                            handler: (id, d, c) => {
                              onSelectRow && onSelectRow(id, d, c);
                            },
                          },
                        };
                      } else {
                        // 通算の場合は詳細画面に遷移しないためactionは不要
                        return {
                          data: tableSubFields
                            .filter((_) => _.tagGroupIndex === _index)
                            .map((f) => (f.value === null ? "" : `${f.value}`)),
                        };
                      }
                    })}
                  />
                </Col>
              );
              elements.push(thisTagsElements);
              // このタグがついているがまだ出力されていない SubField が後続処理で出力されてないようにする
              const sameTagSubFields = subFields.filter((_) => _.tag === d.tag);
              sameTagSubFields.forEach((_d) => {
                if (!attachedFields.includes(_d.id)) attachedFields.push(_d.id);
              });
            }
          });
          return elements;
        })()}
        {useControl &&
          !_isEditing &&
          subFields.filter((s) => s.isRelational || s.type === "tableHandler").length === 0 &&
          subFields.some((_) => _.editable) && (
            <Card className="mx-2 mt-3">
              {validDateMap[standardRecordId] && (
                // 詳細表示（1レコード）の場合
                <Card.Header>
                  <Col>データ有効期間</Col>
                </Card.Header>
              )}
              {validDateMap[standardRecordId] && (
                <Row>
                  <Col md="5" className="--text-align-center py-3">
                    {dayjs(validDateMap[standardRecordId].valid_from).format("YYYY 年 MM 月 DD 日")}
                  </Col>
                  <Col md="2" className="--text-align-center py-3">
                    から
                  </Col>
                  {validDateMap[standardRecordId].valid_to && validDateMap[standardRecordId].valid_to < "9999-12-31" ? (
                    <Col md="5" className="--text-align-center py-3">
                      {dayjs(validDateMap[standardRecordId].valid_to).format("YYYY 年 MM 月 DD 日")}
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              )}
            </Card>
          )}
        {useControl &&
          _isEditing &&
          subFields.filter((s) => s.isRelational || s.type === "tableHandler").length === 0 &&
          subFields.some((_) => _.editable) && (
            <Row>
              {validDateMap[standardRecordId] && (
                // 詳細表示（1レコード）の場合
                <Row className="mt-4 mb-2">
                  <Col>データ有効期間</Col>
                </Row>
              )}
              {validDateMap[standardRecordId]?.valid_from && (
                <Row>
                  <Col md="5">
                    <SnapshotTimeSelector
                      selectedPointDate={dayjs(validDateMap[standardRecordId].valid_from)}
                      onChange={async ({ selectedPointDate }: onChangeOption) => {
                        if (!selectedPointDate) return;
                        if (useControl) {
                          const validateRecordParam = {
                            id: "valid_from",
                            value: selectedPointDate.format("YYYY-MM-DD"),
                            tagGroupIndex: 1,
                            recordId: standardRecordId,
                          };
                          await validateRecord(validateRecordParam);
                          updateSubFields(subFields, selectedPointDate).then((updatedSubFields) => {
                            /* 開始日変更に伴い、コード選択肢の中に現在選択中のコードがなくなった場合や、
                               その後復活した場合を考慮して終了日のバリデーションを行う */
                            validateCodeChoices({
                              id: "valid_to",
                              value: validDateMap[standardRecordId].valid_to,
                              subFields: updatedSubFields,
                            });
                            if (dataType === "master") validateCircularReference(validateRecordParam, updatedSubFields);
                            onEditOrCancel && onEditOrCancel(updatedSubFields, true);
                          });
                        }
                        $validDateMap({
                          ...validDateMap,
                          [standardRecordId]: {
                            ...validDateMap[standardRecordId],
                            valid_from: selectedPointDate.format("YYYY-MM-DD"),
                          },
                        });
                      }}
                      useFarthestDaySelector={false}
                    />
                  </Col>
                  <Col md="2" className="--text-align-center py-3">
                    から
                  </Col>
                  {validDateMap[standardRecordId].valid_to ? (
                    sectorRegularColumns[sectorId].some((c) => c.id === "valid_to") ? (
                      <Col md="5">
                        <SnapshotTimeSelector
                          selectedPointDate={dayjs(validDateMap[standardRecordId].valid_to)}
                          onChange={({ selectedPointDate }: onChangeOption) => {
                            if (!selectedPointDate) return;
                            $validDateMap({
                              ...validDateMap,
                              [standardRecordId]: {
                                ...validDateMap[standardRecordId],
                                valid_to: selectedPointDate.format("YYYY-MM-DD"),
                              },
                            });
                          }}
                          useFarthestPastDaySelector={false}
                        />
                      </Col>
                    ) : validDateMap[standardRecordId].valid_to &&
                      validDateMap[standardRecordId].valid_to < "9999-12-31" ? (
                      <Col md="5" className="--text-align-center py-3">
                        {dayjs(validDateMap[standardRecordId].valid_to).format("YYYY 年 MM 月 DD 日")}
                      </Col>
                    ) : (
                      ""
                    )
                  ) : (
                    <Col md="5" className="--text-align-center py-3">
                      {sectorRegularColumns[sectorId].some((c) => c.id === "valid_to") &&
                        showEditValidToButtom(String(standardRecordId))}
                    </Col>
                  )}
                </Row>
              )}
              {_fieldStatus[`${fieldName}/valid_from_1`] && !_fieldStatus[`${fieldName}/valid_from_1`].validated && (
                <Row className="mt-2 mb-1">
                  <Col className="--text-annotation">{_fieldStatus[`${fieldName}/valid_from_1`].errorMessage}</Col>
                </Row>
              )}
              {_fieldStatus[`${fieldName}/valid_to`] && !_fieldStatus[`${fieldName}/valid_to`].validated && (
                <Row className="mt-2 mb-1">
                  <Col className="--text-annotation">{_fieldStatus[`${fieldName}/valid_to`].errorMessage}</Col>
                </Row>
              )}
            </Row>
          )}
        {useControl && (
          <Row className="mt-2">
            <Col>
              {/* 画面下部に編集ボタンは表示しない　編集時(_isEditing)のみキャンセルボタンを表示する */}
              {subFields.some((_) => _.editable) && _isEditing && showEditOrCancelButtom()}
              {/* 編集時(!_isEditing)　かつ　権限がある　場合に保存ボタンを表示する */}
              {_isEditing &&
                showHeader &&
                (ignorePermission ||
                  (!ignorePermission && dataType === "master" && isPermitted(policies, resource, "POST")) ||
                  (dataType === "user" &&
                    permissionsByTargetAccount[+accountId]?.[fieldName]?.["is_edit_permitted"])) &&
                showSaveButtom()}
            </Col>
          </Row>
        )}
      </Card.Body>
      {/*保存ボタン押下時のモーダル */}
      <ModalDialog
        show={activeModal === "before_saving"}
        onConfirm={save}
        onCancel={() => {
          $activeModal("");
        }}
        message="保存します。よろしいですか？"
      />
      {/*履歴削除ボタン押下時のモーダル */}
      <ModalDialog
        show={activeModal === "before_deleting"}
        onConfirm={deleteHistory}
        onCancel={() => {
          $deletedRecordIds([]);
          $activeModal("");
        }}
        message={`履歴を削除します。${
          files && files.length > 0 ? "ファイルも併せて削除されます。" : ""
        }よろしいですか？`}
        type="destructiveConfirm"
        confirmButtonName="削除"
      />
      {/*キャンセルボタン押下時のモーダル */}
      <ModalDialog
        show={activeModal === "before_canceling"}
        onConfirm={cancel}
        onCancel={() => {
          $activeModal("");
        }}
        message="入力内容を破棄します。よろしいですか？"
        type="destructiveConfirm"
        confirmButtonName="破棄"
      />
    </Card>
  );
}

export default ProfileField;
