import { useEffect } from "react";
import Sidebar_Evaluation from "../../component/Sidebar_Evaluation";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { getMethodTags, selectESState } from "./evaluationSystemSlice";

function App() {
  const dispatch = useAppDispatch();
  const { workspaces } = useAppSelector(selectESState);
  useEffect(() => {
    dispatch(getMethodTags());
  }, []);
  useEffect(() => {
    console.log(workspaces);
  }, [workspaces]);

  return (
    <div className="Ev-container --editor">
      <Sidebar_Evaluation current="admin_dashboard" />
      <div className="Ev-edit-content">
        <h2 className="Headline--page">管理者ダッシュボード</h2>
        <Container className="bg-white mt-3 p-4 px-2 px-md-4">
          <Row>
            <Col>
              <div className="d-flex flex-row-reverse">
                <Button variant="outline-primary" className="mx-1">
                  評価制度設定へ
                </Button>
                <Button variant="outline-primary" className="mx-1">
                  評価プロセス管理へ
                </Button>
                <Button variant="outline-primary" className="mx-1">
                  評価シート一覧へ
                </Button>
              </div>
              <Alert className="mt-4" variant="secondary">
                新しい通知はありません。
              </Alert>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <h3 className="Headline--section mb-2">評価制度</h3>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={9}>
              <h4 className="Headline--section-sub">登録状況</h4>
            </Col>
            <Col md={3} className="d-flex flex-row-reverse">
              <Button variant="outline-primary" className="mx-1">
                評価制度設定へ
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
