import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  setUnitAppearance,
  getComputedValues,
  emptyEvaluationTodo,
  emptyEvaluationPersonalData,
  emptyLayout,
  apply as _apply,
  emptyPattern,
} from "./evaluationValues";
import {
  dummySystems,
  dummySystem_hyoka_nakagawa,
  dummyTodos,
  dummyPersonalData,
  dummyProjectGroups,
  dummyProjects,
  patterns,
  layouts,
} from "./dummy/";
import {
  Evaluation,
  Unit,
  EvaluationTodo,
  EvaluationValue,
  UnitChangePayload,
  UnitGroupTemplate,
  EvaluationPersonalData,
  EvaluationTodoTemplate,
  EvaluationProjectGroup,
  EvaluationProject,
  EvaluationPattern,
  EvaluationSystem,
  EvaluationTransformProps,
  EvaluationAnswers,
  EvaluationLayoutUsage,
  EvaluationAnswerItem,
} from "./EvaluationTypes";

const SLICE_NAME = "evaluationSheet";

interface EvaluationState {
  // 制度
  system: EvaluationSystem | null;
  systems: EvaluationSystem[];
  // 年度
  projectGroups: EvaluationProjectGroup[];
  // プロジェクト
  project: EvaluationProject | null;
  patterns: EvaluationPattern[];
  debug: boolean;
  evaluation: Evaluation | null;
  layouts: EvaluationLayoutUsage[];
  layout: EvaluationLayoutUsage | null;
  pattern: EvaluationPattern | null;
  individualMetaData: EvaluationPersonalData;
  todo: EvaluationTodo;
  todos: EvaluationTodoTemplate[];
  answers: { [unitId: string]: EvaluationValue | undefined };
  variables: { [variable: string]: EvaluationValue | EvaluationValue[] };
  editTarget: { projectId: string; systemId: string; patternId: string };
  focusedProcedureStepId: string;
  computed: { [variable: string]: EvaluationValue };
  hookResults: { message: string; type: string }[];
  answerItems: EvaluationAnswerItem[];
  processing: boolean;
  closedReason: string;
  contextMenu: {
    active: boolean;
    x: number;
    y: number;
    unit: Unit | null;
  };
  previewWithEditor: boolean;
}

const initVariables = {
  CONTEXT: "answering",
};

const initialState: EvaluationState = {
  system: null,
  systems: [],
  projectGroups: [],
  project: null,
  patterns: [],
  debug: false,
  evaluation: null,
  layouts: [],
  layout: null,
  pattern: null,
  individualMetaData: { ...emptyEvaluationPersonalData },
  todo: { ...emptyEvaluationTodo },
  todos: [],
  answers: {},
  variables: {
    ...initVariables,
  },
  editTarget: {
    projectId: "",
    systemId: "",
    patternId: "",
  },
  focusedProcedureStepId: "",
  computed: {},
  hookResults: [],
  answerItems: [],
  processing: false,
  closedReason: "",
  contextMenu: {
    active: false,
    x: 0,
    y: 0,
    unit: null,
  },
  previewWithEditor: true,
};

export const getEvaluationUnitGroupTemplates = createAsyncThunk(
  SLICE_NAME + "/getEvaluationUnitGroupTemplates",
  async () => {
    return [] as UnitGroupTemplate[];
  }
);

export const getEvaluationProject = createAsyncThunk(
  SLICE_NAME + "/getEvaluationProject",
  async ({ id }: { id: string }) => {
    return dummyProjects.find((p) => p.id === id);
  }
);

export const getEvaluationSystems = createAsyncThunk(SLICE_NAME + "/getEvaluationSystems", async () => {
  return dummySystems;
});

export const getEvaluationSystem = createAsyncThunk(
  SLICE_NAME + "/getEvaluationSystem",
  async ({ id, patternId }: { id: string; patternId?: string }) => {
    // プロジェクトを取得
    const system = await new Promise<EvaluationSystem>((resolve, reject) => {
      return resolve({ ...dummySystem_hyoka_nakagawa });
    });

    // プロジェクトに紐づくパターン、レイアウトを取得
    const [_patterns, _layouts] = await Promise.all([
      new Promise<EvaluationPattern[]>((resolve, _) => {
        resolve(patterns.filter((pattern) => pattern.projectId === system.id));
      }),
      new Promise<EvaluationLayoutUsage[]>((resolve, _) => {
        resolve(layouts.filter((layout) => layout.projectId === system.id));
      }),
    ]);
    const pattern = (patternId ? _patterns.find((p) => p.id === patternId) : _patterns[0]) ?? emptyPattern;
    // todo, indivualMetaData はテスト用に設定したもの
    const todo = {
      ...dummyTodos[0],
    };
    const individualMetaData = { ...emptyEvaluationPersonalData };

    // プロジェクトに紐づく SeedTemplate を取得, LayoutRow と同じ構造にしておく
    const layoutUsage = _layouts.find((l) => l.id === pattern?.layoutId) ?? { ...emptyLayout };
    const { evaluation, variables, answers, computed } = _apply({
      system,
      layoutUsage,
      pattern,
      todo,
      individualMetaData,
      variables: {
        ...initVariables,
      },
      answers: {},
      useSeedTemplateDefault: false,
    });

    return {
      system,
      evaluation,
      patterns: _patterns,
      pattern,
      layouts: _layouts,
      layoutUsage,
      variables,
      answers,
      computed,
      todo,
      individualMetaData,
    };
  }
);

/*
  - 回答画面向けにプロジェクト関連の情報を取得する
*/
export const getEvaluationAnsweringProject = createAsyncThunk(
  SLICE_NAME + "/getEvaluationAnsweringProject",
  async ({ todoId, answers: cachedAnswers }: { todoId: string; answers: EvaluationAnswers }) => {
    /*
        - 回答時 :
        todoId から todo を取得
        todo から project, projectVersion を取得
      */
    console.log(todoId);
    const todo = { ...dummyTodos[1] };
    const individualMetaData = { ...dummyPersonalData[1] };
    const system = { ...dummySystem_hyoka_nakagawa };
    const pattern = patterns.filter((_) => _.projectId === system.id)[0] ?? null;
    const layoutUsage = layouts.find((_) => _.id === pattern?.layoutId) ?? { ...emptyLayout };
    const { evaluation, variables, answers, computed } = _apply({
      system,
      layoutUsage,
      pattern,
      todo,
      individualMetaData,
      variables: { ...initVariables },
      answers: cachedAnswers,
      useSeedTemplateDefault: false,
    });

    return {
      system,
      evaluation,
      variables,
      answers,
      computed,
      todo,
      pattern,
      layouts: [layoutUsage],
      layoutUsage,
      individualMetaData,
      closedReason: "",
    };
  }
);

/*
  [apply]
  UnitTemplate, UnitGroupTemplate から Unit, UnitGroup　へ変換
*/
export const apply = createAsyncThunk(SLICE_NAME + "/apply", async (props: EvaluationTransformProps) => {
  const { system, evaluation, todo, variables, answers, computed, layoutUsage, pattern } = _apply(props);
  return {
    system,
    todo,
    evaluation,
    layoutUsage,
    pattern,
    variables,
    answers,
    computed,
  };
});

export const getTodoTemplates = createAsyncThunk(
  SLICE_NAME + "/getTodoTemplates",
  async ({ projectId }: { projectId: string }) => {
    console.log(projectId);
    return [
      {
        id: "todo_1",
        status: "running",
        patternId: "pattern_g1",
        phase: { key: "phase_key_self" },
        evaluator: { accountId: 3, displayName: "本人" },
        evaluatee: { accountId: 3, displayName: "本人" },
      },
      {
        id: "todo_2",
        status: "running",
        patternId: "pattern_g1",
        phase: { key: "phase_key_1" },
        evaluator: { accountId: 4, displayName: "" },
        evaluatee: { accountId: 3, displayName: "大野麻衣子" },
      },
      {
        id: "todo_3",
        status: "done",
        patternId: "pattern_g1",
        phase: { key: "phase_key_1" },
        evaluator: { accountId: 9998, displayName: "" },
        evaluatee: { accountId: 3, displayName: "大野麻衣子" },
      },
    ] as EvaluationTodoTemplate[];
  }
);

export const getEvaluationProjectGroups = createAsyncThunk(SLICE_NAME + "/getEvaluationProjectGroups", async () => {
  return dummyProjectGroups;
});

export const callHook = createAsyncThunk(
  SLICE_NAME + "/callHook",
  async ({ endpoint, payload }: { endpoint: string; payload: any }) => {
    // call("post",`hook/${endpoint}`)(payload)
    return {
      hookResults: [
        {
          message: `${endpoint} 完了しました。`,
          type: "info",
        },
        // {
        //   message: `${endpoint} エラーが発生しました。`,
        //   type: "warning",
        // },
      ],
    };
  }
);

export const commitChanges = createAsyncThunk(SLICE_NAME + "/commitChanges", async () => {
  // TODO
  await new Promise((_) => setTimeout(_, 1000));
});

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    stage: (state, action) => {
      /*
        Unit で変更があった場合
        - variable が定義されていれば、 variables に値をセット
        - id が定義されていれば、 answers に値をセット
        - computed が定義されていれば、再計算
      */
      const { unit, unitGroupId, value } = action.payload as UnitChangePayload;
      let variables = { ...state.variables };
      const answers = { ...state.answers };
      variables[unit.id] = value;
      answers[unit.id] = value;

      const computedValues = getComputedValues(state.evaluation?.computingRuleSet ?? {}, answers, variables);
      variables = {
        ...variables,
        ...computedValues,
      };
      if (state.evaluation) {
        state.evaluation = {
          ...state.evaluation,
          layoutRows:
            state.evaluation?.layoutRows.map((row) => {
              return {
                ...row,
                unitGroups: row.unitGroups.map((ug) => {
                  return {
                    ...ug,
                    units: ug.units.map((u) => {
                      return setUnitAppearance({
                        unit:
                          ug.id !== unitGroupId || u.id !== unit.id
                            ? u
                            : {
                                ...u,
                                value,
                              },
                        behaviorMap: state.evaluation?.behaviorMap ?? {},
                        phaseKey: state.todo.phase.key ?? "",
                        variables,
                      });
                    }),
                  };
                }),
              };
            }) ?? [],
        };
      }
      state.variables = variables;
      state.answers = answers;
      state.computed = computedValues;
    },
    focusProcedureStep: (state, action) => {
      state.focusedProcedureStepId = action.payload;
    },
    setDebug: (state, action) => {
      state.debug = action.payload;
    },
    setOpenPane: (state, action) => {
      state.variables = {
        ...state.variables,
        OPEN_PANE: action.payload,
      };
    },
    setEditTarget: (state, action) => {
      if (action.payload.systemId) state.editTarget.systemId = action.payload.systemId;
      if (action.payload.projectId) state.editTarget.projectId = action.payload.projectId;
      if (action.payload.patternId) state.editTarget.patternId = action.payload.patternId;
    },
    switchLayout: (state, action) => {
      const next = state.layouts.find((l) => l.id === action.payload);
      if (next) state.layout = next;
    },
    switchPattern: (state, action) => {
      const next = state.patterns.find((l) => l.id === action.payload);
      if (next) state.pattern = next;
    },
    setContextMenu: (state, action) => {
      state.contextMenu = action.payload;
    },
    setPreviewWithEditor: (state, action) => {
      state.previewWithEditor = action.payload;
    },
    updateAnswerItems: (state, _) => {
      if (state.evaluation?.layoutRows) {
        const answerItems = state.evaluation.layoutRows
          .map((row) => {
            return row.unitGroups
              .map((ug) => {
                return ug.units.filter((unit) => unit.answerMetaData).flat();
              })
              .flat();
          })
          .reduce((prev, current) => {
            return [...prev, ...current];
          }, [])
          .map((unit) => {
            return {
              axis: unit.answerMetaData?.axis ?? "",
              label: unit.answerMetaData?.label ?? "",
              relatedPhase: unit.answerMetaData?.relatedPhase ?? "",
              path: unit.answerMetaData?.path ?? [],
              unitGroupId: unit.unitGroupId,
              value: state.answers[unit.id] ?? state.computed[unit.id] ?? "",
            } as EvaluationAnswerItem;
          });
        state.answerItems = answerItems;
      }
    },
    setPatterns: (state, action) => {
      if (action.payload) {
        state.patterns = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvaluationSystems.pending, (state) => {
      state.processing = true;
    });
    builder.addCase(getEvaluationSystems.fulfilled, (state, action) => {
      state.processing = false;
      state.systems = action.payload;
    });
    builder.addCase(getEvaluationSystems.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getEvaluationProjectGroups.pending, (state) => {
      state.processing = true;
    });
    builder.addCase(getEvaluationProjectGroups.fulfilled, (state, action) => {
      state.processing = false;
      state.projectGroups = action.payload;
    });
    builder.addCase(getEvaluationProjectGroups.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getEvaluationProject.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getEvaluationProject.pending, (state) => {
      state.processing = true;
    });
    builder.addCase(getEvaluationProject.fulfilled, (state, action) => {
      state.processing = false;
      if (action.payload) state.project = action.payload;
    });
    builder.addCase(getEvaluationAnsweringProject.pending, (state) => {
      state.processing = true;
    });
    builder.addCase(getEvaluationAnsweringProject.fulfilled, (state, action) => {
      state.processing = false;
      if (action.payload) {
        state.system = action.payload.system;
        state.evaluation = action.payload.evaluation;
        state.variables = action.payload.variables;
        state.answers = action.payload.answers;
        state.closedReason = action.payload.closedReason;
        state.pattern = action.payload.pattern;
        state.layouts = action.payload.layouts;
        state.layout = action.payload.layoutUsage;
        state.todo = action.payload.todo;
        state.computed = action.payload.computed;
        state.individualMetaData = action.payload.individualMetaData;
      }
    });
    builder.addCase(getEvaluationAnsweringProject.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getEvaluationSystem.pending, (state) => {
      state.processing = true;
    });
    builder.addCase(getEvaluationSystem.fulfilled, (state, action) => {
      state.processing = false;
      state.system = action.payload.system;
      state.patterns = action.payload.patterns;
      state.layouts = action.payload.layouts;
      state.layout = action.payload.layoutUsage;
      state.todo = action.payload.todo;
      state.individualMetaData = action.payload.individualMetaData;
      state.evaluation = action.payload.evaluation;
      state.variables = action.payload.variables;
      state.answers = action.payload.answers;
      state.computed = action.payload.computed;
      if (action.payload.pattern) state.pattern = action.payload.pattern;
    });
    builder.addCase(getEvaluationSystem.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getEvaluationUnitGroupTemplates.pending, (state) => {
      state.processing = true;
    });
    builder.addCase(getEvaluationUnitGroupTemplates.fulfilled, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getEvaluationUnitGroupTemplates.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(apply.rejected, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(apply.fulfilled, (state, action) => {
      if (action.payload) {
        state.system = action.payload.system;
        state.todo = action.payload.todo;
        state.evaluation = action.payload.evaluation;
        state.layout = action.payload.layoutUsage;
        state.pattern = action.payload.pattern;
        state.variables = action.payload.variables;
        state.answers = action.payload.answers;
        state.computed = action.payload.computed;
      }
    });
    builder.addCase(callHook.pending, (state, action) => {
      state.hookResults = [];
    });
    builder.addCase(callHook.fulfilled, (state, action) => {
      state.hookResults = action.payload.hookResults;
    });
    builder.addCase(getTodoTemplates.pending, (state, action) => {
      state.todos = [];
    });
    builder.addCase(getTodoTemplates.fulfilled, (state, action) => {
      state.todos = action.payload;
    });
  },
});

export const {
  stage,
  setOpenPane,
  setDebug,
  setEditTarget,
  switchLayout,
  switchPattern,
  setContextMenu,
  focusProcedureStep,
  setPreviewWithEditor,
  updateAnswerItems,
  setPatterns,
} = slice.actions;

export const selectEvaluationState = (state: RootState) => {
  return state.evaluationSheet as EvaluationState;
};

export const selectEvaluationAnswers = (state: RootState) => {
  const evaluation = state.evaluationSheet as EvaluationState;
  return evaluation.answers;
};

export const selectUnitGroupOptions = (state: RootState) => {
  const evaluation = state.evaluationSheet as EvaluationState;
  return (
    evaluation.system?.seedTemplates
      .map((seed) => {
        return { value: seed.id, label: seed.name };
      })
      .flat() ?? []
  );
};

export const selectEvaluateeList = (state: RootState) => {
  const evaluation = state.evaluationSheet as EvaluationState;
  return evaluation.todos.reduce(
    (prev, current) => {
      const thisAccount = prev.find((_) => _.evaluatee.accountId === current.evaluatee.accountId);
      if (thisAccount) {
        return [
          ...prev.filter((a) => a.evaluatee.accountId !== current.evaluatee.accountId),
          { ...thisAccount, todos: [...thisAccount.todos, current] },
        ];
      } else {
        return [...prev, { patternId: current.patternId, evaluatee: current.evaluatee, todos: [current] }];
      }
    },
    [] as {
      patternId: string;
      evaluatee: { accountId: number; displayName: string };
      todos: EvaluationTodoTemplate[];
    }[]
  );
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
