import { useState, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { Button, Form, Tabs, Tab } from "react-bootstrap";
import "../../css/style.scss";
import { apply, selectEvaluationState, setPreviewWithEditor } from "./evaluationSheetSlice";
import { selectLayoutState } from "../layout/layoutSlice";
import classNames from "classnames";

function App({ onClose }: { onClose: (v: any) => any }) {
  const dispatch = useAppDispatch();
  const { evaluation, system, todo, layout, pattern, individualMetaData, variables, previewWithEditor } =
    useAppSelector(selectEvaluationState);
  const { sidebarStatus } = useAppSelector(selectLayoutState);
  const [editingTab, $editingTab] = useState("layout");
  const phaseList = useMemo(() => {
    if (!evaluation?.phases) return [];
    return evaluation.phases.map((phase) => {
      return {
        label: phase.label,
        value: phase.key,
      };
    });
  }, [evaluation]);

  return (
    <section className={classNames({ "Ev-edit-controls": true, "--expanded": sidebarStatus === "folded" })}>
      <Tabs className="mb-3" onSelect={(key) => key && $editingTab(key)} activeKey={editingTab}>
        <Tab eventKey="layout" title="条件">
          <div className="--flex --align-items-center my-1">
            <div className="--shrink-0">フェーズ</div>
            <Form.Select
              className="mx-2"
              value={todo.phase.key}
              onChange={(e) => {
                if (!system || !layout || !pattern) return;
                // phase を更新する
                const nextPhase = system.phaseTemplates.find((p) => p.key === e.target.value);
                if (!nextPhase) return;
                dispatch(
                  apply({
                    system,
                    todo: {
                      ...todo,
                      phase: {
                        ...nextPhase,
                        id: e.target.value,
                      },
                    },
                    layoutUsage: layout,
                    pattern,
                    variables,
                    individualMetaData,
                    useSeedTemplateDefault: false,
                  })
                );
              }}
            >
              {phaseList.map(({ value, label }, i) => {
                return (
                  <option key={`option_${i}`} value={value}>
                    {label}
                  </option>
                );
              })}
            </Form.Select>
          </div>
          <div className="--flex --align-items-center my-1">
            <Form.Check
              type="checkbox"
              id="previewWithEditor"
              label="編集パネルを表示しない"
              checked={!previewWithEditor}
              onChange={() => {
                dispatch(setPreviewWithEditor(!previewWithEditor));
              }}
              inline
            />
          </div>
        </Tab>
      </Tabs>
      <div className="mt-2">
        <Button onClick={onClose} variant="outline-secondary" className="--font-s">
          閉じる
        </Button>
      </div>
    </section>
  );
}

export default App;
