import {
  UnitTemplate,
  EvaluationModuleCommonProps,
  EvaluationHelperProduct,
  EvaluationHelperProps,
} from "../EvaluationTypes";
import { toStories, mergeContext } from "../evaluationValues";
import AnswerRowModule from "./AnswerRowModule";
import StaticUnitModule from "./StaticUnitModule";
/*
  - ヘッダーの units を作成
  - 評価フェーズを設定
  - 標準のビヘイビアを設定
*/
const seedDataTypes = {
  textList: {
    description: "カテゴリと項目",
    data: {
      type: "array",
      schema: {
        value: {
          type: "text",
          label: "表記（カンマ区切り）",
        },
      },
    },
  },
  headerList: {
    description: "各行の構成",
    data: {
      type: "array",
      schema: {
        factors: {
          type: "text[]",
          label: "項目名",
        },
        axis: {
          type: "text",
          label: "分析用タグ",
        },
        relatedPhase: {
          type: "phase",
          label: "関係する評価フェーズ",
        },
        type: {
          type: "valueType",
          label: "種類",
        },
        helper: {
          type: "query",
          label: "Query",
        },
      },
    },
  },
  summaryDepth: {
    description: "評点をまとめる階層の深さ",
    data: {
      type: "object",
      schema: {
        value: {
          type: "number",
          label: "値",
        },
      },
    },
  },
  useCategoryTotal: {
    description: "小計を使用する",
    data: {
      type: "object",
      schema: {
        use: {
          type: "boolean",
          label: "使用する",
        },
      },
    },
  },
  useRowIndex: {
    description: "先頭に行番号を使用する",
    data: {
      type: "object",
      schema: {
        use: {
          type: "boolean",
          label: "使用する",
        },
      },
    },
  },
};
const AnswerRowListModule = (
  common: EvaluationModuleCommonProps,
  props: {
    origin: {
      row: number;
      column: number;
    };
    textList: { value: string }[];
    headerList: EvaluationHelperProps["iteration"];
    summaryDepth: {
      value: number;
    };
    useCategoryTotal: {
      use: boolean;
    };
    useRowIndex?: {
      use: boolean;
    };
  }
): EvaluationHelperProduct => {
  const CSSBundleUsageMap = {
    DEFAULT: ["header_cell", "flex_centering"],
  };
  const useRowIndex = !!props.useRowIndex?.use;
  const useCategoryTotal = !!props.useCategoryTotal?.use;
  const { stories } = toStories(props.textList, useCategoryTotal);
  const units = [] as UnitTemplate[];
  const depth = props.summaryDepth?.value ?? 1; // 何階層目を１回の AnswerRow に対応させるか
  let piledRows = 0;

  const dig = (categories: any) => {
    const _ = categories.map((category: any) => (category.sub?.length > 0 ? category.sub : [category]));
    return _.flat();
  };

  stories.sub.forEach((rootCategory: any, rootCategoryIndex: number) => {
    if (useCategoryTotal && rootCategoryIndex > 0) {
      // 「小計」の分を一番上のブロックについてひとつ加算する
      piledRows++;
    }
    const init = rootCategory.sub.length > 0 ? rootCategory.sub : [rootCategory];
    const categories = depth ? new Array(depth - 1).fill(0).reduce((acc, _) => dig(acc), init) : init;
    categories.forEach((category: any) => {
      if (useRowIndex) {
        const {
          units: [indexUnit],
        } = StaticUnitModule.run(common, {
          origin: { row: props.origin.row + piledRows, column: props.origin.column },
          unit: {
            value: `${rootCategory.label}`,
            cssBundleKeys: CSSBundleUsageMap["DEFAULT"],
            gridRowSpan: category.occupiedRow,
          },
        });
        units.push({ ...indexUnit });
      }
      const results = AnswerRowModule(common, {
        iteration: props.headerList.map((_) => {
          const meta = {
            axis: _.axis ?? (_.factors ? _.factors.join("-") : ""),
            label:
              (rootCategory.sub.length > 0 ? rootCategory.label + " - " + category.label : rootCategory.label) +
              (_.factors ? ` [ ${_.factors?.join(" / ")} ]` : ""),
            relatedPhase: _.relatedPhase ?? "",
            path: category.path as number[],
          };
          return {
            ..._,
            unitSize: ["評価", "評価点"].every((f) => (_.factors || []).includes(f)) ? category.occupiedRow : 1,
            meta,
            description: "DESCRIPTION",
          };
        }),
        origin: {
          row: props.origin.row + piledRows,
          column: props.origin.column + (useRowIndex ? 1 : 0),
        },
      });
      units.push(...results.units);
      common.context = mergeContext(common.context, results.context);
      piledRows += category.occupiedRow;
    });
  });

  return {
    units,
    origin: { row: props.origin.row, column: props.origin.column },
    occupied: { row: props.origin.row + piledRows, column: props.origin.column + props.headerList.length },
    context: common.context,
    meta: {},
  };
};

const sampleArgv = {
  textList: [],
  headerList: [],
  useCategoryTotal: {
    use: false,
  },
  useRowIndex: {
    use: false,
  },
};

export default {
  label: "回答欄リスト",
  run: AnswerRowListModule,
  seedDataTypes,
  sampleArgv,
};
