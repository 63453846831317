const data = {
  category: "social_insurance",
  table: "social_insurance",
  subFields: {
    basic_pension_number: {},
    social_insurance_category_type: {},
    insurance_fee_category_type: {},
    parttime_category_type: {},
    health_insurance_number: {},
    is_not_applicable_for_care_insurance: {},
    care_insurance_category_type: {},
    health_insurance_on: {},
    loss_of_health_insurance_on: {},
    health_insurance_standard_remuneration: {},
    pension_insurance_reference_number: {},
    pension_insurance_category_type: {},
    pension_insurance_on: {},
    loss_of_pension_insurance_on: {},
    pension_insurance_standard_remuneration: {},
    pension_fund_number: {},
    pension_fund_on: {},
    loss_of_pension_fund_on: {},
    has_dependent_spouse: {},
    has_dependent_relative: {},
  },
};
export default data;
