import "../css/style.scss";
import { Link } from "react-router-dom";
import { Operation } from "../features/dashboard/operationValues";
import Icon from "../component/Icon";
import { Button } from "react-bootstrap";

function App({ data, className }: { data: Operation; className?: string }) {
  const { title, description, flows } = data;
  return (
    <article className={`Setting-card ${className ? ` ${className}` : ""}`}>
      <h2 className="Setting-card__title">{title}</h2>
      <div className="Setting-card__body">
        {description && <div className="Setting-card__description">{description}</div>}
        {flows.length > 0 && (
          <section className="Setting-card__flows">
            {flows.map((flow, flowIndex) => {
              return (
                <div className="Setting-card__flow" key={`flow${flowIndex}`}>
                  <h3 className="Setting-card__flow-title">[ {flow.title} ]</h3>
                  {flow.tasks.length > 0 &&
                    flow.tasks.map((p, pi) => {
                      return (
                        <div className="Setting-card__task" key={`task_${pi}`}>
                          <h4 className="Setting-card__task-title">{p.title}</h4>
                          {p.links && p.links.length > 0 && (
                            <div className="Setting-card__task-links">
                              {p.links?.map((link, ii) => {
                                return (
                                  <li className="Setting-card__link" key={`task-link_${ii}`}>
                                    {link.to ? (
                                      <Link to={link.to}>
                                        {link.label}
                                        {link.isExternalLink ? (
                                          <span className="--px-1">
                                            <Icon type="external-tab" width={16} height={16} />
                                          </span>
                                        ) : null}
                                      </Link>
                                    ) : (
                                      <span>{link.label}</span>
                                    )}
                                    {link.description && (
                                      <div className="Setting-card__link-description">{link.description}</div>
                                    )}
                                  </li>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </section>
        )}
      </div>
    </article>
  );
}

export default App;
