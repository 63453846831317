import { useMemo, useState } from "react";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { UnitGroupSeedUsage, UnitLayoutRow } from "./EvaluationTypes";
import EvUnitGroup from "./UnitGroup";
import { Button, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { apply, selectEvaluationState } from "./evaluationSheetSlice";
import Icon from "../../component/Icon";
import { yieldId } from "../../app/util";

let timer = 0;

function EvLayoutRow({
  layoutRow,
  fitScreen = false,
  editorMode = "off",
}: {
  layoutRow: UnitLayoutRow;
  fitScreen: boolean;
  editorMode?: "off" | "editing" | "selecting";
}) {
  const dispatch = useAppDispatch();
  const { system, layout, pattern, todo, individualMetaData, variables, previewWithEditor } =
    useAppSelector(selectEvaluationState);
  const [editingStyle, $editingStyle] = useState(false);
  const [stage, $stage] = useState({
    preference: "",
  });

  const edit = () => {
    $editingStyle(true);
    $stage({ preference: layoutRow.preference.join(",") });
  };
  const save = () => {
    $editingStyle(false);
    $stage({ preference: layoutRow.preference.join(",") });
  };

  const [sectionStyle, rowStyle] = useMemo(() => {
    const sectionStyle = {} as React.CSSProperties;
    const rowStyle = {
      display: "flex",
    } as React.CSSProperties;
    layoutRow.preference.forEach((p) => {
      if (p.startsWith("mb-")) {
        sectionStyle.marginBottom = +p.replace("mb-", "") * 10 + "px";
      } else if (p.startsWith("mt-")) {
        sectionStyle.marginTop = +p.replace("mt-", "") * 10 + "px";
      } else if (p.startsWith("align-items-")) {
        rowStyle.alignItems = p.replace("align-items-", "");
      } else if (p.startsWith("justify-content-")) {
        rowStyle.justifyContent = p.replace("justify-content-", "");
      } else if (p.startsWith("flex-direction-")) {
        rowStyle.flexDirection = p.replace("flex-direction-", "") as
          | "row"
          | "column"
          | "row-reverse"
          | "column-reverse";
      }
    });
    return [sectionStyle, rowStyle];
  }, [layoutRow]);

  const remove = () => {
    if (!system || !layout || !pattern) return;
    const usedInLayout = (() => {
      if (editorMode === "selecting") return false;
      const usedTemplateIds = layout.rows
        .map((row) => row.seeds.map((seed) => seed.templateId))
        .flat()
        .filter((id, i, self) => self.indexOf(id) === i);
      return usedTemplateIds.includes(layoutRow.unitGroups[0].id);
    })();
    if (usedInLayout) {
      alert("レイアウトで使用されているため削除できません。");
      return;
    }
    if (editorMode === "selecting" && !window.confirm("列をまとめて削除してもよいですか？")) return;
    const nextSystem = {
      ...system,
      seedTemplates:
        editorMode === "editing"
          ? system.seedTemplates.filter((seed) => {
              return seed.id !== layoutRow.unitGroups[0].id;
            })
          : system.seedTemplates,
    };
    const nextLayoutUsage = {
      ...layout,
      rows:
        editorMode === "selecting"
          ? layout.rows.filter((row) => {
              return row.id !== layoutRow.id;
            })
          : layout.rows,
    };
    dispatch(
      apply({
        system: nextSystem,
        layoutUsage: nextLayoutUsage,
        pattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: editorMode === "editing",
      })
    );
  };

  const reapply = (field: string, updated: any) => {
    if (!stage || !system || !layout || !pattern) return;
    const next = { ...stage };
    if (field === "style") {
      next.preference = updated;
      $stage({ ...stage, preference: updated });
    }
    if (timer > 0) {
      clearTimeout(timer);
    }
    timer = +setTimeout(() => {
      dispatch(
        apply({
          system,
          layoutUsage: {
            ...layout,
            rows: layout.rows.map((row) => {
              return {
                ...row,
                preference: row.id === layoutRow.id ? next.preference.split(",") : row.preference,
              };
            }),
          },
          pattern,
          todo,
          individualMetaData,
          variables,
          answers: {},
          useSeedTemplateDefault: false,
        })
      );
    }, 500);
  };

  const addUnitGroup = () => {
    if (!system || !system.seedTemplates.length || !layout || !pattern) return;
    const initSeed = { ...system.seedTemplates[0] };
    const seedId = `seed_${yieldId()}`;
    const nextLayout = {
      ...layout,
      rows: layout.rows.map((row) => {
        return row.id === layoutRow.id
          ? {
              ...row,
              seeds: [
                ...row.seeds,
                {
                  id: seedId,
                  templateId: initSeed.id,
                  omittingPatternIds: [],
                  preference: [],
                } as UnitGroupSeedUsage,
              ],
            }
          : row;
      }),
    };
    const nextPattern = {
      ...pattern,
      seedData: {
        ...pattern.seedData,
        [seedId]: initSeed.procedure.reduce((prev, current) => {
          return { ...prev, [`${seedId}_${current.id}`]: { ...current.argv } };
        }, {}),
      },
    };
    dispatch(
      apply({
        system,
        layoutUsage: nextLayout,
        pattern: nextPattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: false,
      })
    );
  };

  return (
    <section style={sectionStyle}>
      {previewWithEditor && (
        <section className="mb-2">
          {!editingStyle && editorMode !== "off" && (
            <div>
              {editorMode === "selecting" && (
                <Button onClick={edit} variant="outline-primary" size="sm" className="mx-1">
                  列の属性を編集
                </Button>
              )}
              <Button onClick={remove} variant="outline-danger" size="sm">
                {editorMode === "selecting" ? "列を削除" : "削除"}
              </Button>
            </div>
          )}
          {editorMode === "selecting" && editingStyle && (
            <div className="Ev-unit-group__style-editor">
              <div className="--flex --align-items-center mb-2">
                <Button onClick={save} size="sm">
                  閉じる
                </Button>
              </div>
              <Form.Control
                onChange={(e) => {
                  reapply("style", e.target.value);
                }}
                type="text"
                placeholder=""
                value={stage.preference}
              />
            </div>
          )}
        </section>
      )}
      <div style={rowStyle}>
        {layoutRow.unitGroups.length > 0 ? (
          layoutRow.unitGroups.map((ug, gi: number) => {
            return <EvUnitGroup key={`unitGroup_${gi}`} unitGroup={ug} fitScreen={fitScreen} editorMode={editorMode} />;
          })
        ) : (
          <div className="--text-annotation --font-s">ユニットグループ未設定</div>
        )}
        {previewWithEditor && editorMode === "selecting" && (
          <div className="mx-4 --shrink-0">
            <Button onClick={addUnitGroup}>
              <Icon type="plus" width={20} height={20} />
              ユニットグループを追加
            </Button>
          </div>
        )}
      </div>
    </section>
  );
}

export default EvLayoutRow;
