import { useState, useMemo } from "react";
import { useAppSelector } from "../../app/store";
import { Table, Button, Alert } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { selectEvaluationState } from "./evaluationSheetSlice";
import { EvaluationAnswerItem } from "./EvaluationTypes";

function EvAnswersList({ className }: { className?: string }) {
  const { evaluation, todo, answerItems } = useAppSelector(selectEvaluationState);
  const [showAll, $showAll] = useState(false);

  const buttonLabel = useMemo(() => {
    if (!showAll) return "全ての回答内容を表示";
    const phase = evaluation?.phases.find((p) => p.key === todo.phase.key);
    return showAll ? `${phase?.label ?? ""}の回答内容だけを表示` : "全ての回答内容を表示";
  }, [showAll, evaluation, todo]);

  const answersPerUnitGroups = useMemo(() => {
    if (!evaluation?.layoutRows) return [];
    const answersPerUnitGroups = evaluation.layoutRows.reduce((prev, current) => {
      return [
        ...prev,
        ...current.unitGroups.map((unitGroup) => {
          return answerItems
            .filter((item) => item.unitGroupId === unitGroup.id)
            .filter((unit) => {
              return showAll || unit.relatedPhase === todo.phase.key;
            });
        }),
      ];
    }, [] as EvaluationAnswerItem[][]);
    return answersPerUnitGroups.filter((_) => _ && _.length > 0);
  }, [evaluation, answerItems, showAll, todo]);

  return (
    <section className={className}>
      <div className="mb-2">
        <Button variant="outline-primary" onClick={() => $showAll(!showAll)}>
          {buttonLabel}
        </Button>
      </div>
      {answersPerUnitGroups.length > 0 ? (
        answersPerUnitGroups.map((listOfUnitGroup, _i) => {
          return (
            <Table striped bordered hover size="sm" key={`table_${_i}`} className="Answer-list">
              <tbody>
                {listOfUnitGroup.map(({ label, value }, i) => (
                  <tr key={`row${i}`}>
                    <td className="p-2 --font-s">{label}</td>
                    <td className="p-2 --font-s">{value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          );
        })
      ) : (
        <Alert variant="info">現在のステップで入力された回答データはありません。</Alert>
      )}
    </section>
  );
}

export default EvAnswersList;
