import { useEffect, useMemo, useState, Fragment } from "react";
import { Container, Row, Col, Alert, Card, Form, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  getFileProject,
  unselectFileProject,
  selectFileState,
  attachFile,
  deleteFile,
  downloadFile,
  commitFileProject,
  deleteProject,
  putFileName,
  putFileOrder,
  downloadPermissions,
} from "./fileSlice";
import { selectUserState } from "../login/userSlice";
import Table from "../../component/Table";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "../../component/Icon";
import ModalDialog from "../../component/ModalDialog";
import Uploader from "../../component/Uploader";
import { ProfileFileElement } from "../profile/profileFieldValues";
import { setLoading } from "../notification/notificationSlice";
import classNames from "classnames";
import {
  FILE_ERROR_MESSAGE_MAP,
  FILE_NAME_FORBIDDEN_CHARS,
  FILE_PROJECT_DISPLAY_TYPES,
  MAX_FILE_SIZE,
} from "./fileValues";
import { testResponse } from "../../app/util";
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { selectedFileProject } = useAppSelector(selectFileState);
  const { user } = useAppSelector(selectUserState);

  const [state, $state] = useState({
    activeModal: "",
    selectingFile: null as ProfileFileElement | null,
    mode: "preview" as "previewing" | "projectEditing" | "fileNameEditing" | "fileOrderEditing",
    name: "",
    description: "",
    startTime: new Date(),
    endTime: new Date(),
    displayType: "public",
    usePrefix: false,
    isNotify: false,
    editingFileName: "",
    editingFileExt: "",
    editingFileOrderKeys: [] as string[], // filekey の配列
    bulkUploadErrors: {} as { [fileName: string]: string[] },
  });

  useEffect(() => {
    return () => {
      dispatch(unselectFileProject());
    };
  }, []);

  useEffect(() => {
    if (id && id !== "create")
      dispatch(getFileProject({ id })).then((res) => {
        // 存在しないidの場合は、ファイルプロジェクト一覧へ遷移させる
        if (!res.payload) navigate("/_/files/file_admin");
      });
  }, [id]);

  const userTimeZone = useMemo(() => {
    return user.current_company.timezone;
  }, [user]);

  const resetProjectDetail = () => {
    $state({
      ...state,
      activeModal: Object.keys(selectedFileProject?.errors ?? {}).length > 0 ? "bulk_upload_error" : "",
      mode: "previewing",
      name: selectedFileProject.name,
      description: selectedFileProject.description ?? "",
      startTime: dayjs(selectedFileProject.start_time).tz(userTimeZone).toDate(),
      endTime: dayjs(selectedFileProject.end_time).tz(userTimeZone).toDate(),
      displayType: selectedFileProject.display_type,
      usePrefix: selectedFileProject.use_prefix,
      isNotify: !!selectedFileProject.is_notify,
    });
  };

  useEffect(() => {
    if (selectedFileProject.id) resetProjectDetail();
  }, [selectedFileProject]);

  const errorMessages = useMemo(() => {
    const errorMessages = [] as { place: string; message: string }[];
    if (state.mode === "projectEditing") {
      if (state.name === "") errorMessages.push({ place: "name", message: "入力してください" });
      if (state.startTime.getTime() >= state.endTime.getTime())
        errorMessages.push({ place: "endTime", message: "公開開始日時より後の日時を設定してください" });
    } else if (state.mode === "fileNameEditing") {
      const fullFileName = `${state.editingFileName}${state.editingFileExt}`;
      if (state.editingFileName === "") errorMessages.push({ place: "fileName", message: "入力してください" });
      else if (FILE_NAME_FORBIDDEN_CHARS.some((c) => state.editingFileName.indexOf(c) !== -1))
        errorMessages.push({
          place: "fileName",
          message: `${FILE_NAME_FORBIDDEN_CHARS.join(" ")} はファイル名に使用できません`,
        });
      else if (
        selectedFileProject.files.some(({ name, key }) => name === fullFileName && key !== state.selectingFile?.key)
      )
        errorMessages.push({ place: "fileName", message: "既に同じ名前のファイルが存在します" });
    }
    return errorMessages;
  }, [state]);

  const files = useMemo(() => {
    if (state.mode !== "fileOrderEditing") return selectedFileProject.files;
    return state.editingFileOrderKeys.reduce((prev, current) => {
      const file = selectedFileProject.files.find(({ key }) => key === current);
      if (!file) return prev;
      else return [...prev, file];
    }, [] as ProfileFileElement[]);
  }, [state.mode, state.editingFileOrderKeys, selectedFileProject]);

  const isForbiddenChangeDisplayType = useMemo(() => {
    const now = new Date().getTime();
    return (
      selectedFileProject.display_type === "private" &&
      state.displayType === "public" &&
      state.startTime.getTime() < now &&
      now < state.endTime.getTime()
    );
  }, [selectedFileProject, state.displayType, state.startTime, state.endTime]);

  const moveFileIndex = (value: number, index: number) => {
    const next = [...state.editingFileOrderKeys];
    const toTurn = index + value; // 今移動先にある列。選択した列の直前か直後
    state.editingFileOrderKeys.forEach((_, i) => {
      if (i === index) next[toTurn] = _;
      else if (i === toTurn) next[index] = _;
      else next[i] = _;
    });
    $state({ ...state, editingFileOrderKeys: next });
  };

  const closeModal = () => {
    $state({ ...state, activeModal: "", selectingFile: null });
  };

  return (
    <Container>
      <Row>
        <Col>
          <Row className="mb-1">
            <Col>
              {state.mode === "projectEditing" ? (
                <>
                  <Button variant="outline-secondary" onClick={resetProjectDetail}>
                    キャンセル
                  </Button>
                  <Button
                    className="ms-1"
                    disabled={isForbiddenChangeDisplayType}
                    onClick={() => $state({ ...state, activeModal: "before_commit" })}
                  >
                    更新
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    disabled={state.mode !== "previewing"}
                    variant="outline-primary"
                    onClick={() => $state({ ...state, mode: "projectEditing" })}
                  >
                    編集
                  </Button>
                  <Button
                    disabled={state.mode !== "previewing"}
                    variant="outline-danger"
                    className="float-end"
                    onClick={() => $state({ ...state, activeModal: "before_delete_project" })}
                  >
                    削除
                  </Button>
                </>
              )}
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <div
                    className={classNames({
                      "--bold": true,
                      "--required-label": state.mode === "projectEditing",
                    })}
                  >
                    プロジェクト名
                  </div>
                  <Form.Control
                    type="text"
                    disabled={state.mode !== "projectEditing"}
                    value={state.name}
                    onChange={(e) => $state({ ...state, name: e.target.value })}
                  />
                  {(() => {
                    const m = errorMessages?.find((message) => message.place === "name");
                    return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
                  })()}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="--bold">説明</div>
                  <Form.Control
                    as="textarea"
                    disabled={state.mode !== "projectEditing"}
                    value={state.description}
                    onChange={(e) => $state({ ...state, description: e.target.value })}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    className={classNames({
                      "--bold": true,
                      "--required-label": state.mode === "projectEditing",
                    })}
                  >
                    公開期間
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={5}>
                  <DatePicker
                    dateFormat={"yyyy-MM-dd HH:mm"}
                    selected={state.startTime}
                    showTimeSelect
                    className="form-control"
                    timeIntervals={1}
                    disabled={state.mode !== "projectEditing"}
                    onChange={(selected) => {
                      if (!selected) return;
                      $state({ ...state, startTime: selected });
                    }}
                  />
                </Col>
                <Col md={1} className="d-flex align-items-center justify-content-center">
                  ～
                </Col>
                <Col md={5}>
                  <DatePicker
                    dateFormat={"yyyy-MM-dd HH:mm"}
                    selected={state.endTime}
                    className="form-control"
                    showTimeSelect
                    timeIntervals={1}
                    disabled={state.mode !== "projectEditing"}
                    onChange={(selected) => {
                      if (!selected) return;
                      $state({ ...state, endTime: selected });
                    }}
                  />
                  {(() => {
                    const m = errorMessages?.find((message) => message.place === "endTime");
                    return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
                  })()}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    className={classNames({
                      "--bold": true,
                      "--required-label": state.mode === "projectEditing",
                    })}
                  >
                    公開タイプ
                  </div>
                  {state.mode === "projectEditing" && selectedFileProject.display_type === "private" && (
                    <>
                      <div className="--font-s">
                        ※公開中は「閲覧者を限定」から「全社員に公開」への変更はできません。
                      </div>
                      {isForbiddenChangeDisplayType && (
                        <div className="--text-annotation --font-s">
                          「閲覧者を限定」を選択するか、公開期間外へ変更してください。
                        </div>
                      )}
                    </>
                  )}
                  {FILE_PROJECT_DISPLAY_TYPES.map(({ value, label }, i) => (
                    <Fragment key={`check_${i}`}>
                      <Form.Check
                        type="radio"
                        label={label}
                        disabled={state.mode !== "projectEditing"}
                        key={`display_type_${value}`}
                        id={`display_type_${value}`}
                        checked={state.displayType === value}
                        value={value}
                        onChange={(e) => $state({ ...state, displayType: e.target.value })}
                      />
                      {value === "private" && state.displayType === "private" && (
                        <div className="ms-4">
                          <Form.Check
                            type="checkbox"
                            disabled={state.mode !== "projectEditing"}
                            label="アップロード時のファイル名の接頭辞とログインIDが一致する場合に権限を付与する"
                            key="option_usePrefix"
                            id="option_usePrefix"
                            checked={state.displayType === "private" && state.usePrefix}
                            onChange={() => $state({ ...state, usePrefix: !state.usePrefix })}
                          />
                          <div className="--font-s ms-4 text-muted">
                            ※チェックを入れると、アップロード時のファイル名先頭がログインIDと_（アンダースコア）である場合に権限が付与されます。
                          </div>
                          <div className="--font-s ms-4 text-muted">
                            （例）001_test.txt: ログインIDが001のアカウントに権限が付与されます。
                          </div>
                          <div className="--font-s ms-4 text-muted">
                            ※ゲストアカウント・無効アカウントは対象外です。
                          </div>
                          <div className="--font-s ms-4 text-muted">※ファイル名の変更では権限が変更されません。</div>
                        </div>
                      )}
                    </Fragment>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="--bold">通知</div>
                  <div className="--font-s text-muted">
                    ※こちらを有効にすると、公開時と公開中のファイル追加時、閲覧権限があるアカウントに通知が送信されます。
                  </div>
                  <Form.Check
                    type="switch"
                    id="isNotify"
                    disabled={state.mode !== "projectEditing"}
                    label={state.isNotify ? "通知する" : "通知しない"}
                    checked={state.isNotify}
                    onChange={() => $state({ ...state, isNotify: !state.isNotify })}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="my-1">
          <Button
            variant="outline-primary"
            disabled={state.mode !== "previewing"}
            onClick={() => $state({ ...state, activeModal: "upload" })}
          >
            ファイル追加
          </Button>
          <Button
            className="ms-1"
            variant="outline-primary"
            disabled={state.mode !== "previewing"}
            onClick={() => $state({ ...state, activeModal: "bulk_upload" })}
          >
            ファイル一括追加
          </Button>
          {state.mode !== "fileOrderEditing" ? (
            <Button
              variant="outline-primary"
              disabled={state.mode !== "previewing" || selectedFileProject.files.length < 2}
              className="ms-1"
              onClick={() =>
                $state({
                  ...state,
                  mode: "fileOrderEditing",
                  editingFileOrderKeys: selectedFileProject.files.map(({ key }) => key),
                })
              }
            >
              ファイル表示順変更
            </Button>
          ) : (
            <>
              <Button
                variant="outline-secondary"
                className="ms-1"
                onClick={() => $state({ ...state, mode: "previewing", editingFileOrderKeys: [] })}
              >
                キャンセル
              </Button>
              <Button className="ms-1" onClick={() => $state({ ...state, activeModal: "before_update_order" })}>
                保存
              </Button>
            </>
          )}
          {selectedFileProject.display_type === "private" && (
            <Button
              variant="outline-primary"
              className="float-end"
              onClick={() => dispatch(downloadPermissions({ id: selectedFileProject.id }))}
            >
              権限ダウンロード
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <div className="--bold">ファイル一覧</div>
        <Col>
          {selectedFileProject.files.length === 0 ? (
            <Alert variant="info">ファイルがありません。</Alert>
          ) : (
            <Table
              col={[
                {
                  name: "ファイル名",
                  width: "50%",
                },
                {
                  name: "追加日時",
                  width: "20%",
                },
                {
                  name: "ダウンロード",
                  width: "15%",
                },
                {
                  name: "操作",
                  width: "15%",
                },
              ]}
              row={files.map((file, i) => {
                return {
                  data: [
                    state.mode === "fileNameEditing" && state.selectingFile?.key === file.key ? (
                      <>
                        <Row className="align-items-center">
                          <Col md={7}>
                            <Form.Control
                              type="text"
                              value={state.editingFileName}
                              onChange={(e) => $state({ ...state, editingFileName: e.target.value })}
                            />
                          </Col>
                          <Col md={1}>{state.editingFileExt}</Col>
                          <Col md={4}>
                            <Button
                              size="sm"
                              className="ms-1"
                              variant="outline-secondary"
                              onClick={() =>
                                $state({ ...state, mode: "previewing", selectingFile: null, editingFileName: "" })
                              }
                            >
                              キャンセル
                            </Button>
                            <Button
                              disabled={errorMessages.length > 0}
                              size="sm"
                              className="ms-1"
                              onClick={() => $state({ ...state, activeModal: "before_update_file_name" })}
                            >
                              更新
                            </Button>
                          </Col>
                        </Row>
                        {(() => {
                          const m = errorMessages?.find((message) => message.place === "fileName");
                          return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
                        })()}
                      </>
                    ) : (
                      <Row className="align-items-center">
                        {state.mode === "fileOrderEditing" && (
                          <Col md={2}>
                            <Button variant="link" disabled={i === 0} onClick={() => moveFileIndex(-1, i)}>
                              <Icon width={15} height={15} type="caret-up-fill" />
                            </Button>
                            <Button
                              variant="link"
                              disabled={i === state.editingFileOrderKeys.length - 1}
                              onClick={() => moveFileIndex(1, i)}
                            >
                              <Icon width={15} height={15} type="caret-down-fill" />
                            </Button>
                          </Col>
                        )}
                        <Col>{file.name}</Col>
                      </Row>
                    ),
                    dayjs(file.created_at).format("YYYY/MM/DD HH:mm"),
                    <Button
                      size="sm"
                      className="ms-2"
                      variant="outline-primary"
                      onClick={async () => {
                        dispatch(setLoading(true));
                        await dispatch(downloadFile({ id: selectedFileProject.id, key: file.key, isAdmin: true }));
                        dispatch(setLoading(false));
                      }}
                    >
                      <Icon type="download" width={16} height={16} />
                    </Button>,
                    <>
                      <Button
                        size="sm"
                        disabled={state.mode !== "previewing"}
                        onClick={() => {
                          // ファイル名と拡張子を分離
                          const pos = file.name.lastIndexOf(".");
                          const [fileName, ext] =
                            pos === -1 ? [file.name, ""] : [file.name.slice(0, pos), file.name.slice(pos)];
                          $state({
                            ...state,
                            mode: "fileNameEditing",
                            selectingFile: file,
                            editingFileName: fileName,
                            editingFileExt: ext,
                          });
                        }}
                      >
                        <Icon type="pencil-square" width={16} height={16} />
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        className="ms-2"
                        disabled={state.mode !== "previewing"}
                        onClick={() => $state({ ...state, activeModal: "before_delete", selectingFile: file })}
                      >
                        <Icon type="x-lg" width={16} height={16} />
                      </Button>
                    </>,
                  ],
                };
              })}
            />
          )}
        </Col>
      </Row>
      <Modal
        show={state.activeModal === "upload"}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <h2 className="Headline--section mb-2">ファイルの追加</h2>
              <div className="my-2">
                <Uploader
                  onFileLoad={async (decodedFileData) => {
                    const fileName = decodedFileData.name;
                    const pos = fileName.lastIndexOf(".");
                    const fileSize = decodedFileData?.size ?? 0;
                    if (fileSize > MAX_FILE_SIZE) {
                      // ファイルサイズ制限
                      return $state({ ...state, activeModal: "file_size_limit" });
                    } else if (selectedFileProject.files.some(({ name }) => name === fileName)) {
                      // ファイル名重複は不可
                      return $state({ ...state, activeModal: "duplicate_file_name" });
                    } else if (fileName.slice(0, pos) === "") {
                      // 拡張子前が空のファイル名は不可
                      return $state({ ...state, activeModal: "empty_file_name" });
                    }
                    dispatch(setLoading(true));
                    const res = testResponse(
                      await dispatch(attachFile({ id: selectedFileProject.id, decodedFileData, isBulk: false }))
                    );
                    if (!res) $state({ ...state, activeModal: "file_error" });
                    else closeModal();
                    dispatch(setLoading(false));
                  }}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="outline-secondary">
            キャンセル
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={state.activeModal === "bulk_upload"}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <h2 className="Headline--section mb-2">ファイルの一括追加</h2>
              <div className="text-muted --font-s">※ ファイルをまとめてzip形式に圧縮してアップロードしてください。</div>
              <div className="text-muted --font-s">※ アップロードできるファイルは .zip 形式に限ります。</div>
              <div className="my-2">
                <Uploader
                  accepts={["application/zip", "application/x-zip-compressed"]}
                  onFileLoad={async (decodedFileData) => {
                    dispatch(setLoading(true));
                    const res = testResponse(
                      await dispatch(attachFile({ id: selectedFileProject.id, decodedFileData, isBulk: true }))
                    );
                    if (!res) $state({ ...state, activeModal: "file_error" });
                    else closeModal();
                    dispatch(setLoading(false));
                  }}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="outline-secondary">
            キャンセル
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={state.activeModal === "before_delete"}
        onConfirm={async () => {
          if (!selectedFileProject || !state.selectingFile) return;
          dispatch(setLoading(true));

          await dispatch(deleteFile({ id: selectedFileProject.id, key: state.selectingFile.key }));

          closeModal();
          dispatch(setLoading(false));
        }}
        onCancel={closeModal}
        message={`ファイル（${state.selectingFile?.name}）を削除します。よろしいですか？`}
        type="destructiveConfirm"
        confirmButtonName="削除"
      />
      <ModalDialog
        show={state.activeModal === "file_size_limit"}
        onConfirm={closeModal}
        message="5MBを超えるファイルは追加できません。"
        type="alert"
      />
      <ModalDialog
        show={state.activeModal === "duplicate_file_name"}
        onConfirm={closeModal}
        message="既に同じ名前のファイルが存在します。"
        type="alert"
      />
      <ModalDialog
        show={state.activeModal === "empty_file_name"}
        onConfirm={closeModal}
        message="ファイル名空のファイルは追加できません。"
        type="alert"
      />
      <ModalDialog
        show={state.activeModal === "before_commit"}
        onConfirm={async () => {
          await dispatch(
            commitFileProject({
              id: selectedFileProject.id,
              name: state.name,
              description: state.description,
              startTime: state.startTime.getTime(),
              endTime: state.endTime.getTime(),
              displayType: state.displayType,
              usePrefix: state.displayType === "private" && state.usePrefix,
              isNotify: state.isNotify,
            })
          );
        }}
        onCancel={closeModal}
        message="更新します。よろしいですか？"
      />
      <ModalDialog
        show={state.activeModal === "before_delete_project"}
        onConfirm={async () => {
          if (!selectedFileProject) return;
          dispatch(setLoading(true));
          await dispatch(deleteProject({ id: selectedFileProject.id }));
          dispatch(setLoading(false));
          navigate("/_/files/file_admin");
        }}
        onCancel={closeModal}
        message="プロジェクトを削除します。紐づくファイルも削除されます。よろしいですか？"
        type="destructiveConfirm"
        confirmButtonName="削除"
      />
      <ModalDialog
        show={state.activeModal === "before_update_file_name"}
        onConfirm={async () => {
          if (!selectedFileProject || !state.selectingFile) return;
          await dispatch(
            putFileName({
              id: selectedFileProject.id,
              key: state.selectingFile?.key,
              name: `${state.editingFileName}${state.editingFileExt}`,
            })
          );
        }}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message="ファイル名を更新します。よろしいですか？"
      />
      <ModalDialog
        show={state.activeModal === "before_update_order"}
        onConfirm={async () => {
          if (!selectedFileProject) return;
          await dispatch(putFileOrder({ id: selectedFileProject.id, keys: state.editingFileOrderKeys }));
        }}
        onCancel={closeModal}
        message="ファイル表示順を更新します。よろしいですか？"
      />
      <ModalDialog
        show={state.activeModal === "bulk_upload_error"}
        onConfirm={closeModal}
        message={(() => {
          return (
            "以下ファイルは登録できませんでした。\n" +
            Object.keys(selectedFileProject?.errors ?? {})
              .map((fileName) => {
                const reasons = selectedFileProject?.errors?.[fileName] ?? [];
                const msg = reasons.map((r) => FILE_ERROR_MESSAGE_MAP[r]).join("/");
                return `・${fileName} : ${msg}`;
              })
              .join("\n")
          );
        })()}
        type="alert"
      />
      <ModalDialog
        show={state.activeModal === "file_error"}
        onConfirm={closeModal}
        message="ファイルを追加できませんでした。しばらく経ってから再度お試しください。"
        type="alert"
      />
    </Container>
  );
}

export default App;
