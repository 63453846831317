import AnswerHeaderRowModule from "./AnswerHeaderRowModule";
import TextStoriesModule from "../procedure/TextStoriesModule";
import AnswerRowListModule from "./AnswerRowListModule";
import StaticUnitModule from "./StaticUnitModule";
import ComputedUnitModule from "./ComputedUnitModule";

const subRoutines = {
  TextStoriesModule,
  AnswerHeaderRowModule,
  AnswerRowListModule,
  StaticUnitModule,
  ComputedUnitModule,
} as {
  [module: string]: any;
};
export default subRoutines;
