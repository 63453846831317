import { useAppDispatch, useAppSelector } from "../../app/store";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  Modal,
  Form,
  Accordion,
  Card,
  Alert,
  ButtonToolbar,
  Nav,
  Badge,
} from "react-bootstrap";
import Sidebar from "../../component/Sidebar";
import {
  getRunningForm,
  getApplyTemplate,
  unselectRunningForm,
  selectApplyState,
  selectTemplateAsProfileField,
  putRunningForm,
  deleteRunningForm,
  getApplicationHistory,
  unselectFormTemplate,
  getApplyTemplateSummaries,
  getAttachedFiles,
  attachFile,
  deleteFile,
} from "./applySlice";
import { selectUserState, selectUserRootRoles } from "../login/userSlice";
import { useState, useEffect, useMemo, Fragment, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import ProfileField from "../profile/ProfileField";
import { ProfileFieldStatus, USER_TABLE_PREFIX } from "../profile/profileValues";
import { FieldValue, ProfileSubField, isDisplayField } from "../profile/profileFieldValues";
import { getAllTerms } from "../../app/translate";
import { toTimeLabel, testResponse } from "../../app/util";
import Table from "../../component/Table";
import {
  ApplyStepperStep,
  APPLY_STEPPER_KEYS,
  ProcessorsPerStep,
  validateSteps,
  PutApplicationParameters,
  ApplyTemplateStep,
  ProcessorChoice,
  Assignment,
  isEditableItem,
} from "./applyValues";
import Stepper from "../../component/Stepper";
import ApplySteps from "./ApplySteps";
import TooltipButton from "../../component/TooltipButton";
import ModalDialog from "../../component/ModalDialog";
import Icon from "../../component/Icon";
import Linkify from "linkify-react";
import { downloadFile } from "../profile/profileSlice";
import { selectNotificationState, setLoading } from "../notification/notificationSlice";

function App() {
  const TERMS = getAllTerms();
  const { applicantId, formId } = useParams();
  const { selectedTemplate, selectedRunningForm, applicationHistory, templateSummaries, attachedFiles } =
    useAppSelector(selectApplyState);
  const { originalProfileField, validateProfileField } = useAppSelector(selectTemplateAsProfileField);
  const { user, policies } = useAppSelector(selectUserState);
  const roles = useAppSelector(selectUserRootRoles);
  const { loading } = useAppSelector(selectNotificationState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [state, $state] = useState({
    mode: "previewing" as "previewing" | "editing" | "stepEditing",
    isError: false,
    activeModal: "",
    rejectComment: "",
    pullbackComment: "",
    rejectTo: undefined as number | undefined,
    toRejectStep: false,
    isRightAfterCreate: false,
    pullbackStepOrder: 0,
    pageIndex: 0,
  });
  const [profileField, $profileField] = useState({ ...originalProfileField });
  const [prevProfileField, $prevProfileField] = useState({ ...originalProfileField });
  const [fieldStatus, $fieldStatus] = useState({} as ProfileFieldStatus);
  const [validated, $validated] = useState(true);
  const [processorsPerSteps, $processorsPerSteps] = useState([] as ProcessorsPerStep[]);
  const [prevProcessorsPerSteps, $prevProcessorsPerSteps] = useState([] as ProcessorsPerStep[]);
  const [currentStepOrder, isCurrentStepProcessor] = useMemo(() => {
    let currentStepOrder = 0;
    let isCurrentProcessor = false;
    selectedRunningForm.steps.forEach((step) => {
      if (step.status === "running") {
        currentStepOrder = step.order;
        isCurrentProcessor = step.processors.some((p) => p.id === user.id);
      }
    });
    // 完了済の場合、currentStepOrderが0だと不都合が多いのでmaxのorder+1にしておく
    if (selectedRunningForm.status === "done") {
      currentStepOrder = selectedRunningForm.steps.length + 1;
    }
    return [currentStepOrder, isCurrentProcessor];
  }, [selectedRunningForm]);
  const [updatedAt, $updatedAt] = useState(0);

  const moveToTop = () => {
    navigate("/_/apply/");
  };

  const isAdmin = useMemo(() => {
    return Object.keys(policies)
      .filter((key: string) => key.includes("application_admin_manager"))
      ?.some((api) => policies[api]?.includes("GET"));
  }, [roles, policies]);

  const flattenInputs = useMemo(() => {
    // 入力項目をフラットにする
    return selectedTemplate.input_pages
      .map(({ inputs, processor_inputs }) => [...(inputs ?? []), ...(processor_inputs ?? [])])
      .flat();
  }, [selectedTemplate]);

  const beforeApplyRecord = useMemo(() => {
    // 変更前の内容
    const rawRecord = selectedRunningForm.before_apply_record;
    return rawRecord
      ? flattenInputs
          .filter(({ key, display }) => display !== false && rawRecord[key] !== undefined)
          .map(({ key, label, type }) => {
            return { key, type, value: rawRecord[key], label };
          })
      : [];
  }, [selectedRunningForm, flattenInputs]);

  const templateSummary = useMemo(() => {
    return templateSummaries.find(({ application_type }) => application_type === selectedRunningForm.template_type);
  }, [templateSummaries, selectedRunningForm]);

  const isProcessable = useMemo(() => {
    // データ反映済であれば処理可能
    if (["copied", "done"].includes(selectedRunningForm.status)) return true;
    // 廃止済
    if (templateSummary?.template_status === "obsolete") return false;
    // データ反映対象が存在しない
    if (Object.values(selectedTemplate.exist_target).some((v) => v === false)) return false;
    return true;
  }, [templateSummary, selectedTemplate, selectedRunningForm]);

  const isApplicant = useMemo(() => {
    const { applicant_id, representative_applicant_id } = selectedRunningForm;
    if (representative_applicant_id) return representative_applicant_id === user.id;
    return applicant_id === user.id;
  }, [selectedRunningForm, user]);

  const alertMessages = useMemo(() => {
    // 申請者や処理者に操作を促すメッセージ
    const { status } = selectedRunningForm;
    const latestRejectComment = (() => {
      if (["rejected_to_applicant", "rejected"].includes(status)) {
        return applicationHistory.find((_) => _.type === "reject")?.comment;
      }
    })();
    const templateStatus = templateSummary?.template_status;
    let alertMessages = [] as { variant: string; message: string }[];
    if (!["copied", "done"].includes(status)) {
      if (Object.values(selectedTemplate.exist_target).some((v) => v === false)) {
        alertMessages.push({
          variant: "danger",
          message: "データ反映対象が存在しません。これ以上フローを進めることはできません。",
        });
      }
      if (templateStatus === "obsolete") {
        alertMessages.push({
          variant: "danger",
          message: "この申請書種別は廃止済です。これ以上フローを進めることはできません。",
        });
      } else if (templateStatus === "depricated") {
        alertMessages.push({
          variant: "warning",
          message: `この申請書種別は ${templateSummary?.obsolete_on} に廃止になります。`,
        });
      }
    }
    if (isCurrentStepProcessor && isProcessable) {
      if (status === "rejected") {
        alertMessages.push({
          variant: "warning",
          message: `差し戻されました。コメントをご確認の上、修正して再度「承認」してください。\nコメント：${latestRejectComment}`,
        });
      } else {
        const hasInput = flattenInputs.some((_) => _.input_step_order === currentStepOrder);
        if (hasInput) {
          alertMessages.push({
            variant: "warning",
            message:
              "入力項目があります。「承認」前に「編集」ボタンを押下して値を設定してください。\n入力項目は、申請内容の下部にあります。",
          });
        }
        const rejectMessage = status !== "copied" ? "\n問題がある場合は、「差し戻し」てください。" : "";
        alertMessages.push({
          variant: "info",
          message: `申請内容をご確認の上、問題なければ「承認」してください。${rejectMessage}`,
        });
      }
    } else if (isApplicant && isProcessable) {
      if (status === "todo") {
        if (validated) {
          alertMessages.push({
            variant: "info",
            message: "申請可能です。\n入力内容をご確認の上、問題なければ「申請」ボタンを押下してください。",
          });
        } else {
          alertMessages.push({
            variant: "danger",
            message: "入力内容に不備があります。「申請」するには「編集」ボタンを押下して内容を修正してください。",
          });
        }
      } else if (status === "rejected_to_applicant") {
        alertMessages.push({
          variant: "warning",
          message: `差し戻されました。コメントをご確認の上、修正して「再申請」してください。\nコメント：${latestRejectComment}`,
        });
      }
    }
    return alertMessages;
  }, [
    selectedRunningForm,
    applicationHistory,
    isCurrentStepProcessor,
    user,
    templateSummary,
    isProcessable,
    isApplicant,
  ]);

  const visibleLastStepOrder = useMemo(() => {
    // 確認可能な最も後ろのステップ（デフォルトは0=申請者の入力項目のみ）
    let _visibleStepOrder = 0;
    selectedRunningForm.steps.forEach(({ processors, order }) => {
      if (processors.some(({ id }) => id === user.id)) _visibleStepOrder = order;
    });
    return _visibleStepOrder;
  }, [selectedRunningForm, user]);

  useEffect(() => {
    if (selectedRunningForm.id) {
      const next = { ...originalProfileField };
      next.subFields = next.subFields.map((subField) => {
        const correspondingInput =
          flattenInputs.find((_) => _.key === subField.id) ??
          selectedTemplate.inputs_on_create.find((_) => _.key === subField.id);
        if (!correspondingInput) return subField; // 通常ない
        const inputStepOrder = correspondingInput.input_step_order;
        const editable = subField.editable && inputStepOrder === currentStepOrder;
        const currentSubField = profileField.subFields.find((_) => _.id === subField.id);
        const [isVisible, value, label] = (() => {
          // 確認可能なステップより後ろの場合、必ず非表示
          // 現在のステップより後ろの入力項目で未入力の場合は承認処理で入力されるため、非表示
          const _value = selectedRunningForm.inputs[subField.id];
          const _label = selectedRunningForm.inputs[`${subField.id}**LABEL`];
          const inputStepOrder = correspondingInput.input_step_order;
          if (
            (inputStepOrder > visibleLastStepOrder || (inputStepOrder > currentStepOrder && _value === undefined)) &&
            correspondingInput.type !== "staticLabel"
          ) {
            return [true, `（ステップ${inputStepOrder}入力項目）`];
          }
          return [
            false,
            currentSubField?.entered ? currentSubField?.value : _value ?? correspondingInput?.value ?? null,
            _label,
          ];
        })();
        let count = 0;
        if (subField.type === "file") {
          if (attachedFiles.length > 0) {
            const targetFiles = attachedFiles.filter((f) => f.id === value);
            if (targetFiles.length > 0 && targetFiles[0]?.files) {
              const validFiles = targetFiles[0].files.filter((f: any) => f.deleted_at === null);
              count = validFiles.length;
            }
          }
        }
        return {
          ...subField,
          value,
          editable,
          type: isVisible ? "string" : subField.type,
          label,
          count,
          entered: currentSubField?.entered ?? false,
        };
      });

      const isRightAfterCreate = selectedRunningForm.created_at === selectedRunningForm.updated_at && isApplicant;
      // 操作可能で、変更前の内容がある場合
      if (isProcessable && selectedTemplate.show_before_apply_record && selectedRunningForm.before_apply_record) {
        if (isRightAfterCreate) {
          // 作成直後は変更前の内容の内容をコピー
          next.subFields = copyBeforeApplyRecord(next.subFields);
        } else {
          // 変更前の内容と比較
          next.subFields = compareToBeforeApplyRecord(next.subFields);
        }
      }
      $profileField({ ...next });
      // 一時保存の場合のみ prevProfileField を更新（添付ファイルのアップロード／削除時にも更新されてしまうのを防ぐ）
      if (originalProfileField.fieldName && updatedAt !== selectedRunningForm.updated_at) {
        $prevProfileField({ ...next });
        $updatedAt(selectedRunningForm.updated_at);
      }
      // 初回のバリデート実施
      const { validated, subFields } = validateProfileField(next.subFields);
      $validated(validated);
      const fieldName = next.fieldName;
      let nextFieldStatus = {} as ProfileFieldStatus;
      subFields.forEach((sf) => {
        const path = `${fieldName}/${sf.id}`;
        nextFieldStatus = {
          ...nextFieldStatus,
          [path]: { validated: !sf.errorMessage, errorMessage: sf.errorMessage ?? "" },
        };
      });
      $fieldStatus(nextFieldStatus);
      const mode = (() => {
        if (!isProcessable) return "previewing";
        if (isRightAfterCreate) return "editing";
        return state.mode;
      })();
      $state({ ...state, isRightAfterCreate, mode });
    }
  }, [originalProfileField.fieldName, selectedRunningForm, attachedFiles, isProcessable]);
  useEffect(() => {
    if (formId && applicantId && user.id) {
      getApplication();
    }
    if (templateSummaries.length === 0) {
      dispatch(getApplyTemplateSummaries());
    }
    return () => {
      dispatch(unselectRunningForm());
      dispatch(unselectFormTemplate());
    };
  }, [formId, applicantId, user, isAdmin]);
  useEffect(() => {
    if (selectedRunningForm.id) {
      if (!selectedTemplate.id) {
        // 初回のみ取得
        dispatch(
          getApplyTemplate({
            application_type: selectedRunningForm.template_type,
            application_id: selectedRunningForm.id,
          })
        );
      }
      dispatch(getApplicationHistory({ application_id: selectedRunningForm.id }));
    }
  }, [selectedRunningForm]);

  const templateSteps = useMemo(() => {
    if (selectedTemplate.id) {
      // テンプレートの情報を用いて
      return selectedRunningForm.template_steps.map((s) => ({
        ...s,
        hasInput: flattenInputs.some((_) => _.input_step_order === s.order),
      }));
    }
    return [] as ApplyTemplateStep[];
    // runningForm→templateの順で取得するのでselectedTemplateのみ依存
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate.id && selectedRunningForm.id) {
      const fileInputs = flattenInputs.filter(({ type }) => type === "file").map((_) => _.key);
      const id__in = Object.keys(selectedRunningForm.inputs)
        .filter((key) => fileInputs.includes(key))
        .map((key) => selectedRunningForm.inputs[key])
        .flat()
        .filter((id): id is string => typeof id === "string");
      if (id__in.length > 0) {
        dispatch(getAttachedFiles({ id__in }));
      }
    }
    // runningForm→templateの順で取得するのでselectedTemplateのみ依存
  }, [selectedTemplate]);

  const getApplication = async () => {
    // 申請書を取得、取得できなければトップへ誘導するモーダル表示
    if (formId) {
      dispatch(setLoading(true));
      const result = testResponse(await dispatch(getRunningForm({ id: formId, isAdmin })));
      if (!result) $state({ ...state, activeModal: "not_exist" });
      dispatch(setLoading(false));
    }
  };
  const putApplication = async (params: PutApplicationParameters) => {
    // 申請書を更新、エラーの場合はモーダル表示
    dispatch(setLoading(true));
    const result = testResponse(await dispatch(putRunningForm(params)));
    if (!result) $state({ ...state, activeModal: "no_permission" });
    dispatch(setLoading(false));
  };

  const copyBeforeApplyRecord = (subFields: ProfileSubField[]) => {
    const beforeApplyRecord = selectedRunningForm.before_apply_record;
    if (!beforeApplyRecord) return subFields;
    // 変更前の内容がコピーされたsubFieldsを取得する
    return subFields.map((s) => {
      // 編集不可の値はコピーしない
      if (s.editable !== true) return s;
      // 変更前の内容の値を取得
      const value = beforeApplyRecord[s.id];
      if (value === undefined) return s;
      // 添付ファイルはprofile::fileにレコードが作成されるためコピーしない
      if (s.value) return s;
      return {
        ...s,
        value,
      };
    });
  };

  const compareToBeforeApplyRecord = (subFields: ProfileSubField[]) => {
    const beforeApplyRecord = selectedRunningForm.before_apply_record;
    if (!beforeApplyRecord) return subFields;
    // 変更前の内容との差分がある場合に項目をハイライトする
    return subFields.map((s) => {
      const correspondingInput = flattenInputs.find((_) => _.key === s.id);
      if (!correspondingInput) return s;
      // 申請者が入力する内容のみ
      if (correspondingInput.input_step_order !== 0) return s;
      // 変更前の内容の値がない場合はスルー
      const beforeApplyRecordValue = beforeApplyRecord[s.id];
      if (beforeApplyRecordValue === undefined) return s;
      // 値の比較
      let value = s.value;
      // mongoでdatetime型で保存される関係で日付の場合は変換を実施
      if (s.type === "date" && typeof value === "string") value = dayjs(value).format("YYYY-MM-DD");
      if (beforeApplyRecordValue === value) return s;
      // 差分がある場合はhilightedをtrueにする
      return {
        ...s,
        highlighted: true,
      };
    });
  };

  useEffect(() => {
    // 承認ステップ 候補と候補外から追加されたアカウントを表示
    const { steps, template_steps } = selectedRunningForm;
    const processorsPerSteps = steps.map(({ processors }, i) => {
      // 設定中の承認者とテンプレートの承認者(候補)を全て表示する
      const processorChoices = [...template_steps[i].processors, ...processors].reduce(
        (prev: ProcessorChoice[], processor: { id: number; name: string }) => {
          if (prev.some((_) => _.id === processor.id)) return prev;
          return [
            ...prev,
            { id: processor.id, name: processor.name, isSelected: processors.some((_) => _.id === processor.id) },
          ];
        },
        [] as ProcessorChoice[]
      );
      return { processorChoices };
    });
    $processorsPerSteps(processorsPerSteps);
    $prevProcessorsPerSteps(processorsPerSteps);
  }, [selectedRunningForm]);

  const edit = () => {
    $state({ ...state, mode: "editing" });
  };

  const editStep = () => {
    $state({ ...state, mode: "stepEditing" });
  };
  const save = () => {
    const isAdminRequest = state.mode === "stepEditing";
    putApplication({
      request_type: !isAdminRequest ? "update" : undefined,
      id: selectedRunningForm.id,
      updated_at: selectedRunningForm.updated_at,
      inputs:
        state.mode === "editing"
          ? profileField.subFields.reduce((prev, current) => {
              if (!current.editable) return prev;
              else {
                const correspondingInputInTemplate = flattenInputs.find((_) => _.key === current.id);
                if (!correspondingInputInTemplate || correspondingInputInTemplate.input_step_order > currentStepOrder)
                  return prev;
                if (
                  current.type === "file" ||
                  !isEditableItem({
                    type: current.type,
                    display: correspondingInputInTemplate.display ?? true,
                    referenceType: correspondingInputInTemplate.reference_type,
                  })
                )
                  return prev;
                const correspondingPrevValue = prevProfileField.subFields.find((_) => _.id === current.id)?.value;
                // boolean型の場合はnullでなく明示的にfalseをセットする。
                // nullだとDBのデフォルト値が設定されるため
                if (current.type === "boolean" && current.value === null) {
                  current.value = false;
                }
                let value = current.value;
                // float型の場合はstringからnumberに変換
                if (current.type === "float" && value !== null && value !== "") {
                  value = +value;
                }
                // 表示されていない項目は null とする
                // ただし、file 項目、および 常に保持する値は対象外
                const isDisplay = isDisplayField(current, profileField.subFields);
                if (!isDisplay && current.keepValue !== true) value = null;
                // 作成直後のPUTは全ての値を送信する
                if (!state.isRightAfterCreate && correspondingPrevValue === value) {
                  return prev;
                } else {
                  return {
                    ...prev,
                    [current.id]: value,
                  };
                }
              }
            }, {} as { [key: string]: FieldValue })
          : undefined,
      steps:
        currentStepOrder === 0 || isAdminRequest
          ? selectedTemplate.steps.map((step, stepIndex) => {
              return {
                order: step.order,
                processors: processorsPerSteps[stepIndex].processorChoices
                  .filter(({ isSelected }) => isSelected)
                  .map(({ id }) => ({ id })),
              };
            })
          : undefined,
      isAdmin: isAdminRequest,
    });
    $state({ ...state, mode: "previewing", activeModal: "" });
  };
  const apply = () => {
    putApplication({
      request_type: "apply",
      id: selectedRunningForm.id,
      updated_at: selectedRunningForm.updated_at,
      to_rejected_step: state.toRejectStep,
    });
    closeModal();
  };
  const cancelEdit = () => {
    $state({ ...state, mode: "previewing" });
    $profileField({
      ...prevProfileField,
      subFields: prevProfileField.subFields.map((s) => ({ ...s, errorMessage: "" })),
    });
    $processorsPerSteps([...prevProcessorsPerSteps]);
  };

  const approve = () => {
    // 承認
    putApplication({
      request_type: "approve",
      id: selectedRunningForm.id,
      updated_at: selectedRunningForm.updated_at,
      step_order: currentStepOrder,
      to_rejected_step: state.toRejectStep,
    });
    closeModal();
  };

  const reject = () => {
    // 差し戻し
    putApplication({
      request_type: "reject",
      id: selectedRunningForm.id,
      updated_at: selectedRunningForm.updated_at,
      step_order: currentStepOrder,
      comment: state.rejectComment,
      reject_to: state.rejectTo,
    });
    closeModal();
  };

  const pullback = () => {
    // 引き戻し
    putApplication({
      request_type: "pullback",
      id: selectedRunningForm.id,
      updated_at: selectedRunningForm.updated_at,
      comment: state.pullbackComment,
      step_order: state.pullbackStepOrder,
    });
    closeModal();
  };

  const deleteApplication = () => {
    dispatch(setLoading(true));
    // 削除
    dispatch(
      deleteRunningForm({
        id: selectedRunningForm.id,
      })
    )
      .then(() => {
        // ローディングは申請書トップ画面側の初期表示でstart~stopされるので、ここでは何もしない
        moveToTop();
      })
      .catch(() => {
        // 削除に失敗した場合はローディング停止
        dispatch(setLoading(false));
      });
  };

  const openModal = (activeModal: string) => {
    $state({ ...state, activeModal });
  };

  const closeModal = () => {
    $state({
      ...state,
      activeModal: "",
      toRejectStep: false,
      rejectTo: undefined,
      rejectComment: "",
      pullbackComment: "",
      pullbackStepOrder: 0,
    });
  };

  const print = () => {
    window.print();
  };

  const activeStepperIndex = useMemo(() => {
    // ステッパーのactiveなindexを取得
    if (selectedRunningForm.id) {
      const { status } = selectedRunningForm;
      if (status === "todo") return ApplyStepperStep.Input;
      if (status === "rejected_to_applicant") return ApplyStepperStep.Input;
      if (status === "done") return ApplyStepperStep.Approve;
      return ApplyStepperStep.Apply;
    }
    return ApplyStepperStep.Create;
  }, [selectedRunningForm]);

  const isInputRequired = useMemo(() => {
    return (
      ((isCurrentStepProcessor && currentStepOrder > 0) || (isApplicant && currentStepOrder === 0)) &&
      flattenInputs.some((_) => _.input_step_order === currentStepOrder)
    );
  }, [isApplicant, isCurrentStepProcessor, currentStepOrder, flattenInputs]);

  const isStepEditable = useMemo(() => {
    return isAdmin && currentStepOrder !== 0 && selectedRunningForm.status !== "done";
  }, [isAdmin, currentStepOrder, selectedRunningForm]);

  const controls = useMemo(() => {
    const { status, steps } = selectedRunningForm;
    // 最新の引き戻し可能ステップ
    const copiedSteps = [...steps];
    copiedSteps.sort((s1, s2) => s2.order - s1.order);
    const latestPullbackStep =
      ["running", "rejected"].includes(status) &&
      copiedSteps.find(
        (s) =>
          // 自分が処理者かつ入力欄があったステップをフィルダー
          s.processed_by === user.id && s.status === "done" && flattenInputs.find((_) => _.input_step_order === s.order)
      );
    if (state.mode === "previewing") {
      // プレビュー
      return (
        <Row className="--print-none">
          <Col>
            <ButtonToolbar className="justify-content-between">
              <div>
                {isInputRequired && isProcessable && (
                  <Button variant="outline-secondary" className="mx-1" onClick={edit}>
                    {isStepEditable ? "入力項目編集" : "編集"}
                  </Button>
                )}
                {isStepEditable && isProcessable && (
                  <Button variant="outline-secondary" className="mx-1" onClick={editStep}>
                    ステップ編集
                  </Button>
                )}
                {isApplicant && (
                  <Fragment>
                    {status === "todo" && isProcessable && (
                      <TooltipButton
                        tooltipText="申請を実行すると、最初の承認者が承認処理待ちになります。"
                        variant="primary"
                        disabled={!validated || loading}
                        className="mx-1"
                        onClick={() => openModal("before_apply")}
                      >
                        申請
                      </TooltipButton>
                    )}
                    {status === "rejected_to_applicant" && isProcessable && (
                      <TooltipButton
                        tooltipText="再申請を実行すると、最初の承認者または差し戻し元の承認者が承認処理待ちになります。"
                        variant="primary"
                        disabled={!validated || loading}
                        className="mx-1"
                        onClick={() => openModal("select_re_apply_destination")}
                      >
                        再申請
                      </TooltipButton>
                    )}
                    {["running", "rejected"].includes(status) && !latestPullbackStep && (
                      <TooltipButton
                        tooltipText="申請前の状態へ戻します。承認のステータスは全てクリアされます。"
                        variant="outline-primary"
                        className="mx-1"
                        disabled={loading}
                        onClick={() => openModal("input_pullback_comment")}
                      >
                        申請前へ引き戻し
                      </TooltipButton>
                    )}
                  </Fragment>
                )}
                {isCurrentStepProcessor && (
                  <Fragment>
                    {status !== "copied" && (
                      <TooltipButton
                        tooltipText="申請者もしくは承認済のステップへ差し戻します。"
                        variant="outline-primary"
                        className="mx-1"
                        disabled={loading}
                        onClick={() => openModal("input_reject_comment")}
                      >
                        差し戻し
                      </TooltipButton>
                    )}
                    {isProcessable && (
                      <TooltipButton
                        tooltipText={`承認すると、次${
                          status === "rejected" ? "または差し戻し元" : ""
                        }のステップへ移動します。最後のステップの場合は申請書が完了になります。`}
                        variant="primary"
                        className="mx-1"
                        disabled={!validated || loading}
                        onClick={() => {
                          $state({
                            ...state,
                            activeModal:
                              status !== "rejected" ||
                              (status === "rejected" &&
                                selectedRunningForm.steps.find(
                                  ({ order, processors }) => order > currentStepOrder && processors.length > 0
                                )?.status === "rejected")
                                ? "before_approve"
                                : "select_re_approve_destination",
                          });
                        }}
                      >
                        承認
                      </TooltipButton>
                    )}
                  </Fragment>
                )}
                {latestPullbackStep && (
                  <TooltipButton
                    tooltipText={`ステップ${latestPullbackStep.order}の承認前まで戻します。ステップ${latestPullbackStep.order}以降の承認のステータスは全てクリアされます。`}
                    variant="outline-primary"
                    className="mx-1"
                    disabled={loading}
                    onClick={() =>
                      $state({
                        ...state,
                        activeModal: "input_pullback_comment",
                        pullbackStepOrder: latestPullbackStep.order,
                      })
                    }
                  >
                    {`ステップ${latestPullbackStep.order}承認前へ引き戻し`}
                  </TooltipButton>
                )}
              </div>
              {["todo", "rejected_to_applicant"].includes(status) && isApplicant && (
                <TooltipButton
                  tooltipText="申請書を削除します。削除した申請書は元に戻せません。"
                  variant="danger"
                  className="float-end"
                  disabled={loading}
                  onClick={() => openModal("before_delete")}
                >
                  削除
                </TooltipButton>
              )}
            </ButtonToolbar>
          </Col>
        </Row>
      );
    } else {
      // 編集中
      if (!isProcessable) return null;
      return (
        <Row className="--print-none">
          <Col>
            {!state.isRightAfterCreate && (
              <Button variant="outline-secondary" className="mx-1" onClick={cancelEdit}>
                キャンセル
              </Button>
            )}
            <Button
              variant="primary"
              className="mx-1"
              onClick={() => {
                if (state.mode === "editing") save();
                if (state.mode === "stepEditing") openModal("before_update_steps");
              }}
              disabled={processorsPerSteps.some((_) => _.errorMessage)}
            >
              一時保存
            </Button>
          </Col>
        </Row>
      );
    }
  }, [
    selectedRunningForm,
    state,
    validated,
    profileField,
    currentStepOrder,
    processorsPerSteps,
    isInputRequired,
    templateSummary,
    isStepEditable,
    isApplicant,
  ]);

  const pagingControls = useMemo(() => {
    if (selectedTemplate.input_pages.length === 1) return null;
    return (
      <Nav
        variant="tabs"
        className="my-2"
        activeKey={state.pageIndex}
        onSelect={(pageIndex) => {
          if (pageIndex !== null) {
            $state({ ...state, pageIndex: +pageIndex });
          }
        }}
      >
        {selectedTemplate.input_pages.map(({ name, inputs, processor_inputs, display }, i) => {
          if (display === false) return null;
          const hasError = (() => {
            if (!isInputRequired) return false;
            if (state.mode === "editing") return false;
            const inputKeys = [...(inputs ?? []), ...(processor_inputs ?? [])]
              .filter(({ key }) => key)
              .map(({ key }) => `${selectedTemplate.id}/${key}`);
            return Object.keys(fieldStatus).some((key) => inputKeys.includes(key) && !fieldStatus[key].validated);
          })();
          return (
            <Nav.Item key={i}>
              <Nav.Link eventKey={i}>
                {name}
                {hasError && (
                  <Badge pill bg="danger" className="ms-1">
                    !
                  </Badge>
                )}
              </Nav.Link>
            </Nav.Item>
          );
        })}
      </Nav>
    );
  }, [selectedTemplate, fieldStatus, isInputRequired, state.pageIndex, state.mode, isProcessable]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"apply"} />
      </div>
      {selectedRunningForm.id && (
        <div className="Layout__main">
          <ButtonToolbar className="justify-content-between">
            <h1 className="Headline--page">
              {templateSummary?.name}
              <span
                className={classNames({
                  "Badge--waiting": ["todo", "pulling_back"].includes(selectedRunningForm.status),
                  "Badge--running": ["running", "copied"].includes(selectedRunningForm.status),
                  "Badge--danger": ["rejected", "rejected_to_applicant"].includes(selectedRunningForm.status),
                  "Badge--ok": selectedRunningForm.status === "done",
                  "mx-1": true,
                })}
              >
                {TERMS[`APPLICATION_STATUS_${selectedRunningForm.status}`]}
              </span>
            </h1>
            <div>
              <Button variant="outline-secondary" className="mx-1" onClick={print}>
                印刷
              </Button>
              <Button variant="outline-secondary" className="mx-1" onClick={moveToTop}>
                申請書トップに戻る
              </Button>
            </div>
          </ButtonToolbar>
          {isApplicant && (
            <div className="bg-white py-2 my-2">
              <Stepper
                steps={APPLY_STEPPER_KEYS.map((key) => ({ label: TERMS[key] }))}
                activeIndex={activeStepperIndex}
              ></Stepper>
            </div>
          )}
          <main className="mt-3 py-4 px-md-2 bg-white">
            <Container>
              <div className="mb-2">{controls}</div>
              {state.mode === "previewing" &&
                alertMessages.map((message, i) => (
                  <Col key={`message${i}`}>
                    <Alert variant={message.variant} className="--pre-wrap">
                      {message.message}
                    </Alert>
                  </Col>
                ))}
              <Row className="mb-2">
                <Col className="--font-s d-md-flex">
                  {selectedRunningForm.created_at ? (
                    <div className="my-1 mx-1">
                      作成：
                      {dayjs(selectedRunningForm.created_at).format("YYYY/MM/DD")}
                    </div>
                  ) : null}
                  {selectedRunningForm.updated_at ? (
                    <div className="my-1 mx-1">
                      更新：
                      {dayjs(selectedRunningForm.updated_at).format("YYYY/MM/DD")}
                    </div>
                  ) : null}
                  {selectedRunningForm.applied_at ? (
                    <div className="my-1 mx-1">
                      申請：
                      {dayjs(selectedRunningForm.applied_at).format("YYYY/MM/DD")}
                    </div>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <h2 className="Headline--section mb-2">申請者</h2>
                  {(() => {
                    const assignments = selectedRunningForm.assignments;
                    const mainAssignment = assignments?.find((assignment: Assignment) => !assignment.is_concurrent);
                    const concurrentAssignments = assignments?.filter(
                      (assignment: Assignment) => assignment.is_concurrent
                    );
                    return (
                      <div>
                        <Row>
                          <Col xs={4} sm={4} className="--bold">
                            名前
                          </Col>
                          <Col xs={8} sm={8}>
                            {selectedRunningForm.applicant_name}
                          </Col>
                          <Col xs={4} sm={4} className="--bold">
                            部署
                          </Col>
                          <Col xs={8} sm={8}>
                            {mainAssignment?.section_name}
                          </Col>
                          <Col xs={4} sm={4} className="--bold">
                            役職
                          </Col>
                          <Col xs={8} sm={8}>
                            {mainAssignment?.position_name}
                          </Col>
                          {concurrentAssignments?.map((assignment, index) => {
                            return (
                              <Fragment key={assignment.section_name}>
                                {index === 0 ? (
                                  <Col xs={4} sm={4} className="--bold">
                                    兼務
                                  </Col>
                                ) : (
                                  <Col xs={4} sm={4}></Col>
                                )}
                                <Col xs={8} sm={8}>
                                  {assignment.section_name}（{assignment.position_name}）
                                </Col>
                              </Fragment>
                            );
                          })}
                        </Row>
                      </div>
                    );
                  })()}
                  {selectedRunningForm.representative_applicant_id && (
                    <>
                      <h2 className="Headline--section mt-2">代理申請者</h2>
                      <Row>
                        <Col xs={4} sm={4} className="--bold">
                          名前
                        </Col>
                        <Col xs={8} sm={8}>
                          {selectedRunningForm.representative_applicant_name}
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
                <Col md={8} className="mt-md-0 mt-2">
                  <h2 className="Headline--section mb-2">承認ステップ</h2>
                  <ApplySteps
                    templateSteps={templateSteps}
                    runningSteps={selectedRunningForm.steps}
                    processorsPerSteps={processorsPerSteps}
                    mode={(() => {
                      if (state.mode === "stepEditing") return "editing";
                      if (currentStepOrder === 0 && state.mode === "editing") return "editing";
                      return "previewing";
                    })()}
                    loginUserId={user.id}
                    terms={selectedRunningForm.steps.reduce(
                      (prev, current) => ({
                        ...prev,
                        [`APPLICATION_STEP_STATUS_${current.status}`]:
                          TERMS[`APPLICATION_STEP_STATUS_${current.status}`],
                      }),
                      {}
                    )}
                    isAdmin={isStepEditable}
                    isTesting={!!selectedRunningForm.is_testing}
                    applicant={selectedRunningForm.applicant_id}
                    onChange={(_next) => {
                      const next = validateSteps(_next, templateSteps);
                      $processorsPerSteps(next);
                    }}
                  ></ApplySteps>
                </Col>
              </Row>
              {selectedTemplate.inputs_on_create.length > 0 && (
                <Row className="mt-4">
                  <Col>
                    <h2 className="Headline--section mb-2">作成時入力項目</h2>
                    <Card>
                      <Card.Body>
                        <ListGroup className="my-1">
                          {selectedTemplate.inputs_on_create.map((input) => {
                            const { key, type, options, label, targets } = input;
                            let value = selectedRunningForm.inputs[key];
                            const _label = selectedRunningForm.inputs[`${key}**LABEL`] || null;
                            // checkbox の場合は value が配列になるので、全て配列にする
                            let labelValues = [{ label: value, value }];
                            if (type === "options") {
                              const selectedOption = options?.find((o) => o.value === value);
                              labelValues = [{ label: _label ?? selectedOption?.label ?? value, value }];
                            } else if (type === "checkbox" && Array.isArray(value)) {
                              labelValues = value.map((v, i) => {
                                const selectedOption = options?.find((o) => o.value === v);
                                const __label = _label && Array.isArray(_label) && _label?.[i];
                                return { label: __label ?? selectedOption?.label ?? v, value: v };
                              });
                            } else if (type === "date" && typeof value === "string") {
                              const _value = dayjs(value).format("YYYY 年 MM 月 DD 日");
                              labelValues = [{ label: _value, value: _value }];
                            }
                            const codeMatch = targets
                              ?.map(({ column }) => (column ?? key).match("(.+)_code"))
                              ?.filter((_) => _)?.[0];
                            const idTarget = targets
                              ?.map(
                                ({ table, column }) =>
                                  ["id", "id__in"].includes(column ?? key) && table.replace(USER_TABLE_PREFIX, "")
                              )
                              ?.filter((_) => _)?.[0];

                            return (
                              <Row key={`inputs-${key}`} className="align-items-center my-1">
                                <Col md={6} className="--bold">
                                  {label}
                                </Col>
                                <Col md={6} className="my-1">
                                  {labelValues.map((labelValue, i) => {
                                    return (
                                      <div key={i}>
                                        {labelValue.label}
                                        {codeMatch && (
                                          <Link
                                            className="ms-1"
                                            target="_blank"
                                            to={`/_/profile/${selectedRunningForm.applicant_id}/?field=${codeMatch[1]}&type=history`}
                                          >
                                            履歴
                                            <span className="--px-1">
                                              <Icon type="external-tab" width={16} height={16} />
                                            </span>
                                          </Link>
                                        )}
                                        {idTarget && !["copied", "done"].includes(selectedRunningForm.status) && (
                                          <Link
                                            className="ms-1"
                                            target="_blank"
                                            to={`/_/profile/${selectedRunningForm.applicant_id}/?field=${idTarget}&type=detail&id=${labelValue.value}`}
                                          >
                                            参照
                                            <span className="--px-1">
                                              <Icon type="external-tab" width={16} height={16} />
                                            </span>
                                          </Link>
                                        )}
                                      </div>
                                    );
                                  })}
                                </Col>
                              </Row>
                            );
                          })}
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
              {selectedTemplate.show_before_apply_record && selectedRunningForm.status !== "done" && (
                <Row className="mt-4">
                  <Col>
                    <h2 className="Headline--section mb-2">変更前の内容</h2>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>変更前の内容</Accordion.Header>
                        <Accordion.Body>
                          {beforeApplyRecord?.length
                            ? beforeApplyRecord.map(({ key, label, type, value }) => {
                                if (type === "boolean") value = value ? "はい" : "いいえ";
                                if (type === "number" && typeof value === "number" && value)
                                  value = value.toLocaleString("ja-JP");
                                return (
                                  <Row key={`current-record-${key}`} className="align-items-center my-1">
                                    <Col md={6} className="--bold">
                                      {label}
                                    </Col>
                                    <Col md={6} className="my-1">
                                      {value}
                                    </Col>
                                  </Row>
                                );
                              })
                            : "レコードがありません"}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
              )}
              <Row className="mt-4">
                <Col>
                  <h2 className="Headline--section mb-2">申請内容</h2>
                  {selectedTemplate.notes && (
                    <Alert variant={"info"} className="--pre-wrap">
                      <Linkify
                        options={{
                          render: ({ attributes, content }) => {
                            const { href, ...props } = attributes;
                            return (
                              <Link to={href} {...props} target="_blank">
                                {content}
                                <span className="--px-1">
                                  <Icon type="external-tab" width={16} height={16} />
                                </span>
                              </Link>
                            );
                          },
                        }}
                      >
                        {selectedTemplate.notes}
                      </Linkify>
                    </Alert>
                  )}
                  {selectedTemplate.show_before_apply_record && beforeApplyRecord.length && state.isRightAfterCreate ? (
                    <span className="--font-s mx-1">※初期値は「変更前の内容」を表示しています。</span>
                  ) : selectedTemplate.show_before_apply_record &&
                    beforeApplyRecord.length &&
                    state.mode === "previewing" ? (
                    <span className="--font-s mx-1">※「変更前の内容」との差分を赤字で表示しています。</span>
                  ) : null}
                  {pagingControls}
                  {profileField.fieldName && (
                    <ProfileField
                      fieldName={profileField.fieldName}
                      labelMap={profileField.labelMap}
                      subFields={profileField.subFields}
                      isEditing={state.mode === "editing"}
                      useControl={false}
                      showHeader={false}
                      fieldStatus={fieldStatus}
                      onChange={async (next, updatedSubFieldIndex) => {
                        const f = profileField.fieldName;
                        let { validated, subFields } = validateProfileField(next);
                        // actions（変更があった場合に実行する処理）を取得
                        const subField = subFields[updatedSubFieldIndex];

                        const nextFieldStatus = { ...fieldStatus };
                        nextFieldStatus[f] = nextFieldStatus[f] || {
                          validated: true,
                          errorMessage: "",
                        };
                        nextFieldStatus[f].validated = validated;
                        nextFieldStatus[f].errorMessage = validated ? "" : "入力内容を確認してください";
                        subFields.forEach((sf) => {
                          const path = `${f}/${sf.id}`;
                          nextFieldStatus[path] = nextFieldStatus[path] || {
                            validated: true,
                            errorMessage: "",
                          };
                          nextFieldStatus[path].validated = !!sf.errorMessage;
                          nextFieldStatus[path].errorMessage = sf.errorMessage ?? "";
                        });
                        $profileField({
                          ...profileField,
                          subFields,
                        });
                        $fieldStatus(nextFieldStatus);
                        $validated(validated);
                      }}
                      onEditOrCancel={cancelEdit}
                      pageIndex={state.pageIndex}
                      files={attachedFiles}
                      dataType="application"
                      onFileAdd={async (subField, decodedFileData) => {
                        await dispatch(
                          attachFile({
                            applicationId: selectedRunningForm.id,
                            applicationUpdatedAt: selectedRunningForm.updated_at,
                            subField,
                            decodedFileData,
                          })
                        );
                      }}
                      onFileDownload={async (fileId, key) => {
                        const result = testResponse(await dispatch(downloadFile({ fileId, key })));
                        if (!result) $state({ ...state, activeModal: "file_not_exist" });
                      }}
                      onFileDelete={async (fileId, key) => {
                        await dispatch(deleteFile({ fileId, key }));
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <h2 className="Headline--section mb-2">申請書処理履歴</h2>
                  <Table
                    col={["処理日時", "処理者", "処理内容", "コメント", "処理後のステータス"].map((name) => ({ name }))}
                    row={applicationHistory.map(
                      ({ created_at, processor_name, type, status, comment, step_order, step_name }) => {
                        type = TERMS[`APPLICATION_ACTION_${type}`];
                        if (step_order && step_name) type = `${type}（${step_order}. ${step_name}）`;
                        else if (step_order === 0) type = `${type}（申請者）`;
                        return {
                          data: [
                            toTimeLabel(created_at),
                            processor_name,
                            type,
                            comment ?? "--",
                            TERMS[`APPLICATION_STATUS_${status}`],
                          ],
                        };
                      }
                    )}
                  />
                </Col>
              </Row>
              <div className="mt-4">{controls}</div>
            </Container>
          </main>
        </div>
      )}
      <Modal
        show={state.activeModal === "input_pullback_comment"}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row className="mb-2">
            <Col md="8">
              <div className="--bold mb-2">引き戻しコメント</div>
              <Form.Control
                as="textarea"
                className="my-2"
                value={state.pullbackComment}
                onChange={(e) => {
                  $state({ ...state, pullbackComment: e.target.value });
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button
            onClick={() => $state({ ...state, activeModal: "before_pullback" })}
            variant="primary"
            disabled={state.pullbackComment.length === 0}
          >
            引き戻し
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={state.activeModal === "input_reject_comment"}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row className="mb-2">
            <Col md="8">
              <div className="--bold mb-2 --required-label">差し戻しコメント</div>
              <Form.Control
                as="textarea"
                className="my-2"
                value={state.rejectComment}
                onChange={(e) => {
                  $state({ ...state, rejectComment: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="8">
              <div className="--bold mb-2">差し戻し先</div>
              <div className="--font-s">※選択がない場合は一つ前のステップに差し戻されます</div>
              <Form.Select
                value={undefined}
                onChange={(e) => {
                  $state({ ...state, rejectTo: Number(e.target.value) });
                }}
              >
                <option value={undefined}>---</option>
                <option value={0}>申請者</option>
                {selectedRunningForm.steps
                  // 今のステップより前 かつ 承認者がいるステップを選べる
                  .filter(({ order, processors }) => order < currentStepOrder && processors.length > 0)
                  .map(({ order, name }, i) => {
                    return (
                      <option key={`option${i}`} value={order}>
                        {`step${order}（${name}）`}
                      </option>
                    );
                  })}
              </Form.Select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button
            onClick={() => $state({ ...state, activeModal: "before_reject" })}
            variant="primary"
            disabled={state.rejectComment.length === 0}
          >
            差し戻し
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={state.activeModal === "select_re_apply_destination"}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row className="mb-2">
            <Col md="8">
              <div className="--bold mb-2">再申請先</div>
              <div className="--font-s">※選択がない場合は最初の承認者が承認処理待ちになります。</div>
              <Form.Select
                value={undefined}
                onChange={(e) => {
                  const toRejectStep = !!+e.target.value;
                  $state({ ...state, toRejectStep });
                }}
              >
                <option value={0}>---</option>
                <option value={0}>最初の承認者</option>
                <option value={1}>差し戻し元の承認者</option>
              </Form.Select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button onClick={() => $state({ ...state, activeModal: "before_apply" })} variant="primary">
            再申請
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={state.activeModal === "select_re_approve_destination"}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row className="mb-2">
            <Col md="8">
              <div className="--bold mb-2">再承認先</div>
              <div className="--font-s">※選択がない場合は次の承認者が承認処理待ちになります。</div>
              <Form.Select
                value={undefined}
                onChange={(e) => {
                  const toRejectStep = !!+e.target.value;
                  $state({ ...state, toRejectStep });
                }}
              >
                <option value={0}>---</option>
                <option value={0}>次の承認者</option>
                <option value={1}>差し戻し元の承認者</option>
              </Form.Select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button onClick={() => $state({ ...state, activeModal: "before_approve" })} variant="primary">
            承認
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={state.activeModal === "before_apply"}
        onConfirm={apply}
        onCancel={closeModal}
        message={"この内容で申請してよろしいですか？"}
      />
      <ModalDialog
        show={state.activeModal === "before_approve"}
        onConfirm={approve}
        onCancel={closeModal}
        message={"承認します。よろしいですか？"}
      />
      <ModalDialog
        show={state.activeModal === "before_reject"}
        onConfirm={reject}
        onCancel={closeModal}
        message={"差し戻します。よろしいですか？"}
      />
      <ModalDialog
        show={state.activeModal === "before_delete"}
        onConfirm={deleteApplication}
        onCancel={closeModal}
        message={"申請書を削除します。よろしいですか？"}
        confirmButtonName="削除"
        type="destructiveConfirm"
      />
      <ModalDialog
        show={state.activeModal === "before_pullback"}
        onConfirm={pullback}
        onCancel={closeModal}
        message={`引き戻します。引き戻しを実行すると、
          ${state.pullbackStepOrder === 0 ? "申請前" : `ステップ${state.pullbackStepOrder}の承認前`}
          の状態に戻ります。 よろしいですか？`}
      />
      <ModalDialog
        show={state.activeModal === "not_exist"}
        onConfirm={moveToTop}
        message="申請書が存在しないか、閲覧権限がありません。"
        type="alert"
      />
      <ModalDialog
        show={state.activeModal === "no_permission"}
        onConfirm={() => {
          closeModal();
          getApplication();
          dispatch(
            getApplyTemplate({
              application_type: selectedRunningForm.template_type,
              application_id: selectedRunningForm.id,
            })
          );
          dispatch(getApplyTemplateSummaries());
        }}
        message="申請書が最新の状態ではないか権限がないため、操作が完了できませんでした。再度申請書を取得します。"
        type="alert"
      />
      <ModalDialog
        show={state.activeModal === "file_not_exist"}
        onConfirm={() => closeModal()}
        message="ファイルダウンロードできませんでした。ファイルが削除された可能性があります。"
        type="alert"
      />
      <ModalDialog
        show={state.activeModal === "before_update_steps"}
        onConfirm={save}
        onCancel={closeModal}
        message={"承認ステップを更新します。よろしいですか？"}
      />
    </div>
  );
}

export default App;
