const data = {
  category: "labor_contract",
  table: "labor_contract",
  subFields: {
    labor_contract_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    day_prescribed_working_hours_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    earned_income_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_classification_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    wage_structure_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    week_prescribed_working_days_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    week_prescribed_working_hours_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    month_prescribed_working_days_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    month_prescribed_working_hours_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    attendance_category_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    attendance_category_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      labor_contract_history: "労働契約履歴",
    },
  },
};

export default data;
