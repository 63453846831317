import { EvaluationLayoutUsage } from "../EvaluationTypes";
import { dummyUnitLayoutRowUsages2, dummyUnitLayoutRowUsages_sales } from "./unit_groups";

const behaviorMapTemplate_nakagawa = {
  phase_key_0_input: {
    label: "目標設定時に使うセル",
    rules: {
      phase_key_0: "default",
      phase_key_1: "locked",
      phase_key_2: "locked",
      phase_key_3: "locked",
    },
  },
  phase_key_1_input: {
    label: "本人評価で使うセル",
    rules: {
      phase_key_0: "veiled",
      phase_key_1: "default",
      phase_key_2: "locked",
      phase_key_3: "locked",
    },
  },
  phase_key_2_input: {
    label: "１次評価で使うセル",
    rules: {
      phase_key_0: "veiled",
      phase_key_1: "veiled",
      phase_key_2: "default",
      phase_key_3: "locked",
    },
  },
  phase_key_3_input: {
    label: "２次評価で使うセル",
    rules: {
      phase_key_0: "veiled",
      phase_key_1: "veiled",
      phase_key_2: "veiled",
      phase_key_3: "default",
    },
  },
  special: {
    label: "例外（ユーザー設定の想定）",
    rules: {
      phase_key_0: "hidden",
      phase_key_1: "default",
      phase_key_2: "hidden",
      phase_key_3: "default",
    },
  },
  hidden: {
    label: "常に非表示",
    rules: {
      phase_key_0: "hidden",
      phase_key_1: "hidden",
      phase_key_2: "hidden",
      phase_key_3: "hidden",
    },
  },
  DEFAULT_BEHAVIOR: {
    label: "デフォルト",
    rules: {
      phase_key_0: "default",
      phase_key_1: "default",
      phase_key_2: "default",
      phase_key_3: "default",
    },
  },
};

const behaviorDefinition = {
  default: {
    isPreset: true,
    description: "通常",
    rules: [
      {
        variable: "CONTEXT",
        expection: "confirming",
        appearance: "preview",
        priority: 10,
      },
      {
        variable: "CONTEXT",
        expection: "answering",
        appearance: "input",
        priority: 1,
      },
    ],
  },
  locked: {
    isPreset: true,
    description: "入力内容の確認のみ可能",
    rules: [
      {
        variable: "",
        expection: "",
        appearance: "locked",
        priority: 1,
      },
    ],
  },
  veiled: {
    isPreset: true,
    description: "黒塗りで隠す",
    rules: [
      {
        variable: "",
        expection: "",
        appearance: "veiled",
        priority: 1,
      },
    ],
  },
  hidden: {
    isPreset: true,
    description: "非表示",
    rules: [
      {
        variable: "",
        expection: "",
        appearance: "hidden",
        priority: 1,
      },
    ],
  },
  extra_field: {
    isPreset: false,
    description: "選択時にはじめて表示する",
    rules: [
      {
        variable: "show_extra",
        expection: "true",
        appearance: "input",
        priority: 10,
      },
      {
        variable: "",
        expection: "",
        appearance: "veiled",
        priority: 1,
      },
    ],
  },
};

const style_snippets1 = {
  header_cell: {
    label: "列・カラム名",
    styles: {
      background: "rgb(25, 80, 131)",
      color: "#fff",
      border: "1px solid #ddd",
    },
  },
};

const style_snippets2 = {
  header_cell: {
    label: "列・カラム名",
    styles: {
      background: "rgb(131, 80, 25)",
      color: "#fff",
      border: "1px solid #ddd",
    },
  },
};

export const layouts = [
  {
    id: "layout_tech",
    title: "レイアウト: 技術職",
    description: "技術職の評価シートのレイアウト。",
    projectId: "hyoka_nakagawa",
    rows: dummyUnitLayoutRowUsages2,
    behaviorMapTemplate: { ...behaviorMapTemplate_nakagawa },
    behaviorDefinition: { ...behaviorDefinition },
    commonSelectOptions: {},
    computingRuleSet: {},
    cssTraits: { ...style_snippets1 },
    updated_at: Date.now() - 1000 * 60 * 60 * 24 * 3,
  },
  {
    id: "layout_sales",
    title: "レイアウト: 営業職",
    description: "営業職の評価シートのレイアウト。",
    projectId: "hyoka_nakagawa",
    rows: dummyUnitLayoutRowUsages_sales,
    behaviorMapTemplate: { ...behaviorMapTemplate_nakagawa },
    behaviorDefinition: { ...behaviorDefinition },
    commonSelectOptions: {},
    computingRuleSet: {},
    cssTraits: { ...style_snippets2 },
    updated_at: Date.now() - 1000 * 60 * 60 * 24 * 6,
  },
] as EvaluationLayoutUsage[];
