const data = {
  category: "accounting_section_from_m_section",
  table: "accounting_section_from_m_section",
  subFields: {
    accounting_section_from_m_section_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    section_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    section_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    accounting_section_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    accounting_section_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      accounting_section_from_m_section_history: "経理用負担部署（コードテーブル連動）履歴",
    },
  },
};

export default data;
