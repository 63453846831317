import { ReportTemplate } from "./reportValues";
import { SectorStatus } from "../client/clientValues";

export const adminReportTemplates = [
  {
    id: "salary_slip_bulk_download",
    title: "給与明細",
    isAvailable: () => true,
  },
  {
    id: "bonus_slip_bulk_download",
    title: "賞与明細",
    isAvailable: () => true,
  },
  {
    id: "withholding_tax_certificate_bulk_download",
    title: "源泉徴収票",
    isAvailable: (_, sectorUserStatus) => {
      return sectorUserStatus.some((s: SectorStatus) => s.key === "profile_u_withholding_tax" && s.active);
    },
  },
  {
    id: "roster_of_workers_bulk_download",
    title: "労働者名簿",
    isAvailable: () => true,
  },
] as ReportTemplate[];

export const userReportTemplates = [
  {
    id: "salary_slip_single_download",
    title: "給与・賞与明細",
    isAvailable: (policies, _) => {
      return policies?.report_manager?.some((_) => _ === "GET");
    },
  },
  {
    id: "withholding_tax_certificate_single_download",
    title: "源泉徴収票",
    isAvailable: (policies, sectorUserStatus) => {
      return (
        policies?.report_manager?.some((_) => _ === "GET") &&
        sectorUserStatus.some((s: any) => s.key === "profile_u_withholding_tax" && s.active)
      );
    },
  },
  /* 一旦、一般ユーザーには公開しない
  {
    id: "roster_of_workers_single_download",
    title: "労働者名簿",
    isAvailable: (policies) => {
      return policies.report_manager?.some((_) => _ === "GET");
    },
  },
  */
] as ReportTemplate[];
