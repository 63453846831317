import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { yieldId } from "../../app/util";
import { download } from "../../app/download";
import { RootState, store } from "../../app/store";
import { call, callCollaborator } from "../../app/api";
import {
  Client,
  Sector,
  SectorStatus,
  AdhocRecord,
  AdhocColumn,
  AdhocCell,
  Modifier,
  ColumnValidator,
  RegularColumn,
  SectorRegularColumns,
  PreparationFile,
  RenamedColumns,
  ColumnStatus,
  ExternalColumns,
  RawPreparationFile,
  AddingProfileFile,
  ProfileOption,
  ExternalOption,
  ItemColumn,
  ReportItem,
  PayrollSetting,
} from "./clientValues";
import { FieldValidationRule, ProfileSubFieldType, ProfileCondition } from "../profile/profileFieldValues";
import { DecodedFileData } from "../../component/Uploader";
import columnTypeChoicesJson from "../../column_type_choices.json";
import columnTypeMappingsJson from "../../column_type_mappings.json";

const SLICE_NAME = "client";

interface ClientState {
  sectorSettingStatusId: string;
  sectorSettingStatus: {
    [sectorId: string]: {
      status: "todo" | "creating" | "setting_valid_to" | "deleting" | "done";
      import_process_status?: "accepting" | "processing";
      comment: string;
      validation_required?: boolean;
    };
  };
  sectors: Sector[];
  SectorStatusId: string;
  sectorUserStatus: SectorStatus[];
  sectorMasterStatus: SectorStatus[];
  clients: Client[];
  selectedClient: Client | null;
  loadId: string;
  has_more: boolean;
  processedSectorId: string | undefined;
  adhocCells: AdhocCell[];
  adhocColumns: AdhocColumn[];
  adhocRecords: AdhocRecord[];
  adhocRecordsCount: number;
  sectorRegularColumns: SectorRegularColumns;
  mapping: { [key: string]: string };
  phase: "operation" | "preparation";
  processing: boolean;
  preparationFiles: PreparationFile[];
  renamedColumns: RenamedColumns;
  renamedColumnsId: number | null;
  columnStatus: ColumnStatus;
  columnStatusId: number | null;
  externalColumns: ExternalColumns;
  externalColumnsId: number | null;
  defaultLabelColumns: SectorRegularColumns;
  addingProfileFile: AddingProfileFile;
  profileOptions: { [key: string]: ProfileOption[] };
  profileOptionId: string | null;
  externalOptions: { [key: string]: ExternalOption[] };
  externalOptionId: string | null;
  payrollSetting: PayrollSetting;
  defaultOptions: { [columnName: string]: ExternalOption[] };
  reportItem: ReportItem;
  reportItemId: string | null;
  loadError: string;
}

/*
  state の初期状態
*/
const initialState: ClientState = {
  sectorSettingStatusId: "",
  sectorSettingStatus: {},
  sectors: [],
  SectorStatusId: "",
  sectorUserStatus: [],
  sectorMasterStatus: [],
  clients: [],
  selectedClient: null,
  loadId: "",
  has_more: false,
  processedSectorId: "",
  adhocCells: [],
  adhocColumns: [],
  adhocRecords: [],
  adhocRecordsCount: 0,
  sectorRegularColumns: {},
  mapping: {},
  phase: "operation",
  processing: false,
  preparationFiles: [],
  renamedColumns: {},
  renamedColumnsId: null,
  columnStatus: {},
  columnStatusId: null,
  externalColumns: {},
  externalColumnsId: null,
  defaultLabelColumns: {},
  addingProfileFile: { zipFilenames: [], files: [] } as AddingProfileFile,
  profileOptions: {},
  profileOptionId: null,
  externalOptions: {},
  externalOptionId: null,
  payrollSetting: { id: "", payroll_system: "", kot_token: "" },
  defaultOptions: {},
  reportItem: { id: "", report_type: "", items: {} },
  reportItemId: null,
  loadError: "",
};

/*
  @getClients
*/
export const getClients = createAsyncThunk(SLICE_NAME + "/getClients", async () => {
  const res = await callCollaborator("get", "company_manager/customer")();
  const clients = res.data.results[0].result.map((r: any) => ({
    id: r.id,
    name: r.company_name,
    plan: "基本プラン",
    settingStatus: "done",
  })) as Client[];
  return { result: clients };
});

/*
  @selectClient
*/
export const selectClient = createAsyncThunk(SLICE_NAME + "/selectClient", async ({ id }: { id: number | string }) => {
  const res = await call("get", "company_manager/my_company")({ id });
  const client = res.data.result.map((r: any) => ({
    id: r.id,
    name: r.company_name,
    plan: "基本プラン",
    settingStatus: "done",
  }))[0] as Client;
  return { result: client };
});

/*
  @convertLoadDataToCells
  load ドキュメントからセルデータを作成する。
*/
export const convertLoadDataToCells = createAsyncThunk(
  SLICE_NAME + "/convertLoadDataToCells",
  async ({
    loadId,
    mapping,
    sectorId,
    zipFileData,
    zipFileName,
  }: {
    loadId: string;
    mapping: { [key: string]: string };
    sectorId: string;
    zipFileData?: string;
    zipFileName?: string;
  }) => {
    // 処理に時間がかかるので、完了を待たない
    await call(
      "post",
      "data_loader/split"
    )({
      load_id: loadId,
      mapping: mapping,
      zipFile:
        zipFileData && zipFileName
          ? {
              data: zipFileData,
              name: zipFileName,
            }
          : undefined,
      next_process: {
        externalEffect: "_getAdhocCells",
        externalEffectUsed: false,
        payload: {
          sectorId,
        },
      },
    });
  }
);

/*
  @downloadAdhocCells
*/
export const downloadAdhocCells = createAsyncThunk(
  SLICE_NAME + "/downloadAdhocCells",
  async ({ sectorId }: { sectorId: string }) => {
    const res = await call(
      "get",
      "data_loader/celldata"
    )({
      sector: sectorId,
      format: "xlsx",
    });
    const { file, file_name } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }
);

/*
@downloadAdhocFormatFiles
*/
export const downloadAdhocFormatFiles = createAsyncThunk(SLICE_NAME + "/downloadAdhocFormatFiles", async () => {
  const res = await call("get", "data_loader/format_download")();
  const { file, file_name, mime } = res.data.result;
  download({
    file,
    fileName: file_name,
    mimeType: mime,
  });
});

const emptyLoadData = {
  loadId: "",
  adhocColumns: [] as AdhocColumn[],
  validators: [] as ColumnValidator[],
  mapping: {} as { [key: string]: string },
};
const handleLoadedData = ({
  id,
  mapping,
  columns,
}: {
  id: string;
  mapping: { [key: string]: string };
  columns: string[];
}): {
  loadId: string;
  adhocColumns: AdhocColumn[];
  mapping: { [key: string]: string };
} => {
  const result = { ...emptyLoadData };

  let adhocColumns = [] as AdhocColumn[];
  for (const index in columns) {
    const label = columns[index];
    adhocColumns = [
      ...adhocColumns,
      {
        label,
        isKey: false,
        regularColumnId: mapping[label] ?? "",
        presetId: "",
      },
    ];
  }
  result.loadId = id;
  result.adhocColumns = adhocColumns;
  result.mapping = mapping;
  return result;
};

const toAdhocCell = (d: any): AdhocCell => {
  return {
    id: d.id,
    correcting: false,
    column: d.column ?? "",
    comments: d.comments || [],
    key: d.key,
    value: d.value,
    is_valid: d.is_valid,
    priority: d.priority,
    errors: d.errors || [],
    record_id: d.record_id || "",
    type: d.type || "",
  };
};

/*
  @getSectors
*/
export const getSectors = createAsyncThunk(SLICE_NAME + "/getSectors", async () => {
  const res = await call("get", "profile_manager/sector", { doReload: false })();
  if (res.data.status >= 400) throw new Error("failed in getting sector");
  return res.data.result;
});

/*
  @initSectorSettingStatus
*/
export const initSectorSettingStatus = createAsyncThunk(
  SLICE_NAME + "/initSectorSettingStatus",
  async ({ sectors }: { sectors: Sector[] }) => {
    const res = await call("get", "data_loader/sector_settingstatus", { doReload: false })();
    if (res.data.status >= 400) throw new Error("failed in getting sector setting status");
    const sectorSettingStatus = res.data.result[0];
    // sectorSettingStatus の登録がなければ post
    if (!sectorSettingStatus) {
      if (sectors.length > 0) {
        const options = {} as {
          [key: string]: {
            status: string;
          };
        };
        sectors.forEach((sector) => {
          const status = {
            status: "todo",
          };
          options[sector.sector_id] = status;
        });
        const res = await call(
          "post",
          "data_loader/sector_settingstatus"
        )({
          ...options,
        });
        if (res.data.status >= 400) throw new Error("failed in updating sector setting status");
        const _data = res.data.result[0];
        if (_data) {
          const data = { ..._data };
          delete data.id;
          return { id: _data.id, data };
        }
      }
      // sectorSettingStatus の登録があれば put
    } else {
      if (sectors.length > 0) {
        const newSectorSettingStatus = {} as any;
        sectors.forEach((sector) => {
          if (sector.sector_id in sectorSettingStatus !== true) {
            newSectorSettingStatus[sector.sector_id] = {} as {
              [key2: string]: { status: string };
            };
            newSectorSettingStatus[sector.sector_id].status = "todo";
          }
        });
        if (Object.keys(newSectorSettingStatus).length === 0) {
          const data = { ...sectorSettingStatus };
          delete data.id;
          return { id: sectorSettingStatus.id, data };
        }
        const res = await call(
          "put",
          "data_loader/sector_settingstatus"
        )({
          id: sectorSettingStatus.id,
          ...newSectorSettingStatus,
        });
        if (res.data.status >= 400) throw new Error("failed in updating sector setting status");
        const _data = res.data.result[0];
        if (_data) {
          const data = { ..._data };
          delete data.id;
          return { id: _data.id, data };
        }
      }
    }
    return { id: "", data: {} };
  }
);

/*
  @getSectorSettingStatus
*/
export const getSectorSettingStatus = createAsyncThunk(SLICE_NAME + "/getSectorSettingStatus", async () => {
  const res = await call("get", "data_loader/sector_settingstatus", { doReload: false })();
  if (res.data.status >= 400) throw new Error("failed in getting sector setting status");
  return res.data.result[0];
});

/*
  @putSectorSettingStatus
*/
export const putSectorSettingStatus = createAsyncThunk(
  SLICE_NAME + "/putSectorSettingStatus",
  async ({
    sectorSettingStatusId,
    updateData,
  }: {
    sectorSettingStatusId: string;
    updateData: { [key: string]: string | string[] };
  }) => {
    const res = await call(
      "put",
      "data_loader/sector_settingstatus"
    )({
      id: sectorSettingStatusId,
      ...updateData,
    });
    if (res.data.status >= 400) throw new Error("failed in updating sector setting status");
    const data = { ...res.data.result[0] };
    delete data.id;
    return { data };
  }
);

const _toSectorStatus = (
  data: any[],
  sectorStatus: { [key: string]: boolean }
): { user: SectorStatus[]; master: SectorStatus[] } => {
  return data.reduce(
    (prev: { user: SectorStatus[]; master: SectorStatus[] }, r: any) => {
      const isUserSector = r.sector_id.indexOf("profile_m") === -1;
      const active = sectorStatus[r.sector_id] ?? false;
      const sector: SectorStatus = {
        active,
        description: r.description,
        key: r.sector_id,
        label: r.logical_name,
      };

      if (isUserSector) {
        prev.user.push(sector);
      } else {
        prev.master.push(sector);
      }

      return prev;
    },
    { user: [], master: [] }
  );
};

/*
  @initSectorStatus
*/
export const initSectorStatus = createAsyncThunk(SLICE_NAME + "/initSectorStatus", async () => {
  const res = await call("get", "template_manager/sector_status", { doReload: false })();
  if (res.data.status >= 400) throw new Error("failed in getting sector status");
  const _data = res.data.result[0];
  if (_data) {
    // 通常
    const res = await call("get", "profile_manager/sector", { doReload: false })();
    return {
      id: _data.id,
      data: _toSectorStatus(res.data.result, _data),
    };
  } else {
    // 初回のみ、デフォルトの sectorStatus を登録する
    const resPost = await call("post", "template_manager/sector_status")({});
    const postedData = resPost.data.result[0];
    // 登録した sectorStatus を取得する
    const res = await call("get", "profile_manager/sector", { doReload: false })();
    return {
      id: postedData.id,
      data: _toSectorStatus(res.data.result, postedData),
    };
  }
});

/*
  @getSectorStatus
*/
export const getSectorStatus = createAsyncThunk(SLICE_NAME + "/getSectorStatus", async () => {
  const res = await call("get", "template_manager/sector_status", { doReload: false })({});
  if (res.data.status >= 400) throw new Error("failed in getting sector status");
  const _data = { ...res.data.result[0] };
  const sectors = await call("get", "profile_manager/sector", { doReload: false })();
  return {
    data: _toSectorStatus(sectors.data.result, _data),
  };
});
/*
  @putSectorStatus
*/
export const putSectorStatus = createAsyncThunk(
  SLICE_NAME + "/putSectorStatus",
  async ({ SectorStatusId, sectorStatus }: { SectorStatusId: string; sectorStatus: { [key: string]: boolean } }) => {
    const res = await call(
      "put",
      "template_manager/sector_status"
    )({
      id: SectorStatusId,
      ...sectorStatus,
    });
    if (res.data.status >= 400) throw new Error("failed in updating sector status");
    const _data = { ...res.data.result[0] };
    const sectors = await call("get", "profile_manager/sector", { doReload: false })();
    return {
      data: _toSectorStatus(sectors.data.result, _data),
    };
  }
);

const _toRegularColumns = (fieldNames: {
  [id: string]: {
    is_key: boolean;
    name: string;
    required: boolean;
    required_conditions?: ProfileCondition;
    display_conditions?: ProfileCondition;
    display_editable?: boolean;
    order: number;
    reference_field: boolean;
    virtual_field: boolean;
    edit_only: boolean;
    input_type: ProfileSubFieldType;
    rules: FieldValidationRule[];
    rename_target: boolean;
    option_editable?: boolean;
    options?: string[];
    category_type?: string;
  };
}) => {
  let regularColumns: RegularColumn[] = [];
  for (const label in fieldNames) {
    regularColumns = [
      ...regularColumns,
      {
        id: fieldNames[label].name,
        label,
        isKey: fieldNames[label].is_key,
        required: fieldNames[label].required,
        required_conditions: fieldNames[label].required_conditions,
        display_conditions: fieldNames[label].display_conditions,
        display_editable: fieldNames[label].display_editable ?? true,
        order: fieldNames[label].order,
        reference_field: fieldNames[label].reference_field || false,
        virtual_field: fieldNames[label].virtual_field || false,
        edit_only: fieldNames[label].edit_only || false,
        input_type: fieldNames[label].input_type || "string",
        rules: fieldNames[label].rules || [],
        rename_target: fieldNames[label].rename_target || false,
        option_editable: fieldNames[label].option_editable || false,
        options: fieldNames[label].options,
        type: "content",
        category_type: fieldNames[label].category_type ?? undefined,
      } as RegularColumn,
    ].sort((a: RegularColumn, b: RegularColumn) => {
      return a.order - b.order;
    });
  }
  return regularColumns;
};

/*
  @getRegularColumns
*/
export const getRegularColumns = createAsyncThunk(
  SLICE_NAME + "/getRegularColumns",
  async ({ sectorId, activeOnly = true }: { sectorId: string; activeOnly?: boolean }) => {
    const res = await call("get", "profile_manager/field", { doReload: false })({
      sector: sectorId,
      active_only: activeOnly,
    });
    if (res.data.status >= 400) throw new Error("failed in loading file");
    const regularColumns = _toRegularColumns(res.data.result[0]);
    return { regularColumns, sectorId };
  }
);

export const getDefaultLabelColumns = createAsyncThunk(
  SLICE_NAME + "/getDefaultLabelColumns",
  async ({ sectorId }: { sectorId: string }) => {
    const res = await call("get", "profile_manager/field", { doReload: false })({ sector: sectorId, default: true });
    if (res.data.status >= 400) throw new Error("failed in loading file");
    const regularColumns = _toRegularColumns(res.data.result[0]);
    return { regularColumns, sectorId };
  }
);

/*
  @getLoadData
*/
export const getLoadData = createAsyncThunk(SLICE_NAME + "/getLoadData", async ({ sectorId }: { sectorId: string }) => {
  const res = await call(
    "get",
    "data_loader/load"
  )({
    sector: sectorId,
  });
  if (res.data.status >= 400) throw new Error("failed in loading file");
  return handleLoadedData(res.data.result?.[0] ?? emptyLoadData);
});

/*
  @postLoadData
*/
export const postLoadData = createAsyncThunk(
  SLICE_NAME + "/postLoadData",
  async ({
    sectorId,
    importType,
    file,
    fileName,
  }: {
    sectorId: string;
    importType: string;
    file: string;
    fileName: string;
  }) => {
    const res1 = await call("post", "data_loader/load", { handleError: false })({
      sector: sectorId,
      import_type: importType,
      file,
      file_name: fileName,
    }).catch((e) => {
      const error = e?.response?.data?.errors[0]?.reason ?? "";
      if (error.startsWith("W003") && error.indexOf("`file`") !== -1) throw new Error("ファイルの形式が不正です");
      else if (error.startsWith("W003") && error.indexOf("`file.row`") !== -1) throw new Error("ファイルが空です");
      else if (error.startsWith("W003") && error.indexOf("`file.data`") !== -1)
        throw new Error("ファイルにデータがありません");
      else if (error.startsWith("W040")) throw new Error("項目名にドット（.）を含めることはできません");
      else if (error.startsWith("W038")) throw new Error("項目名の重複があります");
      else if (error.startsWith("W060")) throw new Error("レコード件数が上限の3000件を超えています");
      else throw new Error("予期せぬエラーが発生しました");
    });

    const load = handleLoadedData(res1.data.result[0]);
    const res2 = await call("get", "data_loader/sector_settingstatus", { doReload: false })();
    if (res2.data.status >= 400) throw new Error("failed in getting sector setting status");
    const _data = res2.data.result[0];
    if (_data) {
      const data = { ..._data };
      delete data.id;
      return { ...load, data };
    }
    return { ...load, data: {} };
  }
);

export const getAdhocCells = createAsyncThunk(
  SLICE_NAME + "/getAdhocCells",
  // エラー有りのセルデータを取得する
  async ({ sectorId, regularColumns }: { sectorId: string; regularColumns: RegularColumn[] }) => {
    const res1 = await call(
      "get",
      "data_loader/celldata"
    )({
      sector: sectorId,
      format: "json-cell-error",
    });
    if (res1.data.status >= 400) return { adhocCells: [], adhocRecords: [] };

    const adhocCells: AdhocCell[] = res1.data.result.map((r: any) => toAdhocCell(r));
    const adhocRecords = arrangeAdhocRecords(adhocCells, regularColumns);
    const adhocRecordsCount = res1.data.count;
    const has_more = res1.data.has_more;
    const processedSectorId = sectorId;
    const res2 = await call("get", "data_loader/sector_settingstatus", { doReload: false })();
    if (res2.data.status >= 400) throw new Error("failed in getting sector setting status");
    const _data = res2.data.result[0];

    if (_data) {
      const data = { ..._data };
      delete data.id;
      return {
        adhocCells,
        adhocRecords,
        adhocRecordsCount,
        has_more,
        data,
        processedSectorId,
      };
    }
    return {
      adhocCells,
      adhocRecords,
      adhocRecordsCount,
      has_more,
      data: {},
      processedSectorId,
    };
  }
);

/*
  @deleteLoadData
*/
export const deleteLoadData = createAsyncThunk(
  SLICE_NAME + "/deleteLoadData",
  async ({ loadId }: { loadId: string }) => {
    const res = await call(
      "delete",
      "data_loader/load"
    )({
      id: loadId,
    });
    if (res.data.status >= 400) throw new Error("failed in deleting file");

    return;
  }
);

/*
  @deleteAdhocCell
*/
export const deleteAdhocCell = createAsyncThunk(
  SLICE_NAME + "/deleteAdhocCell",
  async ({ sectorId, recordIds }: { sectorId: string; recordIds?: string[] }) => {
    const options = {
      sector: sectorId,
      record_id__in: recordIds,
      next_process: {
        externalEffect: "_getAdhocCells",
        externalEffectUsed: false,
        payload: {
          sectorId,
        },
      },
    };
    await call("post", "data_loader/delete")(options);
    return;
  }
);

type ModifyKey = "normalize" | "remove_space" | "format_date" | "remove_time" | "complement_code";
type DateFormat = "LE" | "ME";
/*
  @correctAdhocCells
*/
export const correctAdhocCells = createAsyncThunk(
  SLICE_NAME + "/correctAdhocCells",
  async ({
    sectorId,
    column,
    columnModifier,
    currentValue,
    updateValue,
    cellId,
  }: {
    sectorId: string;
    column: string;
    columnModifier: Modifier;
    currentValue?: string;
    updateValue?: string;
    cellId?: string;
  }) => {
    if (!sectorId || !column) return;
    if (columnModifier.key === "dedupe") {
      // 重複削除の data_loader/dedupe を使うパターン
      const options = {
        sector: sectorId,
        next_process: {
          externalEffect: "_getAdhocCells",
          externalEffectUsed: false,
          payload: {
            sectorId,
          },
        },
      };
      await call("post", "data_loader/dedupe")(options);
    } else if (columnModifier.key === "combine") {
      // レコード結合の data_loader/combine を使うパターン
      const options = {
        sector: sectorId,
        next_process: {
          externalEffect: "_getAdhocCells",
          externalEffectUsed: false,
          payload: {
            sectorId,
          },
        },
      };
      await call("post", "data_loader/combine")(options);
    } else {
      // 重複削除以外の data_loader/correct を使うパターン
      const options = {
        sector: sectorId,
        column,
        key: columnModifier.key,
        from_format: columnModifier.fromFormat,
        overwrite: columnModifier.overwrite,
        current_value:
          columnModifier.currentValueRequired || currentValue || currentValue === "" ? currentValue : undefined,
        update_value: columnModifier.valueRequired || updateValue || updateValue === "" ? updateValue : undefined,
        id: cellId,
        next_process: {
          externalEffect: "_getAdhocCells",
          externalEffectUsed: false,
          payload: {
            sectorId,
          },
        },
      } as {
        sector: string;
        column: string;
        key: ModifyKey;
        from_format?: DateFormat;
        overwrite?: boolean;
        current_value?: string;
        update_value?: string;
        id?: string;
      };
      await call("post", "data_loader/correct")(options);
    }
  }
);

/*
  @putAdhocCell
*/
export const putAdhocCell = createAsyncThunk(
  SLICE_NAME + "/putAdhocCell",
  async ({ sectorId, cell }: { sectorId: string; cell: AdhocCell }) => {
    const res = await call(
      "put",
      "data_loader/celldata"
    )({
      sector: sectorId,
      id: cell.id,
      column: cell.column,
      value: cell.value,
    });
    return { cell: toAdhocCell(res.data.result[0]), sectorId };
  }
);

export const validate = createAsyncThunk(
  // バリデーション実行
  SLICE_NAME + "/validate",
  async ({ sectorId }: { sectorId: string }) => {
    if (!sectorId) return;
    await call(
      "post",
      "data_loader/validate"
    )({
      sector: sectorId,
      next_process: {
        externalEffect: "_getAdhocCells",
        externalEffectUsed: false,
        payload: {
          sectorId,
        },
      },
    });
  }
);

export const finalize = createAsyncThunk(
  // 完了処理（エラーになる場合もあるため、完了後に再度セルデータを取得する）
  SLICE_NAME + "/finalize",
  async ({
    sectorId,
    loadId,
    sectorSettingStatusId,
  }: {
    sectorId: string;
    loadId: string;
    sectorSettingStatusId: string;
  }) => {
    if (!sectorId) return;
    await call(
      "post",
      "data_loader/finalize"
    )({
      sector: sectorId,
      next_process: {
        externalEffect: "_getAdhocCells",
        externalEffectUsed: false,
        payload: {
          loadId,
          sectorId,
          sectorSettingStatusId,
        },
      },
    });
  }
);

export const getPreparationFiles = createAsyncThunk(SLICE_NAME + "/getPreparationFiles", async () => {
  const res = await call("get", "data_loader/preparation_file")();
  const preparationFiles = res.data.result;
  const fileIds = preparationFiles.map((f: RawPreparationFile) => f.file_id);
  const fileRes = await call("get", "file_manager/attach")({ id__in: fileIds });
  const files = fileRes.data.result;
  return preparationFiles.map((f: RawPreparationFile) => {
    const file = files.find((file: any) => file.id === f.file_id);
    const _files = file.files;
    const { name, object_name, key } = _files?.[0] ?? { name: "", object_name: "", key: "" };
    return { ...f, name, object_name, key };
  }) as PreparationFile[];
});

export const postPreparationFile = createAsyncThunk(
  SLICE_NAME + "/postPreparationFile",
  async ({
    type,
    tags,
    remarks,
    decodedFileData,
  }: {
    type: string;
    tags: string[];
    remarks: string;
    decodedFileData: DecodedFileData;
  }) => {
    const res = await call("post", "data_loader/preparation_file")({ type, tags, remarks });
    if (!res.data.result) {
      throw new Error("file attachments failed");
    }
    const body = {
      id: res.data.result[0].file_id,
      name: decodedFileData.name,
      file: decodedFileData.dataURI,
    };
    // 添付ファイルの登録
    const fileRes = await call("post", "file_manager/attach")(body);
    if (!fileRes.data.result) {
      throw new Error("file attachments failed");
    }
    return res.data.result[0];
  }
);

export const putPreparationFile = createAsyncThunk(
  SLICE_NAME + "/putPreparationFile",
  async ({ id, tags, remarks }: { id: string; tags: string[]; remarks: string }) => {
    await call("put", "data_loader/preparation_file")({ id, tags, remarks });
  }
);

export const deletePreparationFile = createAsyncThunk(
  SLICE_NAME + "/deletePreparationFile",
  async ({ id }: { id: string }) => {
    await call("delete", "data_loader/preparation_file")({ id });
  }
);

// ファイル添付
export const attachFile = createAsyncThunk(
  SLICE_NAME + "/attachFile",
  async ({ fileId, decodedFileData }: { fileId: string; decodedFileData: DecodedFileData }) => {
    if (!fileId) return;
    // body作成
    const body = {
      id: fileId,
      name: decodedFileData.name,
      file: decodedFileData.dataURI,
    };
    // 添付ファイルの登録
    const fileRes = await call("post", "file_manager/attach")(body);
    if (!fileRes.data.result) {
      throw new Error("file attachments failed");
    }
  }
);

export const getRenamedColumns = createAsyncThunk(SLICE_NAME + "/getRenamedColumns", async () => {
  const res = await call("get", "template_manager/renamed_column")();
  return res.data.result[0] ?? {};
});

export const getColumnStatus = createAsyncThunk(SLICE_NAME + "/getColumnStatus", async () => {
  const res = await call("get", "template_manager/column_status")();
  return res.data.result[0] ?? {};
});

export const getExternalColumns = createAsyncThunk(SLICE_NAME + "/getExternalColumns", async () => {
  const res = await call("get", "template_manager/external_column_mapping")();
  return res.data.result[0] ?? {};
});

export const downloadRenamedColumns = createAsyncThunk(
  SLICE_NAME + "/downloadRenamedColumns",
  async ({ sector }: { sector: string }) => {
    const res = await call(
      "get",
      `template_manager/renamed_column`
    )({
      sector: sector,
      format: "xlsx",
    });
    const { file, file_name } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }
);

export const commitRenamedColumns = createAsyncThunk(
  SLICE_NAME + "/commitRenamedColumns",
  async ({
    sectorId,
    renameColumns,
  }: {
    sectorId: string;
    renameColumns: {
      [columnName: string]: string;
    };
  }) => {
    const id = store.getState().client.renamedColumnsId;
    const res = id
      ? await call("put", "template_manager/renamed_column")({ id, [sectorId]: renameColumns })
      : await call("post", "template_manager/renamed_column")({ [sectorId]: renameColumns });
    return res.data.result[0];
  }
);

export const commitColumnStatus = createAsyncThunk(
  SLICE_NAME + "/commitColumnStatus",
  async ({
    sectorId,
    columnStatus,
  }: {
    sectorId: string;
    columnStatus: {
      [columnName: string]: boolean;
    };
  }) => {
    const id = store.getState().client.columnStatusId;
    const res = id
      ? await call("put", "template_manager/column_status")({ id, [sectorId]: columnStatus })
      : await call("post", "template_manager/column_status")({ [sectorId]: columnStatus });
    return res.data.result[0];
  }
);

export const uploadRenamedColumns = createAsyncThunk(
  SLICE_NAME + "/uploadRenamedColumns",
  async ({ sector, file, name }: { sector: string; file: string; name: string }) => {
    const res = await call(
      "post",
      `template_manager/renamed_column_uploader`
    )({
      sector,
      file,
      name,
    });
    if (res.data.status >= 400) throw new Error("failed in uploading file");
    return res.data.result[0];
  }
);

export const commitExternalColumns = createAsyncThunk(
  SLICE_NAME + "/commitExternalColumns",
  async ({
    sectorId,
    renameColumns,
  }: {
    sectorId: string;
    renameColumns: {
      [columnName: string]: string;
    };
  }) => {
    const id = store.getState().client.externalColumnsId;
    const res = id
      ? await call("put", "template_manager/external_column_mapping")({ id, [sectorId]: renameColumns })
      : await call("post", "template_manager/external_column_mapping")({ [sectorId]: renameColumns });
    return res.data.result[0];
  }
);

export const downloadExternalColumns = createAsyncThunk(
  SLICE_NAME + "/downloadExternalColumns",
  async ({ sector }: { sector: string }) => {
    const res = await call(
      "get",
      `template_manager/external_column_mapping`
    )({
      sector: sector,
      format: "xlsx",
    });
    const { file, file_name } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }
);

export const uploadExternalColumns = createAsyncThunk(
  SLICE_NAME + "/uploadExternalColumns",
  async ({ sector, file, name }: { sector: string; file: string; name: string }) => {
    const res = await call(
      "post",
      `template_manager/external_column_uploader`
    )({
      sector,
      file,
      name,
    });
    if (res.data.status >= 400) throw new Error("failed in uploading file");
    return res.data.result[0];
  }
);

export const getDefaultOptions = createAsyncThunk(
  SLICE_NAME + "getDefaultOptions",
  async ({ sectorId }: { sectorId: string }) => {
    // データ連携の設定を取得
    const setting = await call("get", "template_manager/payroll_settings")();
    if (setting.data.status >= 400) throw new Error("failed in payroll_settings");
    const externalConfig: string = setting.data.result[0].payroll_system ?? "";
    // profile::externalOptionMapping
    const res = await call("get", "template_manager/external_option_mapping", { doReload: false })();
    if (res.data.status >= 400) throw new Error("failed in external_option_mapping");
    const settingChoices = res.data.result[0] ?? {};
    // profile::option
    const userRes = await call("get", `template_manager/profile_option`, { doReload: false })({ sector: sectorId });
    if (userRes.data.status >= 400) throw new Error("failed in profile_option");
    const userChoices = userRes.data.result[0] ?? {};
    // 2つのjsonファイル取得
    const columnTypeMappings = columnTypeMappingsJson as { [table: string]: { [column: string]: string } };
    const columnTypeChoices = columnTypeChoicesJson as { [key: string]: { [label: string]: string }[] };
    const tableKey = sectorId.replace("profile_", "");
    const choiceKeys = columnTypeMappings[tableKey];
    let columnChoice = {} as { [columnName: string]: ExternalOption[] };
    for (const columnName in choiceKeys) {
      // カラムに対する選択肢の配列を取得
      const choiceKey = choiceKeys[columnName];
      // 設定された選択肢があればそちらを使用、なければデフォルトの選択肢を使用
      const choicesData = userChoices[columnName] ?? columnTypeChoices[choiceKey];
      const newChoices = choicesData.map((item: any) => {
        const value = settingChoices[sectorId]?.[columnName]?.[item.label] ?? item[externalConfig] ?? "";
        return { ...item, value } as ExternalOption;
      });
      columnChoice = { ...columnChoice, [columnName]: newChoices };
    }
    return columnChoice;
  }
);

export const getAddingProfileFile = createAsyncThunk(
  SLICE_NAME + "/getAddingProfileFile",
  async ({ sectorId }: { sectorId: string }) => {
    const res = await call(
      "get",
      "file_manager/attach"
    )({ sector: sectorId }).catch(() => {
      // 404の場合にここまでくる想定なので、空で扱う
      return { data: { result: [] } };
    });
    const file = res.data.result?.[0] ?? {};

    return {
      id: file.id,
      zipFilenames: file.zip_filenames ?? [],
      files: file.files?.map((f: any) => f.name) ?? [],
    };
  }
);

export const extract = createAsyncThunk(
  SLICE_NAME + "/extract",
  async ({ sector, data, filename }: { sector: string; data: string; filename: string }) => {
    await call(
      "post",
      "data_loader/extract"
    )({
      sector,
      data,
      filename,
      next_process: {
        externalEffect: "_getAddingProfileFile",
        externalEffectUsed: false,
        payload: {
          sectorId: sector,
        },
      },
    });
  }
);

export const getProfileOptions = createAsyncThunk(
  SLICE_NAME + "/getProfileOptions",
  async ({ sector }: { sector: string }) => {
    const res = await call("get", "template_manager/profile_option", { doReload: false })({ sector });
    return res.data.result[0] ?? {};
  }
);

export const commitProfileOptions = createAsyncThunk(
  SLICE_NAME + "/commitProfileOptions",
  async ({
    sectorId,
    options,
  }: {
    sectorId: string;
    options: {
      [columnName: string]: ProfileOption[];
    };
  }) => {
    const id = store.getState().client.profileOptionId;
    const res = id
      ? await call("put", "template_manager/profile_option")({ id, ...options })
      : await call("post", "template_manager/profile_option")({ sector: sectorId, ...options });
    return res.data.result[0];
  }
);

export const getExternalOptions = createAsyncThunk(SLICE_NAME + "/getExternalOptions", async () => {
  const res = await call("get", "template_manager/external_option_mapping")();
  return res.data.result[0] ?? {};
});

export const commitExternalOptions = createAsyncThunk(
  SLICE_NAME + "/commitExternalOptions",
  async ({
    options,
  }: {
    options: {
      [key: string]: string;
    };
  }) => {
    const id = store.getState().client.externalOptionId;
    const res = id
      ? await call("put", "template_manager/external_option_mapping")({ id, ...options })
      : await call("post", "template_manager/external_option_mapping")({ ...options });
    return res.data.result[0];
  }
);

export const downloadExternalOptions = createAsyncThunk(SLICE_NAME + "/downloadExternalOptions", async () => {
  const res = await call(
    "get",
    "template_manager/external_option_mapping"
  )({
    format: "xlsx",
  });
  const { file, file_name } = res.data.result;
  download({
    file,
    fileName: file_name,
    mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
});

export const getPayrollSetting = createAsyncThunk(SLICE_NAME + "/getPayrollSetting", async () => {
  const res = await call("get", "template_manager/payroll_settings")();
  return res.data.result[0] ?? {};
});

export const postPayrollSetting = createAsyncThunk(
  SLICE_NAME + "/postPayrollSetting",
  async (payrollSetting: PayrollSetting) => {
    await call("post", "template_manager/payroll_settings")(payrollSetting);
  }
);

export const putPayrollSetting = createAsyncThunk(
  SLICE_NAME + "/putPayrollSetting",
  async (payrollSetting: PayrollSetting) => {
    await call("put", "template_manager/payroll_settings")(payrollSetting);
  }
);

export const getReportItem = createAsyncThunk(
  SLICE_NAME + "/getReportItem",
  async ({ reportType }: { reportType: string }) => {
    const res = await call("get", "template_manager/report_item")({ report_type: reportType });
    return res.data.result[0] ?? {};
  }
);

export const commitReportItem = createAsyncThunk(
  SLICE_NAME + "/commitReportItem",
  async ({
    reportType,
    items,
  }: {
    reportType: string;
    items: {
      [categoryType: string]: ItemColumn[];
    };
  }) => {
    const id = store.getState().client.reportItemId;
    const res = id
      ? await call("put", "template_manager/report_item")({ id, items: items })
      : await call("post", "template_manager/report_item")({ report_type: reportType, items: items });
    return res.data.result[0];
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    unselectClient: (state) => {
      state.selectedClient = null;
      return state;
    },
    unselectLoadData: (state) => {
      state.loadId = "";
      return state;
    },
    setAdhocColumns: (state, action) => {
      state.adhocColumns = action.payload.adhocColumns as AdhocColumn[];
      state.mapping = action.payload.adhocColumns.reduce(
        (obj: { [key: string]: string }, col: AdhocColumn) => ({
          ...obj,
          [col.label]: col.regularColumnId,
        }),
        {}
      );
    },
    clearAdhocColumns: (state) => {
      state.adhocColumns = [];
      state.adhocRecords = [];
      state.adhocRecordsCount = 0;
      return state;
    },
    setAdhocCells: (state, action) => {
      state.adhocCells = action.payload.adhocCells as AdhocCell[];
      state.adhocRecords = arrangeAdhocRecords(state.adhocCells, action.payload.regularColumns);
      return state;
    },
    clearSectorRegularColumns: (state) => {
      state.sectorRegularColumns = {};
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClients.pending, (state) => {
      state.processing = true;
    });
    builder.addCase(getClients.fulfilled, (state, action) => {
      state.clients = action.payload.result;
      state.processing = false;
    });
    builder.addCase(getClients.rejected, (state) => {
      state.processing = false;
    });

    builder.addCase(selectClient.pending, (state) => {
      state.processing = true;
    });
    builder.addCase(selectClient.fulfilled, (state, action) => {
      state.selectedClient = action.payload.result;
      state.processing = false;
    });
    builder.addCase(selectClient.rejected, (state) => {
      state.processing = false;
    });

    // getSectors
    builder.addCase(getSectors.fulfilled, (state, action) => {
      state.sectors = action.payload;
    });

    builder.addCase(initSectorSettingStatus.fulfilled, (state, action) => {
      state.sectorSettingStatusId = action.payload.id;
      state.sectorSettingStatus = action.payload.data;
    });

    builder.addCase(putSectorSettingStatus.fulfilled, (state, action) => {
      state.sectorSettingStatus = action.payload.data;
    });

    builder.addCase(getSectorSettingStatus.fulfilled, (state, action) => {
      state.sectorSettingStatus = action.payload;
    });

    builder.addCase(initSectorStatus.fulfilled, (state, action) => {
      state.SectorStatusId = action.payload.id;
      state.sectorUserStatus = action.payload.data.user;
      state.sectorMasterStatus = action.payload.data.master;
    });

    builder.addCase(getSectorStatus.fulfilled, (state, action) => {
      state.sectorUserStatus = action.payload.data.user;
      state.sectorMasterStatus = action.payload.data.master;
    });
    builder.addCase(putSectorStatus.fulfilled, (state, action) => {
      state.sectorUserStatus = action.payload.data.user;
      state.sectorMasterStatus = action.payload.data.master;
    });

    // getRegularColumns
    builder.addCase(getRegularColumns.fulfilled, (state, action) => {
      const { sectorId, regularColumns } = action.payload;
      state.sectorRegularColumns[sectorId] = regularColumns;
    });

    builder.addCase(getDefaultLabelColumns.fulfilled, (state, action) => {
      const { sectorId, regularColumns } = action.payload;
      state.defaultLabelColumns[sectorId] = regularColumns;
    });

    builder.addCase(getAdhocCells.fulfilled, (state, action) => {
      state.adhocCells = action.payload.adhocCells;
      state.adhocRecords = action.payload.adhocRecords;
      state.adhocRecordsCount = action.payload.adhocRecordsCount;
      state.has_more = action.payload.has_more;
      state.sectorSettingStatus = action.payload.data;
      state.processedSectorId = action.payload.processedSectorId;
    });

    builder.addCase(putAdhocCell.fulfilled, (state, action) => {
      const { sectorId } = action.payload;
      state.sectorSettingStatus = Object.entries(state.sectorSettingStatus).reduce((prev, [id, settings]) => {
        // セルの個別修正をおこなった場合は validation_required に true を設定
        const updatedSettings = {
          ...settings,
          validation_required: id === sectorId ? true : !!settings.validation_required,
        };
        return {
          ...prev,
          [id]: updatedSettings,
        };
      }, {});
    });

    builder.addCase(getLoadData.fulfilled, (state, action) => {
      state.adhocColumns = action.payload.adhocColumns;
      state.mapping = action.payload.mapping;
      state.loadId = action.payload.loadId;
    });

    builder.addCase(postLoadData.fulfilled, (state, action) => {
      state.adhocColumns = action.payload.adhocColumns;
      state.mapping = action.payload.mapping;
      state.loadId = action.payload.loadId;
      state.sectorSettingStatus = action.payload.data;
      state.loadError = "";
    });
    builder.addCase(postLoadData.rejected, (state, action) => {
      state.loadError = action.error.message ?? "";
    });

    builder.addCase(correctAdhocCells.fulfilled, (state, action) => {});

    builder.addCase(getPreparationFiles.fulfilled, (state, action) => {
      state.preparationFiles = action.payload;
    });

    builder.addCase(getRenamedColumns.fulfilled, (state, action) => {
      state.renamedColumns = action.payload;
      state.renamedColumnsId = action.payload.id ?? null;
    });
    builder.addCase(commitRenamedColumns.fulfilled, (state, action) => {
      state.renamedColumns = action.payload;
      state.renamedColumnsId = action.payload.id;
    });
    builder.addCase(getColumnStatus.fulfilled, (state, action) => {
      state.columnStatus = action.payload;
      state.columnStatusId = action.payload.id ?? null;
    });
    builder.addCase(commitColumnStatus.fulfilled, (state, action) => {
      state.columnStatus = action.payload;
      state.columnStatusId = action.payload.id;
    });
    builder.addCase(uploadRenamedColumns.fulfilled, (state, action) => {
      state.renamedColumns = action.payload;
      state.renamedColumnsId = action.payload.id;
    });

    builder.addCase(getExternalColumns.fulfilled, (state, action) => {
      state.externalColumns = action.payload;
      state.externalColumnsId = action.payload.id ?? null;
    });
    builder.addCase(uploadExternalColumns.fulfilled, (state, action) => {
      state.externalColumns = action.payload;
      state.externalColumnsId = action.payload.id;
    });

    builder.addCase(getDefaultOptions.fulfilled, (state, action) => {
      state.defaultOptions = action.payload;
    });

    builder.addCase(getProfileOptions.fulfilled, (state, action) => {
      state.profileOptions = action.payload;
      state.profileOptionId = action.payload.id ?? null;
    });

    builder.addCase(commitProfileOptions.fulfilled, (state, action) => {
      state.profileOptions = action.payload;
      state.profileOptionId = action.payload.id;
    });

    builder.addCase(getExternalOptions.fulfilled, (state, action) => {
      state.externalOptions = action.payload;
      state.externalOptionId = action.payload.id ?? null;
    });

    builder.addCase(commitExternalOptions.fulfilled, (state, action) => {
      state.externalOptions = action.payload;
      state.externalOptionId = action.payload.id;
    });

    builder.addCase(getPayrollSetting.fulfilled, (state, action) => {
      state.payrollSetting = action.payload;
    });

    builder.addCase(getReportItem.fulfilled, (state, action) => {
      state.reportItem = action.payload;
      state.reportItemId = action.payload.id ?? null;
    });

    builder.addCase(commitReportItem.fulfilled, (state, action) => {
      state.reportItem = action.payload;
      state.reportItemId = action.payload.id;
    });

    builder.addCase(getAddingProfileFile.fulfilled, (state, action) => {
      state.addingProfileFile = action.payload;
    });
  },
});

export const arrangeAdhocRecords = (adhocCells: AdhocCell[], regularColumns: RegularColumn[]): AdhocRecord[] => {
  const map = {} as { [key: string]: any };
  adhocCells.forEach((cell) => {
    map[cell.record_id] = map[cell.record_id] || [];
    map[cell.record_id] = [cell, ...map[cell.record_id]];
  });
  let adhocRecords: AdhocRecord[] = [];
  for (const key in map) {
    const thisRecord = regularColumns.map(({ id }) => {
      let cellData = map[key].find((cell: AdhocCell) => cell.column === id);
      if (cellData) {
        if (id === "metadata") {
          const { is_valid, errors } = cellData;
          if (!is_valid && errors.length === 0) {
            errors.push("One or more cells have errors");
          }
          cellData = { ...cellData, value: is_valid };
        }
        return cellData;
      } else
        return {
          id: yieldId(),
          correcting: false,
          comments: [],
          errors: [],
          column: id,
          is_valid: false,
          value: "",
          key,
          record_id: "",
          type: "content",
        };
    });
    adhocRecords = [...adhocRecords, thisRecord];
  }
  return adhocRecords;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;

export const {
  unselectClient,
  unselectLoadData,
  setAdhocColumns,
  setAdhocCells,
  clearSectorRegularColumns,
  clearAdhocColumns,
} = slice.actions;

export const selectClientState = (state: RootState) => {
  return state.client as ClientState;
};

export const selectRawDataFiles = (state: RootState) => {
  return state.client.preparationFiles.filter((f: PreparationFile) => f.type === "raw") as PreparationFile[];
};
export const selectImportFormatFiles = (state: RootState) => {
  return state.client.preparationFiles.filter((f: PreparationFile) => f.type === "import") as PreparationFile[];
};

export const selectActiveSectors = (state: RootState): Sector[] => {
  if (state.client.sectorUserStatus.length === 0) return [];
  const _ = state.client.sectorUserStatus.filter((c: any) => c.active);
  return state.client.sectors.filter((s: Sector) => {
    return _.find((c: any) => c.key === s.sector_id);
  });
};

export const selectMasterActiveSectors = (state: RootState): Sector[] => {
  if (state.client.sectorMasterStatus.length === 0) return [];
  const _ = state.client.sectorMasterStatus.filter((c: any) => c.active);
  return state.client.sectors.filter((s: Sector) => {
    return _.find((c: any) => c.key === s.sector_id);
  });
};
