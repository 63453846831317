import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Button, Dropdown, Alert, Accordion, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  getAdminReportTemplates,
  bulkDownloadReport,
  selectReportState,
  bulkDownloadReportByValidFrom,
} from "../report/reportSlice";
import { FieldViewData, SearchItem, SearchCondition } from "../report/reportValues";
import MemberListSelector from "../report/MemberListSelector";
import SnapshotTimeSelector, { onChangeOption } from "../profile/SnapshotTimeSelector";
import Sidebar from "../../component/Sidebar";
import dayjs from "dayjs";
import "react-calendar/dist/Calendar.css";
import ModalDialog from "../../component/ModalDialog";
import { selectClientState, getSectorStatus } from "../client/clientSlice";

function ReportList() {
  const { adminInitialized, adminReportTemplates: reportTemplates } = useAppSelector(selectReportState);
  const { sectorUserStatus } = useAppSelector(selectClientState);
  const dispatch = useAppDispatch();
  const [state, $state] = useState({
    fieldDownloadReports: [] as FieldViewData[],
    checkedMemberIds: [] as number[],
    selectedPointDate: dayjs(),
    searchItems: [] as SearchItem[],
    checkedsectionCodes: [] as string[],
    isPdfOutput: false,
  });
  useEffect(() => {
    if (sectorUserStatus.length === 0) {
      dispatch(getSectorStatus());
    }
  }, []);

  /* adminInitialized は恐らく"一度読み込むべきものが決まればその後も同じなので読み込まない"という意図があるため 
     本来であれば adminInitializedがtrueの時だけgetAdminReportTemplatesを呼び出すべき、かつ
     源泉徴収情報の使用を変更したときに adminInitialized をリセットしておくべき、という方が元々の思想に合っていそうだが、
     sectorUserStatusが変更された時にも呼び出したいため、条件からは除外している。
     */
  useEffect(() => {
    if (sectorUserStatus.length > 0) {
      dispatch(getAdminReportTemplates({ policies: {}, sectorUserStatus }));
    }
  }, [adminInitialized, sectorUserStatus]);

  useEffect(() => {
    if (reportTemplates.length > 0) {
      resetFieldDownloadReport();
    }
  }, [reportTemplates]);

  const resetFieldDownloadReport = () => {
    const fields = reportTemplates.map((field) => {
      return {
        id: field.id,
        label: field.title,
        checked: false,
      } as FieldViewData;
    });
    $state({ ...state, fieldDownloadReports: fields });
  };

  const onSelectedMemberChange = async (next1: number[], next2: string[]) => {
    $state({ ...state, checkedMemberIds: next1, checkedsectionCodes: next2 });
  };

  const [isModalActive, $isModalActive] = useState(false);

  const selectField = async (id: string) => {
    const isSelectedField = state.fieldDownloadReports.some((s) => s.id === id);
    const next = state.fieldDownloadReports.map((field) => {
      const fieldCheckedNext = field.id === id ? !field.checked : field.checked;
      return {
        ...field,
        checked: isSelectedField ? (field.id === id ? !field.checked : false) : fieldCheckedNext,
      };
    });
    $state({ ...state, fieldDownloadReports: next });
  };

  const _getFieldIds = () => {
    const fieldIds = [] as string[];
    state.fieldDownloadReports.forEach((field) => {
      if (!field.checked) return;
      fieldIds.push(field.id);
    });
    return { fieldIds };
  };

  const _getConditions = () => {
    let conditions = {} as SearchCondition;
    state.searchItems.forEach((item) => {
      const choices = item.choices?.filter((choice) => choice.checked) || [];
      if (choices.length > 0) {
        const { column } = item;
        conditions = {
          ...conditions,
          [`${column}__in`]: choices.map((c) => c.value),
        };
      }
    });
    if (state.checkedMemberIds.length > 0) {
      conditions = {
        ...conditions,
        [`account_id__in`]: state.checkedMemberIds,
      };
    }
    return conditions;
  };

  const download = () => {
    const { fieldIds } = _getFieldIds();
    if (fieldIds[0] === "roster_of_workers_bulk_download") {
      dispatch(
        bulkDownloadReport({
          report: fieldIds[0],
          baseDate: state.selectedPointDate,
          conditions: _getConditions(),
          isPdfOutput: state.isPdfOutput,
        })
      );
    } else {
      dispatch(
        bulkDownloadReportByValidFrom({
          report: fieldIds[0],
          validFrom: state.selectedPointDate,
          conditions: _getConditions(),
          isPdfOutput: state.isPdfOutput,
        })
      );
    }
    $isModalActive(true);
  };

  const checkedFields = state.fieldDownloadReports.filter((_) => _.checked);

  return adminInitialized ? (
    <Container>
      <Row className="mt-2">
        <Col md="2">
          <div className="--bold pt-md-3">出力項目</div>
        </Col>
        <Col md="10">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {checkedFields.length > 0 ? checkedFields.map((_) => _.label).join(", ") : "（未選択）"}
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  {state.fieldDownloadReports.map((f, i) => (
                    <Col md="6" key={`${f.id}_${i}`}>
                      <Form.Check
                        key={f.id}
                        checked={f.checked}
                        id={f.id}
                        type="checkbox"
                        label={f.label}
                        onChange={() => selectField(f.id)}
                        inline
                      />
                    </Col>
                  ))}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="2">
          <div className="--bold pt-md-3">基準日</div>
        </Col>
        <Col md="10">
          <SnapshotTimeSelector
            selectedPointDate={state.selectedPointDate}
            onChange={({ selectedPointDate }: onChangeOption) => {
              if (!selectedPointDate) return;
              $state({
                ...state,
                selectedPointDate,
              });
            }}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="2">
          <div className="--bold pt-md-3">絞込条件</div>
        </Col>
        <Col md="10">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {state.checkedsectionCodes.length > 0
                  ? (() => {
                      let checkedFieldLabels = [] as string[];
                      if (state.checkedsectionCodes.length > 0) {
                        checkedFieldLabels = [...checkedFieldLabels, "対象者"];
                      }
                      return checkedFieldLabels.join(", ");
                    })()
                  : "（未選択）"}
              </Accordion.Header>
              <Accordion.Body>
                <MemberListSelector
                  checkedsectionCodes={state.checkedsectionCodes}
                  onSelectedMemberChange={onSelectedMemberChange}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="2">
          <div className="--bold md-3">オプション</div>
        </Col>
        <Col md="10" className="d-flex align-items-center">
          <Form.Check
            inline
            type="checkbox"
            id={"isOutputPdf"}
            label={"1枚のPDFにまとめて出力する"}
            checked={state.isPdfOutput}
            onChange={() => $state({ ...state, isPdfOutput: !state.isPdfOutput })}
          ></Form.Check>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Button
            className="mx-2"
            disabled={
              state.fieldDownloadReports.filter((report) => report.checked).length === 0 ||
              (state.checkedsectionCodes.length > 0 && state.checkedMemberIds.length === 0)
            }
            onClick={download}
            variant="outline-secondary"
          >
            ダウンロード
          </Button>
          <Button
            className="mx-2"
            variant="outline-secondary"
            onClick={() => {
              $state({
                ...state,
                checkedMemberIds: [],
                checkedsectionCodes: [],
              });
            }}
          >
            絞込条件をクリア
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="12">
          {state.checkedsectionCodes.length > 0 && state.checkedMemberIds.length === 0 ? (
            <Alert variant={"info"}>該当するレコードはありません。</Alert>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <ModalDialog
        show={isModalActive}
        onConfirm={() => $isModalActive(false)}
        message="ファイルの作成を開始しました。ダウンロード可能になったら通知されます。"
        type="alert"
      />
    </Container>
  ) : (
    <></>
  );
}

export default ReportList;
