import ClassNames from "classnames";
import "../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Alert, Button, Spinner } from "react-bootstrap";
import { logout, useAppDispatch, useAppSelector } from "../app/store";
import Header from "../component/Header";
import ToastView from "../features/notification/ToastView";
import { selectUserState } from "../features/login/userSlice";
import { selectFatalErrorState } from "../features/error/fatalErrorSlice";
import { selectNotificationState } from "../features/notification/notificationSlice";
import { store } from "../app/store";
import { deleteError } from "../features/error/fatalErrorSlice";
import { useEffect, useState } from "react";
import { getQuery } from "../app/util";
import { useLocation, useNavigate } from "react-router-dom";
import { switchCompany } from "../features/login/idTokenSlice";
import ModalDialog from "./ModalDialog";

function App(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { initialized, user } = useAppSelector(selectUserState);
  const { fatalError, error } = useAppSelector(selectFatalErrorState);
  const { loading } = useAppSelector(selectNotificationState);
  const [isRoleConfirmed, $isRoleConfirmed] = useState(false); // URLにあるロールが確認済であるかどうか
  const [switchRoleModal, $switchRoleModal] = useState({ isOpen: false, url: "", role: "" });
  const [showNoRoleModal, $showNoRoleModal] = useState(false);
  const { search, pathname } = useLocation();
  const _logout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (!user.id) return; // userが取得できていない場合は何もしない
    if (isRoleConfirmed) return; // ロール確認済みの場合は何もしない
    // ロール切替が必要かどうかを確認
    const queries = getQuery(search);
    const targetRoles = queries?.target_roles?.split("/") ?? [];
    if (targetRoles.length === 0) {
      // target_rolesが指定されていない場合は、確認完了
      $isRoleConfirmed(true);
      return;
    }
    // 判定後はtarget_rolesのパラメータは不要なので、新しいURLを作成
    const newSearch = Object.entries(queries)
      .filter(([key]) => key !== "target_roles")
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    const newUrl = `${pathname}${newSearch && `?${newSearch}`}`;
    if (targetRoles.some((role) => user.role === role)) {
      // 既に操作可能なロールの場合は、URLを変更してそのまま遷移
      window.history.replaceState({}, "", newUrl);
      $isRoleConfirmed(true);
    } else if (user.profile_roles.some((role) => targetRoles.includes(role))) {
      // 切替が必要な場合は、切替を行うかどうかを確認するモーダルを表示
      const role = targetRoles.find((role) => user.profile_roles.includes(role)) as string;
      $switchRoleModal({ isOpen: true, url: newUrl, role });
    } else {
      // 該当のロールを持たない場合、アラートを表示する
      $showNoRoleModal(true);
    }
  }, [search, user]);

  return (
    <div className={ClassNames({ Root: !initialized, "Root--ready": initialized })}>
      <ToastView />
      <Header />
      {loading && (
        <div className="Overlay">
          <div className="Overlay__content">
            <Spinner animation="border" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )}
      <ModalDialog
        show={switchRoleModal.isOpen}
        onConfirm={async () => {
          await dispatch(
            switchCompany({
              companyCode: user.current_company.code,
              role: switchRoleModal.role,
              isProfile: true,
              isServiceSwitch: false,
            })
          );
          window.location.href = switchRoleModal.url;
        }}
        onCancel={() => {
          $switchRoleModal({ isOpen: false, url: "", role: "" });
          if (document.referrer) window.history.back(); // リファラがある=通知を押下された場合は戻る
          else navigate("/_/dashboard"); // ない場合はダッシュボードに遷移
        }}
        message="ロール切替が必要です。切り替えてよろしいですか？"
        type="confirm"
      />
      <ModalDialog
        show={showNoRoleModal}
        onConfirm={() => {
          $showNoRoleModal(false);
          if (document.referrer) window.history.back(); // リファラがある=通知を押下された場合は戻る
          else navigate("/_/dashboard"); // ない場合はダッシュボードに遷移
        }}
        message="該当の通知を確認する権限がありません。"
        type="alert"
      />
      {fatalError.length === 0 ? (
        <>
          {error.length > 0 && (
            <div className="Overlay">
              <div className="Overlay__content">
                {error.map(({ type, message, id }) => (
                  <Alert
                    variant={type === "error" ? "danger" : "warning"}
                    key={id}
                    onClose={() => {
                      store.dispatch(deleteError(id));
                    }}
                    dismissible
                  >
                    {message}
                  </Alert>
                ))}
              </div>
            </div>
          )}
          <div className="Root__content">{isRoleConfirmed && props.content}</div>
        </>
      ) : (
        <div className="Root__content">
          <div className="Layout">
            <div className="Layout__main">
              {fatalError.map(({ type, message }, i) => (
                <Alert variant={type === "error" ? "danger" : "warning"} key={`error${i}`}>
                  <Alert.Heading>処理中にエラーが発生しました</Alert.Heading>
                  <div>{message}</div>
                </Alert>
              ))}
              <Button onClick={_logout}>再ログインする</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
