import { LangDictionary } from "../../app/translate";
const Dictionary = {
  IMPORT_STATUS_todo: { ja: "開始前", en: "unregistered" },
  IMPORT_STATUS_creating: { ja: "履歴追加中", en: "under setting of creating new records" },
  IMPORT_STATUS_setting_valid_to: { ja: "終了日設定中", en: "under setting of end date" },
  IMPORT_STATUS_deleting: { ja: "履歴削除中", en: "under setting of deleting records" },
  IMPORT_STATUS_done: { ja: "完了", en: "completed" },
  FILE_TYPE_raw: { ja: "元データ", en: "raw data" },
  FILE_TYPE_import: { ja: "アップロードファイル", en: "intermediate file" },
  KOT_USAGE_STATUS_in_use: { ja: "使用中", en: "in use" },
  KOT_USAGE_STATUS_testing: { ja: "テスト中", en: "testing" },
  KOT_USAGE_STATUS_not_in_use: { ja: "不使用", en: "not in use" },
} as LangDictionary;
export default Dictionary;
