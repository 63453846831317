import {
  EvaluationHelperProduct,
  EvaluationPattern,
  EvaluationProcedureStep,
  EvaluationModuleCommonProps,
} from "../EvaluationTypes";
import { mergeContext } from "../evaluationValues";
import subRoutineMap from "./subModules";

const Execute = (
  _common: EvaluationModuleCommonProps,
  {
    procedure,
    pattern,
    useSeedTemplateDefault,
  }: {
    procedure: EvaluationProcedureStep[];
    pattern: EvaluationPattern;
    useSeedTemplateDefault: boolean;
  }
) => {
  try {
    const common = { ..._common };
    const { units, context } = procedure.reduce(
      (prev, current) => {
        const module = subRoutineMap[current.subRoutineId];
        if (!module) {
          console.log("module not found: " + current.subRoutineId);
          return prev;
        }

        /*
          pattern.seedData から今回 subRoutine に渡す引数を準備
        */
        const { units, context, occupied, meta } = module.run(
          {
            context: prev.context,
            unitGroupId: common.unitGroupId,
            subRoutineId: current.id,
            units: prev.units,
          },
          {
            ...Object.keys(current.argv).reduce((p, c) => {
              const value = (() => {
                if (useSeedTemplateDefault) {
                  return current.argv[c];
                } else {
                  // パターンで設定されていなければ、デフォルトの値を使う
                  const unitGroupArgv = pattern.seedData[common.unitGroupId];
                  if (c !== "unit") {
                    // unit 以下のパラメータ
                    return unitGroupArgv?.[current.id]?.[c] ?? current.argv[c];
                  } else {
                    // unit はフィールドごとに確認する
                    const actualArgv = Object.keys(current.argv[c]).reduce((p, unitProp) => {
                      const patternValue = unitGroupArgv?.[current.id]?.[c][unitProp];
                      // TODO: unitProp ごとにデフォルト値を採用する条件を分けたほうが丁寧
                      return {
                        ...p,
                        [unitProp]:
                          patternValue !== undefined && patternValue !== "" ? patternValue : current.argv[c][unitProp],
                      };
                    }, {} as { [unitProp: string]: any });
                    return actualArgv;
                  }
                }
              })();
              return {
                ...p,
                [c]: value,
              };
            }, {} as { [key: string]: any }),
            origin: {
              row: (() => {
                if (typeof current.origin.row === "number") return current.origin.row;
                if (`${current.origin.row}`.indexOf("FOLLOW") === 0) {
                  const [_, index] = current.origin.row.split("$$");
                  return prev.results[+index]?.occupied.row + 1;
                } else {
                  return 9;
                }
              })(),
              column: (() => {
                if (typeof current.origin.column === "number") return current.origin.column;
                if (`${current.origin.column}`.indexOf("FOLLOW") === 0) {
                  const [_, index] = current.origin.column.split("$$");
                  return prev.results[+index]?.occupied.column + 1;
                } else {
                  return 9;
                }
              })(),
            },
          }
        );
        return {
          units: [...prev.units, ...units],
          context: mergeContext(prev.context, context),
          results: [...prev.results, { occupied, meta }],
        };
      },
      {
        units: [] as EvaluationHelperProduct["units"],
        context: { ...common.context } as EvaluationHelperProduct["context"],
        results: [] as {
          occupied: EvaluationHelperProduct["occupied"];
          meta: EvaluationHelperProduct["meta"];
        }[],
      }
    );

    return {
      units,
      context,
    };
  } catch (e) {
    console.log(e);
    return {
      units: [],
      context: _common.context,
    };
  }
};

export default Execute;
