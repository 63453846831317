import {
  EvaluationValue,
  UnitTemplate,
  EvaluationModuleCommonProps,
  EvaluationHelperProduct,
} from "../EvaluationTypes";

const seedDataTypes = {
  unit: {
    description: "パラメータ",
    data: {
      type: "object",
      schema: {
        unit: {
          type: "unit",
          label: "パラメータ",
          props: {
            conditions: {
              type: "conditions",
              label: "表示テキストと条件",
            },
          },
        },
      },
    },
  },
};

/*
  - context に算出条件を追加
*/
const ComputedUnitModule = (
  common: EvaluationModuleCommonProps,
  props: {
    origin: {
      row: number;
      column: number;
    };
    use: boolean;
    unit: {
      test: string;
      conditions?: {
        computedKey: string;
        value: EvaluationValue;
      }[];
      id?: string;
      gridRowSpan?: number;
      gridColumnSpan?: number;
      factor?: string;
      classNames?: string[];
      cssBundleKeys?: string[];
      style?: React.CSSProperties;
    };
  }
): EvaluationHelperProduct => {
  if (!props.use) {
    return {
      units: [],
      origin: { row: props.origin.row, column: props.origin.column },
      occupied: { row: props.origin.row, column: props.origin.column },
      context: common.context,
      meta: {},
    };
  }

  const units = [] as UnitTemplate[];
  const gridRow = props.origin.row ?? 1;
  const gridColumn = props.origin.column ?? 1;
  const gridRowSpan = props.unit.gridRowSpan ?? 1;
  const gridColumnSpan = props.unit.gridColumnSpan ?? 1;
  const id = `${common.unitGroupId}__${common.subRoutineId}__${gridRow}__${gridColumn}`;
  const computedKey = id;
  const conditions = (props.unit.conditions ?? []).map((row) => {
    const _computedKey = row.computedKey !== "$$SELF_ID" ? row.computedKey : computedKey;
    return {
      computedKey: _computedKey,
      value: row.value,
    };
  });
  units.push({
    id,
    type: "computed",
    conditions,
    unitGroupId: common.unitGroupId,
    subRoutineId: common.subRoutineId,
    factor: props.unit.factor ?? "",
    gridRow: props.origin.row ?? 1,
    gridColumn: props.origin.column ?? 1,
    gridRowSpan,
    gridColumnSpan,
    style: {
      ...(props.unit.style ?? {}),
      ...(props.unit.cssBundleKeys ?? []).reduce((prev, current) => {
        const snippets = common.context.cssTraits[current];
        return {
          ...prev,
          ...(snippets?.styles ?? {}),
        };
      }, {} as React.CSSProperties),
    },
  });
  const targetUnits = common.units.filter((u) => u.factorUnitIds?.includes("ウェイト"));

  if (props.unit.test === "A") {
    common.context.computingRuleSet[computedKey] = {
      label: `合計_${gridRow}_${gridColumn}`,
      rules: [
        ...targetUnits.map((unit) => ({
          operands: unit.type === "computed" ? [`COMPUTED$$${unit.conditions?.[0].computedKey ?? ""}`] : [unit.id],
          method: "ASSIGN",
        })),
        {
          operands: targetUnits.map((_, i) => `$$${i}`),
          method: "SUM",
        },
      ],
    };
  } else {
    common.context.computingRuleSet[computedKey] = {
      label: `合計バリデーション_${gridRow}_${gridColumn}`,
      rules: [
        ...targetUnits.map((unit) => ({
          operands: unit.type === "computed" ? [`COMPUTED$$${unit.conditions?.[0].computedKey ?? ""}`] : [unit.id],
          method: "ASSIGN",
        })),
        {
          operands: targetUnits.map((_, i) => `$$${i}`),
          method: "SUM",
        },
        {
          operands: [`$$${targetUnits.length}`],
          method: "EQ",
          subOperands: [100],
        },
      ],
    };
  }

  return {
    units,
    origin: { row: props.origin.row, column: props.origin.column },
    occupied: { row: props.origin.row + 1, column: props.origin.column + 1 },
    context: common.context,
    meta: {},
  };
};

const sampleArgv = {
  unit: {
    conditions: [],
  },
};

export default {
  label: "算出ユニット",
  run: ComputedUnitModule,
  seedDataTypes,
  sampleArgv,
};
