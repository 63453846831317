import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import { ProfileState } from "../profile/profileSlice";
import { call } from "../../app/api";
import { DecodedFileData } from "../../component/Uploader";
import download from "../../app/download";
import { handleError } from "../error/fatalErrorSlice";
import { MY_NUMBER_LIST_UPTO, MyNumber, MyNumberFiles, MyNumberLog, MyNumberView } from "./myNumberValues";

const SLICE_NAME = "myNumber";

interface MyNumberState {
  processing: boolean;
  myNumberViews: MyNumberView[];
  selectedMyNumber: MyNumber | null;
  concernedAccountIds: number[];
  logs: MyNumberLog[];
  configId?: string;
  textForEmployee: string;
  textForFamily: string;
  holdImageFiles: boolean;
  notificationTemplate: string;
  pendingYears: number;
  pendingMonths: number;
  downloadLogs: MyNumberLog[];
  myNumberValue: string;
  myNumberFiles: MyNumberFiles;
  statusMessages: { [key: string]: string };
  hasMyNumberRecord: { [accountId: number]: boolean };
  myNumberLogsTotalCount: number;
  myNumberLogsHasMore: boolean;
  fetchedMyNumberLogsPage: number;
  myNumberTodoCount: number;
}

const initialState: MyNumberState = {
  processing: false,
  myNumberViews: [],
  selectedMyNumber: null,
  concernedAccountIds: [],
  logs: [],
  textForEmployee: "",
  textForFamily: "",
  holdImageFiles: false,
  notificationTemplate: "",
  pendingYears: 0,
  pendingMonths: 0,
  downloadLogs: [],
  myNumberValue: "",
  myNumberFiles: {},
  statusMessages: {},
  hasMyNumberRecord: {},
  myNumberLogsTotalCount: 0,
  myNumberLogsHasMore: false,
  fetchedMyNumberLogsPage: 0,
  myNumberTodoCount: 0,
};

export const getMyNumberViews = createAsyncThunk<
  { myNumberViews: MyNumberView[]; concernedAccountIds: number[] },
  { accountId?: number } | void
>(SLICE_NAME + "/getMyNumberViews", async (options) => {
  const myNumberRes = await call("get", "my_number_manager/list")({ account_id: options?.accountId });
  const myNumberViews = myNumberRes.data.result.map((res: any) => {
    if (!res.id) return { ...res, id: `_temp_${res.account_id}_${res.type}_${res.dependent_serial}` };
    return res;
  }) as MyNumberView[];
  const concernedAccountIds = Array.from(new Set([...myNumberViews.map((v) => v.account_id)]));
  return { myNumberViews, concernedAccountIds };
});

export const selectMyNumber = createAsyncThunk(SLICE_NAME + "/selectMyNumber", async ({ id }: { id: string }) => {
  const res = await call("get", "my_number_manager/my_number")({ id });
  const myNumber = res.data.result?.[0] as MyNumber;
  const { number_file_id, identification_file_1_id, identification_file_2_id } = myNumber;
  const myNumberFiles = await Promise.all(
    Object.entries({ number_file_id, identification_file_1_id, identification_file_2_id }).map(
      async ([columnName, id]) => {
        const { file_name, file, mime } = await (async () => {
          if (id) {
            const result = await call("get", "my_number_manager/file")({ id });
            const { file_name, file, mime } = result.data.result;
            return { file_name, file, mime };
          } else {
            return { file_name: "", file: "", mime: "" };
          }
        })();

        return {
          [columnName]: {
            name: file_name,
            dataURI: file,
            type: mime,
            dataURIprefix: `data:${mime};base64`,
          },
        };
      }
    )
  ).then((result) => {
    // すべての非同期処理の結果を一度に処理
    return result.reduce((prev, current) => ({ ...prev, ...current }), {});
  });
  return { myNumber, myNumberFiles };
});

export const attachMyNumberFile = createAsyncThunk(
  SLICE_NAME + "/attachMyNumberFile",
  async ({ fileId, decodedFileData }: { fileId: string; decodedFileData: DecodedFileData }) => {
    const { dataURI, name } = decodedFileData;
    await call("post", "my_number_manager/file")({ id: fileId, file: dataURI, name });
  }
);

export const deleteMyNumberFile = createAsyncThunk(
  SLICE_NAME + "/deleteMyNumberFile",
  async ({ fileId }: { fileId: string }) => {
    await call("delete", "my_number_manager/file")({ id: fileId });
  }
);

export const postMyNumber = createAsyncThunk(
  SLICE_NAME + "/postMyNumber",
  async (data: { accountId?: number; type: string; dependentSerial?: number }) => {
    const res = await call(
      "post",
      "my_number_manager/my_number"
    )({
      account_id: data.accountId,
      type: data.type,
      dependent_serial: data.dependentSerial,
    });
    return res.data.result[0];
  }
);

export const getMyNumber = createAsyncThunk(SLICE_NAME + "/getMyNumber", async ({ id }: { id: string }) => {
  const res = await call("get", "my_number_manager/decrypt")({ id });
  return res.data.result?.[0].value as string;
});

export const applyMyNumber = createAsyncThunk(
  SLICE_NAME + "/applyMyNumber",
  async ({ id, value, agreed_at }: { id: string; value: string; agreed_at?: number }) => {
    const res = await call("post", "my_number_manager/apply")({ id, value, agreed_at });
    return res.data.result[0];
  }
);
export const approveMyNumber = createAsyncThunk(SLICE_NAME + "/approveMyNumber", async ({ id }: { id: string }) => {
  const res = await call("post", "my_number_manager/approve")({ id });
  return res.data.result[0];
});

export const rejectMyNumber = createAsyncThunk(
  SLICE_NAME + "/rejectMyNumber",
  async ({ id, reason }: { id: string; reason: string }) => {
    const res = await call("post", "my_number_manager/reject")({ id, reason });
    return res.data.result[0];
  }
);

export const registerMyNumber = createAsyncThunk(
  SLICE_NAME + "/registerMyNumber",
  async ({ id, value }: { id: string; value: string }) => {
    const res = await call("post", "my_number_manager/register")({ id, value });
    return res.data.result[0];
  }
);

export const setMyNumberDeletable = createAsyncThunk(
  SLICE_NAME + "/setMyNumberDeletable",
  async ({ ids }: { ids: string[] }) => {
    const res = await call("post", "my_number_manager/set_deletable")({ id__in: ids });
    return res.data.result[0];
  }
);

export const revertMyNumberDeletable = createAsyncThunk(
  SLICE_NAME + "/revertMyNumberDeletable",
  async ({ id }: { id: string }) => {
    const res = await call("post", "my_number_manager/revert_deletable")({ id });
    return res.data.result[0];
  }
);

export const requestMyNumberApply = createAsyncThunk(
  SLICE_NAME + "/requestMyNumberApply",
  async ({ targets }: { targets: { account_id: number; type: string; dependent_serial?: number }[] }) => {
    await call("post", "my_number_manager/request_apply")({ targets });
  }
);

export const getMyNumberSettings = createAsyncThunk(SLICE_NAME + "/getMyNumberSettings", async () => {
  const res = await call("get", "my_number_manager/config")();
  return res.data.result[0];
});

export const commitMyNumberSettings = createAsyncThunk(
  SLICE_NAME + "/commitMyNumberSettings",
  async ({
    textForEmployee,
    textForFamily,
    pendingYears,
    pendingMonths,
    holdImageFiles,
    notificationTemplate,
  }: {
    textForEmployee: string;
    textForFamily: string;
    pendingYears: number;
    pendingMonths: number;
    holdImageFiles: boolean;
    notificationTemplate: string;
  }) => {
    const id = store.getState().myNumber.configId;
    const method = id ? "put" : "post";
    const res = await call(
      method,
      "my_number_manager/config"
    )({
      text_for_employee: textForEmployee,
      text_for_family: textForFamily,
      pending_years: pendingYears,
      pending_months: pendingMonths,
      hold_image_files: holdImageFiles,
      notification_template: notificationTemplate,
      id,
    });
    return res.data.result[0];
  }
);

export const getMyNumberDownloadLogs = createAsyncThunk(SLICE_NAME + "/getMyNumberDownloadLogs", async () => {
  const res = await call(
    "get",
    "my_number_manager/operation_log"
  )({
    action_type: "download",
    sort_by: '[{ "time": -1 }]',
  });
  return res.data.result;
});

export const getMyNumberLogs = createAsyncThunk(
  SLICE_NAME + "/getMyNumberLogs",
  async ({ myNumberId, page }: { myNumberId: string; page?: number }) => {
    const res = await call(
      "get",
      "my_number_manager/operation_log"
    )({
      objects: myNumberId,
      sort_by: '[{ "time": -1 }]',
      page: page ?? 0,
      limit: page ? MY_NUMBER_LIST_UPTO : 0,
    });
    const myNumberLogsTotalCount = res.data.count;
    const myNumberLogsHasMore = res.data.has_more;
    const myNumberLogs = res.data.result;
    const fetchedMyNumberLogsPage = page ?? 1;
    return { myNumberLogsTotalCount, myNumberLogsHasMore, myNumberLogs, fetchedMyNumberLogsPage };
  }
);

export const getStatusMessages = createAsyncThunk(
  SLICE_NAME + "/getStatusMessages",
  async ({ myNumberIds }: { myNumberIds: string[] }) => {
    return await Promise.all(
      myNumberIds.map(async (myNumberId) => {
        const res = await call(
          "get",
          "my_number_manager/operation_log"
        )({
          objects: myNumberId,
          action_type__in: ["reject"],
          sort_by: '[{ "time": -1 }]',
          limit: 1,
        });
        return { [myNumberId]: res.data.result[0] };
      })
    ).then((result) => {
      // すべての非同期処理の結果を一度に処理
      return result.reduce((prev, res) => {
        Object.entries(res).forEach(([myNumberId, record]) => {
          prev[myNumberId] = record?.remarks;
        });
        return prev;
      }, {});
    });
  }
);

export const issueOneTimePassword = createAsyncThunk(SLICE_NAME + "/issueOneTimePassword", async () => {
  await call("post", "my_number_manager/one_time_password")();
});

export const downloadMyNumbers = createAsyncThunk(
  SLICE_NAME + "/downloadMyNumbers",
  async ({
    oneTimePassword,
    purpose,
    terms,
  }: {
    oneTimePassword: string;
    purpose: string;
    terms: { [key: string]: string };
  }) => {
    const res = await call("get", "my_number_admin_manager/download", { handleError: false })({
      one_time_password: oneTimePassword,
      purpose,
      terms,
    }).catch((e) => {
      const error = e?.response?.data?.errors[0]?.reason ?? "";
      if (error.startsWith("E004"))
        throw new Error("ダウンロードできませんでした。ワンタイムパスワードをご確認ください。");
      store.dispatch(handleError({ type: "error", message: "予期せぬエラーが発生しました。" }));
    });

    const { file, file_name, mime } = res.data.result;
    download({ file, fileName: file_name, mimeType: mime });
  }
);

export const uploadMyNumber = createAsyncThunk(
  SLICE_NAME + "/uploadMyNumber",
  async ({ file, name }: { file: string; name: string }) => {
    const next_process = {
      externalEffect: "_getMyNumbers",
      externalEffectUsed: false,
      payload: {},
    };
    await call("post", "my_number_manager/upload")({ file, name, next_process });
  }
);

export const downloadUploadFormat = createAsyncThunk(
  SLICE_NAME + "/downloadUploadFormat",
  async ({ terms, type }: { terms: { [key: string]: string }; type: string }) => {
    const res = await call("get", "my_number_manager/format_download")({ terms, type });
    const { file, file_name, mime } = res.data.result;
    download({ file, fileName: file_name, mimeType: mime });
  }
);

export const checkHasMyNumberRecord = createAsyncThunk(
  SLICE_NAME + "/checkHasMyNumberRecord",
  async ({ account_id }: { account_id: number }) => {
    const res = await call(
      "get",
      "my_number_manager/my_number"
    )({ account_id, limit: 1, page: 1, sort_by: '[{ "created_at": -1 }]' });
    return { [account_id]: res.data.count > 0 };
  }
);

export const getMyNumberTodoCount = createAsyncThunk(
  SLICE_NAME + "/getMyNumberTodoCount",
  async ({ role }: { role: "admin" | "user" }) => {
    if (role === "admin") {
      const res = await call("get", "my_number_manager/my_number", { doReload: false })({
        status: "reviewing",
        sort_by: '[{ "created_at": -1 }]',
        page: 1,
        limit: 1, // カウントが欲しいだけなので1件
      });
      return res.data.count;
    } else {
      const res = await call("get", "my_number_manager/list")({ is_current: true });
      return res.data.result.filter((r: any) => !["done", "reviewing"].includes(r.status)).length;
    }
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearMyNumberValue: (state) => {
      state.myNumberValue = "";
    },
    clearSelectedMyNumber: (state) => {
      state.selectedMyNumber = null;
      state.myNumberFiles = {};
    },
    clearMyNumberLogs: (state) => {
      state.logs = [];
    },
    clearMyNumberTodoCount: (state) => {
      state.myNumberTodoCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyNumberViews.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberViews.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberViews.fulfilled, (state, action) => {
      state.processing = false;
      state.myNumberViews = action.payload.myNumberViews;
      state.concernedAccountIds = action.payload.concernedAccountIds;
    });
    builder.addCase(selectMyNumber.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(selectMyNumber.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(selectMyNumber.fulfilled, (state, action) => {
      state.processing = false;
      state.selectedMyNumber = action.payload.myNumber;
      state.myNumberFiles = action.payload.myNumberFiles;
    });
    builder.addCase(getMyNumber.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumber.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumber.fulfilled, (state, action) => {
      state.processing = false;
      state.myNumberValue = action.payload;
    });
    builder.addCase(getMyNumberLogs.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberLogs.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberLogs.fulfilled, (state, action) => {
      state.processing = false;
      state.myNumberLogsTotalCount = action.payload.myNumberLogsTotalCount;
      state.myNumberLogsHasMore = action.payload.myNumberLogsHasMore;
      state.logs =
        action.payload.fetchedMyNumberLogsPage === 1
          ? action.payload.myNumberLogs
          : [...state.logs, ...action.payload.myNumberLogs];
      state.fetchedMyNumberLogsPage = action.payload.fetchedMyNumberLogsPage;
    });
    builder.addCase(getMyNumberSettings.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberSettings.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberSettings.fulfilled, (state, action) => {
      state.processing = false;
      state.textForEmployee = action.payload.text_for_employee;
      state.textForFamily = action.payload.text_for_family;
      state.pendingYears = action.payload.pending_years;
      state.pendingMonths = action.payload.pending_months;
      state.holdImageFiles = action.payload.hold_image_files;
      state.notificationTemplate = action.payload.notification_template;
      state.configId = action.payload.id;
    });
    builder.addCase(commitMyNumberSettings.fulfilled, (state, action) => {
      state.processing = false;
      state.textForEmployee = action.payload.text_for_employee;
      state.textForFamily = action.payload.text_for_family;
      state.pendingYears = action.payload.pending_years;
      state.pendingMonths = action.payload.pending_months;
      state.holdImageFiles = action.payload.hold_image_files;
      state.notificationTemplate = action.payload.notification_template;
      state.configId = action.payload.id;
    });
    builder.addCase(getMyNumberDownloadLogs.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberDownloadLogs.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberDownloadLogs.fulfilled, (state, action) => {
      state.processing = false;
      state.downloadLogs = action.payload;
    });
    builder.addCase(getStatusMessages.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getStatusMessages.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getStatusMessages.fulfilled, (state, action) => {
      state.processing = false;
      state.statusMessages = action.payload;
    });
    builder.addCase(attachMyNumberFile.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(attachMyNumberFile.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(attachMyNumberFile.fulfilled, (state, action) => {
      state.processing = false;
    });
    builder.addCase(deleteMyNumberFile.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(deleteMyNumberFile.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(deleteMyNumberFile.fulfilled, (state, action) => {
      state.processing = false;
    });
    builder.addCase(checkHasMyNumberRecord.fulfilled, (state, action) => {
      state.hasMyNumberRecord = { ...state.hasMyNumberRecord, ...action.payload };
    });
    builder.addCase(getMyNumberTodoCount.fulfilled, (state, action) => {
      state.myNumberTodoCount = action.payload;
    });
  },
});

export const { clearMyNumberValue, clearSelectedMyNumber, clearMyNumberLogs, clearMyNumberTodoCount } = slice.actions;

export const selectMyNumberState = (state: RootState) => {
  return state.myNumber as MyNumberState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
