import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Alert, Form } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { getPhases, getTodos, selectESState } from "./evaluationSystemSlice";
import { ESProcessMethod, ESProcess, ESTodo } from "./ESTypes";

type TodoCardProps = {
  todo: ESTodo;
};

function ESTodoCard({ todo: t }: TodoCardProps) {
  const dispatch = useAppDispatch();
  //   const { phaseGroups, processes, methods, todos } = useAppSelector(selectESState);

  return (
    <article className="Todo-card">
      <div className="Todo-card__name">
        <figure className="Todo-card__portrait">{t.assignee_image_path && <img src={t.assignee_image_path} />}</figure>
        <div className="--font-s">{t.assignee_name}</div>
      </div>
      <div className="--font-s mt-1">{t.status_label}</div>
      <div className="--font-s mt-1">期限：{t.due_date}</div>
      <div className="--font-s mt-1">{t.process_name}</div>
      <div className="--font-s mt-1">
        {t.workflow_phase_label} / {t.workflow_step_label} / {t.workflow_role_label}
      </div>
      <Button className="mt-2 w-100" size="sm" variant="outline-primary">
        {(() => {
          switch (t.status) {
            case "not_started":
              return "着手する";
            case "in_progress":
              return "再開する";
            case "pending_review":
              return "--";
            case "on_hold":
              return "--";
            case "completed":
              return "--";
            case "canceled":
              return "--";
            default:
              return "--";
          }
        })()}
      </Button>
    </article>
  );
}

export default ESTodoCard;
