import {
  EvaluationAnswers,
  EvaluationConditionRule,
  EvaluationScopedVariables,
  EvaluationValue,
} from "./EvaluationTypes";

export const methodOptions = [
  {
    value: "GT",
    label: "次の値より大きい",
    subOperandType: "number",
  },
  {
    value: "LT",
    label: "次の値より小さい",
    subOperandType: "number",
  },
  {
    value: "ALL",
    label: "全てが次の値に合致する",
    subOperandType: "boolean",
  },
  {
    value: "ASSIGN",
    label: "代入する",
    subOperandType: "",
  },
  {
    value: "MULTIPLY",
    label: "掛ける",
    subOperandType: "number",
  },
  {
    value: "DEVIDE",
    label: "割る",
    subOperandType: "number",
  },
  {
    value: "AVERAGE",
    label: "平均する",
    subOperandType: "",
  },
  {
    value: "SUM",
    label: "合計する",
    subOperandType: "number",
  },
  {
    value: "CLASSIFY",
    label: "分類する",
    subOperandType: "class",
  },
];

const calculate = ({
  rule,
  answers,
  variables,
  accumulated,
}: {
  rule: EvaluationConditionRule;
  answers: EvaluationAnswers;
  variables: EvaluationScopedVariables;
  accumulated: (EvaluationValue | EvaluationValue[])[];
}): EvaluationValue | EvaluationValue[] => {
  const { operands, method, subOperands } = rule;
  const operandValues = operands.map((operand) => {
    if (operand === "$$ALL") return accumulated;
    const _ = operand.match(/^\$\$([0-9]+)$/);
    if (_?.[1]) {
      return accumulated[+_[1]];
    }
    if (operand.indexOf("COMPUTED$$") === 0) operand = operand.replace("COMPUTED$$", "");
    return variables[operand] ?? answers[operand];
  }) as (EvaluationValue | EvaluationValue[])[];
  const subOperandValues = (subOperands ?? []).map((operand) => {
    if (operand === "$$ALL") return accumulated;
    if (Array.isArray(operand)) return operand;
    let _operand = `${operand}`;
    const _ = _operand.match(/^\$\$([0-9]+)$/);
    if (_?.[1]) {
      return accumulated[+_[1]];
    } else if (_operand.indexOf("COMPUTED$$") === 0) {
      _operand = _operand.replace("COMPUTED$$", "");
      return variables[_operand] ?? answers[_operand];
    } else {
      return operand;
    }
  }) as (EvaluationValue | EvaluationValue[])[];
  switch (method) {
    case "EQ":
      if (Number.isNaN(+operandValues[0]) || typeof subOperandValues[0] !== "number") return false;
      return +operandValues[0] === subOperandValues[0];
    case "GT":
      if (Number.isNaN(+operandValues[0]) || typeof subOperandValues[0] !== "number") return false;
      return +operandValues[0] >= subOperandValues[0];
    case "LT":
      if (Number.isNaN(+operandValues[0]) || typeof subOperandValues[0] !== "number") return false;
      return +operandValues[0] <= subOperandValues[0];
    case "ALL":
      return (
        Array.isArray(operandValues) &&
        Array.isArray(operandValues[0]) &&
        operandValues[0].every((value) => value === subOperandValues[0])
      );
    case "ASSIGN":
      return operandValues[0];
    case "MULTIPLY":
      const a = operandValues.reduce((prev: number, current) => {
        if (Array.isArray(current)) return prev;
        return prev * +current;
      }, 1);
      const sub = subOperandValues.length > 0 && +subOperandValues[0];
      if (typeof sub !== "number") {
        return a;
      } else {
        return a * +sub;
      }
    case "AVERAGE":
      const sum = operandValues.reduce((prev: number, current) => {
        const v = +current;
        if (Number.isNaN(v) || Array.isArray(current)) return prev;
        return prev + v;
      }, 0);
      return Math.floor((sum / operandValues.length) * 1000) / 1000;
    case "SUM":
      const sum_ = operandValues.reduce((prev: number, current) => {
        const v = +current;
        if (Number.isNaN(v) || Array.isArray(current)) return prev;
        return prev + v;
      }, 0);
      return Math.floor(sum_ * 100) / 100;
    case "DEVIDE":
      const a_ = operandValues.reduce((prev: number, current) => {
        if (Array.isArray(current)) return prev;
        return prev * +current;
      }, 1);
      const sub_ = subOperandValues.length > 0 && +subOperandValues[0];
      if (typeof sub_ !== "number" && +sub_ !== 0) {
        return a_;
      } else {
        return Math.floor((a_ / +sub_) * 1000) / 1000;
      }
    case "PERCENT":
      const o = operandValues.reduce((prev: number, current) => {
        if (Array.isArray(current)) return prev;
        return prev * +current;
      }, 1);
      const per = subOperandValues[0];
      if (!per) return o;
      return Math.floor(((o * +per) / 100) * 1000) / 1000;
    case "CLASSIFY":
      /*
        subOperands: [[0,10,"E"],[11,20,"D"],...[41,50,"A"]]
        のような形を想定
      */
      if (!subOperandValues.every((_) => Array.isArray(_) && _.length >= 3)) return "";
      const value = +operandValues[0];
      if (Number.isNaN(value)) return "";
      const matched = (subOperandValues as EvaluationValue[][]).find((_) => value >= +_[0] && value <= +_[1]);
      return matched?.[2] ?? "";
    default:
      return false;
  }
};
export default calculate;
