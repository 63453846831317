import { useMemo } from "react";
import "../css/style.scss";
import classNames from "classnames";

type RatioBarProps = {
  width: number | string;

  steps: {
    value: number;
    valuePrefix?: string;
    valueSuffix?: string;
    backgroundColor: string;
    color?: string;
    showValue?: boolean;
  }[];
  className?: string;
};

function RatioBar({ width, steps = [], className }: RatioBarProps) {
  const _steps = useMemo(() => {
    const total = steps.reduce((prev, current) => {
      return prev + current.value;
    }, 0);
    let values: number[] = [];
    const temporary = steps.map((step, i) => {
      const ratio = Math.round((step.value / total) * 100);
      values[i] = ratio;
      return {
        ratio,
        accumulatedRatio: values.slice(0, i).reduce((prev, current) => {
          return prev + current;
        }, 0),
        backgroundColor: step.backgroundColor,
        color: step.color || "#333",
        value: step.value,
        showValue: step.showValue !== false,
        valuePrefix: step.valuePrefix,
        valueSuffix: step.valueSuffix,
      };
    });
    const sum = values.reduce((a, b) => a + b, 0);
    if (sum > 0 && sum < 100) {
      // 合計が 100% にならない場合末尾で調整
      temporary[values.findLastIndex((v) => v > 0)].ratio += 100 - sum;
    }
    return temporary;
  }, [steps]);

  return (
    <div className={`Stats__gage${className ? ` ${className}` : ""}`}>
      <div className="Stats__gage-content">
        <div className="Stats__gage-line" style={{ width: typeof width === "number" ? `${width}px` : width }}>
          {_steps.map((s, si) => (
            <div
              key={`step${si}`}
              className="Stats__gage-line-bar"
              style={{
                width: `${s.ratio}%`,
                left: `${s.accumulatedRatio}%`,
                zIndex: 32 + _steps.length - si,
                backgroundColor: s.backgroundColor,
                color: s.color,
                paddingLeft: s.ratio > 0 ? "2px" : "0",
              }}
            >
              {s.showValue && s.value > 0 ? s.valuePrefix : ""}
              {s.showValue && s.value > 0 ? `${s.value}` : ""}
              {s.showValue && s.value > 0 ? s.valueSuffix : ""}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RatioBar;
