const data = {
  category: "accounting_section_from_m_section",
  table: "accounting_section_from_m_section",
  subFields: {
    section_code: {},
    accounting_section_code: {},
    accounting_section_name: {},
  },
};
export default data;
