import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { Button, Alert } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getEvaluationAnsweringProject,
  selectEvaluationState,
  setDebug,
  selectEvaluationAnswers,
  callHook,
  apply,
  updateAnswerItems,
} from "./evaluationSheetSlice";
import EvAnswersList from "./EvaluationAnswersList";
import { selectLayoutState } from "../layout/layoutSlice";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import EvLayoutRow from "./LayoutRow";

function App() {
  const { todoId = "todo_nakagawa" } = useParams();
  const dispatch = useAppDispatch();
  const { layout, debug, pattern, individualMetaData, evaluation, variables, system, todo, hookResults, closedReason } =
    useAppSelector(selectEvaluationState);
  const answers = useAppSelector(selectEvaluationAnswers);
  const { isTouchDevice } = useAppSelector(selectLayoutState);
  const [fitScreen, $fitScreen] = useState(false);
  const [isAnswerPreviewed, $isAnswerPreviewed] = useState(false);
  useEffect(() => {
    let cachedAnswers = localStorage.getItem(todoId) ? JSON.parse(localStorage.getItem(todoId) ?? "") : {};
    dispatch(
      getEvaluationAnsweringProject({
        todoId: "todo_nakagawa",
        answers: cachedAnswers,
      })
    );
  }, []);

  useEffect(() => {
    if (todo.id !== "empty") {
      localStorage.setItem(todo.id, JSON.stringify(answers));
    }
  }, [todo.id, answers]);

  const toggleContext = () => {
    if (!system || !layout || !pattern) return;
    dispatch(
      apply({
        system,
        todo,
        layoutUsage: layout,
        pattern,
        individualMetaData,
        variables: {
          ...variables,
          CONTEXT: variables.CONTEXT === "answering" ? "confirming" : "answering",
        },
        answers,
        useSeedTemplateDefault: false,
      })
    );
  };
  const togglePhase = () => {
    if (!system || !layout || !pattern) return;
    const currentPhaseIndex = system.phaseTemplates.findIndex((p) => p.key === todo.phase.key);
    const nextPhaseTemplate =
      currentPhaseIndex === system.phaseTemplates.length - 1
        ? system.phaseTemplates[0]
        : system.phaseTemplates[currentPhaseIndex + 1];
    dispatch(
      apply({
        system,
        todo: {
          ...todo,
          phase: { id: todo.phase.id, ...nextPhaseTemplate },
        },
        layoutUsage: layout,
        pattern,
        individualMetaData,
        variables,
        answers,
        useSeedTemplateDefault: false,
      })
    );
  };

  useEffect(() => {
    $fitScreen(isTouchDevice);
  }, [isTouchDevice]);

  if (!system) return <div />;

  return (
    <div className="Ev-container" style={{ overflow: "scroll" }}>
      <h1 className="Headline--page">{system.label}</h1>
      {!closedReason ? (
        <div className={classNames({ "Ev-unit-groups": true, "--fit-screen": fitScreen })}>
          <div className="--flex mb-4">
            <Button variant="outline-primary" onClick={toggleContext} className="mx-1">
              context 切り替え
            </Button>
            <Button variant="outline-primary" onClick={togglePhase} className="mx-1">
              phase 切り替え
            </Button>
            <Button variant="outline-primary" onClick={() => dispatch(setDebug(!debug))} className="mx-1">
              Debug 表示
            </Button>
          </div>
          <div className="my-3">
            <Button variant="outline-primary" onClick={() => $fitScreen(!fitScreen)} className="mx-1">
              {fitScreen ? "内容を基準に幅を設定" : "画面を基準に幅を設定"}
            </Button>
          </div>
          {evaluation?.layoutRows.map((row, ri) => {
            return <EvLayoutRow layoutRow={row} fitScreen={fitScreen} editorMode="off" key={`row${ri}`} />;
          })}
          <div className="mt-4">
            <Button
              variant="outline-primary"
              onClick={() => {
                if (!isAnswerPreviewed) {
                  dispatch(updateAnswerItems({}));
                }
                $isAnswerPreviewed(!isAnswerPreviewed);
              }}
              className="mx-1"
            >
              回答確認
            </Button>
          </div>
          {isAnswerPreviewed && <EvAnswersList className="mt-4" />}

          <div className="mt-4">
            {evaluation?.hooks
              .filter((hook) => {
                return hook.usingPhases.includes(todo.phase.key);
              })
              .map((hook, i) => {
                return (
                  <Button
                    variant="outline-primary"
                    key={`hook${i}`}
                    onClick={() => {
                      dispatch(
                        callHook({
                          endpoint: hook.endpoint,
                          payload: { answers },
                        })
                      );
                    }}
                    className="mx-1"
                  >
                    {hook.label}
                  </Button>
                );
              })}
          </div>
          {hookResults.length > 0 && (
            <div className="mt-4">
              {hookResults.map(({ type, message }, i) => {
                return (
                  <Alert variant={type} key={`hookResult${i}`}>
                    {message}
                  </Alert>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <div className="Ev-unit-groups d-block">
          <Alert variant="warning" className="mb-0">
            {closedReason}
          </Alert>
        </div>
      )}
    </div>
  );
}

export default App;
