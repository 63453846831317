import { useEffect, useState } from "react";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  EvaluationLayoutUsage,
  EvaluationPattern,
  EvaluationPhaseGroupTemplate,
  EvaluationPhaseTemplate,
  EvaluationProcedureStep,
} from "./EvaluationTypes";
import { Button, Form, Alert, Card, ListGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { apply, selectEvaluationState, setPatterns } from "./evaluationSheetSlice";
import { yieldId } from "../../app/util";

let timer = 0;

function EvaluationEditorPhase() {
  const dispatch = useAppDispatch();
  const { system, layout, patterns, pattern, todo, individualMetaData, variables } =
    useAppSelector(selectEvaluationState);
  const [phaseGroupTemplates, $phaseGroupTemplates] = useState<EvaluationPhaseGroupTemplate[]>([]);
  const [phaseTemplates, $phaseTemplates] = useState<EvaluationPhaseTemplate[]>([]);

  const addPhaseGroup = () => {
    // TODO
    if (!system || !layout || !pattern) return;
    dispatch(
      apply({
        system: {
          ...system,
          phaseGroupTemplates: [
            ...system.phaseGroupTemplates,
            {
              key: "phase_group_" + yieldId(),
              label: "新しいフェーズ",
            },
          ],
        },
        layoutUsage: layout,
        pattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: true,
      })
    );
  };

  const deletePhaseGroup = (group: EvaluationPhaseGroupTemplate) => {
    // TODO
  };

  const addStep = (group: EvaluationPhaseGroupTemplate) => {
    if (!system || !layout || !pattern) return;
    const currentGroupIndex = system.phaseGroupTemplates.findIndex((g) => g.key === group.key);
    const [beforeSteps, sameGroupSteps, afterSteps] = system.phaseTemplates.reduce(
      (prev, current) => {
        const groupIndex = system.phaseGroupTemplates.findIndex((g) => g.key === current.phaseGroupKey);
        if (groupIndex < currentGroupIndex) return [[...prev[0], { ...current }], prev[1], prev[2]];
        else if (groupIndex === currentGroupIndex) return [prev[0], [...prev[1], { ...current }], prev[2]];
        else return [prev[0], prev[1], [...prev[2], { ...current }]];
      },
      [[], [], []] as EvaluationPhaseTemplate[][]
    );
    const newPhaseId = "phase_key_" + yieldId();
    const nextPhaseTemplates = [
      ...beforeSteps,
      ...sameGroupSteps,
      {
        key: newPhaseId,
        label: "新しいステップ",
        phaseGroupKey: group.key,
      },
      ...afterSteps,
    ];
    // - デフォルトの behaviorMapTemplate では、新しいステップを指定できる
    // - デフォルトの behaviorDefinition

    dispatch(
      apply({
        system: {
          ...system,
          phaseTemplates: nextPhaseTemplates,
        },
        layoutUsage: (() => {
          const t = layout.behaviorMapTemplate;

          // ステップに対応しない標準の定義
          const layoutUsage = {
            ...layout,
            behaviorMapTemplate: {
              DEFAULT_BEHAVIOR: {
                label: t.DEFAULT_BEHAVIOR.label,
                rules: nextPhaseTemplates.reduce((prev, current) => {
                  return { ...prev, [current.key]: "default" };
                }, {}),
              },
              hidden: {
                label: t.hidden.label,
                rules: nextPhaseTemplates.reduce((prev, current) => {
                  return { ...prev, [current.key]: "default" };
                }, {}),
              },
            },
          } as EvaluationLayoutUsage;

          // ステップに対応する標準の定義
          nextPhaseTemplates.forEach((phase) => {
            const thisPhaseIndex = nextPhaseTemplates.findIndex((p) => p.key === phase.key);
            layoutUsage.behaviorMapTemplate[`${phase.key}_input`] = {
              label: `${phase.label}で使うセル`,
              rules: nextPhaseTemplates.reduce((prev, current, i) => {
                return {
                  ...prev,
                  [current.key]: i < thisPhaseIndex ? "veiled" : i === thisPhaseIndex ? "default" : "locked",
                };
              }, {}),
            };
          });
          // 独自に設定した定義
          const _defaults = Object.keys(layoutUsage.behaviorMapTemplate);
          Object.keys(t).forEach((key) => {
            if (_defaults.includes(key)) return;
            layoutUsage.behaviorMapTemplate[key] = {
              label: t[key].label,
              rules: {
                ...t[key].rules,
                [newPhaseId]: "veiled",
              },
            };
          });
          return layoutUsage;
        })(),
        pattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: true,
      })
    );
  };

  const deleteStep = (phase: EvaluationPhaseTemplate) => {
    // TODO behaviorMapTemplate, UnitTemplateSeed を更新
    if (!system || !layout || !pattern) return;
    const concerningFields = ["headerList"];
    const modifyArgv = (argv: { [field: string]: any }) => {
      return Object.keys(argv).reduce((prev: any, field: string) => {
        return {
          ...prev,
          [field]: !concerningFields.includes(field)
            ? argv[field]
            : argv[field].map((d: any) => {
                return {
                  ...d,
                  relatedPhase: d.relatedPhase === phase.key ? "" : d.relatedPhase,
                };
              }),
        };
      }, {} as { [field: string]: any });
    };

    // =====
    const nextPhaseTemplates = system.phaseTemplates.filter((p) => p.key !== phase.key);
    const nextSeedTemplates = system.seedTemplates.map((seed) => {
      return {
        ...seed,
        procedure: seed.procedure.map((_) => {
          return {
            ..._,
            argv: modifyArgv(_.argv),
          };
        }),
      };
    });

    const modifySeedData = (seedData: { [templateId: string]: any }) => {
      const nextSeedData = Object.keys(seedData).reduce((prev, seedId) => {
        const seed = seedData[seedId];
        return {
          ...prev,
          [seedId]: Object.keys(seed).reduce((prev: any, subModuleId: string) => {
            return {
              ...prev,
              [subModuleId]: modifyArgv(seed[subModuleId]),
            };
          }, {} as { [subModuleId: string]: any }),
        };
      }, {} as { [templateId: string]: any });
      return nextSeedData;
    };

    // 現在選択していないパターンの seedData も更新する
    const nextPatterns = patterns.map((p) => {
      return { ...p, seedData: modifySeedData(p.seedData) };
    });
    dispatch(setPatterns(nextPatterns));
    dispatch(
      apply({
        system: {
          ...system,
          phaseTemplates: nextPhaseTemplates,
          seedTemplates: nextSeedTemplates,
        },
        layoutUsage: (() => {
          const t = layout.behaviorMapTemplate;
          const layoutUsage = {
            ...layout,
            behaviorMapTemplate: Object.keys(t).reduce((prev, current) => {
              if (current === `${phase.key}_input`) return prev;
              else return { ...prev, [current]: t[current] };
            }, {}),
          } as EvaluationLayoutUsage;
          return layoutUsage;
        })(),
        pattern: { ...pattern, seedData: modifySeedData(pattern.seedData) },
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: true,
      })
    );
  };

  const reapply = (field: string, updated: any) => {
    if (!system) return;
    let nextPhaseGroupTemplates = [...phaseGroupTemplates];
    let nextPhaseTemplates = [...phaseTemplates];
    if (field === "phaseGroup") {
      nextPhaseGroupTemplates = updated;
      $phaseGroupTemplates(updated);
    } else if (field === "phase") {
      nextPhaseTemplates = updated;
      $phaseTemplates(updated);
    }
    if (timer > 0) {
      clearTimeout(timer);
    }
    timer = +setTimeout(() => {
      if (!pattern || !layout) return;
      dispatch(
        apply({
          system: {
            ...system,
            phaseGroupTemplates: nextPhaseGroupTemplates,
            phaseTemplates: nextPhaseTemplates,
          },
          layoutUsage: layout,
          pattern,
          todo,
          individualMetaData,
          variables,
          answers: {},
          useSeedTemplateDefault: false,
        })
      );
    }, 500);
  };

  useEffect(() => {
    if (system) {
      $phaseGroupTemplates(system.phaseGroupTemplates);
      $phaseTemplates(system.phaseTemplates);
    }
  }, [system]);
  if (!system) return <div></div>;

  return (
    <section>
      <div>
        {phaseGroupTemplates.map((group, i) => {
          const matchedPhases = phaseTemplates.filter((phase) => phase.phaseGroupKey === group.key);
          return (
            <Card key={`group_${i}`} className="mb-4">
              <Card.Header>
                <div className="--flex --align-items-center">
                  <div className="--shrink-0">{i + 1}&nbsp;:&nbsp;</div>
                  <Form.Control
                    type="string"
                    defaultValue={group.label}
                    onChange={(e) => {
                      if (!e.target.value) return;
                      reapply(
                        "phaseGroup",
                        phaseGroupTemplates.map((g) => (g.key === group.key ? { ...g, label: e.target.value } : g))
                      );
                    }}
                  />
                  <Button variant="outline-danger" className="mx-2 --shrink-0" onClick={() => deletePhaseGroup(group)}>
                    削除
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                {matchedPhases.length > 0 ? (
                  <ListGroup>
                    {matchedPhases.map((phase, j) => {
                      return (
                        <ListGroup.Item key={`phase${j}`}>
                          <div className="--flex --align-items-center">
                            <Form.Control
                              type="string"
                              defaultValue={phase.label}
                              onChange={(e) => {
                                if (!e.target.value) return;
                                reapply(
                                  "phase",
                                  phaseTemplates.map((p) => (p.key === phase.key ? { ...p, label: e.target.value } : p))
                                );
                              }}
                            />
                            <Button
                              variant="outline-danger"
                              className="mx-2 --shrink-0"
                              onClick={() => deleteStep(phase)}
                            >
                              削除
                            </Button>
                          </div>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                ) : (
                  <Alert variant="warning">評価ステップが登録されていません</Alert>
                )}
                <Button onClick={() => addStep(group)} variant="outline-primary" className="mt-2">
                  ステップを追加
                </Button>
              </Card.Body>
            </Card>
          );
        })}
      </div>
      <div className="mt-4">
        <Button onClick={addPhaseGroup} variant="outline-primary">
          フェーズを追加
        </Button>
      </div>
    </section>
  );
}

export default EvaluationEditorPhase;
