import { useState, useMemo, useEffect } from "react";
import { Row, Col, Accordion, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { fieldViewData } from "../../features/profile/profileValues";
import { useAppDispatch } from "../../app/store";
import { getSectors, initSectorStatus } from "../client/clientSlice";

function FieldListSelector({
  fields,
  onFieldSelected,
  onSubFieldsTriggerClick,
  useSubFieldsTrigger = true,
  showSubFieldsOnly = false,
  alwaysShowTrigger = false,
  summary,
  onSelectAllSubFields,
  useSelectAllSubFields = false,
}: {
  fields: fieldViewData[];
  onFieldSelected: (id: string, parentFieldId?: string) => any;
  onSubFieldsTriggerClick?: (id: string, isExpanded: boolean) => any;
  useSubFieldsTrigger?: boolean;
  showSubFieldsOnly?: boolean;
  alwaysShowTrigger?: boolean;
  summary?: string;
  onSelectAllSubFields?: (fieldId: string, isSelectAll: boolean) => any;
  useSelectAllSubFields?: boolean;
}) {
  const selectField = async (id: string, parentFieldId?: string) => {
    onFieldSelected(id, parentFieldId);
  };
  const [state, $state] = useState({
    expandedFields: [] as string[],
    selectAllSubFields: {} as { [key: string]: boolean },
  });
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(initSectorStatus());
    dispatch(getSectors());
  }, [dispatch]);

  const checkedFields = fields.filter((_) => _.checked);

  useEffect(() => {
    const selectAllSubFields = Object.fromEntries(
      fields.map((f) => [f.id, f.subFields.every((subField) => subField.checked)])
    );
    $state({ ...state, selectAllSubFields });
  }, [fields]);

  // １画面で複数このコンポーネントを使ったときのため
  const componentNumber = useMemo(() => Math.ceil(Math.random() * 10000000), []);
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          {(() => {
            if (summary) return summary;
            else return checkedFields.length > 0 ? checkedFields.map((_) => _.label).join(", ") : "（未選択）";
          })()}
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            {fields.map((f, i) => (
              <Col md="6" key={`${f.id}_${i}`}>
                {!showSubFieldsOnly ? (
                  <Form.Check
                    key={`${f.id}_${componentNumber}`}
                    checked={f.checked}
                    id={`${f.id}_${componentNumber}`}
                    type="checkbox"
                    label={f.label}
                    onChange={() => {
                      // switchをオフにする 別テーブルの同名項目に反映されるのを防ぐ
                      $state({
                        ...state,
                        expandedFields: state.expandedFields.filter((_) => _ !== f.id),
                      });
                      selectField(f.id);
                    }}
                    inline
                  />
                ) : (
                  <div className="--bold mb-2">{f.label}</div>
                )}
                {useSubFieldsTrigger && (f.checked || alwaysShowTrigger) && (
                  <Form.Check
                    type="switch"
                    id={`custom-switch_${f.id}_${componentNumber}`}
                    label={"項目名表示"}
                    onChange={() => {
                      $state({
                        ...state,
                        expandedFields: state.expandedFields.includes(f.id)
                          ? state.expandedFields.filter((_) => _ !== f.id)
                          : [...state.expandedFields, f.id],
                      });
                      onSubFieldsTriggerClick && onSubFieldsTriggerClick(f.id, !state.expandedFields.includes(f.id));
                    }}
                    checked={state.expandedFields.includes(f.id)}
                    className="text-secondary"
                    inline
                  />
                )}
                {useSubFieldsTrigger && (f.checked || alwaysShowTrigger) && state.expandedFields.includes(f.id) && (
                  <>
                    {useSelectAllSubFields && (
                      <Form.Check
                        key={`${f.id}_${componentNumber}_select_all`}
                        checked={state.selectAllSubFields[f.id]}
                        id={`${f.id}_${componentNumber}_select_all`}
                        className="mx-4 mb-2 --bold"
                        type="checkbox"
                        label="全項目"
                        onChange={() => {
                          $state({
                            ...state,
                            selectAllSubFields: {
                              ...state.selectAllSubFields,
                              [f.id]: !state.selectAllSubFields[f.id],
                            },
                          });
                          onSelectAllSubFields && onSelectAllSubFields(f.id, !state.selectAllSubFields[f.id]);
                        }}
                      />
                    )}
                    {f.subFields.map((sub) => (
                      <Form.Check
                        key={`${f.id}_${componentNumber}_${sub.id}`}
                        checked={sub.checked}
                        id={`${f.id}_${componentNumber}_${sub.id}`}
                        className="mx-4"
                        type="checkbox"
                        label={sub.label}
                        onChange={() => selectField(sub.id, f.id)}
                      />
                    ))}
                  </>
                )}
              </Col>
            ))}
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FieldListSelector;
