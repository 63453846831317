import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { download } from "../../app/download";
import { RootState } from "../../app/store";
import { call, callCollaborator } from "../../app/api";
import {
  RoleBehavior,
  SavedRoleApiCondition,
  RoleApiResource,
  Role,
  Permission,
  apiSummaries,
  CRUDMethod,
  APISummary,
  readymadeRoleDataList,
  PERMISSION_LIST_UPTO,
  SearchCondition,
  ServiceContract,
  AssessRole,
  serviceLabels,
  uneditablePolicies,
} from "./permissionValues";
import { isStringArraySame } from "../../app/util";
import dayjs from "dayjs";

const SLICE_NAME = "permission";

interface PermissionState {
  policyDocId: string;
  roleBehaviors: RoleBehavior[];
  roles: Role[];
  assessRoles: AssessRole[];
  selectedRoleId: string;
  permissions: Permission[];
  apiSummaries: APISummary[];
  permissionTotalCount: number;
  permissionHasMore: boolean;
  fetchedPermissionPage: number;
  serviceContracts: ServiceContract[];
  serviceValidation: {
    valid: boolean;
    message: string;
  };
}

const initialState: PermissionState = {
  policyDocId: "",
  roleBehaviors: [],
  roles: [],
  assessRoles: [],
  selectedRoleId: "",
  permissions: [],
  apiSummaries: [],
  permissionTotalCount: 0,
  permissionHasMore: false,
  fetchedPermissionPage: 0,
  serviceContracts: [],
  serviceValidation: {
    valid: true,
    message: "",
  },
};

/*
  @getRoles
*/
export const getRoles = createAsyncThunk(SLICE_NAME + "/getRoles", async () => {
  const res = await call("get", "template_manager/role", { doReload: false })();
  const originalRoles: Role[] = res.data.result.map((r: any) => {
    return {
      id: r.role_name,
      label: r.label,
      description: r.description,
      includeFuturePermission: r.include_future_permission || false,
      isReadymadeRole: r.id ? false : true,
      canEdit: r.role_name === "admin" ? false : true,
      canDelete: r.role_name === "admin" ? false : true,
      order: r.order,
    } as Role;
  });
  const roles = readymadeRoleDataList.concat(originalRoles);
  return roles.sort((a, b) => a.order - b.order) as Role[];
});

/*
  @getAssessRoles
*/
export const getAssessRoles = createAsyncThunk(
  SLICE_NAME + "/getAssessRoles",
  async (options?: { accountId?: number; serviceId?: number }) => {
    const _options: { [key: string]: any } = {
      sort_by: ["company_code"],
    };
    if (options) {
      if (options.accountId) _options.account_id = options.accountId;
      if (options.serviceId) _options.service_id = options.serviceId;
    }
    const res = await call("get", "account_admin_manager/role")(_options);
    if (res.data.status !== 200) return [] as AssessRole[];
    const roles = res.data.result.map((r: any) => ({
      accountId: r.account_id,
      companyId: r.company_id,
      currentCompanyCode: r["company_code"],
      currentCompanyName: r["company_name"],
      id: r.id,
      loginCode: r.login_code,
      mainCompanyName: r["main_company_name"],
      mainCompanyCode: r["main_company_code"],
      name: r.name,
      roleName: r.role_name,
      serviceId: r.service_id,
    }));
    return roles as AssessRole[];
  }
);

/*
  @postAssessRoles
*/
export const postAssessRoles = createAsyncThunk(
  SLICE_NAME + "/postAssessRoles",
  async ({ assessRoles }: { assessRoles: AssessRole[] }): Promise<AssessRole[]> => {
    const results = await Promise.all(
      assessRoles.map(async (role) => {
        const res = await call(
          "post",
          "account_admin_manager/role"
        )({
          account_id: role.accountId,
          service_id: role.serviceId,
          role_name: role.roleName,
        });
        const data = res.data.result[0];
        return {
          accountId: data.account_id,
          companyId: data.company_id,
          currentCompanyCode: data["company_code"],
          currentCompanyName: data["company_name"],
          id: data.id,
          loginCode: data.login_code,
          mainCompanyCode: data["main_company_code"],
          mainCompanyName: data["main_company_name"],
          name: data.name,
          roleName: data.role_name,
          serviceId: data.service_id,
        } as AssessRole;
      })
    );
    return results;
  }
);

/*
  @deleteAssessRoles
*/
export const deleteAssessRoles = createAsyncThunk(
  SLICE_NAME + "/deleteAssessRoles",
  async ({ assessRoles }: { assessRoles: AssessRole[] }): Promise<number[]> => {
    const result = await Promise.all(
      assessRoles.map(async (role) => {
        await call(
          "delete",
          "account_admin_manager/role"
        )({
          id: role.id,
        });
        return role.id;
      })
    );
    return result;
  }
);

/*
  @getPermissions
*/
export const getPermissions = createAsyncThunk(
  SLICE_NAME + "/getPermissions",
  async ({ conditions, page }: { conditions?: SearchCondition; page?: number }) => {
    const params = {} as SearchCondition;
    if (conditions && conditions["keyword"])
      params.or = [{ name__contain: conditions.keyword, login_code__contain: conditions.keyword }];
    if (conditions && conditions["checkRoles"]) {
      params.roles__contain = conditions["checkRoles"];
    }
    if (page) {
      params.limit = PERMISSION_LIST_UPTO;
      params.page = page;
    }
    const res = await call("get", "access_manager/profile_role_view")(params);
    const permissionTotalCount = res.data.count;
    const permissionHasMore = res.data.has_more;
    const permissionData = res.data.result as Permission[];
    const fetchedPermissionPage = page ?? 1;
    return { permissionData, permissionTotalCount, permissionHasMore, fetchedPermissionPage };
  }
);

/*
  @downloadPermissions
*/
export const downloadPermissions = createAsyncThunk(SLICE_NAME + "/downloadPermissions", async () => {
  const res = await call(
    "get",
    `access_manager/permission`
  )({
    format: "xlsx",
  });
  const { file, file_name } = res.data.result;
  download({
    file,
    fileName: file_name,
    mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
});

/*
  @uploadPermissions
*/
export const uploadPermissions = createAsyncThunk(
  SLICE_NAME + "/uploadPermissions",
  async ({ file, name }: { file: string; name: string }) => {
    const next_process = {
      externalEffect: "_getPermissions",
      externalEffectUsed: false,
      payload: {},
    };
    const res = await call(
      "post",
      `access_manager/permission_uploader`
    )({
      file,
      name,
      next_process,
    });
    if (res.data.status >= 400) throw new Error("failed in uploading file");
  }
);

export const getApiSummaries = createAsyncThunk(SLICE_NAME + "/getApiSummaries", async () => {
  const res = await call("get", "profile_manager/sector", { doReload: false })();
  // user_data, master_data の resource を補完
  const { user, master } = res.data.result.reduce(
    (
      prev: {
        user: RoleApiResource[];
        master: RoleApiResource[];
      },
      current: any
    ) => {
      if (current.sector_id.indexOf("profile_u_") === 0) {
        return {
          ...prev,
          user: [
            ...prev.user,
            {
              id: current.sector_id.replace("profile_u_", ""),
              isView: current.is_view,
              isSummary: current.is_summary,
              label: current.logical_name,
            } as RoleApiResource,
          ],
        };
      } else if (current.sector_id.indexOf("profile_m_") === 0) {
        return {
          ...prev,
          master: [
            ...prev.master,
            {
              id: current.sector_id.replace("profile_m_", ""),
              isView: current.is_view,
              isSummary: current.is_summary,
              label: current.logical_name,
            } as RoleApiResource,
          ],
        };
      } else {
        return prev;
      }
    },
    {
      user: [],
      master: [],
    }
  );
  return apiSummaries.map((summary) => {
    if (summary.id === "master_data_manager") return { ...summary, resource: master };
    if (summary.id === "user_data_manager") return { ...summary, resource: user };
    return { ...summary, resource: [{ id: "all", label: "全て", isView: false, isSummary: false }] };
  });
});

/*
  @downloadPermittedAccount
*/
export const downloadPermittedAccount = createAsyncThunk(
  SLICE_NAME + "/downloadPermittedAccount",
  async ({ baseDate, target }: { baseDate: dayjs.Dayjs; target: string }) => {
    const res = await call(
      "get",
      `access_manager/permitted_account_download`
    )({
      target: target,
      base_date: baseDate.format("YYYY-MM-DD"),
    });
    const { file, file_name } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }
);

/*
  @getRoleBehaviors
*/
export const getRoleBehaviors = createAsyncThunk(
  SLICE_NAME + "/getRoleBehaviors",
  async ({ apiSummaries }: { apiSummaries: APISummary[] }) => {
    if (!apiSummaries) {
      return {
        id: "",
        roleBehaviors: [],
      };
    }
    const [policyData, roleData] = await Promise.all([
      new Promise<{ [key: string]: any }>(async (resolve, reject) => {
        try {
          const res = await call("get", "template_manager/policy")();
          resolve(res.data.result[0]);
        } catch (e) {
          reject(e);
        }
      }),
      new Promise<Role[]>(async (resolve, reject) => {
        try {
          const res = await call("get", "template_manager/role", { doReload: false })();
          const roleData = [
            ...res.data.result.map((r: any) => {
              return {
                id: r.id as string,
                label: r.label as string,
                roleName: r.role_name as string,
                description: r.description as string,
                includeFuturePermission: r.include_future_permission || false,
                fixed: false,
                isReadymadeRole: false,
                canEdit: true,
                canDelete: true,
                order: r.order as number,
              } as Role;
            }),
            ...readymadeRoleDataList,
          ];
          resolve(roleData);
        } catch (e) {
          reject(e);
        }
      }),
    ]);
    /*
      作成したばかりの企業には profile::policy ドキュメントがないので
      ここで作成する。
    */
    if (!policyData.id) {
      // adminとuserのPOSTは許されておらず、エラーになるためpolicyDataではなく空データを送信する
      const res = await call("post", "template_manager/policy")({});
      policyData.id = res.data.result[0].id;
    }
    const result = Object.keys(policyData).reduce(
      (prev, current) => {
        if (current === "id") {
          return { ...prev, id: policyData[current] };
        } else if (!Array.isArray(policyData[current])) {
          return prev;
        } else {
          const correspondingRoleData =
            roleData.find((_) => _.roleName === current) ?? readymadeRoleDataList.find((_) => _.roleName === current);
          const roleBehavior = {
            isReadymadeRole: readymadeRoleDataList.some((_) => _.roleName === current),
            roleName: current,
            roleDocId: correspondingRoleData?.id ?? "",
            label: correspondingRoleData ? correspondingRoleData.label : current,
            description: correspondingRoleData ? correspondingRoleData.description : current,
            includeFuturePermission: correspondingRoleData ? correspondingRoleData.includeFuturePermission : current,
            content: [],
            order: correspondingRoleData?.order ?? 0,
            canEdit: correspondingRoleData?.canEdit ?? true,
            canDelete: correspondingRoleData?.canDelete ?? true,
          } as RoleBehavior;
          type ApiTemporaryData = {
            id: string;
            label: string;
            description?: string;
            conditionRawData: {
              target: number;
              resource: RoleApiResource[];
              methods: string[];
            }[];
          };
          const content = policyData[current]
            .map((_: { api: string; target?: number; resource?: string[]; methods: CRUDMethod[] }) => {
              const apiSummary = apiSummaries.find((a) => a.id === _.api);
              return {
                id: _.api,
                label: apiSummary?.label ?? "",
                conditionRawData: [
                  {
                    target: _.target ?? -1,
                    resource:
                      apiSummary?.resource.filter((r) => _.resource?.some((resourceName) => r.id === resourceName)) ??
                      [],
                    methods: _.methods,
                  },
                ],
              };
            })
            .reduce((prev: ApiTemporaryData[], current: ApiTemporaryData) => {
              // 各API１つずつにまとめる
              const sameApiBehavior = prev.find((_) => _.id === current.id);
              if (sameApiBehavior) {
                sameApiBehavior.conditionRawData = [...sameApiBehavior.conditionRawData, current.conditionRawData[0]];
              }
              return sameApiBehavior ? prev : [...prev, current];
            }, [] as ApiTemporaryData[])
            .map((temporaryData: ApiTemporaryData) => {
              const defaultResource = apiSummaries.find((a) => a.id === temporaryData.id)?.resource ?? [];
              const conditions = [];
              if (temporaryData.conditionRawData.length === 1) {
                // 全ての resource で共通の methods, target の値を適用
                conditions.push(
                  ...defaultResource.map((resource) => {
                    const conditionRawData = temporaryData.conditionRawData.find((_) =>
                      _.resource.map((r) => r.label).includes(resource.label)
                    );
                    if (temporaryData.conditionRawData[0].resource.length > 0 && !conditionRawData)
                      // 詳細設定の項目がある権限で可能な操作のチェックが全て空である（データベース上にない）項目の場合は、共通のmethods, targetの値とならないようにする
                      return {
                        resource,
                        target: "-5",
                        methods: [],
                      };
                    return {
                      resource,
                      target: temporaryData.conditionRawData[0].target,
                      methods: temporaryData.conditionRawData[0].methods as CRUDMethod[],
                    };
                  })
                );
              } else {
                // 項目ごとに methods, target の値が異なる
                conditions.push(
                  ...defaultResource
                    .map((resource) => {
                      const conditionRawData = temporaryData.conditionRawData.find((_) =>
                        _.resource.map((r) => r.label).includes(resource.label)
                      );
                      if (!conditionRawData)
                        // 可能な操作のチェックが全て空である（データベース上にない）項目の場合は、空である状態をreturnして項目を表示させるようにする
                        return {
                          resource,
                          target: "-5",
                          methods: [] as CRUDMethod[],
                        };
                      return {
                        resource,
                        target: conditionRawData.target,
                        methods: conditionRawData.methods as CRUDMethod[],
                      };
                    })
                    .filter((_) => _)
                );
              }
              return {
                id: temporaryData.id,
                label: temporaryData.label,
                conditions,
              };
            });
          /*
            このロールに API グループ単位で設定されていない場合、
            以下で補完するようにする
            */
          apiSummaries.forEach((summary) => {
            if (content.some((c: any) => c.id === summary.id)) {
              return;
            }
            content.push({
              id: summary.id,
              label: summary.label,
              conditions: summary.resource.map((r) => ({
                resource: r,
                target: "-5",
                methods: [],
              })),
            });
          });
          roleBehavior.content = content.sort((a: any, b: any) => {
            return apiSummaries.findIndex((s) => s.id === a.id) - apiSummaries.findIndex((s) => s.id === b.id);
          });
          return {
            ...prev,
            roleBehaviors: [...prev.roleBehaviors, roleBehavior],
          };
        }
      },
      { id: "", roleBehaviors: [] } as {
        id: string;
        roleBehaviors: RoleBehavior[];
      }
    );
    result.roleBehaviors.sort((a, b) => a.order - b.order);
    return result;
  }
);

/*
  @createEmptyRoleBehavior
*/
export const createEmptyRoleBehavior = createAsyncThunk(
  SLICE_NAME + "/createEmptyRoleBehavior",
  async ({ id = "__new", apiSummaries }: { id?: string; apiSummaries: APISummary[] }): Promise<RoleBehavior> => {
    if (!apiSummaries) {
      return {
        isReadymadeRole: false,
        roleDocId: id,
        roleName: "",
        label: "",
        description: "",
        includeFuturePermission: false,
        content: [],
        order: 0,
        canEdit: true,
        canDelete: true,
      };
    }
    const content = apiSummaries.map((summary) => {
      // 常にONであるべきメソッド
      const uneditableMethods = uneditablePolicies.find((_) => _.id === summary.id)?.methods ?? [];
      return {
        id: summary.id,
        label: summary.label,
        conditions: summary.resource.map((r) => {
          return {
            target: "-5",
            resource: r,
            methods: (() => {
              let methods = [] as CRUDMethod[];
              if (uneditableMethods.includes("GET")) methods = [...methods, "GET"];
              if (uneditableMethods.includes("EDIT")) methods = [...methods, "PUT", "POST", "DELETE"];
              return methods;
            })(),
          };
        }),
      };
    });
    return {
      isReadymadeRole: false,
      roleDocId: id,
      roleName: "",
      label: "",
      description: "",
      includeFuturePermission: false,
      content,
      order: 0,
      canEdit: true,
      canDelete: true,
    };
  }
);

/*
  @commitRoleBehaviors
*/
export const commitRoleBehaviors = createAsyncThunk(
  SLICE_NAME + "/commitRoleBehaviors",
  async ({
    policyDocId,
    roleDocId,
    roleBehaviors,
  }: {
    policyDocId: string;
    roleDocId: string;
    roleBehaviors: RoleBehavior[];
  }) => {
    if (!policyDocId || !roleDocId || !Array.isArray(roleBehaviors)) return;
    const metaData = roleBehaviors
      .filter((role) => role.roleDocId === roleDocId)
      .map((role) => {
        return {
          id: role.roleDocId,
          roleName: role.roleName,
          label: role.label,
          description: role.description,
          includeFuturePermission: role.includeFuturePermission,
        };
      });
    const data = roleBehaviors
      .filter((role) => role.canEdit)
      .map((role) => {
        const conditions = [] as SavedRoleApiCondition[];
        role.content.forEach((_) => {
          const api = _.id;
          _.conditions.forEach(({ methods, resource, target }) => {
            if (methods.length === 0) {
              return;
            }
            const correspondingCondition = conditions.find(
              (c) => c.api === api && c.target === target && isStringArraySame(c.methods, methods)
            );
            if (!correspondingCondition) {
              conditions.push({
                api,
                target,
                resource: [resource.id],
                methods,
              });
            } else {
              correspondingCondition.resource?.push(resource.id);
            }
          });
        });

        return {
          id: role.roleDocId,
          roleName: role.roleName,
          content: conditions.map((c) => {
            const apiSummary = apiSummaries.find((a) => a.id === c.api)!;
            const resource = !isStringArraySame(
              apiSummary.resource.map((_) => _.id),
              c.resource ?? []
            )
              ? c.resource
              : undefined;
            const toSave = { api: c.api, methods: c.methods } as SavedRoleApiCondition;
            if (resource) toSave.resource = resource;
            if (c.target !== undefined && !["-1", "-5"].includes(String(c.target))) toSave.target = String(c.target);
            return toSave;
          }),
        };
      });
    const commitData = data.reduce((prev, current) => {
      const value = { ...current } as {
        id?: string;
        content: SavedRoleApiCondition[];
      };
      delete value.id;
      prev[current.roleName] = value.content.map((c) => {
        const _c = {
          api: c.api,
          methods: c.methods,
        } as SavedRoleApiCondition;
        if (c.resource?.some((_) => _ !== "all")) _c.resource = c.resource;
        if (c.target !== undefined && !["-1", "-5"].includes(String(c.target))) _c.target = String(c.target);
        return _c;
      });
      return prev;
    }, {} as { [key: string]: any });
    await Promise.all([
      ...metaData
        .filter((_) => _.roleName !== "user")
        .map(({ id, roleName, label, description, includeFuturePermission }) => {
          return id !== "__new"
            ? call(
                "put",
                "template_manager/role"
              )({
                id,
                label,
                description,
                include_future_permission: includeFuturePermission,
              })
            : call(
                "post",
                "template_manager/role"
              )({
                role_name: roleName,
                label,
                description,
              });
        }),
      call(
        "put",
        "template_manager/policy"
      )({
        id: policyDocId,
        ...commitData,
      }),
    ]);
    return;
  }
);

/*
  @deleteRoleBehaviors
*/
export const deleteRoleBehaviors = createAsyncThunk(
  SLICE_NAME + "/deleteRoleBehaviors",
  async ({ roleDocId }: { roleDocId: string }) => {
    if (!roleDocId) return;
    await call(
      "delete",
      "template_manager/role"
    )({
      id: roleDocId,
    });
  }
);

export const putRolePriority = createAsyncThunk(
  SLICE_NAME + "/putRolePriority",
  async ({ roleDocId, order }: { roleDocId: string; order: number }) => {
    await call(
      "put",
      "template_manager/role"
    )({
      id: roleDocId,
      order,
    });
    return {
      id: roleDocId,
      order,
    };
  }
);

export const getServiceContracts = createAsyncThunk(
  SLICE_NAME + "/getServiceContracts",
  async ({ companyId, isActive = true }: { companyId: number; isActive?: boolean }) => {
    const res = await call(
      "get",
      "company_manager/my_service_contract"
    )({
      company_id: companyId,
      is_active: isActive,
      sort_by: "service.order",
    });
    const serviceContracts = res.data.result.map((data: any) => {
      return {
        id: data["service_contract.id"],
        companyId: data.company_id,
        isActive: data.is_active,
        serviceName: data["service.name"],
        serviceOrder: data["service.order"],
        serviceType: data["service.type"],
        serviceId: data.service_id,
        startDate: data.start_date,
      } as ServiceContract;
    });
    return serviceContracts;
  }
);

/*
  @validateAssessRoles
*/
export const validateAssessRoles = createAsyncThunk(
  SLICE_NAME + "/validateAssessRoles",
  async ({
    assessRoles,
    serviceContracts,
    companyId,
    accountId,
  }: {
    assessRoles: AssessRole[];
    serviceContracts: ServiceContract[];
    companyId: number;
    accountId: number;
  }): Promise<{ valid: boolean; message: string }> => {
    const res = await call(
      "get",
      "account_admin_manager/role"
    )({
      company_id: companyId,
      role_name: "admin",
    });
    if (res.data.status !== 200) {
      return { valid: false, message: "管理者のロールを取得するときにエラーが発生しました。" };
    }
    const roles = res.data.result;
    const adminsForServices = (() => {
      const adminsForServices = new Map();
      serviceContracts.forEach((s) => {
        if (["evaluation", "profile"].includes(s.serviceName)) {
          // 無視するサービス
          return;
        }
        adminsForServices.set(
          s.serviceId,
          roles.filter((r: any) => r.service_id === s.serviceId).map((r: any) => r.account_id)
        );
      });
      return adminsForServices;
    })();

    // アカウントに 1 つもロールがない状態になるような操作はできない
    if (assessRoles.length === 0) {
      return {
        valid: false,
        message: "アカウントには少なくとも1つのロールを設定してください。",
      };
    }

    // 今回の操作により、全社でそのサービスの admin 権限を持つユーザーがいなくなる場合は削除できない
    let errorServiceName = "";
    const isDeletingLastAdminRole = serviceContracts.some((c) => {
      const thisServiceAdmins = adminsForServices.get(c.serviceId);
      if (!thisServiceAdmins) {
        // サービス自体を無視する（人事評価、プロファイル）
        return false;
      }
      const isDeletingLastAdminRoleForThisService =
        adminsForServices.get(c.serviceId).length <= 1 &&
        adminsForServices.get(c.serviceId).includes(accountId) &&
        !assessRoles.find((r) => r.serviceId === c.serviceId && r.roleName === "admin");
      if (isDeletingLastAdminRoleForThisService) {
        errorServiceName = c.serviceName;
      }
      return isDeletingLastAdminRoleForThisService;
    });
    if (isDeletingLastAdminRole) {
      return {
        valid: false,
        message: `この操作により「${serviceLabels[errorServiceName].label}」の管理者ロールを持つアカウントがいなくなるため、変更できません。`,
      };
    }

    return {
      valid: true,
      message: "",
    };
  }
);
export const hasRoleMembers = createAsyncThunk(
  SLICE_NAME + "/hasRoleMembers",
  async ({ roleNames }: { roleNames: string[] }) => {
    const res = await call("get", "access_manager/profile_role_view", { doReload: false })({
      roles__contain: roleNames,
      page: 1,
      limit: 1,
    });
    return res.data.result.length > 0;
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    stageRoleApiBehaviors: (state, action) => {
      state.roleBehaviors = action.payload;
    },
    unselectRoleApiBehaviors: (state) => {
      state.roleBehaviors = [];
    },
  },
  extraReducers: (builder) => {
    // getRoles
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
    });
    // getAssessRoles
    builder.addCase(getAssessRoles.fulfilled, (state, action) => {
      state.assessRoles = action.payload;
    });
    builder.addCase(getAssessRoles.rejected, (state, action) => {
      state.assessRoles = [];
    });
    builder.addCase(postAssessRoles.fulfilled, (state, action) => {
      state.assessRoles = [...state.assessRoles, ...action.payload];
    });
    builder.addCase(deleteAssessRoles.fulfilled, (state, action) => {
      state.assessRoles = state.assessRoles.filter((_) => action.payload.every((deletedId) => deletedId !== _.id));
    });
    // getPermissions
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.permissions =
        action.payload.fetchedPermissionPage === 1
          ? action.payload.permissionData
          : [...state.permissions, ...action.payload.permissionData];
      state.permissionTotalCount = action.payload.permissionTotalCount;
      state.permissionHasMore = action.payload.permissionHasMore;
      state.fetchedPermissionPage = action.payload.fetchedPermissionPage ?? 1;
    });
    // getRoleBehaviors
    builder.addCase(getRoleBehaviors.fulfilled, (state, action) => {
      state.policyDocId = action.payload.id;
      state.roleBehaviors = action.payload.roleBehaviors;
    });
    // createEmptyRoleBehavior
    builder.addCase(createEmptyRoleBehavior.fulfilled, (state, action) => {
      if (state.roleBehaviors.every((_) => _.roleDocId !== action.payload.roleDocId)) {
        // 通常ありえないが、複数回 dispatch されたとき同一IDのものが重複して存在しないよう配慮
        state.roleBehaviors = [...state.roleBehaviors, action.payload];
      }
    });
    // getApiSummaries
    builder.addCase(getApiSummaries.fulfilled, (state, action) => {
      state.apiSummaries = action.payload;
    });
    // putRolePriority
    builder.addCase(putRolePriority.fulfilled, (state, action) => {
      state.roleBehaviors = state.roleBehaviors.map((role) => {
        if (role.roleDocId === action.payload.id) {
          role.order = action.payload.order;
        }
        return {
          ...role,
          order: role.roleDocId === action.payload.id ? action.payload.order : role.order,
        };
      });
    });
    // getServiceContracts
    builder.addCase(getServiceContracts.fulfilled, (state, action) => {
      state.serviceContracts = action.payload;
    });
    builder.addCase(validateAssessRoles.fulfilled, (state, action) => {
      state.serviceValidation = action.payload;
    });
  },
});

export const { stageRoleApiBehaviors, unselectRoleApiBehaviors } = slice.actions;

export const selectPermissionState = (state: RootState) => {
  return state.permission as PermissionState;
};

export const selectSwitchableCompanyRoles = (state: RootState): AssessRole[] => {
  const currentCompanyId = state.user.user.current_company.id;
  return state.permission.assessRoles.reduce((prev: AssessRole[], current: AssessRole) => {
    if (prev.every((_: AssessRole) => _.companyId !== current.companyId) && current.companyId !== currentCompanyId) {
      prev.push(current);
    }
    return prev;
  }, [] as AssessRole[]);
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
