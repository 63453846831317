import React, { useEffect, useMemo, useState } from "react";
import Sidebar_Evaluation from "../../component/Sidebar_Evaluation";
import Table from "../../component/Table";
import { Container, Row, Col, Button, Alert, Form, ListGroup } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { getPhases, getScales, getTodos, getWorkflows, getMethodTags, selectESState } from "./evaluationSystemSlice";
import { ESProcessMethod, ESWorkflow } from "./ESTypes";
import Icon from "../../component/Icon";
import dayjs from "dayjs";
import { getEvaluationSystem, selectEvaluationState } from "../evaluationSheet/evaluationSheetSlice";
import { EvaluationLayoutUsage } from "../evaluationSheet/EvaluationTypes";
import NoData from "../../component/NoData";
import { useNavigate } from "react-router-dom";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { workflows, methodTags, methods, scales } = useAppSelector(selectESState);
  const { patterns, layouts } = useAppSelector(selectEvaluationState);
  const [selectedMethod, $selectedMethod] = useState<ESProcessMethod | null>(null);
  const [selectedWorkflow, $selectedWorkflow] = useState<ESWorkflow | null>(null);
  const [selectedSheetLayout, $selectedSheetLayout] = useState<EvaluationLayoutUsage | null>(null);

  useEffect(() => {
    dispatch(getMethodTags());
    dispatch(getPhases());
    dispatch(getTodos());
    dispatch(getScales());
    dispatch(getWorkflows());
    dispatch(getEvaluationSystem({ id: "TODO" }));
  }, []);

  const methodOptions = useMemo(() => {
    return methods.map((m, i) => {
      return { value: m.method_id, label: m.method_name };
    });
  }, [methods]);

  const workflowOptions = useMemo(() => {
    return workflows.map((w) => {
      return { value: w.workflow_id, label: w.workflow_name };
    });
  }, [workflows]);

  const sheetLayoutOptions = useMemo(() => {
    return layouts.map((m) => {
      return { value: m.id, label: m.title };
    });
  }, [layouts]);

  const open = (view: string) => {
    switch (view) {
      case "layout":
      case "pattern":
        navigate("/_/evaluation/layout/");
        return;
    }
  };

  return (
    <div className="Ev-container --editor">
      <Sidebar_Evaluation current="system_settings" />
      <div className="Ev-edit-content">
        <Alert variant="success" className="--font-s">
          <div>・制度プロジェクトを選択する箇所が必要？</div>
          <div className="opacity-50">・パターンもこの並びに必要？　→　指標・基準と統合</div>
        </Alert>
        <h2 className="Headline--page">制度プロジェクト設定</h2>
        <Container className="bg-white mt-3 p-4 px-2 px-md-4">
          {methodOptions.length > 0 ? (
            <Row className="mb-4">
              <Col>
                <div className="d-flex flex-row-reverse flex-wrap gap-1">
                  <Button variant="outline-primary" className="mx-1 --flex --align-items-center">
                    <div className="mx-1">スケール設定へ</div>
                    <Icon type="arrow-right-circle" width={18} height={18} />
                  </Button>
                  <Button variant="outline-primary" className="mx-1 --flex --align-items-center">
                    <div className="mx-1">指標・基準設定へ</div>
                    <Icon type="arrow-right-circle" width={18} height={18} />
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => open("layout")}
                    className="mx-1 --flex --align-items-center"
                  >
                    <div className="mx-1">シート・レイアウト設定へ</div>
                    <Icon type="arrow-right-circle" width={18} height={18} />
                  </Button>
                  <Button variant="outline-primary" className="mx-1 --flex --align-items-center">
                    <div className="mx-1">ワークフロー設定へ</div>
                    <Icon type="arrow-right-circle" width={18} height={18} />
                  </Button>
                </div>
              </Col>
            </Row>
          ) : null}
          <Row className="mb-4 --align-items-center">
            <Col md={6}>
              <h3 className="Headline--section">方式・手法</h3>
            </Col>
            <Col md={6}>
              <div className="--flex --content-end">
                <Button
                  variant={methodOptions.length > 0 ? "outline-primary" : "primary"}
                  className="mx-1 --flex --align-items-center"
                >
                  <Icon type="plus-circle" width={18} height={18} />
                  <div className="mx-1">方式・手法を追加する</div>
                </Button>
              </div>
            </Col>
          </Row>
          {methodOptions.length > 0 ? (
            <Row>
              <Col md={4}>
                <ListGroup>
                  {methodOptions.map((m, i) => {
                    return (
                      <ListGroup.Item
                        onClick={() => {
                          $selectedMethod(
                            selectedMethod?.method_id !== m.value
                              ? methods.find((_) => _.method_id === m.value) ?? null
                              : null
                          );
                        }}
                        active={selectedMethod?.method_id === m.value}
                        key={`method${i}`}
                      >
                        {m.label}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Col>
              {selectedMethod && (
                <Col md={8} className="mt-4 mt-md-0">
                  <Row className="--align-items-center">
                    <Col md={8}>
                      <h2 className="Headline--section">{selectedMethod.method_name}</h2>
                    </Col>
                    <Col md={4} className="--text-align-right --font-s">
                      <div>最終更新日</div>
                      <div>{dayjs(selectedMethod.last_updated).format("YYYY/MM/DD")}</div>
                    </Col>
                  </Row>
                  {selectedMethod.method_tags.length > 0 ? (
                    <Row className="mt-2 mb-3">
                      <Col>
                        <div className="--flex">
                          {selectedMethod.method_tags.map((tag, i) => {
                            const tagLabel = methodTags.find((_) => _.id === tag)?.name ?? "";
                            return (
                              tagLabel && (
                                <div key={`method_tag${i}`} className="Table__value-group --font-s mx-1">
                                  <Icon type="trophy-fill" width={12} height={12} />
                                  <span className="mx-1">{tagLabel}</span>
                                </div>
                              )
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                  <Row className="mt-2">
                    <Col>
                      <h3 className="Headline--section-sub">説明</h3>
                      <div className="mt-2">{selectedMethod.description}</div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={6}>
                      <h3 className="Headline--section-sub">評価サイクル</h3>
                      <div className="mt-2">{selectedMethod.cycle}</div>
                    </Col>
                    <Col md={6}>
                      <h3 className="Headline--section-sub">設定状態</h3>
                      <div className="mt-2">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="利用可能"
                          defaultChecked={selectedMethod.configuration_status === "active"}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          ) : (
            <NoData>
              <div className="mb-2">
                「方式・手法」の登録がありません。
                <br />
                はじめに「方式・手法」を登録してください。
              </div>
              <Button variant={"primary"} className="mx-1 --flex --align-items-center">
                <Icon type="plus-circle" width={18} height={18} />
                <div className="mx-1">方式・手法を追加する</div>
              </Button>
            </NoData>
          )}

          <Row className="mt-5 mb-4 --align-items-center">
            <Col md={6}>
              <h3 className="Headline--section">ワークフロー</h3>
            </Col>
            <Col md={6}>
              <div className="--flex --content-end">
                <Button
                  variant="outline-primary"
                  disabled={methodOptions.length === 0}
                  className="mx-1 --flex --align-items-center"
                >
                  <div className="mx-1">ワークフロー設定へ</div>
                  <Icon type="arrow-right-circle" width={18} height={18} />
                </Button>
              </div>
            </Col>
          </Row>
          {workflowOptions.length > 0 ? (
            <Row>
              <Col md={4}>
                <ListGroup>
                  {workflowOptions.map((m, i) => {
                    return (
                      <ListGroup.Item
                        onClick={() => {
                          $selectedWorkflow(
                            selectedWorkflow?.workflow_id !== m.value
                              ? workflows.find((_) => _.workflow_id === m.value) ?? null
                              : null
                          );
                        }}
                        active={selectedWorkflow?.workflow_id === m.value}
                        key={`method${i}`}
                      >
                        {m.label}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Col>
              {selectedWorkflow && (
                <Col md={8} className="mt-4 mt-md-0">
                  <Row>
                    <Col md={8}>
                      <h2 className="Headline--section">{selectedWorkflow.workflow_name}</h2>
                    </Col>
                    <Col md={4} className="--text-align-right --font-s">
                      <div>最終更新日</div>
                      <div>{dayjs(selectedWorkflow.last_updated).format("YYYY/MM/DD")}</div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <h3 className="Headline--section-sub">説明</h3>
                      <div className="mt-2">{selectedWorkflow.description}</div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      {selectedWorkflow &&
                        selectedWorkflow.phases.map((phase, i) => {
                          return (
                            <section key={`phase${i}`} className="Workflow-card">
                              <h3 className="Workflow-card__title">{phase.phase_name}</h3>
                              {phase.steps.length > 0 && (
                                <ul className="Workflow-card__steps">
                                  {phase.steps.map((step) => {
                                    return (
                                      <li key={step.step_id} className="Workflow-card__step">
                                        <div className="w-50 px-2 --bold --text-align-right">{step.step_name}</div>
                                        <div className="w-50 px-2">{step.roles.map((r) => r.role_name).join(",")}</div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </section>
                          );
                        })}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          ) : (
            <NoData>
              「ワークフロー」の登録がありません。
              <br />
              必要に応じて「ワークフロー」を登録してください。
            </NoData>
          )}
          <Row className="mt-5 mb-4 --align-items-center">
            <Col md={6}>
              <h3 className="Headline--section">シートレイアウト</h3>
            </Col>
            <Col md={6}>
              <div className="--flex --content-end">
                <Button
                  variant="outline-primary"
                  disabled={methodOptions.length === 0}
                  className="mx-1 --flex --align-items-center"
                  onClick={() => open("layout")}
                >
                  <div className="mx-1">シートレイアウト設定へ</div>
                  <Icon type="arrow-right-circle" width={18} height={18} />
                </Button>
              </div>
            </Col>
          </Row>
          {sheetLayoutOptions.length > 0 ? (
            <Row>
              <Col md={4}>
                <ListGroup>
                  {sheetLayoutOptions.map((m, i) => {
                    return (
                      <ListGroup.Item
                        onClick={() => {
                          $selectedSheetLayout(
                            selectedSheetLayout?.id !== m.value ? layouts.find((_) => _.id === m.value) ?? null : null
                          );
                        }}
                        active={selectedSheetLayout?.id === m.value}
                        key={`sheetLayout${i}`}
                      >
                        {m.label}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Col>
              {selectedSheetLayout && (
                <Col md={8} className="mt-4 mt-md-0">
                  <Row>
                    <Col md={8}>
                      <h2 className="Headline--section">{selectedSheetLayout.title}</h2>
                    </Col>
                    <Col md={4} className="--text-align-right --font-s">
                      <div>最終更新日</div>
                      <div>{dayjs(selectedSheetLayout.updated_at).format("YYYY/MM/DD")}</div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <h3 className="Headline--section-sub">説明</h3>
                      <div className="mt-2">{selectedSheetLayout.description}</div>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          ) : (
            <NoData>
              「シートレイアウト」の登録がありません。
              <br />
              必要に応じて「シートレイアウト」を登録してください。
            </NoData>
          )}
          <Row className="mt-5 mb-4 --align-items-center">
            <Col md={6}>
              <h3 className="Headline--section">パターン</h3>
            </Col>
            <Col md={6}>
              <div className="--flex --content-end">
                <Button
                  variant="outline-primary"
                  disabled={methodOptions.length === 0}
                  className="mx-1 --flex --align-items-center"
                >
                  <div className="mx-1">パターン設定へ</div>
                  <Icon type="arrow-right-circle" width={18} height={18} />
                </Button>
              </div>
            </Col>
          </Row>
          {patterns.length > 0 ? (
            <Row className="mt-2">
              <Col>
                <Table
                  col={[
                    {
                      name: "パターン",
                    },
                    {
                      name: "属性",
                    },
                    {
                      name: "最終更新日",
                    },
                  ]}
                  row={patterns.map((p) => {
                    return {
                      data: [
                        p.title,
                        <div className="Table__value-group-wrapper">
                          {p.attribute_groups.map((a, ai) => {
                            return (
                              <div className="--font-s Table__value-group mb-1" key={`values${ai}`}>
                                {a.attribute_group_name +
                                  ":" +
                                  a.attribute_values.map((v) => v.attribute_value).join(", ")}
                              </div>
                            );
                          })}
                        </div>,
                        dayjs(p.updated_at).format("YYYY/MM/DD"),
                      ],
                      link: `/_/evaluation/layout/`,
                    };
                  })}
                ></Table>
              </Col>
            </Row>
          ) : (
            <NoData>
              「指標・基準」の登録がありません。
              <br />
              必要に応じて「指標・基準」を登録してください。
            </NoData>
          )}
          <Row className="mt-5 mb-4 --align-items-center">
            <Col md={6}>
              <h3 className="Headline--section">スケール</h3>
            </Col>
            <Col md={6}>
              <div className="--flex --content-end">
                <Button
                  variant="outline-primary"
                  disabled={methodOptions.length === 0}
                  className="mx-1 --flex --align-items-center"
                >
                  <div className="mx-1">スケール設定へ</div>
                  <Icon type="arrow-right-circle" width={18} height={18} />
                </Button>
              </div>
            </Col>
          </Row>
          {scales.length > 0 ? (
            <Row className="mt-2">
              <Col>
                <Table
                  col={[
                    {
                      name: "スケール",
                    },
                    {
                      name: "条件",
                    },
                    {
                      name: "最終更新日",
                    },
                  ]}
                  row={scales.map((f) => {
                    return {
                      data: [
                        f.scale_name,
                        f.condition_groups.map((c) => {
                          return (
                            <div key={`${c.condition_group_id}`} className="--font-s Table__value-group mb-1">
                              {c.condition_group_name}：
                              {c.conditions.map((_, i) => {
                                return (
                                  <span key={`${c.condition_group_id}_${i}`}>
                                    {_.attribute_name}/{_.attribute_value}
                                    {i < c.conditions.length - 1 ? ", " : ""}
                                  </span>
                                );
                              })}
                            </div>
                          );
                        }),
                        dayjs(f.last_updated).format("YYYY/MM/DD"),
                        ,
                      ],

                      link: `/_/evaluation/scale/${f.scale_id}`,
                    };
                  })}
                ></Table>
              </Col>
            </Row>
          ) : (
            <NoData>
              「スケール」の登録がありません。
              <br />
              必要に応じて「スケール」を登録してください。
            </NoData>
          )}
        </Container>
      </div>
    </div>
  );
}

export default App;
