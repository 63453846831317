import { useCallback, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { Form } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Unit, UnitChangePayload } from "./EvaluationTypes";
import { selectEvaluationState, setContextMenu, stage, focusProcedureStep } from "./evaluationSheetSlice";
import Icon from "../../component/Icon";
import { getComputedUnitValue } from "./evaluationValues";
import { selectLayoutState } from "../layout/layoutSlice";
import classNames from "classnames";

/*
  - 回答中
  - 回答確認中
  - 編集中
    - [ Unit 個別 ]
      - プレビュー（回答確認中と同じ）
      - プレビューの
      - 各セルの編集中
    - [ Unitまとめて ]
      - TBD
    - [ UnitGroup 個別 ]
*/
function EvUnit({ unit, editorMode }: { unit: Unit; editorMode?: string }) {
  const dispatch = useAppDispatch();
  const { debug, computed, contextMenu, focusedProcedureStepId, previewWithEditor } =
    useAppSelector(selectEvaluationState);
  const { isTouchDevice } = useAppSelector(selectLayoutState);

  const gridStyle = useMemo(() => {
    return {
      gridRow: `${unit.gridRow} / span ${unit.gridRowSpan}`,
      gridColumn: `${unit.gridColumn} / span ${unit.gridColumnSpan}`,
    };
  }, [unit.gridRow, unit.gridRowSpan, unit.gridColumn, unit.gridColumnSpan]);

  const computedValue = useMemo(() => {
    if (!unit.conditions) return "";
    return getComputedUnitValue({
      conditions: unit.conditions,
      computed,
    });
  }, [unit.conditions, computed]);

  const contentAlignCenter = useMemo(() => {
    return unit.type === "checkbox";
  }, [unit.type, editorMode]);

  const unitContent = useMemo(() => {
    const style = unit.style || {};
    switch (unit.type) {
      case "static":
        return (
          <div className="Ev-unit__value --static" style={style}>
            {unit.value}
          </div>
        );
      case "text":
        if (unit.appearance === "input") {
          return (
            <Form.Control
              as="textarea"
              style={{
                ...style,
                fontSize: "0.8rem",
              }}
              value={`${unit.value}`}
              onChange={(e) => onChange({ unit, value: e.target.value })}
              placeholder="入力してください"
            />
          );
        } else if (unit.appearance === "preview" || editorMode === "editing") {
          return (
            <div className="Ev-unit__value" style={style}>
              {unit.value}
            </div>
          );
        } else if (unit.appearance === "veiled") {
          return <div className="Ev-unit__value --veiled" style={style}></div>;
        } else if (unit.appearance === "locked") {
          return (
            <div className="Ev-unit__value --locked" style={style}>
              {unit.value}
            </div>
          );
        } else {
          return <div style={style}></div>;
        }
      case "select":
        if (unit.options?.length) {
          const selectedLabel = unit.options.find((o) => o.value === unit.value)?.label ?? "";
          if (unit.appearance === "input") {
            return (
              <Form.Select
                value={`${unit.value}`}
                onChange={(e) =>
                  onChange({
                    unit,
                    value: e.target.value,
                  })
                }
                style={{ ...style, fontSize: "13px" }}
              >
                <option value={""}>---</option>
                {unit.options.map(({ label, value }, i) => {
                  return (
                    <option key={`option_${i}`} value={value}>
                      {label}
                    </option>
                  );
                })}
              </Form.Select>
            );
          } else if (unit.appearance === "preview" || editorMode === "editing") {
            return (
              <div className="Ev-unit__value" style={style}>
                {selectedLabel}
              </div>
            );
          } else if (unit.appearance === "veiled") {
            return <div className="Ev-unit__value --veiled" style={style}></div>;
          } else if (unit.appearance === "locked") {
            return (
              <div className="Ev-unit__value --locked" style={style}>
                {selectedLabel}
              </div>
            );
          } else {
            return <div style={style}></div>;
          }
        } else {
          return <div style={style}></div>;
        }
      case "checkbox":
        if (editorMode === "editing" || unit.appearance === "input" || unit.appearance === "locked") {
          return (
            <Form.Check
              type="checkbox"
              label={unit.label}
              disabled={unit.appearance === "locked"}
              id={`check-${unit.id}`}
              checked={unit.value === "true"}
              value={`${unit.value}`}
              className="--font-s"
              style={style}
              onChange={(e) => {
                onChange({
                  unit,
                  value: e.target.value !== "true" ? "true" : "false",
                });
              }}
            />
          );
        } else if (unit.appearance === "preview") {
          return (
            <div className="Ev-_unit__value" style={style}>
              {unit.label}
            </div>
          );
        } else if (unit.appearance === "veiled") {
          return <div className="Ev-unit__value --veiled" style={style}></div>;
        } else {
          return <div style={style}></div>;
        }
      case "computed":
        if (!unit.conditions) {
          return <div className="Ev-unit__value">条件が未設定です</div>;
        } else if (unit.appearance === "preview" || unit.appearance === "input" || editorMode === "editing") {
          return (
            <div className="Ev-unit__value" style={style}>
              {computedValue}
            </div>
          );
        } else if (unit.appearance === "veiled") {
          return <div className="Ev-unit__value --veiled" style={style}></div>;
        } else if (unit.appearance === "locked") {
          return <div className="Ev-unit__value --locked" style={style}></div>;
        } else {
          return <div style={style}></div>;
        }
      default:
        return <div style={style}>{unit.value}</div>;
    }
  }, [unit, editorMode, computed]);

  const onChange = useCallback(
    (payload: UnitChangePayload) => {
      dispatch(
        stage({
          ...payload,
          unitGroupId: unit.unitGroupId,
        })
      );
    },
    [unit.unitGroupId]
  );

  const startEditing = useCallback(() => {
    previewWithEditor && unit.subRoutineId && dispatch(focusProcedureStep(unit.subRoutineId));
  }, [dispatch, unit, previewWithEditor]);
  if (!unit.inActiveArea || unit.appearance === "hidden") return <></>;
  return (
    <div
      id={unit.id}
      data-unit-type={unit.type}
      data-unit-apprearance={unit.appearance}
      data-unit-sub-module-id={unit.subRoutineId ?? ""}
      data-unit-behavior-id={unit.behaviorId}
      data-computed-key={unit.type === "computed" ? unit.conditions?.[0].computedKey : ""}
      data-factor={unit.factor}
      data-factor-unit-ids={unit.factorUnitIds?.map((id) => id).join(",") ?? ""}
      className={`Ev-unit ${unit.classNames?.map((n) => `--${n}`).join(" ") ?? ""} ${
        contextMenu.unit?.id === unit.id ? "--context-menu-target" : ""
      }`}
      style={gridStyle}
      onContextMenu={(e) => {
        if (editorMode === "editing") {
          e.preventDefault();
          dispatch(setContextMenu({ x: e.clientX, y: e.clientY, active: true, unit: unit }));
          return false;
        }
      }}
    >
      {editorMode === "editing" && (
        <div className="Ev-unit__edit-buttons">
          {unit.factor && <Icon type="" width={14} height={14} />}
          {isTouchDevice && !unit.templateId && (
            <div
              onClick={(e: any) => {
                dispatch(setContextMenu({ x: e.clientX, y: e.clientY, active: true, unit: unit }));
              }}
              className="Ev-unit__edit-button --trigger"
            >
              <Icon type="pencil-square" width={12} height={12} />
            </div>
          )}
        </div>
      )}

      {editorMode !== "off" && (
        <div
          className={classNames({
            "Ev-unit__switching-layer": previewWithEditor,
            "--focused": previewWithEditor && focusedProcedureStepId === unit.subRoutineId,
          })}
          onClick={startEditing}
        ></div>
      )}
      <div className={classNames({ "Ev-unit__content": true, "--centering": contentAlignCenter })}>
        {debug && <div className="Ev-unit__debug">{unit.appearance}</div>}
        {unitContent}
      </div>
    </div>
  );
}

export default EvUnit;
