const data = {
  category: "additional_information_from_m_position",
  table: "additional_information_from_m_position",
  subFields: {
    position_code: {},
    additional_information1: {},
    additional_information2: {},
    additional_information3: {},
  },
};

export default data;
