import { useEffect, useState, useMemo, useCallback } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { Row, Col, Modal, Alert } from "react-bootstrap";
import Table from "../../component/Table";
import { getSectors, getRegularColumns, selectClientState } from "../client/clientSlice";
import { RegularColumn } from "../client/clientValues";
import ProfileField from "./ProfileField";
import { FieldValidator, ProfileSubField, generateValidator } from "./profileFieldValues";
import { fieldViewData, ProfileFieldStatus, profileSectorStaff, USER_TABLE_PREFIX } from "./profileValues";

import {
  stageProfileSubFields,
  selectProfileState,
  commitUserData,
  createEmptyProfile,
  getNotAssignedAccounts,
} from "../../features/profile/profileSlice";
import dayjs from "dayjs";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Icon from "../../component/Icon";

function App() {
  const dispatch = useAppDispatch();
  const { notAssignedAccounts, notAssignedAccountLoading, allNotAssignedAccountsLoaded, profileFields } =
    useAppSelector(selectProfileState);
  const { sectorRegularColumns } = useAppSelector(selectClientState);
  const category = "assignment2";
  const sectorId = `${USER_TABLE_PREFIX}${category}`;
  const [state, $state] = useState({
    fieldViewSectors: [] as fieldViewData[],
    fieldStatus: {} as ProfileFieldStatus,
    selectedPointDate: dayjs(),
    selectedAccountId: -1,
    selectedLoginCode: "",
    selectedAccountName: "",
    targetIndex: -1,
    timeoutId: null as number | null,
  });

  const [activeModal, $activeModal] = useState("");
  const [fieldStatus, $fieldStatus] = useState({} as ProfileFieldStatus);

  useEffect(() => {
    if (!notAssignedAccountLoading) {
      dispatch(getNotAssignedAccounts());
    }
    dispatch(getSectors());
    dispatch(getRegularColumns({ sectorId }));
  }, [dispatch]);

  const closeModal = () => {
    $activeModal("");
    $fieldStatus({});
    $state({ ...state, targetIndex: -1 });
  };
  const validateProfileField: FieldValidator = useCallback(
    (subFields: ProfileSubField[]) => {
      const subFieldsData = sectorRegularColumns[`${sectorId}`].reduce(
        (prev: { [fieldName: string]: any }, { input_type, id }: RegularColumn) => {
          return { ...prev, [id]: { type: input_type } };
        },
        {}
      );
      return generateValidator(subFieldsData)(subFields);
    },
    [sectorRegularColumns[`${sectorId}`]]
  );

  const setFieldStatus = (validated: boolean, subFields: ProfileSubField[], subFieldIndex?: number) => {
    const f = profileFields[0]?.fieldName;
    const next = { ...fieldStatus };
    next[f] = next[f] || { validated: true, errorMessage: "" };
    next[f].validated = validated;
    next[f].errorMessage = validated ? "" : "入力内容を確認してください";
    subFields.forEach((sf, i) => {
      if (subFieldIndex && i !== subFieldIndex) return;
      const path = `${f}/${sf.id}`;
      next[path] = next[path] || { validated: true, errorMessage: "" };
      next[path].validated = !sf.errorMessage;
      next[path].errorMessage = sf.errorMessage ?? "";
    });
    $fieldStatus(next);
  };

  return (
    <Col>
      <Row>
        <Col>
          {notAssignedAccountLoading && notAssignedAccounts.length === 0 && (
            <div className="--flex --align-items-center --text-light-color">
              <div>
                <Icon type="box-arrow-down" width={24} height={24} />
              </div>
              <div className="--px-2">
                データをダウンロード中です。データ総数によっては 10
                秒以上かかる場合がありますが、この間他の画面に移動しても問題ありません。
              </div>
            </div>
          )}
          {allNotAssignedAccountsLoaded && notAssignedAccounts.length > 0 ? (
            <Table
              col={[
                {
                  name: "ログインID",
                  colName: "loginId",
                  filterable: true,
                  width: "20%",
                },
                {
                  name: "氏名",
                  colName: "name",
                  filterable: true,
                  width: "30%",
                },
                {
                  name: "メールアドレス",
                  filterable: true,
                  width: "40%",
                },
              ]}
              row={notAssignedAccounts.map((a) => {
                return {
                  key: a.id,
                  data: [a.loginCode, a.name, a.mailAddress],
                  action: {
                    handler: () => {
                      dispatch(
                        createEmptyProfile({
                          category: `${category}`,
                          accountId: a.id,
                          columns: sectorRegularColumns[`${sectorId}`],
                        })
                      );
                      $fieldStatus({
                        [`${category}`]: {
                          validated: false,
                          errorMessage: "入力してください",
                        },
                      } as ProfileFieldStatus);
                      $state({
                        ...state,
                        selectedAccountId: a.id,
                        selectedLoginCode: a.loginCode,
                        selectedAccountName: a.name,
                      });
                      $activeModal("input");
                    },
                  },
                };
              })}
              usePagenation={true}
              useKeywordFilter={true}
            />
          ) : (
            !notAssignedAccountLoading && <Alert variant={"info"}>該当するアカウント情報がありません。</Alert>
          )}
        </Col>
      </Row>
      <Modal
        show={activeModal === "input"}
        onHide={closeModal}
        size="xl"
        className="mt-4"
        centered
        scrollable
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>{state.selectedAccountName}の編集</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <ProfileField
                table={profileFields[0]?.table}
                fieldName={profileFields[0]?.fieldName}
                labelMap={(() => {
                  let labelMap = profileFields[0]?.labelMap["ja"];
                  labelMap = { ...labelMap, [profileFields[0]?.fieldName]: "所属部署・役職" };
                  return { ja: labelMap };
                })()}
                accountId={state.selectedAccountId}
                subFields={profileFields[0]?.subFields}
                isEditing={true}
                dataType={"user"}
                ignorePermission={true}
                fieldStatus={fieldStatus}
                onChange={(next, subFieldIndex) => {
                  const { validated, subFields } = validateProfileField(next);
                  setFieldStatus(validated, subFields, subFieldIndex);
                  dispatch(
                    stageProfileSubFields({
                      fieldName: profileFields[0]?.fieldName,
                      validated,
                      subFields,
                    })
                  );
                }}
                onCommit={async (next, validDateMap) => {
                  const { validated } = validateProfileField(next);
                  if (!validated) return;
                  const fieldsToUpdate = profileSectorStaff[profileFields[0].fieldName].toDiffList(next, validDateMap);
                  await dispatch(
                    commitUserData({
                      activeFieldType: "detail",
                      categoryId: `${category}`,
                      table: profileFields[0]?.table,
                      data: fieldsToUpdate,
                      accountLoginCode: state.selectedLoginCode,
                      api: "account_admin_manager/not_assigned_account",
                    })
                  );
                  dispatch(getNotAssignedAccounts());
                  closeModal();
                }}
                onEditOrCancel={(next, isEditing) => {
                  if (!isEditing) closeModal();
                  else {
                    const { validated, subFields } = validateProfileField(next);
                    setFieldStatus(validated, subFields);
                    dispatch(
                      stageProfileSubFields({
                        fieldName: profileFields[0]?.fieldName,
                        validated,
                        subFields,
                      })
                    );
                  }
                }}
                onChangeFieldStatus={(_fieldStatus) => {
                  $fieldStatus({ ...fieldStatus, ..._fieldStatus });
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Col>
  );
}

export default App;
