import { useEffect, useMemo, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { Form } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Unit, UnitGroup, UnitGroupArea } from "./EvaluationTypes";
import EvUnit from "./Unit";
import { selectLayoutState } from "../layout/layoutSlice";
import { selectEvaluationState } from "./evaluationSheetSlice";
import classNames from "classnames";
import EvUnitGroupEditorPane from "./UnitGroupEditorPane";

const hit = (unit: Unit, area?: UnitGroupArea): Unit => {
  if (!area) return { ...unit, inActiveArea: true };
  const x: number[] = [],
    y: number[] = [];
  for (let i = unit.gridColumn; i < unit.gridColumn + unit.gridColumnSpan; i++) x.push(i);
  for (let i = unit.gridRow; i < unit.gridRow + unit.gridRowSpan; i++) y.push(i);
  const matchedArea = area.definitions.find((a) => {
    return x.some((_x) => _x >= a.s_column && _x <= a.t_column) && y.some((_y) => _y >= a.s_row && _y <= a.t_row);
  });
  if (!matchedArea) {
    return {
      ...unit,
      inActiveArea: false,
    };
  }
  return {
    ...unit,
    gridRow: unit.gridRow < matchedArea.s_row ? matchedArea.s_row : unit.gridRow,
    gridColumn: unit.gridColumn < matchedArea.s_column ? matchedArea.s_column : unit.gridColumn,
    gridRowSpan: (() => {
      const _a1 = unit.gridRow < matchedArea.s_row ? matchedArea.s_row - unit.gridRow : 0;
      const _a2 = Math.max(...y) > matchedArea.t_row ? Math.max(...y) - matchedArea.t_row : 0;
      return unit.gridRowSpan - _a1 - _a2;
    })(),
    gridColumnSpan: (() => {
      const _c1 = unit.gridColumn < matchedArea.s_column ? matchedArea.s_column - unit.gridColumn : 0;
      const _c2 = Math.max(...x) > matchedArea.t_column ? Math.max(...x) - matchedArea.t_column : 0;
      return unit.gridColumnSpan - _c1 - _c2;
    })(),
    inActiveArea: !!matchedArea,
  };
};

function EvUnitGroup({
  unitGroup: ug,
  fitScreen = false,
  editorMode = "off",
}: {
  unitGroup: UnitGroup;
  fitScreen?: boolean;
  editorMode?: "off" | "editing" | "selecting";
}) {
  const dispatch = useAppDispatch();
  const { todo, previewWithEditor } = useAppSelector(selectEvaluationState);
  const { isTouchDevice } = useAppSelector(selectLayoutState);
  const gridBoxStyle = useMemo(() => {
    return {
      background: "transparent",
      fontSize: "0.8rem",
      display: "inline-grid",
      border: "1px solid #ddd",
      flexFlow: "column wrap",
      gridTemplateColumns: ug.columnWidthList.map((n) => (!fitScreen ? `${n}px` : "auto")).join(" "),
      gridTemplateRows: ug.rowHeightList.map((n) => (!fitScreen && n > 0 ? `${n}px` : "auto")).join(" "),
      ...ug.gridBoxStyle,
    };
  }, [ug.columnWidthList, ug.rowHeightList, fitScreen, ug.gridBoxStyle]);
  const [useAreaSelector, $useAreaSelector] = useState<boolean>(false);
  const [selectedAreaIndex, $selectedAreaIndex] = useState<number>(0);

  useEffect(() => {
    if (!isTouchDevice) {
      $useAreaSelector(false);
      $selectedAreaIndex(-1);
    } else {
      $useAreaSelector(true);
      let anyPhaseSelected = false;
      ug.area?.find((area, i) => {
        if (!anyPhaseSelected && area.targetPhases?.includes(todo.phase.key)) {
          $selectedAreaIndex(i);
          anyPhaseSelected = true;
        }
      });
      if (!anyPhaseSelected) $selectedAreaIndex(0);
    }
  }, [ug.area, isTouchDevice, todo.phase]);

  const units = useMemo(() => {
    return ug.units.map((unit) => {
      return hit(unit, selectedAreaIndex >= 0 ? ug.area?.[selectedAreaIndex] : undefined);
    });
  }, [ug.units]);

  const hiddenUnits = useMemo(() => {
    return ug.units.filter((u) => u.appearance === "hidden");
  }, [ug]);

  const unitGroupStyle = useMemo(() => {
    const unitGroupStyle = {} as React.CSSProperties;
    ug.preference.forEach((p) => {
      if (p.startsWith("mb-")) {
        unitGroupStyle.marginBottom = +p.replace("mb-", "") * 10 + "px";
      } else if (p.startsWith("mt-")) {
        unitGroupStyle.marginTop = +p.replace("mt-", "") * 10 + "px";
      } else if (p.startsWith("ml-")) {
        unitGroupStyle.marginLeft = +p.replace("ml-", "") * 10 + "px";
      } else if (p.startsWith("mr-")) {
        unitGroupStyle.marginRight = +p.replace("mr-", "") * 10 + "px";
      }
    });
    return unitGroupStyle;
  }, [ug.preference]);

  return (
    <div
      className={classNames({ "Ev-unit-group": true, "--editing": editorMode === "editing" })}
      style={unitGroupStyle}
    >
      <div className="--flex">
        {(previewWithEditor || editorMode === "editing") && (
          <EvUnitGroupEditorPane unitGroup={ug} editorMode={editorMode} />
        )}
        <div>
          {useAreaSelector && ug.area?.length && (
            <div className="--flex --align-items-center">
              <div>表示範囲</div>
              <Form.Select
                style={{
                  marginLeft: "10px",
                  textAlign: "left",
                  width: "auto",
                }}
                onChange={(e) => {
                  $selectedAreaIndex(+e.target.value);
                }}
                value={selectedAreaIndex}
              >
                <option value="-1">全て表示</option>
                {ug.area.map((_, i) => {
                  return (
                    <option value={i} key={`エリア${i}`}>
                      {_.label}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
          )}
          <section style={ug.style}>
            <div className="w-full" style={gridBoxStyle}>
              {units.map((unit: Unit, ui: number) => {
                return <EvUnit key={`unit_${ui}`} unit={unit} editorMode={editorMode} />;
              })}
            </div>
            {ug.remarks && ug.remarks.length > 0 && (
              <div className="Ev-remarks">
                {ug.remarks.map(({ value, style }, i) => {
                  return (
                    <div style={style} key={`remarks${i}`}>
                      {value}
                    </div>
                  );
                })}
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
}

export default EvUnitGroup;
