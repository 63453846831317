import { ReturnedType } from "./store";
import dayjs from "dayjs";

export const testResponse = (result: ReturnedType): ReturnedType | null =>
  result.meta.requestStatus !== "rejected" ? result : null;

export const yieldId = (): string => {
  let uuid = "",
    i,
    random;
  for (i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0;
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
  }
  return uuid;
};

export const getDateLengthForMonth = (year: number) => [
  31,
  year % 4 > 0 ? 28 : 29,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31,
];

export const toTimeLabel = (datetime: number): string => {
  const now = dayjs().locale("ja");
  const localDateTime = dayjs(datetime).locale("ja");
  if (
    now.year() === localDateTime.year() &&
    now.month() === localDateTime.month() &&
    now.day() === localDateTime.day()
  ) {
    return `本日 ${localDateTime.format("HH:mm")}`;
  } else if (
    now.year() === localDateTime.year() &&
    now.month() === localDateTime.month() &&
    now.add(-1, "day").day() === localDateTime.day()
  ) {
    return `昨日 ${localDateTime.format("HH:mm")}`;
  } else {
    return `${localDateTime.format("MM/DD HH:mm")}`;
  }
};

export const getQuery = (search: string): { [field: string]: string } => {
  if (!search) return {};
  const query: { [field: string]: string } = search
    .replace("?", "")
    .split("&")
    .map((_: string) => _.split("="))
    .reduce((prev, current) => {
      return { ...prev, [current[0]]: decodeURI(current[1]) };
    }, {});
  return query;
};

export const isStringArraySame = (methods1: string[], methods2: string[]) => {
  const s1 = [...methods1].sort((a: string, b: string) => a.length - b.length).join("");
  const s2 = [...methods2].sort((a: string, b: string) => a.length - b.length).join("");
  return s1 === s2;
};

export const trimSpaces = (str: string) => {
  if (typeof str !== "string") return str;
  str = str.replace(/^\s+/, "");
  str = str.replace(/\s+$/, "");
  str = str.replace(/\u200B/g, "");
  return str;
};

export type HandlerEvent = React.ChangeEvent<HTMLInputElement>;
