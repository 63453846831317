const data = {
  category: "personal_classification",
  table: "personal_classification",
  subFields: {
    taxation_type: {},
    widow_single_parent_type: {},
    working_student_type: {},
    disability_type: {},
    relation_file_id: {},
    disability_description: {},
    disability_certificate_on: {},
    disability_certificate_type: {},
    disability_expiration_on: {},
    foreigner_type: {},
    resident_type: {},
    legal_name: {},
    legal_last_name: {},
    legal_first_name: {},
    legal_name_kana: {},
    legal_last_name_kana: {},
    legal_first_name_kana: {},
    remarks: {},
  },
};

export default data;
