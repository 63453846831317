import { EvaluationSelectOptions } from "../EvaluationTypes";
import { readHelperQuery } from "../evaluationValues";

/*
    データ, コンテキスト, 処理 => Unitの配列, 選択式の回答, computingRuleSet など
    - コンテキスト・・１回目の処理で作成した commonSelectOptions を引き継ぐといったことを可能にするために考える
*/
const __stepValues = (query: string): EvaluationSelectOptions => {
  const { method, params } = readHelperQuery(query);
  if (method !== "stepValues") {
    throw new Error("uncorresponding method");
  }
  const min = +params.min;
  const max = +params.max;
  const step = +(params.step ?? 1);
  const format = params.format ?? "";
  if (Number.isNaN(min) || Number.isNaN(max) || Number.isNaN(step) || min < 0 || max < 0 || min >= max) {
    throw new Error("invalid params");
  }
  const options = [] as { label: string; value: string }[];
  for (let i = min; i <= max; i += step) {
    let label = "";
    if (!format) {
      label = i.toString();
    } else if (format === "percent") {
      label = `${i}%`;
    } else {
      throw new Error("invalid format");
    }
    options.push({ label, value: i.toString() });
  }
  return {
    [query]: {
      label: query,
      options,
    },
  };
};
export default __stepValues;
