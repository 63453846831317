import { useState, useEffect } from "react";
import { Row, Col, Accordion, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Account, ACCOUNT_SEARCH_LIMIT, SearchCondition } from "./profileValues";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { searchPermittedAccounts, selectProfileState, clearPermittedAccounts } from "./profileSlice";
import { userColumnChoices } from "./profileFieldValues";

function OtherMemberSelector({
  selectedAccountId,
  onSelectedAccountChange,
}: {
  selectedAccountId?: number;
  onSelectedAccountChange: (id: number | undefined) => any;
}) {
  const dispatch = useAppDispatch();
  const { permittedAccounts } = useAppSelector(selectProfileState);

  const [state, $state] = useState({
    inputKeyword: "",
    keyword: "",
    timeoutId: null as number | null,
    enrollmentTypeOptions: userColumnChoices["profile_u_personal"]["enrollment_type"].map((e, i) => {
      return {
        id: `enrollment_type${i + 1}`,
        label: e,
        checked: ["在籍", "休職"].includes(e),
      };
    }),
  });

  const [checkedMember, $checkedMember] = useState<Account | null>(null);

  const selectTarget = (id: number | undefined) => {
    onSelectedAccountChange(id);
  };

  useEffect(() => {
    return () => {
      dispatch(clearPermittedAccounts());
    };
  }, []);

  const onChangeKeyWord = (keyword: string) => {
    // timeoutIdがある場合は、前回の検索をキャンセルしてtimeoutIdを更新する
    if (state.timeoutId) window.clearTimeout(state.timeoutId);
    const timeoutId = window.setTimeout(() => {
      // 打ち終わって700ms後に検索用のkeywordを更新してタイムアウトIDをnullにする
      $state({ ...state, timeoutId: null, keyword, inputKeyword: keyword });
    }, 700);
    $state({ ...state, timeoutId, inputKeyword: keyword });
  };

  useEffect(() => {
    const params = {} as SearchCondition;
    if (state.keyword) {
      params.or = [{ name__contain: state.keyword, login_code__contain: state.keyword }];
      params.enrollment_type__in = state.enrollmentTypeOptions
        .filter((option) => option.checked)
        .map((option) => option.label);
      dispatch(
        searchPermittedAccounts({
          ...params,
          limit: ACCOUNT_SEARCH_LIMIT,
          page: 1,
        })
      );
    } else {
      // キーワードがなければクリアする
      dispatch(clearPermittedAccounts());
    }
  }, [state.keyword, state.enrollmentTypeOptions]);

  useEffect(() => {
    const _checkedMember = permittedAccounts.find((t) => t.id === selectedAccountId);
    if (_checkedMember) {
      $checkedMember(_checkedMember);
    }
  }, [selectedAccountId, permittedAccounts]);

  useEffect(() => {
    if (selectedAccountId && !permittedAccounts.find((t) => t.id === selectedAccountId)) {
      dispatch(
        searchPermittedAccounts({
          account_id: selectedAccountId,
          limit: 1,
          page: 1,
        })
      );
    }
  }, [selectedAccountId]);

  return (
    <Accordion className="mt-2">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{checkedMember ? checkedMember.name : "（未選択）"}</Accordion.Header>
        <Accordion.Body>
          <Row className="mb-2">
            <Col md="12" className="mb-2 --align-items-center">
              <div className="d-flex flex-row align-items-center">
                <div className="--bold me-3">在籍区分</div>
                <div className="d-flex flex-row align-items-center">
                  {state.enrollmentTypeOptions.map((option) => {
                    return (
                      <Form.Check
                        type="checkbox"
                        label={option.label}
                        key={`option_h_${option.id}`}
                        id={`option_h_${option.id}`}
                        checked={option.checked}
                        value={option.id}
                        onChange={() => {
                          $state({
                            ...state,
                            enrollmentTypeOptions: state.enrollmentTypeOptions.map((o) => {
                              if (o.id !== option.id) return o;
                              return { ...o, checked: !o.checked };
                            }),
                          });
                        }}
                        className="me-3"
                      />
                    );
                  })}
                </div>
              </div>
            </Col>
            <Col md="12" className="mt-md-0">
              <Form.Control
                type="text"
                id="search"
                value={state.inputKeyword}
                className="mb-2"
                placeholder="キーワード（名前・ログインID）で絞り込む"
                onChange={(e) => {
                  onChangeKeyWord(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // エンターキーで即時検索
                    if (state.timeoutId) window.clearTimeout(state.timeoutId);
                    $state({ ...state, timeoutId: null, keyword: state.inputKeyword });
                  }
                }}
              />
              <Row>
                {permittedAccounts.length > 0 ? (
                  permittedAccounts.map((t) => (
                    <Col md="6" key={`permittedAccounts_${t.id}`}>
                      <Form.Check
                        checked={selectedAccountId === t.id}
                        id={`permitted_member_${t.id}`}
                        type="radio"
                        label={`${t.name}（${t.loginCode}）${!t.isActive ? "（無効）" : ""}`}
                        onChange={() => selectTarget(t.id)}
                      />
                    </Col>
                  ))
                ) : (
                  <Col>キーワードに該当するアカウントが存在しません。キーワードを変更してください。</Col>
                )}
                {permittedAccounts.length === ACCOUNT_SEARCH_LIMIT && (
                  <Col>{`${ACCOUNT_SEARCH_LIMIT}名以上存在します。選択したいアカウントが表示されていない場合はキーワードを変更してください。`}</Col>
                )}
              </Row>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default OtherMemberSelector;
