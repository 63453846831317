import { EvaluationTodo } from "../EvaluationTypes";
export const dummyTodo1: EvaluationTodo = {
  id: "todo_57",
  status: "running",
  patternId: "pattern_g1",
  phase: {
    id: "phase_self",
    key: "phase_key_self",
    phaseGroupId: "phase_group_A",
    phaseGroupKey: "phase_group_key_A",
  },
  evaluator: {
    accountId: 4,
    displayName: "清水ひかり",
  },
  evaluatee: {
    accountId: 1,
    displayName: "山本登",
  },
  values: {
    "input-textarea": "上期・確定のコメント",
    "input-textarea2": "年次・確定のコメント",
    "input-options1": "3",
    "input-options2": "1",
    "input-options3": "5",
    "input-options4": "70",
    "input-options5": "40",
    "input-options6": "60",
  },
};

export const dummyTodo_nakagawa: EvaluationTodo = {
  id: "todo_nakagawa",
  status: "running",
  patternId: "pattern_tech_a",
  phase: {
    id: "phase_0",
    key: "phase_key_0",
    phaseGroupId: "phase_group_A",
    phaseGroupKey: "phase_group_key_A",
  },
  evaluator: {
    accountId: 4,
    displayName: "清水ひかり",
  },
  evaluatee: {
    accountId: 1,
    displayName: "山本登",
  },
  values: {},
};

export const dummyTodo11: EvaluationTodo = {
  id: "todo_11",
  status: "waiting",
  patternId: "pattern_tech_a",
  phase: {
    id: "phase_1",
    key: "phase_key_1",
    phaseGroupId: "phase_group_A",
    phaseGroupKey: "phase_group_key_A",
  },
  evaluator: {
    accountId: 9998,
    displayName: "高田司",
  },
  evaluatee: {
    accountId: 1,
    displayName: "山本登",
  },
  values: {},
};

export const dummyTodo12: EvaluationTodo = {
  id: "todo_12",
  status: "running",
  patternId: "pattern_tech_a",
  phase: {
    id: "phase_1",
    key: "phase_key_1",
    phaseGroupId: "phase_group_A",
    phaseGroupKey: "phase_group_key_A",
  },
  evaluator: {
    accountId: 9998,
    displayName: "高田司",
  },
  evaluatee: {
    accountId: 4,
    displayName: "大野麻衣子",
  },
  values: {},
};

export const dummyTodos = [dummyTodo1, dummyTodo_nakagawa];
