import { EvaluationPersonalData } from "../EvaluationTypes";
const dummyPersonalData1: EvaluationPersonalData = {
  id: "idata_57",
  evaluators: [
    {
      accountId: 100,
      displayName: "岡本まいこ",
      phaseId: "phase_self",
      phaseKey: "phase_key_self",
    },
    {
      accountId: 200,
      displayName: "小倉ゆきえ",
      phaseId: "phase_1",
      phaseKey: "phase_key_1",
    },
  ],
  attributes: [
    {
      id: "team",
      label: "所属チーム",
      value: "PS1",
    },
    {
      id: "group",
      label: "所属グループ",
      value: "GX",
    },
    {
      id: "joinedAtLabel",
      label: "入社日",
      value: "2020-04-01",
    },
    {
      id: "name",
      label: "氏名",
      value: "山本登",
    },
    {
      id: "agRank",
      label: "AGランク",
      value: "OP2",
    },
    {
      id: "assign",
      label: "担当業務分類",
      value: "データ加工・DWW（OP1/2）",
    },
    {
      id: "number",
      label: "従業員番号",
      value: "100",
    },
  ],
  approvedPhaseIndex: -1,
};

const dummyPersonalData_nakagawa: EvaluationPersonalData = {
  id: "idata_nakagawa",
  evaluators: [
    {
      accountId: 100,
      displayName: "岡本まいこ",
      phaseId: "phase_0",
      phaseKey: "phase_key_0",
    },
    {
      accountId: 100,
      displayName: "岡本まいこ",
      phaseId: "phase_1",
      phaseKey: "phase_key_1",
    },
    {
      accountId: 200,
      displayName: "小倉ゆきえ",
      phaseId: "phase_2",
      phaseKey: "phase_key_2",
    },
  ],
  attributes: [
    {
      id: "team",
      label: "所属チーム",
      value: "PS1",
    },
    {
      id: "group",
      label: "所属グループ",
      value: "GX",
    },
    {
      id: "joinedAtLabel",
      label: "入社日",
      value: "2020-04-01",
    },
    {
      id: "name",
      label: "氏名",
      value: "山本登",
    },
    {
      id: "agRank",
      label: "AGランク",
      value: "OP2",
    },
    {
      id: "assign",
      label: "担当業務分類",
      value: "データ加工・DWW（OP1/2）",
    },
    {
      id: "number",
      label: "従業員番号",
      value: "100",
    },
  ],
  approvedPhaseIndex: -1,
};

export const dummyPersonalData = [dummyPersonalData1, dummyPersonalData_nakagawa];
