import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ESProcessMethod,
  ESPhaseGroup,
  ESProcess,
  ESTodo,
  ESScale,
  ESIndicator,
  ESSheetLayout,
  ESWorkflow,
  ESMethodTag,
  ESProject,
} from "./ESTypes";
import {
  dummyESTodos,
  dummyProcessMethods,
  dummyPhaseGroups,
  dummyProcesses,
  dummyMethodTags,
  dummyScales,
  dummyIndicators,
  dummyWorkflows,
  dummyESProjects,
} from "./dummy/ESDummy";

const SLICE_NAME = "evaluationSystem";

interface EvaluationState {
  methodTags: ESMethodTag[];
  methods: ESProcessMethod[];
  projects: ESProject[];
  processes: ESProcess[];
  phaseGroups: ESPhaseGroup[];
  todos: ESTodo[];
  scales: ESScale[];
  sheetLayouts: ESSheetLayout[];
  workflows: ESWorkflow[];
}

const initialState: EvaluationState = {
  methodTags: [],
  methods: [],
  projects: [],
  processes: [],
  phaseGroups: [],
  todos: [],
  scales: [],
  sheetLayouts: [],
  workflows: [],
};

export const getMethodTags = createAsyncThunk(`${SLICE_NAME}/getMethodTags`, async () => {
  return dummyMethodTags;
});

export const getESProjects = createAsyncThunk(`${SLICE_NAME}/getESProjects`, async () => {
  return dummyESProjects;
});

export const getPhases = createAsyncThunk(`${SLICE_NAME}/getPhases`, async () => {
  const methods = dummyProcessMethods;
  const processes = dummyProcesses;
  const phaseGroups = dummyPhaseGroups;
  return {
    phaseGroups,
    methods,
    processes,
  };
});

export const getScales = createAsyncThunk(`${SLICE_NAME}/getScales`, async () => {
  const scales = dummyScales;
  return {
    scales,
  };
});

export const getTodos = createAsyncThunk(`${SLICE_NAME}/getTodos`, async () => {
  return { todos: dummyESTodos };
});

export const getWorkflows = createAsyncThunk(`${SLICE_NAME}/getWorkflows`, async () => {
  return { workflows: dummyWorkflows };
});

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    _someAction: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getMethodTags.fulfilled, (state, action) => {
      state.methodTags = action.payload;
    });
    builder.addCase(getESProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
    });
    builder.addCase(getPhases.fulfilled, (state, action) => {
      state.methods = action.payload.methods;
      state.processes = action.payload.processes;
      state.phaseGroups = action.payload.phaseGroups;
    });
    builder.addCase(getTodos.fulfilled, (state, action) => {
      state.todos = action.payload.todos;
    });
    builder.addCase(getScales.fulfilled, (state, action) => {
      state.scales = action.payload.scales;
    });
    builder.addCase(getWorkflows.fulfilled, (state, action) => {
      state.workflows = action.payload.workflows;
    });
  },
});

export const { _someAction } = slice.actions;

export const selectESState = (state: any) => state[SLICE_NAME] as EvaluationState;

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
