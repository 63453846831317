import { useEffect, useMemo, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { Button, Form, Modal } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Icon from "../../component/Icon";
import { EvaluationProcedureStep, UnitGroup } from "./EvaluationTypes";
import { selectEvaluationState, selectUnitGroupOptions, apply, focusProcedureStep } from "./evaluationSheetSlice";
import { subRoutineMap, subRoutineOptions } from "./procedure";
import EvaluationProcedureEditor from "./EvaluationProcedureEditor";
import classNames from "classnames";
import { yieldId } from "../../app/util";
import { UnitGroupSeedTemplate, UnitGroupSeedUsage } from "./EvaluationTypes";

const emptyTarget = {
  showModal: false,
  procedureId: "",
  field: "",
  subRoutineId: "",
  seedData: {},
  seedDataTypes: {},
};

const originOptions = [
  {
    value: "relative",
    label: "相対的",
  },
  {
    value: "fixed",
    label: "固定",
  },
];

let timer = 0;

function EvUnitGroupEditorPane({
  unitGroup: ug,
  editorMode = "off",
}: {
  unitGroup: UnitGroup;
  editorMode?: "off" | "editing" | "selecting";
}) {
  const dispatch = useAppDispatch();
  const { system, layout, todo, individualMetaData, variables, pattern, focusedProcedureStepId } =
    useAppSelector(selectEvaluationState);
  const unitGroupOptions = useAppSelector(selectUnitGroupOptions);

  const [target, $target] = useState<{
    showModal: boolean;
    procedureId: string;
    field: string;
    subRoutineId: string;
    seedData: any;
    seedDataTypes: any;
  }>({ ...emptyTarget });

  const [stagedSeedTemplate, $stagedSeedTemplate] = useState<UnitGroupSeedTemplate | null>(null);
  const [stagedSeedUsage, $stagedSeedUsage] = useState<UnitGroupSeedUsage | null>(null);
  const someValueStaged = useMemo(() => {
    return !!(stagedSeedTemplate || stagedSeedUsage);
  }, [stagedSeedTemplate, stagedSeedUsage]);

  const [newProcedureModal, $newProcedureModal] = useState({
    active: false,
  });

  /*
    [プロジェクト共通]
    時間差で反映する差分を保持する
  */
  const reapplySeedTemplate = (field: string, updated: any, immediately = false) => {
    if (!stagedSeedTemplate || !system || !layout || !pattern) return;
    /*
      方針
      - 全キーイベントで再レンダリングが発生しないよう、このユニットグループの編集中の状態を stagedSeedTemplate に保持する
      - レイアウト固有のユニットグループを更新する場合、 layout.rows を更新する
      - プロジェクト共通のユニットグループを更新する場合 system.seedTemplates を更新する 
    */
    let next = { ...stagedSeedTemplate };
    if (field === "procedureStep") {
      const nextProcedure = stagedSeedTemplate.procedure.map((p) => (p.id === updated.id ? { ...updated } : p));
      next.procedure = nextProcedure;
      $stagedSeedTemplate({ ...stagedSeedTemplate, procedure: nextProcedure });
    } else if (field === "style") {
      next.preference = updated;
      $stagedSeedTemplate({ ...stagedSeedTemplate, preference: updated });
    } else if (field === "unitGroup") {
      next = {
        ...next,
        name: updated.name,
      };
      $stagedSeedTemplate({ ...next });
    }
    if (timer > 0) {
      clearTimeout(timer);
    }
    timer = +setTimeout(
      () => {
        const nextSystem = {
          ...system,
          seedTemplates:
            editorMode === "editing"
              ? system.seedTemplates.map((seed) => {
                  return seed.id !== ug.id
                    ? seed
                    : ({
                        id: ug.id,
                        projectId: seed.projectId,
                        preference: next.preference,
                        procedure: next.procedure,
                        omittingPatternIds: next.omittingPatternIds,
                        name: next.name,
                      } as UnitGroupSeedTemplate);
                })
              : system.seedTemplates,
        };
        const nextLayout = {
          ...layout,
          rows:
            editorMode === "selecting"
              ? layout.rows.map((row) => {
                  return {
                    ...row,
                    seeds: row.seeds.map((seed) => {
                      return seed.id === ug.id
                        ? {
                            id: seed.id,
                            templateId: next.id,
                            omittingPatternIds: seed.omittingPatternIds,
                            preference: seed.preference,
                          }
                        : seed;
                    }),
                  };
                })
              : layout.rows,
        };
        dispatch(
          apply({
            system: nextSystem,
            layoutUsage: nextLayout,
            pattern,
            todo,
            individualMetaData,
            variables,
            answers: {},
            useSeedTemplateDefault: true,
          })
        );
        timer = 0;
      },
      immediately ? 0 : 500
    );
  };

  /*
    [レイアウト固有]
    時間差で反映する差分を保持する
  */
  const reapplyUsage = (field: string, updated: any, immediately = false) => {
    if (!stagedSeedUsage || !system || !layout || !pattern) return;
    let next = { ...stagedSeedUsage };
    if (field === "style") {
      next.preference = updated;
      $stagedSeedUsage({ ...stagedSeedUsage, preference: updated });
    } else if (field === "unitGroup") {
      next = {
        ...next,
        templateId: updated.id,
      };
      $stagedSeedUsage({
        ...stagedSeedUsage,
        templateId: updated.id,
      });
    }
    if (timer > 0) {
      clearTimeout(timer);
    }
    timer = +setTimeout(
      () => {
        const nextLayout = {
          ...layout,
          rows: layout.rows.map((row) => {
            return {
              ...row,
              seeds: row.seeds.map((seed) => {
                return seed.id === ug.id
                  ? {
                      id: seed.id,
                      templateId: next.templateId,
                      omittingPatternIds: next.omittingPatternIds,
                      preference: next.preference,
                    }
                  : seed;
              }),
            };
          }),
        };
        dispatch(
          apply({
            system,
            layoutUsage: nextLayout,
            pattern,
            todo,
            individualMetaData,
            variables,
            answers: {},
            useSeedTemplateDefault: false,
          })
        );
        timer = 0;
      },
      immediately ? 0 : 500
    );
  };

  const startEditing = () => {
    if (!layout || !system) return;
    if (editorMode === "editing") {
      let seedTemplate: UnitGroupSeedTemplate | null = null;
      system.seedTemplates.forEach((s) => {
        if (s.id === ug.id) seedTemplate = s;
      });
      if (!seedTemplate) return;
      $stagedSeedTemplate(seedTemplate);
    } else {
      let seedUsage: UnitGroupSeedUsage | null = null;
      layout.rows.forEach((row) => {
        row.seeds.forEach((s) => {
          if (s.id === ug.id) seedUsage = s;
        });
      });
      if (!seedUsage) return;
      $stagedSeedUsage(seedUsage);
    }
  };
  const commit = () => {
    if (timer > 0) return;
    $stagedSeedTemplate(null);
    $stagedSeedUsage(null);
    dispatch(focusProcedureStep(""));
  };
  useEffect(() => {
    if (!stagedSeedUsage && !stagedSeedTemplate && ug.procedure.some((p) => p.id === focusedProcedureStepId)) {
      startEditing();
    }
  }, [stagedSeedUsage, stagedSeedTemplate, ug, focusedProcedureStepId]);

  /*
    [レイアウト固有]
    ユニットグループを削除する
  */
  const removeUnitGroup = () => {
    if (!system || !layout || !pattern || editorMode !== "selecting") return;
    else if (!window.confirm("本当に削除しますか？")) return;
    const nextLayoutUsage = {
      ...layout,
      rows: layout.rows.map((row) => {
        return {
          ...row,
          seeds: row.seeds.filter((seed) => {
            return seed.id !== ug.id;
          }),
        };
      }),
    };
    dispatch(
      apply({
        system,
        layoutUsage: nextLayoutUsage,
        pattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: false,
      })
    );
  };

  /*
    [プロジェクト共通]
    手順を追加する
  */
  const addProcedureStep = () => {
    if (!system || !layout || !pattern || editorMode !== "editing" || !stagedSeedTemplate) return;
    const subRoutine = subRoutineMap[subRoutineOptions[0].value];
    if (!subRoutine) return;
    const newProcedureStep = {
      id: `step_${yieldId()}`,
      subRoutineId: subRoutineOptions[0].value,
      origin: {
        row: stagedSeedTemplate.procedure.length > 0 ? `FOLLOW$$${stagedSeedTemplate.procedure.length - 1}` : 1,
        column: 1,
      },
      argv: { ...subRoutine.sampleArgv },
    } as EvaluationProcedureStep;
    const procedure = [...stagedSeedTemplate.procedure, newProcedureStep];
    const nextSystem = {
      ...system,
      seedTemplates: system.seedTemplates.map((seed) => {
        if (seed.id !== ug.id) {
          return seed;
        } else {
          const _procedure = [...seed.procedure, newProcedureStep];
          $stagedSeedTemplate({ ...stagedSeedTemplate, procedure });
          return {
            ...seed,
            procedure: _procedure,
          };
        }
      }),
    };
    $stagedSeedTemplate({ ...stagedSeedTemplate, procedure });
    dispatch(
      apply({
        system: nextSystem,
        layoutUsage: layout,
        pattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: true,
      })
    );
  };

  /*
    [プロジェクト共通]
    手順を削除する
  */
  const removeProcedureStep = (id: string) => {
    // procedureStep を削除する際、pattern.seedData から対応するデータも削除。
    // origin が FOLLOW$$ である場合、その後ろの procedureStep の origin を更新する。
    if (!system || !layout || !pattern || editorMode !== "editing" || !stagedSeedTemplate) return;
    const setNextOrigins = (step: EvaluationProcedureStep, deletingStepIndex: number) => {
      const _origin = { ...step.origin };
      if (`${_origin.row}`.includes("FOLLOW") && +`${_origin.row}`.split("$$")[1] >= deletingStepIndex) {
        const v = +`${_origin.row}`.split("$$")[1] - 1;
        _origin.row = v >= 0 ? `FOLLOW$$${v}` : 1;
      }
      if (`${_origin.column}`.includes("FOLLOW") && +`${_origin.column}`.split("$$")[1] >= deletingStepIndex) {
        const v = +`${_origin.row}`.split("$$")[1] - 1;
        _origin.column = v >= 0 ? `FOLLOW$$${v}` : 1;
      }
      return {
        ...step,
        origin: _origin,
      };
    };
    const nextSystem = {
      ...system,
      seedTemplates: system.seedTemplates.map((seed) => {
        if (seed.id === ug.id) {
          return seed;
        } else {
          const deletingStepIndex = seed.procedure.findIndex((p) => p.id === id);
          const _procedure = seed.procedure
            .map((p, i) => {
              if (i < deletingStepIndex) return p;
              else if (i === deletingStepIndex) return;
              else {
                return setNextOrigins(p, deletingStepIndex);
              }
            })
            .filter((p) => p) as EvaluationProcedureStep[];
          $stagedSeedTemplate({ ...stagedSeedTemplate, procedure: _procedure });
          return {
            ...seed,
            procedure: _procedure,
          };
        }
      }),
    };

    const nextPattern = {
      ...pattern,
      seedData: Object.keys(pattern.seedData).reduce((prev, current) => {
        return {
          ...prev,
          [current]: Object.keys(pattern.seedData[current]).reduce((prev2, current2) => {
            if (current2 === id) return prev2;
            return {
              ...prev2,
              [current2]: pattern.seedData[current][current2],
            };
          }, {}),
        };
      }, {}),
    };

    dispatch(
      apply({
        system: nextSystem,
        layoutUsage: layout,
        pattern: nextPattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: true,
      })
    );
  };

  const setUsageProcedureProps = async () => {
    if (!system || !layout || !pattern || !stagedSeedUsage) return;
    const nextPattern = {
      ...pattern,
      seedData: {
        ...pattern.seedData,
        [ug.id]: Object.keys(pattern.seedData[ug.id]).reduce((prev, current) => {
          if (current !== target.procedureId) return { ...prev, [current]: pattern.seedData[ug.id][current] };
          return {
            ...prev,
            [current]: Object.keys(pattern.seedData[ug.id][target.procedureId]).reduce((prev2, current2) => {
              if (current2 !== target.field) {
                return { ...prev2, [current2]: pattern.seedData[ug.id][current][current2] };
              }
              return { ...prev2, [current2]: target.seedData };
            }, {} as any),
          };
        }, {} as any),
      },
    };
    dispatch(
      apply({
        system,
        layoutUsage: layout,
        pattern: nextPattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: false,
      })
    );
    $target({
      ...emptyTarget,
    });
  };

  const setTemplateProcedureProps = async () => {
    if (!system || !layout || !pattern || !stagedSeedTemplate) return;
    const nextSeed = {
      ...stagedSeedTemplate,
      procedure: stagedSeedTemplate.procedure.map((p) => {
        if (p.id !== target.procedureId) return p;
        return {
          ...p,
          argv: {
            ...p.argv,
            [target.field]: target.seedData,
          },
        };
      }),
    };
    $stagedSeedTemplate({ ...nextSeed });

    const nextSystem = {
      ...system,
      seedTemplates: system.seedTemplates.map((seed) => {
        return seed.id !== ug.id ? seed : { ...nextSeed };
      }),
    };

    dispatch(
      apply({
        system: nextSystem,
        layoutUsage: layout,
        pattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: true,
      })
    );
    $target({
      ...emptyTarget,
    });
  };

  // パターンごとの編集時とおおもとの編集時で分ける
  if (editorMode === "off") return <></>;
  return (
    <div>
      {
        <div className="Ev-unit-group__header">
          {!someValueStaged && (
            <div className="--flex --space-between mb-2">
              <Button onClick={startEditing} variant="primary" size="sm">
                編集
              </Button>
              {editorMode === "selecting" && (
                <Button onClick={removeUnitGroup} variant="outline-danger" size="sm">
                  削除
                </Button>
              )}
            </div>
          )}
          {someValueStaged && (
            <div className="--flex mb-2">
              <Button onClick={commit} variant="primary" size="sm">
                閉じる
              </Button>
            </div>
          )}
          {!someValueStaged ? (
            <div className="Ev-unit-group__name">{ug.name}</div>
          ) : editorMode === "editing" ? (
            stagedSeedTemplate && (
              <div className="--flex --align-items-center">
                <div className="mx-1 --font-s --shrink-0">名前</div>
                <Form.Control
                  className="--font-s"
                  defaultValue={stagedSeedTemplate.name}
                  onChange={(e) => {
                    reapplySeedTemplate("unitGroup", { ...stagedSeedTemplate, name: e.target.value });
                  }}
                />
              </div>
            )
          ) : editorMode === "selecting" ? (
            stagedSeedUsage && (
              <Form.Select
                value={ug.templateId}
                className="--font-s"
                onChange={(e) => {
                  if (!system) return;
                  const seedTemplate = system.seedTemplates.find((seed) => {
                    return seed.id === e.target.value;
                  });
                  if (!seedTemplate) return;
                  reapplyUsage("unitGroup", seedTemplate, true);
                  return false;
                }}
              >
                {unitGroupOptions.map((option, i) => {
                  return (
                    <option value={option.value} key={`option${i}`}>
                      {option.label}
                    </option>
                  );
                })}
              </Form.Select>
            )
          ) : null}
          <div className="Ev-unit-group__procedure">
            {(stagedSeedTemplate || ug).procedure.map((p, i) => {
              return (
                <div
                  id={p.id}
                  key={`procedure${i}`}
                  onClick={() => someValueStaged && dispatch(focusProcedureStep(p.id))}
                  className={classNames({
                    "Ev-unit-group__procedure-item": true,
                    "--expanded": focusedProcedureStepId === p.id,
                  })}
                >
                  {!someValueStaged ? (
                    <div className="Ev-unit-group__procedure-label">{subRoutineMap[p.subRoutineId]?.label ?? ""}</div>
                  ) : editorMode === "editing" ? (
                    <div>
                      <div className="--font-s --shrink-0 --bold">種類</div>
                      <Form.Select
                        value={p.subRoutineId}
                        onChange={(e) => {
                          return;
                        }}
                        className="--font-s"
                      >
                        {subRoutineOptions.map((s) => (
                          <option key={s.value} value={s.value}>
                            {s.label}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  ) : editorMode === "selecting" ? (
                    <div className="--font-s">
                      {subRoutineOptions.find((s) => s.value === p.subRoutineId)?.label ?? ""}
                    </div>
                  ) : null}
                  <div
                    className={classNames({
                      "Ev-unit-group__procedure-content": true,
                      "--expanded": focusedProcedureStepId === p.id,
                    })}
                  >
                    {editorMode === "editing" && (
                      <div className="mt-2">
                        <div className="--font-s --shrink-0 --bold">位置</div>
                        {(() => {
                          const policy = `${p.origin.row}`.includes("FOLLOW") ? "relative" : "fixed";
                          const followingStepIndex = policy === "relative" ? +`${p.origin.row}`.split("$$")[1] : 0;
                          const stepIndexOptions = new Array(i).fill("_").map((_, _i) => {
                            return {
                              label: `${_i + 1}の下`,
                              value: _i,
                            };
                          });
                          return (
                            <div className="--font-s --flex --align-items-center">
                              <div>行: </div>
                              {stagedSeedTemplate ? (
                                <Form.Select
                                  value={policy}
                                  disabled={i === 0}
                                  onChange={(e) => {
                                    if (e.target.value === "relative") {
                                      reapplySeedTemplate("procedureStep", {
                                        ...p,
                                        origin: {
                                          ...p.origin,
                                          row: `FOLLOW$$0`,
                                        },
                                      });
                                    } else {
                                      reapplySeedTemplate("procedureStep", {
                                        ...p,
                                        origin: {
                                          ...p.origin,
                                          row: 1,
                                        },
                                      });
                                    }
                                  }}
                                  className="--font-s mx-1"
                                >
                                  {originOptions.map((s) => (
                                    <option key={s.value} value={s.value}>
                                      {s.label}
                                    </option>
                                  ))}
                                </Form.Select>
                              ) : (
                                <div>{originOptions.find((_) => _.value === policy)?.label ?? ""}</div>
                              )}
                              {policy === "relative" ? (
                                stagedSeedTemplate ? (
                                  <Form.Select
                                    value={followingStepIndex}
                                    onChange={(e) => {
                                      reapplySeedTemplate("procedureStep", {
                                        ...p,
                                        origin: {
                                          ...p.origin,
                                          row: `FOLLOW$$${+e.target.value}`,
                                        },
                                      });
                                    }}
                                    className="--font-s"
                                  >
                                    {stepIndexOptions.map((s) => (
                                      <option key={s.value} value={s.value}>
                                        {s.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                ) : (
                                  <div>
                                    &nbsp;/&nbsp;{stepIndexOptions.find((_) => _.value === followingStepIndex)?.label}
                                  </div>
                                )
                              ) : stagedSeedTemplate ? (
                                <Form.Control
                                  className="--font-s"
                                  defaultValue={p.origin.row}
                                  onChange={(e) => {
                                    const v = +e.target.value;
                                    if (Number.isNaN(v)) return;
                                    reapplySeedTemplate("procedureStep", {
                                      ...p,
                                      origin: {
                                        ...p.origin,
                                        row: v > 0 ? v : 1,
                                      },
                                    });
                                  }}
                                />
                              ) : (
                                <div>&nbsp;/&nbsp;{p.origin.row}</div>
                              )}
                            </div>
                          );
                        })()}
                        {(() => {
                          const policy = `${p.origin.column}`.includes("FOLLOW") ? "relative" : "fixed";
                          const followingStepIndex = policy === "relative" ? +`${p.origin.column}`.split("$$")[1] : 0;
                          const stepIndexOptions = new Array(i).fill("_").map((_, _i) => {
                            return {
                              label: `${_i + 1}の右`,
                              value: _i,
                            };
                          });
                          return (
                            <div className="--font-s --flex --align-items-center">
                              <div>列: </div>
                              {stagedSeedTemplate ? (
                                <Form.Select
                                  value={policy}
                                  onChange={(e) => {
                                    if (e.target.value === "relative") {
                                      reapplySeedTemplate("procedureStep", {
                                        ...p,
                                        origin: {
                                          ...p.origin,
                                          column: `FOLLOW$$0`,
                                        },
                                      });
                                    } else {
                                      reapplySeedTemplate("procedureStep", {
                                        ...p,
                                        origin: {
                                          ...p.origin,
                                          column: 1,
                                        },
                                      });
                                    }
                                  }}
                                  className="--font-s mx-1"
                                >
                                  {originOptions.map((s) => (
                                    <option key={s.value} value={s.value}>
                                      {s.label}
                                    </option>
                                  ))}
                                </Form.Select>
                              ) : (
                                <div>{originOptions.find((_) => _.value === policy)?.label ?? ""}</div>
                              )}
                              {policy === "relative" ? (
                                stagedSeedTemplate ? (
                                  <Form.Select
                                    value={followingStepIndex}
                                    onChange={(e) => {
                                      reapplySeedTemplate("procedureStep", {
                                        ...p,
                                        origin: {
                                          ...p.origin,
                                          column: `FOLLOW$$${+e.target.value}`,
                                        },
                                      });
                                    }}
                                    className="--font-s"
                                  >
                                    {stepIndexOptions.map((s) => (
                                      <option key={s.value} value={s.value}>
                                        {s.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                ) : (
                                  <div>
                                    &nbsp;/&nbsp;{stepIndexOptions.find((_) => _.value === followingStepIndex)?.label}
                                  </div>
                                )
                              ) : stagedSeedTemplate ? (
                                <Form.Control
                                  className="--font-s"
                                  defaultValue={p.origin.column}
                                  onChange={(e) => {
                                    const v = +e.target.value;
                                    if (Number.isNaN(v)) return;
                                    reapplySeedTemplate("procedureStep", {
                                      ...p,
                                      origin: {
                                        ...p.origin,
                                        column: v > 0 ? v : 1,
                                      },
                                    });
                                  }}
                                />
                              ) : (
                                <div>&nbsp;/&nbsp;{p.origin.column}</div>
                              )}
                            </div>
                          );
                        })()}
                      </div>
                    )}
                    <div className="mt-2">
                      <div className="--font-s --shrink-0 --bold">
                        {editorMode === "editing" ? "属性" : "パターン属性"}
                      </div>
                      {(() => {
                        const subRoutine = subRoutineMap[p.subRoutineId];
                        const fields = subRoutine?.seedDataTypes ? Object.keys(subRoutine.seedDataTypes) : [];
                        return (
                          <div className="--font-s">
                            {fields.map((f, i) => {
                              const description = subRoutine.seedDataTypes[f].description;
                              return (
                                <div
                                  key={`field${i}`}
                                  onClick={() => {
                                    if (!someValueStaged) return;
                                    $target({
                                      showModal: true,
                                      procedureId: p.id,
                                      field: f,
                                      subRoutineId: p.subRoutineId,
                                      seedData: ug.useSeedTemplateDefault
                                        ? p.argv[f]
                                        : pattern?.seedData[ug.id]?.[p.id]?.[f] ?? [],
                                      seedDataTypes: subRoutine.seedDataTypes[f],
                                    });
                                  }}
                                  className="--bullet flex"
                                >
                                  <span>{description}</span>
                                  {stagedSeedTemplate && (
                                    <Icon type="pencil-square" width={16} height={16} className="mx-1" />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })()}
                    </div>
                    {editorMode === "editing" && (
                      <div className="Ev-unit-group__procedure-bottom-controls">
                        <Button onClick={() => removeProcedureStep(p.id)} variant="outline-danger" size="sm">
                          削除
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            {editorMode === "editing" && stagedSeedTemplate && (
              <Button
                variant="outline-primary"
                onClick={() => $newProcedureModal({ ...newProcedureModal, active: true })}
                size="sm"
              >
                パーツを追加
              </Button>
            )}
            {editorMode === "selecting" && stagedSeedUsage && (
              <div className="Ev-unit-group__styles mt-2">
                <div className="mb-1 --bold --font-s">スタイル</div>
                <Form.Control
                  onChange={(e) => {
                    reapplyUsage("style", e.target.value.split(","));
                  }}
                  type="text"
                  placeholder=""
                  value={stagedSeedUsage.preference}
                />
              </div>
            )}
          </div>
        </div>
      }
      <Modal
        show={target.showModal}
        size="lg"
        centered
        onHide={() => {
          $target({ ...emptyTarget });
        }}
      >
        <Modal.Header>{target.seedDataTypes.description}</Modal.Header>
        <Modal.Body>
          <EvaluationProcedureEditor
            seedData={target.seedData}
            seedDataTypes={target.seedDataTypes}
            onChange={(diff: any) => {
              const type =
                target.seedDataTypes.data.type !== "array"
                  ? target.seedDataTypes.data.type
                  : target.seedDataTypes.data.schema[diff.field].type;
              $target({
                ...target,
                seedData:
                  diff.index === undefined
                    ? {
                        ...target.seedData,
                        [diff.field]: diff.value,
                      }
                    : target.seedData.map((seed: any, i: number) => {
                        if (i !== diff.index) return seed;
                        if (!type.includes("[]")) {
                          return {
                            ...seed,
                            [diff.field]: diff.value,
                          };
                        } else {
                          const nextValues = target.seedData[i][diff.field].map((v: string, vi: number) =>
                            vi !== diff.subSerialNumber ? v : diff.value
                          );
                          return {
                            ...seed,
                            [diff.field]: nextValues,
                          };
                        }
                      }),
              });
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={() => $target({ ...emptyTarget })}>
            キャンセル
          </Button>
          <Button
            variant="primary"
            onClick={editorMode === "editing" ? setTemplateProcedureProps : setUsageProcedureProps}
          >
            保存
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={newProcedureModal.active}
        size="lg"
        centered
        onHide={() => {
          $newProcedureModal({
            ...newProcedureModal,
            active: false,
          });
        }}
      >
        <Modal.Header>パーツを追加</Modal.Header>
        <Modal.Body>追加するパーツをここで選択させる</Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => {
              $newProcedureModal({
                ...newProcedureModal,
                active: false,
              });
            }}
          >
            キャンセル
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              $newProcedureModal({
                ...newProcedureModal,
                active: false,
              });
              addProcedureStep();
            }}
          >
            保存
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EvUnitGroupEditorPane;
