import { createAsyncThunk, createSlice, EnhancedStore, PayloadAction } from "@reduxjs/toolkit";
import { RootState, externalEffects } from "../../app/store";
import { collection, query, limit, orderBy, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../features/firebase/init";
import { toTimeLabel } from "../../app/util";
import { call } from "../../app/api";

const SLICE_NAME = "notification";

export interface Toast {
  active: boolean;
  content: string;
  strong: string | null;
  id: string;
}
export interface NotificationItem {
  content: string;
  checked: boolean;
  createdAt: number;
  timeLabel: string;
  id: string;
  url?: string;
}
interface NotificationState {
  toasts: Toast[];
  notifications: NotificationItem[];
  loading: boolean;
  initialized: boolean;
  fetchedNotificationsPage: number;
  noCheckNotifications: string[];
  hasMore: boolean;
}

const initialState: NotificationState = {
  toasts: [],
  notifications: [],
  loading: false,
  initialized: false,
  fetchedNotificationsPage: 0,
  noCheckNotifications: [],
  hasMore: false,
};

export const putNotificationsRead = createAsyncThunk(
  SLICE_NAME + "/putNotificationsRead",
  async ({ ids }: { ids: string[] }) => {
    const res = await call(
      "put",
      "activity_manager/notification"
    )({
      id__in: ids,
      read: true,
    });
    const notifications = res.data.result.map((data: any) => {
      return {
        id: data.id,
        content: data.message,
        checked: data.read,
        createdAt: data.created_at,
        timeLabel: toTimeLabel(data.created_at),
        url: data.url,
      };
    }) as NotificationItem[];
    return { notifications };
  }
);

/*
  onStoreInitialized の中で getNotifications を dispatch できないので、
  共通部分を抜粋
*/
const _getNotifications = async ({ page }: { page: number }) => {
  const res = await call("get", "activity_manager/notification", { doReload: false })({
    sort_by: '[{ "created_at": -1 }]',
    page,
    limit: GET_NOTIFICATIONS__DEFAULT_LIMIT,
  });
  const newNotifications: NotificationItem[] = res.data.result.map(
    (data: { account_id: number; id: string; read: boolean; message: string; url: string; created_at: number }) => {
      return {
        id: data.id,
        url: data.url,
        content: data.message,
        checked: data.read,
        createdAt: data.created_at,
        timeLabel: toTimeLabel(data.created_at),
      };
    }
  );
  return {
    page: page,
    notifications: newNotifications,
    has_more: res.data.has_more,
  };
};

export const getNotifications = createAsyncThunk(SLICE_NAME + "/getNotifications", _getNotifications);

export const getNoCheckNotifications = createAsyncThunk(SLICE_NAME + "/getNoCheckNotifications", async () => {
  const res = await call("get", "activity_manager/notification", { doReload: false })({ read: false });
  const noCheckNotifications: string[] = res.data.result.map((data: any) => data.id);
  return { noCheckNotifications };
});

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<{ id: string; content: string; strong?: string }>) => {
      if (state.toasts.every((_) => _.id !== action.payload.id)) {
        state.toasts = [
          ...state.toasts,
          {
            active: true,
            content: action.payload.content,
            strong: action.payload.strong || null,
            id: action.payload.id,
          },
        ];
      }
    },
    hideToast: (state, action: PayloadAction<{ id: string }>) => {
      state.toasts = state.toasts.filter((t) => t.id !== action.payload.id);
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addNotification: (
      state,
      action: PayloadAction<{
        content: string;
        createdAt: number;
        timeLabel: string;
        id: string;
        checked: boolean;
        url?: string;
      }>
    ) => {
      // 既に同じidの通知があれば、何もしない
      if (state.notifications.some((n) => n.id === action.payload.id)) return;
      const next = [
        {
          id: action.payload.id,
          createdAt: action.payload.createdAt,
          timeLabel: action.payload.timeLabel,
          content: action.payload.content,
          url: action.payload.url,
          checked: action.payload.checked,
        },
        ...state.notifications,
      ];
      next.sort((a, b) => b.createdAt - a.createdAt);
      state.notifications = next.slice(0, GET_NOTIFICATIONS__DEFAULT_LIMIT);
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    setFetchedNotificationsPage: (state, action: PayloadAction<number>) => {
      state.fetchedNotificationsPage = action.payload;
    },
    setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(putNotificationsRead.fulfilled, (state, action) => {
      state.notifications = state.notifications.map((n) => {
        return action.payload.notifications.find((_n) => _n.id === n.id) ?? n;
      });
    });
    builder.addCase(
      getNotifications.fulfilled,
      (
        state,
        action: PayloadAction<{
          notifications: NotificationItem[];
          page: number;
          has_more: boolean;
        }>
      ) => {
        state.notifications =
          action.payload.page === 1
            ? action.payload.notifications
            : [...state.notifications, ...action.payload.notifications];
        state.fetchedNotificationsPage = action.payload.page;
        state.hasMore = action.payload.has_more;
      }
    );
    builder.addCase(getNoCheckNotifications.fulfilled, (state, action) => {
      state.noCheckNotifications = action.payload.noCheckNotifications;
    });
  },
});

export const {
  showToast,
  hideToast,
  setLoading,
  addNotification,
  setInitialized,
  setFetchedNotificationsPage,
  setHasMore,
  clearNotifications,
} = slice.actions;

export const selectNotificationState = (state: RootState) => {
  return state.notification as NotificationState;
};

export const selectUnreadNotifications = (state: RootState) => {
  return state.notification.noCheckNotifications;
};

export const GET_NOTIFICATIONS__DEFAULT_LIMIT = 2;

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
  onStoreInitialized: async (store: EnhancedStore) => {
    // 初回の通知取得
    try {
      const { notifications, page, has_more } = await _getNotifications({ page: 1 });
      notifications.forEach((n) => {
        store.dispatch(addNotification(n));
      });
      store.dispatch(setFetchedNotificationsPage(page));
      store.dispatch(setHasMore(has_more));
    } catch (e) {}

    // firestore イベントリスナー設定
    try {
      const { user } = store.getState().user;
      const currentCompanyId = `${user.current_company.id}`;
      const userId = `${user.id}`;
      const companies = collection(db, "company");
      const companyDoc = doc(companies, currentCompanyId);
      const accounts = collection(companyDoc, "account");
      const account = doc(accounts, userId);
      const notifications = collection(account, "notification");
      const q = query(notifications, orderBy("createdAt", "desc"), limit(3));

      onSnapshot(q, async (snapshot) => {
        const { notification } = store.getState();
        const notificationDataList = await Promise.all(
          snapshot
            .docChanges()
            .filter((change) => change.type === "added")
            .map(async (change) => {
              const { externalEffect, externalEffectUsed, payload, user_notification_id } = change.doc.data();
              if (!notification.initialized) return;
              let _content = "",
                _url = "",
                _created_at = 0;
              if (user_notification_id) {
                try {
                  const res = await call("get", "activity_manager/notification", { doReload: false })({
                    id: user_notification_id,
                  });
                  const data = res.data.result[0];
                  const { message, url, created_at } = data;
                  _content = message;
                  _url = url;
                  _created_at = created_at;
                } catch (e) {}
              }

              if (_content) {
                /*
                初期化後に追加されたドキュメントに
                content があれば
                ユーザー向けにメッセージを表示する
              */
                store.dispatch(
                  showToast({
                    id: change.doc.ref.id,
                    content: _content,
                  })
                );
              }

              return {
                id: user_notification_id,
                url: _url,
                content: _content,
                checked: false,
                createdAt: _created_at,
                timeLabel: toTimeLabel(_created_at),
                externalEffect,
                externalEffectUsed,
                payload,
              };
            })
        );
        notificationDataList
          .filter((_) => _)
          .map(async (data) => {
            data!.content &&
              store.dispatch(
                addNotification({
                  id: data!.id,
                  url: data!.url,
                  content: data!.content,
                  checked: data!.checked,
                  createdAt: data!.createdAt,
                  timeLabel: data!.timeLabel,
                })
              );
            if (externalEffects[data!.externalEffect] && !data!.externalEffectUsed) {
              await externalEffects[data!.externalEffect](data!.payload);
            }
          });
        if (!notification.initialized) {
          store.dispatch(setInitialized(true));
        }
      });
    } catch (e) {
      console.log(e);
    }
  },
};
export default Module;
