import { useEffect, useState, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { Container, Row, Col, Form, Button, Tabs, Tab, Modal, Alert, Accordion } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  selectEvaluationState,
  apply,
  setOpenPane,
  setEditTarget,
  getEvaluationSystems,
  getEvaluationSystem,
  setContextMenu,
  focusProcedureStep,
  commitChanges,
} from "./evaluationSheetSlice";
import { selectLayoutState } from "../layout/layoutSlice";
import Icon from "../../component/Icon";
import Sidebar_Evaluation from "../../component/Sidebar_Evaluation";
import ContextMenu, { ContextMenuItem } from "../../component/ContextMenu";
import EvaluationConditionsControl from "./EvaluationConditionsControl";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import {
  emptyEvaluationPersonalData,
  emptyEvaluationTodo,
  unitAppearanceOptions,
  procedureOptions,
} from "./evaluationValues";
import { userColumnChoices } from "../profile/profileFieldValues";
import { yieldId } from "../../app/util";
import EvLayoutRow from "./LayoutRow";
import EvaluationEditorStyleSnippets from "./EvaluationEditorStyleSnippets";
import EvaluationEditorPhase from "./EvaluationEditorPhase";
import { setLoading } from "../notification/notificationSlice";
import { getESProjects, selectESState } from "../evaluationSystem/evaluationSystemSlice";

function App() {
  const { systemId = "DEFAULT", patternId } = useParams();
  const dispatch = useAppDispatch();
  const {
    evaluation,
    variables,
    systems,
    system,
    todo,
    individualMetaData,
    patterns,
    layouts,
    pattern,
    layout,
    previewWithEditor,
  } = useAppSelector(selectEvaluationState);
  const { projects } = useAppSelector(selectESState);
  const { isTouchDevice } = useAppSelector(selectLayoutState);
  const [fitScreen, $fitScreen] = useState(false);
  const [activeTab, $activeTab] = useState({
    root: "pattern",
    pattern: "sheet",
    layout: "cssTraits",
    project: "unitGroup",
  });
  const [activeModal, $activeModal] = useState("");
  const [versionSwitchingModal, $versionSwitchingModal] = useState({
    show: false,
    nextSystem: "",
  });
  const [layoutMetaModal, $layoutMetaModal] = useState({
    active: false,
  });

  const contextMenuItems: ContextMenuItem[] = [
    {
      label: "プリセット",
      key: "insert",
    },
    {
      label: "挿入 - １行（下）",
      key: "insert_below",
    },
  ];

  useEffect(() => {
    dispatch(getESProjects());
    dispatch(getEvaluationSystems());
  }, []);

  useEffect(() => {
    /*
        - プロジェクトを取得
        - 最後に編集されたパターンを取得
        - パターンからレイアウト、変数を取得
        - apply
      */
    dispatch(
      getEvaluationSystem({
        id: systemId,
        patternId,
      })
    ).then((res: any) => {
      if (!res.payload?.system) return;
      const { system } = res.payload;
      dispatch(setEditTarget({ systemId: system.id }));
    });
  }, [systemId]);

  const switchLayout = (id: string) => {
    const layoutUsage = layouts.find((l) => l.id === id);
    if (!system || !layoutUsage || !pattern) return;
    dispatch(
      apply({
        system,
        layoutUsage,
        pattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: false,
      })
    );
  };

  const onContextMenuSelect = (item: ContextMenuItem) => {
    dispatch(setContextMenu({ active: false, x: 0, y: 0 }));
    // TODO 右クリックメニューの使い方検討
  };

  const switchPattern = (id: string) => {
    const pattern = patterns.find((l) => l.id === id);
    const layoutUsage = layouts.find((l) => l.id === pattern?.layoutId);
    if (!system || !pattern || !layoutUsage) return;
    dispatch(
      apply({
        system,
        layoutUsage,
        pattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: false,
      })
    );
  };

  const openSheetTab = () => {
    if (!system || !layout || !pattern) return;
    dispatch(
      apply({
        system,
        layoutUsage: layout,
        pattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: false,
      })
    );
    dispatch(setOpenPane(""));
  };

  const openUnitGroupsTab = () => {
    if (!system || !layout || !pattern) return;
    dispatch(
      apply({
        system,
        layoutUsage: layout,
        pattern,
        todo: emptyEvaluationTodo,
        individualMetaData: emptyEvaluationPersonalData,
        variables: {},
        answers: {},
        useSeedTemplateDefault: true,
      })
    );
    dispatch(setOpenPane(""));
  };

  const addLayoutRow = () => {
    if (!system || !layout || !pattern) return;
    dispatch(
      apply({
        system,
        layoutUsage: {
          ...layout,
          rows: [
            ...layout.rows,
            {
              id: `row-${yieldId()}`,
              seeds: [],
              preference: [],
            },
          ],
        },
        pattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: false,
      })
    );
  };

  const addSeedTemplate = () => {
    if (!system || !layout || !pattern) return;
    dispatch(
      apply({
        system: {
          ...system,
          seedTemplates: [
            {
              id: `seed_${yieldId()}`,
              name: "新しいユニットグループ",
              omittingPatternIds: [],
              preference: [],
              procedure: [],
              projectId: system.id,
            },
            ...system.seedTemplates,
          ],
        },
        layoutUsage: layout,
        pattern,
        todo,
        individualMetaData,
        variables,
        answers: {},
        useSeedTemplateDefault: true,
      })
    );
  };

  const _commitChanges = async () => {
    dispatch(setLoading(true));
    await dispatch(commitChanges());
    dispatch(setLoading(false));
  };

  const variableOptions = useMemo(() => {
    if (!evaluation?.layoutRows.length) return [];
    const options = evaluation.layoutRows
      .map((row) => row.unitGroups)
      .flat()
      .map((ug) => {
        return ug.units
          .filter((u) => u.description)
          .map((u) => {
            return {
              value: u.id ?? "",
              label: u.description ?? "",
            };
          });
      })
      .flat();
    return [
      {
        value: "CONTEXT",
        label: "コンテキスト",
      },
      ...options,
    ];
  }, [evaluation]);

  const behaviorDefinitionOptions = useMemo(() => {
    if (!layout?.behaviorDefinition) return [];
    return Object.keys(layout.behaviorDefinition).map((key) => {
      return {
        value: key,
        label: layout.behaviorDefinition[key].description,
      };
    });
  }, [layout]);

  useEffect(() => {
    $fitScreen(isTouchDevice);
  }, [isTouchDevice]);

  useEffect(() => {
    dispatch(setEditTarget({ systemId: systemId }));
  }, [systemId]);

  if (!evaluation || !system) return <div />;
  return (
    <div className="Ev-container --editor">
      <Sidebar_Evaluation current="system_settings/layout" />
      <main className="Ev-edit-content bg-white">
        <Row className="mb-2 --align-items-center">
          <Col
            md={3}
            className="--font-s"
            onClick={() => {
              if (!layout || !pattern) return;
              dispatch(
                apply({
                  system,
                  todo,
                  layoutUsage: layout,
                  pattern,
                  individualMetaData,
                  variables,
                  useSeedTemplateDefault: false,
                })
              );
            }}
          >
            制度プロジェクト
          </Col>
          <Col md={5}>
            <Form.Select
              value={projects[0]?.project_id ?? ""}
              disabled={system?.label === "_NEW"}
              onChange={(e) => {
                $versionSwitchingModal({
                  show: true,
                  nextSystem: e.target.value,
                });
              }}
            >
              {projects.map((p, i) => {
                return (
                  <option key={`option_${i}`} value={p.project_id}>
                    {p.project_name}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mb-2 --align-items-center">
          <Col md={3} className="--font-s">
            パターン
          </Col>
          <Col md={5}>
            <Form.Select value={pattern?.id} onChange={(e) => switchPattern(e.target.value)}>
              {patterns.map((p, i) => {
                return (
                  <option key={`option_${i}`} value={p.id}>
                    {p.title}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
          <Col md={4}>
            <Button variant="outline-primary">編集</Button>
            <Button variant="outline-primary" className="mx-1">
              新規作成
            </Button>
          </Col>
        </Row>
        <Tabs
          activeKey={activeTab.root}
          onSelect={(key) => {
            if (!key || !system) return;
            if (key === "common") {
              openUnitGroupsTab();
            } else if (key === "pattern") {
              openSheetTab();
            }
            dispatch(focusProcedureStep(""));
            $activeTab({ ...activeTab, root: key });
          }}
        >
          <Tab eventKey="common" title="プロジェクト共通">
            <Container className="mt-2">
              <Row>
                <Col>
                  <Tabs
                    activeKey={activeTab.project}
                    onSelect={(key) => {
                      if (!key || !system) return;
                      if (key === "unitGroup") {
                        openUnitGroupsTab();
                      }
                      $activeTab({ ...activeTab, project: key });
                    }}
                  >
                    <Tab eventKey="info" title="基本情報" className="py-4">
                      <Container className="px-0">
                        <Row className="--align-items-center">
                          <Col md={2} className="--bold">
                            タイトル
                          </Col>
                          <Col md={10}>
                            <Form.Control
                              type="string"
                              value={system?.label}
                              onChange={(e) => {
                                return false;
                              }}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Tab>
                    <Tab eventKey="unitGroup" title="ユニットグループ">
                      {activeTab.project === "unitGroup" && (
                        <div>
                          <div className="Ev-edit-content__preview bg-white">
                            <div className={classNames({ "Ev-unit-groups": true, "--fit-screen": fitScreen })}>
                              <div className="mb-4">
                                <Button
                                  variant={"outline-primary"}
                                  disabled={!!variables.OPEN_PANE}
                                  onClick={() => {
                                    $activeModal("newUnitGroup");
                                  }}
                                  className="mx-1"
                                >
                                  ユニットグループを作成
                                </Button>
                              </div>
                              {evaluation.layoutRows.map((row, ri) => {
                                return (
                                  <EvLayoutRow
                                    layoutRow={row}
                                    fitScreen={fitScreen}
                                    editorMode="editing"
                                    key={`row${ri}`}
                                  />
                                );
                              })}
                            </div>
                          </div>
                          <Modal
                            show={activeModal === "newUnitGroup"}
                            onHide={() => $activeModal("")}
                            size="lg"
                            centered
                          >
                            <Modal.Body>初期パラメータを入力したり、プリセットを選択したりできる想定。</Modal.Body>
                            <Modal.Footer>
                              <Button onClick={() => $activeModal("")} variant="outline-secondary">
                                キャンセル
                              </Button>
                              <Button
                                onClick={() => {
                                  addSeedTemplate();
                                  $activeModal("");
                                }}
                                variant="outline-primary"
                              >
                                作成
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      )}
                    </Tab>
                    <Tab eventKey="flowTemplate" title="評価フロー" className="py-4">
                      <EvaluationEditorPhase />
                    </Tab>
                    <Tab eventKey="cssTraits" title="CSSバンドル" className="py-4">
                      <Accordion>
                        <EvaluationEditorStyleSnippets target="system" />
                      </Accordion>
                    </Tab>
                    <Tab eventKey="hook" title="フック" className="py-4">
                      <Container className="px-0">
                        <Row>
                          <Col>
                            <div className="mb-4">
                              <Button variant="outline-primary">フックを作成</Button>
                            </div>
                            {system.hooks?.map((hook, i) => {
                              return (
                                <Row className="mb-4" key={`hook${i}`}>
                                  <Col md={4}>
                                    <div className="mb-2">
                                      <div className="--font-s">概要</div>
                                      <Form.Control
                                        type="string"
                                        value={hook.label}
                                        onChange={(e) => {
                                          return false;
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={8} className="Ev-edit__group">
                                    <div className="mb-2">
                                      <div className="--font-s">エンドポイント</div>
                                      <Form.Control
                                        type="string"
                                        value={hook.endpoint}
                                        onChange={(e) => {
                                          return false;
                                        }}
                                      />
                                    </div>
                                    <div className="mb-2">
                                      <div className="--font-s">使用するフェーズ</div>
                                      <Form.Control
                                        type="string"
                                        value={hook.usingPhases.join(",")}
                                        onChange={(e) => {
                                          return false;
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              );
                            })}
                          </Col>
                        </Row>
                      </Container>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="pattern" title="パターンの設定">
            <Container className="mt-2">
              <Row>
                <Col>
                  <Tabs
                    activeKey={activeTab.pattern}
                    onSelect={(key) => {
                      if (!key || !system) return;
                      if (key === "sheet") {
                        openSheetTab();
                      } else {
                        dispatch(focusProcedureStep(""));
                      }
                      $activeTab({ ...activeTab, pattern: key });
                    }}
                  >
                    <Tab eventKey="sheet" title="使用レイアウト">
                      {activeTab.pattern === "sheet" && (
                        <div>
                          <div className="Ev-edit-content__preview bg-whites">
                            <Container className="mt-4">
                              <Row>
                                <Col md={3}>使用レイアウト</Col>
                                <Col md={5}>
                                  <Form.Select value={layout?.id} onChange={(e) => switchLayout(e.target.value)}>
                                    {layouts.map((l, i) => {
                                      return (
                                        <option key={`option_${i}`} value={l.id}>
                                          {l.title}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </Col>
                                <Col md={4}>
                                  <Button
                                    variant={"outline-primary"}
                                    onClick={() => {
                                      $layoutMetaModal({ active: true });
                                    }}
                                  >
                                    属性を編集
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                            <div className={classNames({ "Ev-unit-groups": true, "--fit-screen": fitScreen })}>
                              <div className="mb-4">
                                <Button
                                  variant={"outline-primary"}
                                  disabled={!!variables.OPEN_PANE}
                                  onClick={() => {
                                    dispatch(setOpenPane("conditions"));
                                  }}
                                  className="mx-1"
                                >
                                  プレビュー条件設定
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  onClick={() => $fitScreen(!fitScreen)}
                                  className="mx-1"
                                >
                                  {fitScreen ? "内容を基準に幅を設定" : "画面を基準に幅を設定"}
                                </Button>
                              </div>
                              {evaluation.layoutRows.map((row, ri) => {
                                return (
                                  <EvLayoutRow
                                    layoutRow={row}
                                    fitScreen={fitScreen}
                                    editorMode="selecting"
                                    key={`row${ri}`}
                                  />
                                );
                              })}
                              {previewWithEditor && (
                                <div>
                                  <Button disabled={!!variables.OPEN_PANE} onClick={addLayoutRow} className="mx-1 my-4">
                                    <Icon type="plus" width={20} height={20} />
                                    列を追加
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                          {variables.OPEN_PANE === "conditions" && (
                            <EvaluationConditionsControl onClose={() => dispatch(setOpenPane(""))} />
                          )}
                        </div>
                      )}
                      <Modal
                        show={layoutMetaModal.active}
                        onHide={() => {
                          $layoutMetaModal({ active: false });
                        }}
                        size="lg"
                        centered
                      >
                        <Modal.Header>レイアウトの属性を編集</Modal.Header>
                        {layout && (
                          <Modal.Body className="--pre-wrap">
                            <Row>
                              <Col md="4">レイアウト名</Col>
                              <Col md="8">
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  defaultValue={layout.title}
                                  onChange={(e) => {
                                    return false;
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col md="4">CSSバンドル</Col>
                              <Col md="8">
                                {Object.keys(layout.cssTraits).map((style, i) => {
                                  return (
                                    <Row key={`style${i}`} className="my-1">
                                      <Col md="4">
                                        <Form.Control
                                          type="text"
                                          placeholder=""
                                          defaultValue={layout.cssTraits[style].label}
                                          onChange={(e) => {
                                            return false;
                                          }}
                                        />
                                      </Col>
                                      <Col md="8">
                                        <Form.Control
                                          type="text"
                                          placeholder=""
                                          defaultValue={JSON.stringify(layout.cssTraits[style].styles, null, 2)}
                                          as="textarea"
                                          style={{ minHeight: 150 }}
                                          className="--font-s"
                                          onChange={(e) => {
                                            return false;
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  );
                                })}
                              </Col>
                            </Row>
                          </Modal.Body>
                        )}
                        <Modal.Footer>
                          <Button
                            onClick={() => {
                              $layoutMetaModal({ active: false });
                            }}
                            variant="outline-secondary"
                          >
                            閉じる
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Tab>
                    <Tab eventKey="attributesDefinition" title="回答者の属性" className="py-4">
                      <Alert variant="info">・マッピングできるテーブルとカラムの情報が追加で必要</Alert>
                      <div className="mb-4">
                        <Button variant="outline-primary">属性データを作成</Button>
                      </div>
                      <div>
                        {pattern?.user_attribute_mappings.map((a, i) => {
                          const [selectedSector, selectedSectorColumn] = a.value.split(".");
                          return (
                            <Row className="mb-4" key={`user_attribute_mappings${i}`}>
                              <Col md={4}>
                                <div className="--font-s">概要</div>
                                <Form.Control
                                  type="string"
                                  value={a.label}
                                  onChange={(e) => {
                                    return false;
                                  }}
                                />
                              </Col>
                              <Col md={8} className="Ev-edit__group">
                                <div className="--font-s">元データのテーブル・カラム</div>
                                <Form.Select
                                  value={selectedSector}
                                  onChange={(e) => {
                                    return false;
                                  }}
                                >
                                  {Object.keys(userColumnChoices)
                                    .map((c) => ({ value: c, label: c }))
                                    .map(({ value, label }, i) => {
                                      return (
                                        <option key={`option_${i}`} value={value}>
                                          {label}
                                        </option>
                                      );
                                    })}
                                </Form.Select>
                                <Form.Select
                                  value={selectedSectorColumn}
                                  onChange={(e) => {
                                    return false;
                                  }}
                                  className="mt-2"
                                >
                                  {Object.keys(userColumnChoices[`profile_${selectedSector}`]).map((column, i) => {
                                    return (
                                      <option key={`option_${i}`} value={column}>
                                        {column}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </Tab>
                    <Tab eventKey="attributes" title="パターン属性" className="py-4">
                      <div className="mb-4">
                        <Button variant="outline-primary">新規作成</Button>
                      </div>
                      <div>
                        {pattern?.attributes.map((a, i) => {
                          return (
                            <Row className="mb-4" key={`user_attribute_mappings${i}`}>
                              <Col md={4}>
                                <div className="--font-s">概要</div>
                                <Form.Control
                                  type="string"
                                  value={a.label}
                                  onChange={(e) => {
                                    return false;
                                  }}
                                />
                              </Col>
                              <Col md={8} className="Ev-edit__group">
                                <div className="mb-2">
                                  <div className="--font-s">値</div>
                                  <Form.Control
                                    type="string"
                                    value={`${a.value}`}
                                    onChange={(e) => {
                                      return false;
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="layout" title="レイアウトの設定">
            <Container className="mt-2">
              <Tabs
                activeKey={activeTab.layout}
                onSelect={(key) => {
                  if (!key || !system) return;
                  $activeTab({ ...activeTab, layout: key });
                }}
              >
                <Tab eventKey="cssTraits" title="CSSバンドル">
                  <Container className="mt-4">
                    <EvaluationEditorStyleSnippets target="layout" />
                  </Container>
                </Tab>
                <Tab eventKey="behavior" title="ビヘイビア" className="py-4">
                  <div className="mb-4">
                    <h3 className="Headline--section mb-2">ビヘイビア</h3>
                    <div className="mb-2">
                      <Button variant="outline-primary">新規ビヘイビアを作成</Button>
                    </div>
                    {layout && (
                      <Accordion>
                        {Object.keys(layout.behaviorMapTemplate).map((defId, i) => {
                          return (
                            <Accordion.Item key={`def${i}`} eventKey={`def${i}`}>
                              <Accordion.Header>{layout.behaviorMapTemplate[defId].label ?? ""}</Accordion.Header>
                              <Accordion.Body>
                                <Row className="mb-2 --align-items-center">
                                  <Col md={1} className="--font-s">
                                    説明
                                  </Col>
                                  <Col md={11}>
                                    <Form.Control
                                      type="string"
                                      value={layout.behaviorMapTemplate[defId].label ?? ""}
                                      onChange={(e) => {
                                        return false;
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <section className="Ev-edit__group">
                                      {Object.keys(layout.behaviorMapTemplate[defId].rules).map((phaseId, pi) => {
                                        const thisPhase = system?.phaseTemplates.find((_) => _.key === phaseId);
                                        const thisRule = layout.behaviorMapTemplate[defId].rules[phaseId];
                                        return (
                                          <Row className="my-1 --align-items-center" key={`phase_${phaseId}_${pi}`}>
                                            <Col md={6}>{thisPhase?.label ?? ""}</Col>
                                            <Col md={6}>
                                              <Form.Select
                                                value={thisRule}
                                                onChange={(e) => {
                                                  return false;
                                                }}
                                              >
                                                {behaviorDefinitionOptions.map(({ value, label }, i) => {
                                                  return (
                                                    <option key={`option_${i}`} value={value}>
                                                      {label}
                                                    </option>
                                                  );
                                                })}
                                              </Form.Select>
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                    </section>
                                  </Col>
                                </Row>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })}
                      </Accordion>
                    )}
                  </div>
                  <div>
                    <h3 className="Headline--section mb-2">ルール</h3>
                    <div className="mb-2">
                      <Button variant="outline-primary">新規ルールを作成</Button>
                    </div>
                    {layout && (
                      <Accordion>
                        {Object.keys(layout?.behaviorDefinition || {})
                          .map((_) => _)
                          .sort((a, b) => {
                            const _a = layout?.behaviorDefinition[a].isPreset;
                            const _b = layout?.behaviorDefinition[b].isPreset;
                            if (_a && !_b) return 1;
                            if (!_a && _b) return -1;
                            return 0;
                          })
                          .map((behaviorId, i) => {
                            const isPreset = layout.behaviorDefinition[behaviorId].isPreset;
                            return (
                              <Accordion.Item key={`behavior${i}`} eventKey={`behavior${i}`}>
                                <Accordion.Header>
                                  {layout.behaviorDefinition[behaviorId].description}
                                  {isPreset ? "(システム規定)" : ""}
                                </Accordion.Header>
                                <Accordion.Body>
                                  {layout?.behaviorDefinition[behaviorId].isPreset ? (
                                    <Alert variant="warning" className="mt-2">
                                      システム規定のビヘイビアのため編集できません。
                                    </Alert>
                                  ) : (
                                    <Row className="mb-2 --align-items-center">
                                      <Col md={1} className="--font-s">
                                        説明
                                      </Col>
                                      <Col md={11}>
                                        <Form.Control
                                          type="string"
                                          value={layout.behaviorDefinition[behaviorId].description}
                                          onChange={(e) => {
                                            return false;
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                  <div className="Ev-edit__group">
                                    {layout?.behaviorDefinition[behaviorId].rules.map(
                                      ({ appearance, expection, priority, variable }, i) => {
                                        return (
                                          <section className="Ev-edit__sub-group" key={`behaviorDefinition${i}`}>
                                            <div className="--flex mb-1">
                                              <Form.Select
                                                value={variable}
                                                disabled={isPreset}
                                                onChange={(e) => {
                                                  return false;
                                                }}
                                              >
                                                {variableOptions.map(({ value, label }, i) => {
                                                  return (
                                                    <option key={`option_${i}`} value={value}>
                                                      {label}
                                                    </option>
                                                  );
                                                })}
                                              </Form.Select>
                                              <div>が</div>
                                            </div>
                                            <div className="--flex mb-1">
                                              <Form.Control
                                                type="string"
                                                disabled={isPreset}
                                                value={`${expection}`}
                                                onChange={(e) => {
                                                  return false;
                                                }}
                                              />
                                              <div>のとき</div>
                                            </div>
                                            <div className="--flex mb-1">
                                              <Form.Select
                                                value={appearance}
                                                disabled={isPreset}
                                                onChange={(e) => {
                                                  return false;
                                                }}
                                              >
                                                {unitAppearanceOptions.map(({ value, label }, i) => {
                                                  return (
                                                    <option key={`option_${i}`} value={value}>
                                                      {label}
                                                    </option>
                                                  );
                                                })}
                                              </Form.Select>
                                            </div>
                                            <div className="--font-s">優先度</div>
                                            <Form.Control
                                              type="string"
                                              disabled={isPreset}
                                              value={`${priority}`}
                                              onChange={(e) => {
                                                return false;
                                              }}
                                            />
                                          </section>
                                        );
                                      }
                                    )}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          })}
                      </Accordion>
                    )}
                  </div>
                </Tab>
                <Tab eventKey="options" title="選択式の回答" className="py-4">
                  <div className="mb-4">
                    <Button variant="outline-primary">選択式の回答を作成</Button>
                  </div>
                  {evaluation && (
                    <Accordion>
                      {Object.keys(evaluation.commonSelectOptions || {}).map((optionId, i) => {
                        return (
                          <Accordion.Item key={`commonSelectOptions${i}`} eventKey={`commonSelectOptions${i}`}>
                            <Accordion.Header>
                              {evaluation.commonSelectOptions[optionId].label ?? optionId}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Row>
                                <Col md={4}>
                                  <div>
                                    <div className="--font-s">概要</div>
                                    <Form.Control
                                      type="string"
                                      value={evaluation.commonSelectOptions[optionId].label ?? ""}
                                      onChange={(e) => {
                                        return false;
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md={8} className="Ev-edit__group">
                                  {evaluation.commonSelectOptions[optionId].options.map((option, i) => {
                                    return (
                                      <Row className="mb-1" key={`option${i}`}>
                                        <Col md={4}>
                                          <div className="--font-s">値</div>
                                          <Form.Control
                                            type="string"
                                            value={option.value}
                                            onChange={(e) => {
                                              return false;
                                            }}
                                          />
                                        </Col>
                                        <Col md={8}>
                                          <div className="--font-s">表示</div>
                                          <Form.Control
                                            type="string"
                                            value={option.label}
                                            onChange={(e) => {
                                              return false;
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  )}
                </Tab>
              </Tabs>
            </Container>
          </Tab>
        </Tabs>
        <nav className="Ev-editor-bottom">
          <Button onClick={_commitChanges} variant="primary">
            変更を保存
          </Button>
        </nav>
      </main>
      <Modal
        show={versionSwitchingModal.show}
        onHide={() =>
          $versionSwitchingModal({
            ...versionSwitchingModal,
            show: false,
          })
        }
        centered
      >
        <Modal.Header>評価プロジェクトの変更</Modal.Header>
        <Modal.Body>編集する評価プロジェクトを切り替えてよいですか？</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              $versionSwitchingModal({
                ...versionSwitchingModal,
                show: false,
              });
            }}
            variant="outline-secondary"
          >
            キャンセル
          </Button>
          <Button
            onClick={() => {
              window.location.href = `/_/evaluation/system/${versionSwitchingModal.nextSystem}`;
            }}
            variant={"primary"}
          >
            変更
          </Button>
        </Modal.Footer>
      </Modal>
      <ContextMenu
        items={contextMenuItems}
        onClose={() => dispatch(setContextMenu({ active: false, x: 0, y: 0, unit: null }))}
        onSelect={onContextMenuSelect}
      ></ContextMenu>
    </div>
  );
}

export default App;
