import { EvaluationProjectGroup } from "../EvaluationTypes";

const dummyProjectGroup1: EvaluationProjectGroup = {
  id: "group_1",
  label: "FY24下期",
  type: "term",
  startDate: "2024-04-01",
  endDate: "2024-09-30",
};
const dummyProjectGroup2: EvaluationProjectGroup = {
  id: "group_2",
  label: "FY25上期",
  type: "term",
  startDate: "2024-10-01",
  endDate: "2025-03-31",
};

export const dummyProjectGroups = [dummyProjectGroup1, dummyProjectGroup2];
