import { useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Table from "../../component/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import { clearMyNumberLogs, getMyNumberLogs, selectMyNumberState } from "./myNumberSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import dayjs from "dayjs";
import { MyNumber, myNumberLogActionDictionary } from "./myNumberValues";
import { setLoading } from "../notification/notificationSlice";

type Props = {
  isActive: boolean;
  name: string;
  myNumber?: MyNumber;
  onClose: () => any;
};

function MyNumberLogsModal({ isActive, myNumber, name, onClose }: Props) {
  const dispatch = useAppDispatch();
  const { logs, myNumberLogsTotalCount, myNumberLogsHasMore, fetchedMyNumberLogsPage } =
    useAppSelector(selectMyNumberState);

  useEffect(() => {
    if (myNumber && isActive) {
      dispatch(setLoading(true));
      dispatch(getMyNumberLogs({ myNumberId: myNumber.id, page: 1 })).then(() => {
        dispatch(setLoading(false));
      });
    } else if (!isActive && logs.length > 0) {
      dispatch(clearMyNumberLogs());
    }
  }, [myNumber, isActive]);

  return (
    <Modal show={isActive} onHide={onClose} size="lg" centered>
      <Modal.Header>
        <Modal.Title>マイナンバー操作ログ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="--align-items-center">
          <Col md="3" className="--bold">
            対象者
          </Col>
          <Col md="9">{name}</Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Table
              col={[
                {
                  name: "種別",
                },
                {
                  name: "操作者",
                },
                {
                  name: "日時",
                },
              ]}
              row={logs.map((log) => {
                return {
                  data: [
                    myNumberLogActionDictionary[log.action_type],
                    log.subject === "batch" ? "バッチ" : log.subject_name,
                    dayjs(log.time).format("YYYY/MM/DD HH:mm:ss"),
                  ],
                };
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              className="mt-2 float-end"
              disabled={!myNumberLogsHasMore}
              onClick={() => {
                if (!myNumber) return;
                dispatch(getMyNumberLogs({ myNumberId: myNumber.id, page: fetchedMyNumberLogsPage + 1 }));
              }}
            >
              さらに表示（全 {myNumberLogsTotalCount} 件中 {logs.length} 件表示中）
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          キャンセル
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyNumberLogsModal;
