import { UnitTemplate, EvaluationModuleCommonProps, EvaluationHelperProduct } from "../EvaluationTypes";
import { countUnitWidth, toStories } from "../evaluationValues";

interface Story {
  label: string;
  sub: Story[];
  occupiedRow?: number;
}

const seedDataTypes = {
  textList: {
    description: "カテゴリと項目",
    data: {
      type: "array",
      schema: {
        value: {
          type: "text",
          label: "表記（カンマ区切り）",
        },
      },
    },
  },
  useCategoryTotal: {
    description: "小計を使用する",
    data: {
      type: "object",
      schema: {
        use: {
          type: "boolean",
          label: "使用する",
        },
      },
    },
  },
};

const sampleArgv = {
  textList: [{ value: "カテゴリ1" }, { value: "カテゴリ2" }, { value: "カテゴリ3" }],
  useCategoryTotal: {
    use: false,
  },
};

const TextStoriesModule = (
  common: EvaluationModuleCommonProps,
  props: {
    origin: {
      row: number;
      column: number;
    };
    textList: { value: string }[];
    useCategoryTotal: {
      use: boolean;
    };
  }
): EvaluationHelperProduct => {
  const CSSBundleUsageMap = {
    NORMAL: ["normal_cell", "flex_centering"],
    HEADER: ["header_cell", "flex_centering"],
  };
  const units = [] as UnitTemplate[];
  const current = {
    row: props.origin.row,
    column: props.origin.column,
  };
  const useCategoryTotal = !!props.useCategoryTotal?.use;
  const { stories, maxStories } = toStories(props.textList, useCategoryTotal);
  // maxStories 分カラムを使って unit を並べる
  const getSubUnits = (s: Story, path: number[]): any => {
    /*
      gridRow, gridColumn は相対的に決まる
    */
    const { v: gridRow } = path.reduce(
      (acc, cur, _i) => {
        if (cur === 0) return { v: acc.v, targets: acc.targets[cur].sub ?? [] };
        const thisStory = acc.targets[cur];
        return {
          v:
            acc.v +
            (thisStory.sub.length
              ? acc.targets.filter((_, i) => i < cur).reduce((acc, s) => acc + (s.occupiedRow ?? 0), 0)
              : cur),
          targets: thisStory.sub.length ? thisStory.sub : [],
        };
      },
      { v: props.origin.row, targets: stories.sub }
    );
    const gridColumn = current.column - 1 + path.length;
    const gridRowSpan = s.occupiedRow;
    const gridColumnSpan = s.sub.length > 0 ? 1 : maxStories - path.length + 1;
    const width = countUnitWidth(s.label.length);
    const topLevel = {
      type: "static",
      id: `${common.unitGroupId}__${common.subRoutineId}__${gridRow}__${gridColumn}`,
      value: s.label,
      description: s.label,
      factor: "",
      unitGroupId: common.unitGroupId,
      subRoutineId: common.subRoutineId,
      gridRow,
      gridColumn,
      gridRowSpan,
      gridColumnSpan,
      style: CSSBundleUsageMap[path.length > 1 ? "NORMAL" : "HEADER"].reduce((prev, current) => {
        return {
          ...prev,
          ...common.context.cssTraits[current]?.styles,
        };
      }, {}),
      width,
    };
    const categoryTotal = {
      type: "static",
      id: `${common.unitGroupId}__${path.join("_")}_total`,
      value: "小計",
      description: "小計",
      factor: "",
      unitGroupId: common.unitGroupId,
      subRoutineId: common.subRoutineId,
      gridRow: gridRow + (gridRowSpan ?? 1) - 1,
      gridColumn: gridColumn + 1,
      gridRowSpan: 1,
      gridColumnSpan: maxStories - 1,
      style: CSSBundleUsageMap["HEADER"].reduce((prev, current) => {
        return {
          ...prev,
          ...common.context.cssTraits[current]?.styles,
        };
      }, {}),
    };
    return useCategoryTotal && path.length === 1
      ? [topLevel, categoryTotal, ...s.sub.map((_: Story, _i) => getSubUnits(_, [...path, _i])).flat()]
      : [topLevel, ...s.sub.map((_: Story, _i) => getSubUnits(_, [...path, _i])).flat()];
  };
  const generated = stories.sub.map((s, si) => getSubUnits(s, [si]));
  units.push(...generated.flat());
  const occupied = {
    row: current.row + stories.sub.map((s) => s.occupiedRow ?? 0).reduce((prev, current) => prev + current, 0),
    column: current.column + maxStories - 1,
  };
  return {
    units,
    origin: current,
    occupied,
    context: common.context,
    meta: {
      stories: stories.sub,
    },
  };
};
export default {
  label: "テキストリスト",
  run: TextStoriesModule,
  seedDataTypes,
  sampleArgv,
};
