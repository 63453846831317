export type MyAccount = {
  name: string;
  mail_address: string;
  language: string;
  image: string;
  is_remind: boolean;
  is_billing: boolean;
};

export type MyAccountToken = {
  id: number;
  token: string;
};

export const BPO_SERVICE_ID = 1;
export const ASSESSMENT_SERVICE_IDS = [1, 2, 3, 4, 5, 6, 7, 8];
export const PROFILE_SERVICE_ID = 9;
export const MY_NUMBER_SERVICE_ID = 10;
