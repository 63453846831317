import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Form,
  ListGroup,
  Alert,
  Badge,
  Card,
  Modal,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  getApplyTemplate,
  getLatestApplicationConfig,
  getEditingApplicationConfig,
  getReferringApplicationConfig,
  createApplicationConfigVersion,
  putApplicationConfig,
  releaseApplicationConfig,
  selectApplyState,
  unselectFormTemplate,
  unselectApplicationConfig,
  getApplyTemplateSummaries,
  unselectEditingApplicationConfig,
  deleteApplicationConfig,
} from "./applySlice";
import {
  ApplicationConfigStep,
  ApplicationConfigInput,
  ApplicationConfigInputPage,
  SELECTABLE_SUB_FIELD_TYPES,
  STATIC_SUB_FIELD_TYPES,
  ApplyFormRow,
  NOT_INPUT_SUB_FIELD_TYPES,
  isEditableItem,
  CALCULATION_FORMAT_REGEXP,
} from "./applyValues";
import { getSections, getPositions, selectSectionState } from "../section/sectionSlice";
import { getRoles, hasRoleMembers, selectPermissionState } from "../permission/permissionSlice";
import dayjs from "dayjs";
import "react-calendar/dist/Calendar.css";
import { selectUserState } from "../login/userSlice";
import { FieldValue, ProfileSubFieldType, userColumnChoices } from "../profile/profileFieldValues";
import { getAllTerms } from "../../app/translate";
import ModalDialog from "../../component/ModalDialog";
import { yieldId } from "../../app/util";
import Icon from "../../component/Icon";
import classNames from "classnames";
import { EditingProfileOption } from "../client/clientValues";
import { hasSectionMembers } from "../profile/profileSlice";
import { getRegularColumns, getSectorStatus, selectClientState } from "../client/clientSlice";

function ApplicationConfig() {
  const { target } = useParams();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUserState);
  const { selectedTemplate, templateSummaries, latestApplicationConfig, editingApplicationConfig } =
    useAppSelector(selectApplyState);
  const { sections, positions } = useAppSelector(selectSectionState);
  const { roles } = useAppSelector(selectPermissionState);
  const { sectorUserStatus, sectorRegularColumns } = useAppSelector(selectClientState);
  const calculationFormatRef = useRef<HTMLTextAreaElement>(null);
  const [state, $state] = useState({
    steps: [] as ApplicationConfigStep[],
    indexToCopy: 0,
    name: "",
    notes: "",
    inputPages: [] as ApplicationConfigInputPage[],
    referringApplicationType: "empty",
    activePageIndex: 0 as number | null,
    activeStepIndex: 0 as number, // 表示中のステップのindex,
    isEditing: false,
    openedWindow: null as Window | null,
  });
  const [optionsModal, $optionsModal] = useState({
    index: -1,
    options: [] as EditingProfileOption[],
    inputType: "",
    editable: false,
  });
  const [calculationModal, $calculationModal] = useState({
    index: -1,
    pageIndex: -1,
    format: "",
    inputType: "",
    editable: false,
    error: "",
    validated: false,
  });
  const [activeModal, $activeModal] = useState("");
  const [hasFixedMembers, $hasFixedMembers] = useState({} as { [key: string]: boolean });
  const [timeoutId, $timeoutId] = useState(null as number | null);
  const navigate = useNavigate();
  const TERMS = getAllTerms();

  useEffect(() => {
    if (sectorUserStatus.length === 0) dispatch(getSectorStatus());
    return () => {
      dispatch(unselectFormTemplate());
      dispatch(unselectApplicationConfig());
      dispatch(unselectEditingApplicationConfig());
    };
  }, []);

  useEffect(() => {
    return () => {
      // 画面遷移時に開いているプレビューウィンドウを閉じる
      if (state.openedWindow) state.openedWindow.close();
    };
  }, [state.openedWindow]);

  useEffect(() => {
    if (user.id) {
      dispatch(
        getSections({
          baseDate: dayjs(),
        })
      );
      dispatch(
        getPositions({
          baseDate: dayjs(),
        })
      );
      dispatch(getRoles());
      dispatch(getApplyTemplateSummaries());
    }
  }, [user]);

  const applicationType = useMemo(() => {
    return target ?? "";
  }, [target]);

  const convertConfigInputs = (
    inputs: ApplyFormRow[],
    configInputs: ApplicationConfigInput[]
  ): ApplicationConfigInput[] => {
    const _inputs = inputs
      .filter(
        ({ key, additional, display }) =>
          // マスタ項目で、元々非表示のもの（configInputsにkeyがないもの）は表示しない
          !additional && !(display === false && !configInputs.some((input) => input.key === key))
      )
      .map(
        ({
          key,
          type,
          label,
          value,
          targets,
          options,
          reference,
          required,
          display,
          reference_type,
          reference_format,
        }) => {
          const configInput = configInputs.find((input) => input.key === key) ?? {};
          const edited = Object.keys(configInput).length > 0;
          const table = targets?.[0]?.table?.split("::")?.[0];
          const column = targets?.[0]?.column ?? key;
          options =
            options ?? (table ? userColumnChoices[table]?.[column]?.map((label) => ({ label, value: label })) : []);
          display = display ?? true;
          return {
            key,
            type: reference_type ? `${type}__${reference_type}` : type,
            label,
            value,
            options,
            reference,
            edited,
            required,
            display,
            reference_format,
            ...configInput,
          };
        }
      );
    const additionalInputs = configInputs
      .filter(({ additional }) => additional)
      .map((input) => {
        return { ...input, type: input.reference_type ? `${input.type}__${input.reference_type}` : input.type };
      });
    return [..._inputs, ...additionalInputs];
  };

  const resetApplicationConfig = () => {
    // 編集中の項目があれば使用する、なければ最新の設定を使用する
    const applicationConfig = editingApplicationConfig.id ? editingApplicationConfig : latestApplicationConfig;
    const next = applicationConfig
      ? applicationConfig.steps.map((step) => {
          return {
            editable: step.editable,
            name: step.name,
            type: step.type,
            group: {
              section_codes: step.group.section_codes ?? [],
              position_codes: step.group.position_codes ?? [],
              role_names: step.group.role_names ?? [],
            },
            copy: step.copy,
            inputs: step.inputs ?? ([] as string[]),
          };
        })
      : [
          {
            editable: true,
            name: "",
            type: "related",
            group: {
              section_codes: [],
              position_codes: [],
              role_names: [],
            },
            copy: false,
            inputs: [],
          },
        ];
    const inputPages =
      selectedTemplate?.input_pages.map(({ inputs, processor_inputs }, i) => {
        const configInputs = applicationConfig.input_pages?.[i]?.inputs ?? [];
        const configProcessorInputs = applicationConfig.input_pages?.[i]?.processor_inputs ?? [];
        return {
          display: applicationConfig.input_pages?.[i]?.display ?? true,
          inputs: inputs ? convertConfigInputs(inputs, configInputs) : [],
          processor_inputs: processor_inputs ? convertConfigInputs(processor_inputs, configProcessorInputs) : [],
        };
      }) ?? [];
    $state({
      ...state,
      name: applicationConfig?.name ?? template?.name ?? "",
      steps: next as ApplicationConfigStep[],
      indexToCopy: applicationConfig?.steps.findIndex((step) => step.copy) ?? 0,
      inputPages,
      notes: applicationConfig?.notes ?? "",
      activePageIndex: selectedTemplate?.input_pages?.length === 1 ? 0 : null,
      isEditing: false,
    });
  };

  useEffect(() => {
    resetApplicationConfig();
  }, [editingApplicationConfig, latestApplicationConfig, selectedTemplate]);

  const processorInputFields = useMemo(() => {
    const isMultiplePages = selectedTemplate.input_pages.length > 1;
    const hidePageIndexes = state.inputPages.reduce((prev, current, i) => {
      if (current.display === false) return [...prev, i];
      return prev;
    }, [] as number[]);
    // 全承認者の項目
    const processorInputs = state.inputPages
      .map((_, i) => _.processor_inputs.map((pi) => ({ ...pi, pageIndex: i })))
      .flat();
    // 表示ページ・入力項目のみに絞る
    return processorInputs
      .filter(({ type, display, pageIndex }) => {
        if (hidePageIndexes.includes(pageIndex)) return false;
        const [_type, _referenceType] = type?.split("__") ?? [];
        return (
          type &&
          isEditableItem({
            display: display ?? true,
            type: _type as ProfileSubFieldType,
            referenceType: _referenceType,
          })
        );
      })
      .map(({ key, label, pageIndex }) => {
        if (isMultiplePages && pageIndex !== undefined) {
          const name = selectedTemplate.input_pages[pageIndex].name;
          label = `【${name}】${label}`;
        }
        return { key, label } as { key: string; label: string };
      });
  }, [selectedTemplate, state.inputPages]);

  useEffect(() => {
    if (templateSummaries.length === 0) return;
    if (
      !templateSummaries.some(
        ({ application_type, template_status }) =>
          application_type === applicationType && template_status !== "obsolete"
      )
    ) {
      navigate("/_/masters/application");
    } else {
      dispatch(getApplyTemplate({ application_type: applicationType }));
      dispatch(getLatestApplicationConfig({ application_type: applicationType }));
      dispatch(getEditingApplicationConfig({ application_type: applicationType }));
    }
  }, [templateSummaries, applicationType]);

  useEffect(() => {
    if (selectedTemplate.id) {
      // 転記先の項目名を取得しに行く
      Object.keys(selectedTemplate.copy_types ?? {}).forEach((sectorId) => {
        if (!sectorRegularColumns[sectorId]) dispatch(getRegularColumns({ sectorId }));
      });
    }
  }, [selectedTemplate]);

  const sectorColumnLabels = useMemo(() => {
    return Object.keys(sectorRegularColumns).reduce((prev, sector) => {
      const columnLabels = sectorRegularColumns[sector].reduce((_p, col) => ({ ..._p, [col.id]: col.label }), {});
      return { ...prev, [sector]: columnLabels };
    }, {} as { [sectorId: string]: { [column: string]: string } });
  }, [sectorRegularColumns]);

  const sectorLabels = useMemo(() => {
    return Object.keys(selectedTemplate.copy_types ?? {}).reduce((prev, sector) => {
      return { ...prev, [sector]: sectorUserStatus.find((s) => s.key === sector)?.label ?? "" };
    }, {} as { [sectorId: string]: string });
  }, [selectedTemplate, sectorUserStatus]);

  const inactiveSectors = useMemo(() => {
    return sectorUserStatus.filter(({ active }) => !active).map(({ key }) => key);
  }, [sectorUserStatus]);

  const updateStep = (
    stepIndex: number,
    field: string,
    value: FieldValue | { section_codes?: string[]; position_codes?: string[] } | string[]
  ) => {
    const nextSteps = state.steps.map((step, _stepIndex) => {
      if (stepIndex !== _stepIndex) return step;
      return {
        ...step,
        [field]: value,
        editable: field === "type" && value === "fixed" ? false : field === "editable" ? !!value : step.editable,
      };
    });
    $state({
      ...state,
      steps: nextSteps,
    });
  };

  const hideProcessorInputKeys = useMemo(() => {
    return state.inputPages
      .map(({ processor_inputs, display }) => {
        return processor_inputs
          .filter((input) => {
            return (
              (display === false || input.display === false) &&
              isEditableItem({
                // ここの判定はマスタの状態で確認したいので、trueを直接渡す（元々display=falseのものはここに来ない）
                display: true,
                type: input.type as ProfileSubFieldType,
                referenceType: input.reference_type,
              })
            );
          })
          .map(({ key }) => key);
      })
      .flat() as string[];
  }, [state.inputPages]);

  const createSaveInputsParam = (inputs: ApplicationConfigInput[], pageIndex: number) => {
    const inputPage = selectedTemplate?.input_pages?.[pageIndex];
    const templateInputs = [...(inputPage?.inputs ?? []), ...(inputPage?.processor_inputs ?? [])];
    return inputs
      .filter(({ additional, edited }) => additional || edited)
      .map(({ type, key, label, value, additional, options, required, display, reference_format }) => {
        const _options = options?.map(({ label }) => ({ label })) ?? [];
        if (additional) {
          let referenceType = undefined;
          [type, referenceType] = type?.split("__") ?? [];
          return {
            type,
            key: type && !STATIC_SUB_FIELD_TYPES.includes(type) ? key : undefined,
            label: type && !STATIC_SUB_FIELD_TYPES.includes(type) ? label : undefined,
            value: type === "staticText" ? value : undefined,
            additional,
            options: type && ["options", "checkbox"].includes(type) ? _options : undefined,
            required: type && !NOT_INPUT_SUB_FIELD_TYPES.includes(type) ? required : undefined,
            reference_type: referenceType,
            reference_format: referenceType ? reference_format : undefined,
          };
        } else {
          // テンプレートの項目を取得
          const templateInput = templateInputs.find((input) => input.key === key);
          return {
            key,
            options: templateInput?.option_editable ? _options : undefined,
            required: templateInput?.required_editable ? required : undefined,
            display: templateInput?.display_editable ? display : undefined,
          };
        }
      });
  };

  const create = () => {
    dispatch(createApplicationConfigVersion({ application_type: applicationType }));
  };

  const release = () => {
    dispatch(releaseApplicationConfig({ id: editingApplicationConfig.id }));
  };

  const discard = () => {
    dispatch(deleteApplicationConfig({ id: editingApplicationConfig.id })).then(() => {
      if (isRightAfterCopy) navigate("/_/masters/application");
    });
  };

  const save = () => {
    dispatch(
      putApplicationConfig({
        id: editingApplicationConfig.id,
        name: state.name,
        steps: state.steps.map((step, i) => {
          return {
            ...step,
            group: {
              section_codes:
                step.type === "fixed" && (step.group.section_codes?.length ?? 0) > 0
                  ? step.group.section_codes
                  : undefined,
              position_codes:
                step.type === "related" && (step.group.position_codes?.length ?? 0) > 0
                  ? step.group.position_codes
                  : undefined,
              role_names:
                step.type === "fixed" && (step.group.role_names?.length ?? 0) > 0 ? step.group.role_names : undefined,
            },
            copy: i === state.indexToCopy,
            inputs: (() => {
              // 非表示にされた承認者の項目は、1ステップ目へ移動して保存する
              const inputs = step.inputs ?? [];
              if (i === 0) {
                return [...inputs, ...hideProcessorInputKeys.filter((k) => !inputs.includes(k))];
              } else {
                return inputs.filter((input) => !hideProcessorInputKeys.some((k) => k === input));
              }
            })(),
          };
        }),
        input_pages: state.inputPages.map(({ inputs, processor_inputs, display }, i) => ({
          display: selectedTemplate?.input_pages[i].display_editable ? display : undefined,
          inputs: createSaveInputsParam(inputs, i),
          processor_inputs: createSaveInputsParam(processor_inputs, i),
        })),
        notes: state.notes,
      })
    );
  };

  const canDeleteStep = useMemo(() => {
    return state.steps.length > 1;
  }, [state.steps]);

  const template = useMemo(() => {
    const template = templateSummaries.find((s) => s.application_type === applicationType);
    return template;
  }, [templateSummaries, applicationType]);

  const deleteStep = (stepIndex: number) => {
    if (!canDeleteStep) return;
    const steps = state.steps.filter((_, i) => i !== stepIndex);
    const indexToCopy = (() => {
      if (state.indexToCopy < stepIndex) return state.indexToCopy;
      else if (state.indexToCopy === stepIndex) return state.steps.length - 2;
      else return state.indexToCopy - 1;
    })();
    const activeStepIndex = (() => {
      if (state.activeStepIndex < stepIndex) return state.activeStepIndex;
      else if (state.activeStepIndex === stepIndex) return state.steps.length - 2;
      else return state.activeStepIndex - 1;
    })();
    $state({ ...state, steps, indexToCopy, activeStepIndex });
  };

  const addStep = () => {
    const steps = [
      ...state.steps,
      {
        editable: false,
        name: "",
        type: "fixed",
        group: {
          position_codes: [],
          section_codes: [],
          role_names: [],
        },
        copy: false,
        inputs: [],
      } as ApplicationConfigStep,
    ];
    $state({ ...state, steps, activeStepIndex: steps.length - 1 });
  };

  const moveStepIndex = (value: number, index: number) => {
    const next = [...state.steps];
    const toTurn = index + value; // 今移動先にある列。選択した列の直前か直後
    state.steps.forEach((_, i) => {
      if (i === index) next[toTurn] = _;
      else if (i === toTurn) next[index] = _;
      else next[i] = _;
    });
    // 移動しようとしているstepが編集中だった場合、編集中のstepも移動
    const activeStepIndex = (() => {
      if (index === state.activeStepIndex) return toTurn;
      else if (toTurn === state.activeStepIndex) return index;
      else return state.activeStepIndex;
    })();
    $state({ ...state, steps: next, activeStepIndex });
  };

  const addInput = (target: "inputs" | "processor_inputs") => {
    if (state.activePageIndex === null) return;
    const currentPage = state.inputPages[state.activePageIndex];
    const nextPage = {
      ...currentPage,
      [target]: [...currentPage[target], { key: yieldId(), type: "", label: "", value: "", additional: true }],
    };
    const next = state.inputPages.map((inputPage, i) => {
      if (i !== state.activePageIndex) return inputPage;
      return nextPage;
    });
    $state({ ...state, inputPages: next });
  };

  const updateInput = (
    target: "inputs" | "processor_inputs",
    index: number,
    field: "label" | "type" | "value" | "options" | "required" | "display" | "reference_format",
    value: string | { label: string }[] | boolean
  ) => {
    if (state.activePageIndex === null) return;
    const isValueString = typeof value === "string";
    const currentPage = state.inputPages[state.activePageIndex];
    const nextPage = {
      ...currentPage,
      [target]: currentPage[target].map((input, i) => {
        if (i !== index) return input;
        return {
          ...input,
          label: input.label || "",
          value: input.value || "",
          [field]: value,
          key: input.key || yieldId(),
          edited: true,
        };
      }),
    };
    const next = state.inputPages.map((inputPage, i) => {
      if (i !== state.activePageIndex) return inputPage;
      return nextPage;
    });
    let steps = state.steps;
    // 承認者の項目のtypeが入力不要なものへ変更される場合、stepに設定されている内容も削除
    if (target === "processor_inputs" && field === "type" && isValueString && STATIC_SUB_FIELD_TYPES.includes(value)) {
      const deleting = currentPage[target][index];
      if (deleting?.key) {
        steps = state.steps.map((step) => {
          return { ...step, inputs: step.inputs?.filter((input) => input !== deleting.key) };
        });
      }
    }
    $state({ ...state, inputPages: next });
  };

  const deleteInput = (target: "inputs" | "processor_inputs", index: number) => {
    if (state.activePageIndex === null) return;
    const currentPage = state.inputPages[state.activePageIndex];
    const nextPage = {
      ...currentPage,
      [target]: currentPage[target].filter((_, i) => i !== index),
    };
    const next = state.inputPages.map((inputPage, i) => {
      if (i !== state.activePageIndex) return inputPage;
      return nextPage;
    });
    let steps = state.steps;
    // 承認者の項目が削除される場合、stepに設定されている内容も削除
    if (target === "processor_inputs") {
      const deleting = currentPage[target][index];
      if (deleting?.key) {
        steps = state.steps.map((step) => {
          return { ...step, inputs: step.inputs?.filter((input) => input !== deleting.key) };
        });
      }
    }
    $state({ ...state, inputPages: next, steps });
  };

  const moveInputIndex = (target: "inputs" | "processor_inputs", value: number, index: number) => {
    if (state.activePageIndex === null) return;
    const currentPage = state.inputPages[state.activePageIndex];
    const nextInputs = [...currentPage[target]];
    const toTurn = index + value; // 今移動先にある列。選択した列の直前か直後
    currentPage[target].forEach((_, i) => {
      if (i === index) nextInputs[toTurn] = _;
      else if (i === toTurn) nextInputs[index] = _;
      else nextInputs[i] = _;
    });
    const nextPage = {
      ...currentPage,
      [target]: nextInputs,
    };
    const next = state.inputPages.map((inputPage, i) => {
      if (i !== state.activePageIndex) return inputPage;
      return nextPage;
    });
    $state({ ...state, inputPages: next });
  };

  const moveOptionsIndex = (value: number, index: number) => {
    if (optionsModal.index === -1) return;
    const current = optionsModal.options;
    const next = [...current];
    const toTurn = index + value; // 今移動先にある列。選択した列の直前か直後
    current.forEach((_, i) => {
      if (i === index) next[toTurn] = _;
      else if (i === toTurn) next[index] = _;
      else next[i] = _;
    });
    $optionsModal({ ...optionsModal, options: next });
  };

  const importSteps = () => {
    if (state.referringApplicationType === "empty") return;
    // 一旦 state を更新するために時間差にする
    setTimeout(async () => {
      const res = await dispatch(
        getReferringApplicationConfig({
          application_type: state.referringApplicationType,
        })
      );
      if (!res.payload?.steps.length) {
        $activeModal("fail_import");
        return;
      }
      const steps: ApplicationConfigStep[] = res.payload.steps.map((step: ApplicationConfigStep) => {
        // インポートしたステップに設定されている承認者が入力する項目のうち、
        // 編集中の申請書タイプに存在しないものは除外する
        const inputs = step.inputs?.filter((input) => {
          return processorInputFields.some((inputField) => inputField.key === input);
        });
        const group = {
          // キーの設定がなくなるとエラーが出るため、ここで補完
          section_codes: step.group.section_codes ?? [],
          position_codes: step.group.position_codes ?? [],
          role_names: step.group.role_names ?? [],
        };
        return {
          ...step,
          group,
          inputs: inputs ?? [],
        };
      });
      $state({
        ...state,
        steps,
        indexToCopy: steps.findIndex((step) => step.copy),
        referringApplicationType: "empty",
      });
    }, 100);
  };

  useEffect(() => {
    if (!state.isEditing) return;
    // 連続でリクエストが送られないよう、チェック後500ms後にリクエストをする
    if (timeoutId) window.clearTimeout(timeoutId);
    const _timeoutId = window.setTimeout(() => {
      const requests = [] as Promise<any>[];
      state.steps.forEach((step, i) => {
        const group = step.group;
        const { role_names, section_codes } = group;
        if (step.type === "related") return;
        else if (role_names && role_names.length > 0) {
          const key = `role_${role_names.join(",")}`;
          if (Object.keys(hasFixedMembers).some((k) => k === key)) return;
          requests.push(dispatch(hasRoleMembers({ roleNames: role_names })));
        } else if (section_codes && section_codes.length > 0) {
          const key = `section_${section_codes.join("/")}`;
          if (Object.keys(hasFixedMembers).some((k) => k === key)) return;
          requests.push(dispatch(hasSectionMembers({ sectionCodes: section_codes })));
        }
      });
      Promise.all(requests).then((result) => {
        // すべての非同期処理の結果を一度に処理
        result.forEach((res) => {
          const key = res.meta.arg.roleNames
            ? `role_${res.meta.arg.roleNames.join(",")}`
            : `section_${res.meta.arg.sectionCodes.join("/")}`;
          $hasFixedMembers({ ...hasFixedMembers, [key]: !!res.payload });
        });
      });
      $timeoutId(null);
    }, 500);
    $timeoutId(_timeoutId);
  }, [state.steps, state.isEditing]);

  const duplicatedLabels = useMemo(() => {
    return state.inputPages.map(({ inputs, processor_inputs }, i) => {
      const labels = [...inputs, ...processor_inputs]
        .filter(({ key, type, label }) => key && type && !STATIC_SUB_FIELD_TYPES.includes(type) && label)
        .map(({ label }) => label) as string[];
      return labels.filter((label, i, array) => label !== "" && !(array.indexOf(label) === i));
    });
  }, [state.inputPages]);

  const duplicatedStepNames = useMemo(() => {
    return state.steps.map((step) => step.name).filter((name, i, array) => name !== "" && !(array.indexOf(name) === i));
  }, [state.steps]);

  const numberInputs = useMemo(() => {
    // 計算項目で選択可能な項目を取得（全ページの項目を保持）
    return state.inputPages.map((page, i) => {
      // TODO: 複製可能なページの場合、計算式の変換機構がないため計算式に使える項目は一旦なしとする
      if (selectedTemplate.input_pages[i].replication_number_key) return [];
      // 項目タイプを追加
      const inputs = page.inputs.map((input) => ({ ...input, inputType: "inputs" }));
      const processorInputs = page.processor_inputs.map((input) => ({ ...input, inputType: "processor_inputs" }));
      // 計算式に使える数値項目（type=number/float, 表示されている）のみを取得
      return [...inputs, ...processorInputs].filter(
        ({ type = "", display = true }) => ["number", "float"].includes(type) && display
      );
    });
  }, [state.inputPages]);

  const numberInputsForCalculationModal = useMemo(() => {
    // 計算式設定モーダルで選択可能な項目を取得
    if (calculationModal.index === -1) return [];
    return numberInputs[calculationModal.pageIndex].filter((input) => {
      // 承認者の項目の場合：全ての数値項目／申請者の項目の場合：申請者の数値項目
      return calculationModal.inputType === "processor_inputs" || input.inputType === calculationModal.inputType;
    });
  }, [numberInputs, calculationModal.index, calculationModal.pageIndex, calculationModal.inputType]);

  const validateCalculationFormat = useCallback(
    (format: string, pageIndex: number, inputType: string) => {
      // 空はNG
      if (format === "") return "入力してください";
      const errors = [] as string[];
      // 不正な差し込み項目あり（{}の形式に変換した状態で表示用形式【】が残っている）はNG
      if (format.match(/【([^【】]*)】/)) errors.push("不正な差し込み項目があります");
      // 差し込み項目の連続はNG
      if (format.indexOf("}{") !== -1) errors.push("差し込み項目が連続しています");
      // 数字と差し込み項目の連続はNG
      if (format.match(/\d\{/) || format.match(/\}\d/)) errors.push("数字と差し込み項目が連続しています");
      // 演算子の連続はNG
      if (format.match(/[\+\\\-\*\/]{2,}/)) errors.push("演算子が連続しています");
      // ここまででエラーがあればつなげて返す
      if (errors.length > 0) return errors.join(" / ");
      // {差し込み項目}を1で置換して、不正な文字が含まれていないか確認
      const converted =
        numberInputs[pageIndex]
          // 承認者の項目の場合：全ての数値項目／申請者の項目の場合：申請者の数値項目
          ?.filter((input) => inputType === "processor_inputs" || input.inputType === inputType)
          // 差し込み項目を仮で1で置換
          .reduce((prev, { key }) => prev.replaceAll(`{${key}}`, "1"), format) ?? "";
      // 数値、ドット、四則演算、括弧以外が含まれている場合はエラー
      if (!converted.match(CALCULATION_FORMAT_REGEXP)) return "計算式が正しくありません";
      // evalで確認
      try {
        eval(converted);
      } catch (_) {
        return "計算式が正しくありません";
      }
      return "";
    },
    [numberInputs]
  );

  const messages = useMemo(() => {
    // 編集中以外はエラーメッセージを表示しない
    if (!state.isEditing) return [];
    const messages = [] as {
      item: "inputs" | "processor_inputs" | "steps" | "copy" | "name";
      pageIndex?: number;
      index?: number;
      place?: string;
      message: string;
    }[];
    state.inputPages.forEach((inputPage, pageIndex) => {
      ["inputs", "processor_inputs"].forEach((_inputType) => {
        const inputType = _inputType as "inputs" | "processor_inputs";
        inputPage?.[inputType]?.forEach((input, _i) => {
          if (!input.type) {
            messages.push({ item: inputType, pageIndex, index: _i, place: "type", message: "選択してください" });
          } else if (!STATIC_SUB_FIELD_TYPES.includes(input.type)) {
            if (!input.label) {
              messages.push({ item: inputType, pageIndex, index: _i, place: "label", message: "入力してください" });
            } else if (duplicatedLabels?.[pageIndex]?.includes(input.label)) {
              messages.push({
                item: inputType,
                pageIndex,
                index: _i,
                place: "label",
                message: "他の項目名と重複するため設定できません。",
              });
            }
          }
          if (input.type === "staticText" && !input.value) {
            messages.push({ item: inputType, pageIndex, index: _i, place: "value", message: "入力してください" });
          }
          if (input.type === "checkbox" && (input.options?.length ?? 0) === 0) {
            messages.push({
              item: inputType,
              pageIndex,
              index: _i,
              place: "options",
              message: "選択肢を追加してください",
            });
          }
          if (
            // コード項目以外の選択肢項目
            input.type === "options" &&
            !input.reference &&
            (input.options?.length ?? 0) === 0
          ) {
            messages.push({
              item: inputType,
              pageIndex,
              index: _i,
              place: "options",
              message: "選択肢を追加してください",
            });
          }
          if (input.required && input.display === false) {
            messages.push({
              item: inputType,
              pageIndex,
              index: _i,
              place: "display",
              message: "必須の項目は表示の設定をしてください",
            });
          }
          if (
            input.type === "number__calculation" &&
            input.additional &&
            validateCalculationFormat(input.reference_format ?? "", pageIndex, inputType)
          ) {
            messages.push({
              item: inputType,
              pageIndex,
              index: _i,
              place: "number__calculation",
              message: "計算式が不正です",
            });
          }
        });
        if (inputType === "inputs" && inputPage?.[inputType]?.length === 0) {
          messages.push({
            item: inputType,
            pageIndex,
            place: "add_input_button",
            message: "申請者の項目を追加してください",
          });
        }
      });
    });
    state.steps.forEach((step, _i) => {
      if (!step.name) {
        messages.push({ item: "steps", index: _i, place: "name", message: "入力してください" });
      } else if (duplicatedStepNames.includes(step.name)) {
        messages.push({
          item: "steps",
          index: _i,
          place: "name",
          message: "他のステップ名と重複するため設定できません。",
        });
      }
      if (step.type === "related" && !step.group.position_codes?.length) {
        messages.push({ item: "steps", index: _i, place: "group", message: "役職を選択してください" });
      }
      if (step.type === "fixed" && !step.group.section_codes?.length && !step.group.role_names?.length) {
        messages.push({ item: "steps", index: _i, place: "group", message: "部署またはロールを選択してください" });
      }
      if (step.type === "related" && state.indexToCopy === _i && !step.editable) {
        messages.push({
          item: "steps",
          index: _i,
          place: "editable",
          message: "データ反映のステップではチェックを入れてください",
        });
      }
      if (step.type === "related" && step.inputs?.length && !step.editable) {
        messages.push({
          item: "steps",
          index: _i,
          place: "editable",
          message: "入力項目があるステップではチェックを入れてください",
        });
      }
      // 固定ステップに誰もいない場合は設定できない
      if (step.type === "fixed") {
        const isRoleSelected = step.group?.role_names && step.group.role_names.length > 0;
        const key = isRoleSelected
          ? `role_${step.group.role_names?.join(",")}`
          : `section_${step.group.section_codes?.join("/")}`;
        if (hasFixedMembers[key] === false) {
          const _message1 = `選択された${isRoleSelected ? "ロールをもつ" : "部署に属する"}アカウントが存在しません。`;
          const _message2 = `アカウントが存在する${isRoleSelected ? "ロール" : "部署"}を選択してください。`;
          messages.push({
            item: "steps",
            index: _i,
            place: "group",
            message: `${_message1}${_message2}`,
          });
        }
      }
    });

    processorInputFields.forEach((input) => {
      const appearedCount = state.steps.reduce((_prev, step, _i) => {
        if (step.inputs?.includes(input.key)) {
          if (_prev > 0) {
            messages.push({
              item: "steps",
              index: _i,
              place: "input",
              message: `${input.label} はいずれかの承認ステップでのみ選択してください。`,
            });
          }
          return _prev + 1;
        } else {
          if (processorInputFields.length > 0 && _prev === 0 && _i === state.steps.length - 1) {
            messages.push({
              item: "steps",
              index: _i,
              place: "input",
              message: `「${processorInputFields
                .map((_) => _.label)
                .join("」「")}」をいずれかの承認ステップで必ず選択してください。`,
            });
          }
        }
        return _prev;
      }, 0);
      return appearedCount === 1;
    }, true);
    const copyAfterInput = state.steps.every(
      (s, i) => !s.inputs?.filter((k) => !hideProcessorInputKeys.includes(k))?.length || state.indexToCopy >= i
    );
    if (!copyAfterInput) {
      messages.push({
        item: "copy",
        message: "これより後のステップに「承認者が入力する項目」が存在するため選択できません。",
      });
    }
    if (state.name === "") {
      messages.push({
        item: "name",
        message: "入力してください。",
      });
    } else if (
      templateSummaries
        .filter((s) => s.application_type !== applicationType)
        .some((s) => s.display_names.includes(state.name))
    ) {
      messages.push({
        item: "name",
        message: "同じ名称の申請書が既に存在します。",
      });
    }
    return messages;
  }, [state, duplicatedLabels, duplicatedStepNames, hideProcessorInputKeys, hasFixedMembers]);

  const activeStep = useMemo(() => {
    return state.steps[state.activeStepIndex];
  }, [state.activeStepIndex, state.steps]);

  const closeOptionsModal = () => {
    $optionsModal({ index: -1, options: [], inputType: "", editable: false });
  };

  const closeCalculationModal = () => {
    $calculationModal({
      index: -1,
      pageIndex: -1,
      format: "",
      inputType: "",
      editable: false,
      error: "",
      validated: false,
    });
  };

  const openPreview = () => {
    const openedWindow = window.open(`/_/masters/application/preview/${applicationType}`, "preview", "width=1000");
    $state({ ...state, openedWindow });
  };

  const [duplicatedOptionLabels, displayOptionLength] = useMemo(() => {
    const labels = optionsModal.options.map((o) => o.label);
    const _duplicatedLabels = labels.filter((label, i, array) => label !== "" && !(array.indexOf(label) === i));
    const _displayOptions = optionsModal.options.filter(({ hidden }) => !hidden);
    return [_duplicatedLabels, _displayOptions.length];
  }, [optionsModal]);

  const isRightAfterCopy = useMemo(() => {
    return applicationType.indexOf("__") >= 0 && !latestApplicationConfig.id;
  }, [latestApplicationConfig]);

  // 計算式のフォーマットを表示用⇔保存用で変換
  const convertCalculationFormat = useCallback(
    (format: string, pageIndex: number, inputType: string, forSave: boolean = true) => {
      const converted =
        numberInputs[pageIndex]
          // 承認者の項目の場合：全ての数値項目／申請者の項目の場合：申請者の数値項目
          ?.filter((input) => inputType === "processor_inputs" || input.inputType === inputType)
          .reduce((prev, { key, label }, i) => {
            const displayFormat = `【${label}[${i}]】`;
            const saveFormat = `{${key}}`;
            return forSave ? prev.replaceAll(displayFormat, saveFormat) : prev.replaceAll(saveFormat, displayFormat);
          }, format) ?? "";
      // 表示用で変換できなかったものは分かりやすい表示とする
      return forSave ? converted : converted.replaceAll(/{([^{}]*)}/g, "【削除/非表示項目】");
    },
    [numberInputs]
  );

  const inactiveCopyTables = useMemo(() => {
    // 必要なデータが取得できるまでは何もしない
    const copyTypes = selectedTemplate.copy_types ?? {};
    const copyTragetTables = Object.keys(copyTypes);
    if (copyTragetTables.length === 0) return [];
    if (!copyTragetTables.every((table) => sectorRegularColumns[table])) return [];

    let copyTargetTables = [] as string[]; // 非表示のもを排除した転記先テーブル名の配列
    let hiddenTableColumns = {} as { [table: string]: string[] }; // 非表示にされた項目の転記先をテーブルをキーにカラムを配列で保持
    selectedTemplate.input_pages
      // テンプレートの全入力項目をフラットにする
      .flatMap(({ inputs, processor_inputs }, i) =>
        [...(inputs ?? []), ...(processor_inputs ?? [])].map((input) => ({ ...input, pageIndex: i }))
      )
      // 非表示にされたものとされていないものを分ける
      .forEach(({ targets, key, pageIndex }) => {
        if (!targets || targets.length === 0) return; // 転記先がない場合は何もしない
        // 該当する入力項目の設定を取得
        const inputPage = state.inputPages[pageIndex] ?? { inputs: [], processor_inputs: [] };
        const input = [...inputPage.inputs, ...inputPage.processor_inputs].find((input) => input.key === key);
        if (!input) return; // 取得できなければ何もしない
        // 何らかで非表示にされている場合は、テーブル名とカラム名をセットで保持
        if (inputPage.display === false || input.display === false)
          targets.forEach(({ table, column }) => {
            const columns = [...(hiddenTableColumns?.[table] ?? []), column ?? key];
            hiddenTableColumns = { ...hiddenTableColumns, [table]: columns };
          });
        // 表示される場合は、テーブル名のみ保持
        else copyTargetTables = [...copyTargetTables, ...targets.map(({ table }) => table)];
      });
    // 非表示になっていない項目が残っていても、転記されないパターンがあるので、そのテーブル名を取得
    // （POST転記で、テーブルとしての必須項目が非表示にされている）
    const noCopyTables = Object.keys(hiddenTableColumns).filter((table) => {
      if (!copyTypes[table]?.startsWith("POST")) return false;
      const regularColumns = sectorRegularColumns[table] ?? [];
      const columns = hiddenTableColumns[table];
      return columns.some((column) => regularColumns.find(({ id }) => id === column)?.required);
    });
    // 重複を排除、非管理テーブルで、転記対象外を除外したものを取得
    const inactiveCopyTables = Array.from(new Set(copyTargetTables)).filter(
      (table) => inactiveSectors.includes(table) && !noCopyTables.includes(table)
    );
    return inactiveCopyTables;
  }, [inactiveSectors, state.inputPages, sectorRegularColumns, selectedTemplate]);

  const controlButtons = useMemo(() => {
    return (
      <Row className="mt-4">
        <Col>
          {editingApplicationConfig.id ? (
            state.isEditing ? (
              <>
                <Button onClick={resetApplicationConfig} variant="outline-secondary" className="me-1">
                  キャンセル
                </Button>
                <Button onClick={save} variant="primary" disabled={messages.length > 0}>
                  保存
                </Button>
              </>
            ) : (
              <>
                <Button onClick={() => $activeModal("before_release")} variant="primary" className="me-1">
                  編集中の内容をリリースする
                </Button>
                <Button
                  onClick={() => {
                    $state({ ...state, isEditing: true });
                  }}
                  variant="outline-primary"
                >
                  編集
                </Button>
                <Button onClick={() => $activeModal("before_delete")} variant="danger" className="float-end">
                  {isRightAfterCopy ? "コピーを削除する" : "編集中の内容を削除する"}
                </Button>
              </>
            )
          ) : (
            <Button onClick={() => $activeModal("before_create")} variant="primary">
              新しいバージョンの作成を開始する
            </Button>
          )}
        </Col>
      </Row>
    );
  }, [state.isEditing, messages, editingApplicationConfig]);

  return (
    <Container>
      {template && (
        <>
          <Row className="mt-4">
            <Col>
              <h2 className="Headline--section mb-2">
                {template.name}の設定
                <span
                  className={classNames({
                    "Badge--ok": template.is_active,
                    "Badge--waiting": !template.is_active,
                    "ms-1": true,
                  })}
                >
                  {template.is_active ? "使用中" : "不使用"}
                </span>
                <span className="Badge--cancel ms-1">
                  リリース済バージョン:
                  {isRightAfterCopy ? "なし" : latestApplicationConfig.version}
                </span>
              </h2>
            </Col>
          </Row>
          {template?.template_status === "depricated" && (
            <Alert variant={"warning"}>{`この申請書種別は、${template?.obsolete_on} に廃止になります。`}</Alert>
          )}
          {inactiveCopyTables.length > 0 && (
            <Alert variant={"warning"}>{`データ反映先に管理対象外のテーブルが含まれています：${inactiveCopyTables
              .map((table) => sectorLabels?.[table])
              .join(", ")}`}</Alert>
          )}
          {editingApplicationConfig.id ? (
            <Alert variant={"info"}>
              設定内容は、新バージョンとしてリリース後に作成される申請書から適用されます。
              既に作成済の申請書には適用されません。
            </Alert>
          ) : (
            <Alert variant={"info"}>
              最新の設定内容を表示しています。 変更したい項目がある場合は、新しいバージョンの編集を開始してください。
            </Alert>
          )}
          {controlButtons}
          <h3 className="Headline--section-sub mt-4">名称</h3>
          <Form.Control
            placeholder=""
            value={state.name}
            onChange={(e) => $state({ ...state, name: e.target.value })}
            disabled={!state.isEditing}
          />
          {(() => {
            const m = messages.find(({ item }) => item === "name");
            return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
          })()}
          <h3 className="Headline--section-sub mt-4">注釈表示</h3>
          <Form.Control
            as="textarea"
            placeholder=""
            value={state.notes}
            style={{ height: "150px" }}
            onChange={(e) => $state({ ...state, notes: e.target.value })}
            disabled={!state.isEditing}
          />
          <div className="d-flex align-items-center mt-4">
            <h3 className="Headline--section-sub">入力項目</h3>
            {!state.isEditing && (
              <div>
                <Button onClick={openPreview} size="sm" variant="outline-primary" className="ms-1 mb-1">
                  プレビュー
                </Button>
              </div>
            )}
          </div>
          <div className="--font-s">※追加項目はデータ反映はされません。申請書上のみで確認できます。</div>
          <Accordion
            alwaysOpen={selectedTemplate.input_pages.length === 1}
            activeKey={selectedTemplate.input_pages.length === 1 ? "0" : `${state.activePageIndex}`}
            onSelect={(eventKey) => {
              $state({ ...state, activePageIndex: eventKey === null || eventKey === undefined ? null : +eventKey });
            }}
          >
            {state.inputPages.map((page, index) => {
              const pageName = selectedTemplate.input_pages[index].name;
              const hasError = state.isEditing
                ? messages.some(
                    ({ pageIndex, item }) => ["inputs", "processor_inputs"].includes(item) && pageIndex === index
                  )
                : false;
              const pageDisplay = page.display;
              const pageDisplayEditable = !!selectedTemplate.input_pages[index].display_editable;
              const hasInactiveCopyTargets = (() => {
                const inputs = selectedTemplate.input_pages[index].inputs ?? [];
                const processorInputs = selectedTemplate.input_pages[index].processor_inputs ?? [];
                const copyTargets = [...inputs, ...processorInputs].map(({ targets }) => targets).flat();
                return copyTargets.some((target) => target && inactiveCopyTables.includes(target.table.split("::")[0]));
              })();

              return (
                <Accordion.Item eventKey={`${index}`} key={`key_${index}`}>
                  {selectedTemplate.input_pages.length > 1 && (
                    <Accordion.Header>
                      <Badge bg={pageDisplay ? "success" : "secondary"} className="mx-1">
                        {pageDisplay ? "表示" : "非表示"}
                      </Badge>
                      【{pageName}】
                      {hasError && (
                        <Badge pill bg="danger" className="mx-1">
                          !
                        </Badge>
                      )}
                      {hasInactiveCopyTargets && (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 50, hide: 50 }}
                          overlay={(props) => (
                            <Tooltip id={`header_tooltip_${index}`} {...props}>
                              データ反映先に管理対象外のテーブルが含まれています
                            </Tooltip>
                          )}
                        >
                          <Badge pill bg="warning" className="mx-1">
                            !
                          </Badge>
                        </OverlayTrigger>
                      )}
                      <span>
                        申請者：{page.inputs.length} 項目 / 承認者：{page.processor_inputs.length} 項目
                      </span>
                    </Accordion.Header>
                  )}
                  <Accordion.Body className="--overflow-auto">
                    {selectedTemplate.input_pages.length > 1 && (
                      <>
                        <div className="--bold mt-2">表示</div>
                        <Form.Check
                          type="switch"
                          checked={pageDisplay}
                          disabled={!pageDisplayEditable || !state.isEditing}
                          onChange={() => {
                            if (state.activePageIndex === null) return;
                            const next = state.inputPages.map((inputPage, i) => {
                              if (i !== state.activePageIndex) return inputPage;
                              return { ...state.inputPages[state.activePageIndex], display: !pageDisplay };
                            });
                            $state({ ...state, inputPages: next });
                          }}
                        />
                      </>
                    )}
                    {["inputs", "processor_inputs"].map((_inputType) => {
                      if (state.activePageIndex !== index) return null;
                      const inputType = _inputType as "inputs" | "processor_inputs";
                      const inputTypeLabel = inputType === "inputs" ? "申請者" : "承認者";
                      const inputs = page?.[inputType] ?? [];
                      const masterInputsLength = inputs.filter(({ additional }) => !additional).length;
                      return (
                        <Row key={`input_row_${inputType}`}>
                          <Col>
                            <div className="--bold mt-2">{inputTypeLabel}</div>
                            {inputs.length > 0 && (
                              <ListGroup as="ol">
                                {inputs.map((input, i) => {
                                  const {
                                    key,
                                    label,
                                    type,
                                    value,
                                    additional,
                                    options,
                                    reference,
                                    required,
                                    display,
                                    reference_format,
                                  } = input;
                                  const inputMessages = state.isEditing
                                    ? messages.filter(
                                        (m) =>
                                          m.item === inputType && m.index === i && m.pageIndex === state.activePageIndex
                                      )
                                    : [];
                                  const disabled = !state.isEditing || !additional;
                                  const templateInputs =
                                    selectedTemplate.input_pages[state.activePageIndex ?? 0]?.[inputType] ?? [];
                                  const templateInput = templateInputs.find((tInput) => tInput.key === key);
                                  const {
                                    option_editable,
                                    required_editable,
                                    required_conditions,
                                    display_editable,
                                    display_conditions,
                                  } = templateInput ?? {};
                                  const hasRequiredConditions =
                                    required_conditions && Object.keys(required_conditions).length > 0;
                                  const hasDisplayConditions =
                                    display_conditions && Object.keys(display_conditions).length > 0;
                                  const optionsEditable = state.isEditing && (option_editable || additional);
                                  const requiredEditable = state.isEditing && (required_editable || additional);
                                  const displayEditable = state.isEditing && display_editable;
                                  const copyTargets = templateInput?.targets
                                    // テーブルのインデックスを削除しつつ重複を除外
                                    ?.reduce((prev, { table, column }) => {
                                      table = table.split("::")[0];
                                      column = column ?? key;
                                      if (!column) return prev;
                                      if (prev.some((p) => p.table === table && p.column === column)) return prev;
                                      return [...prev, { table, column }];
                                    }, [] as { table: string; column: string }[]);
                                  const hasInactiveCopyTargets =
                                    display && copyTargets?.some(({ table }) => inactiveCopyTables.includes(table));

                                  return (
                                    <ListGroup.Item key={`${inputType}_input_${i}`}>
                                      <Row>
                                        <Col md={1}>
                                          <Button
                                            variant="link"
                                            disabled={i === masterInputsLength || disabled}
                                            onClick={() => moveInputIndex(inputType, -1, i)}
                                          >
                                            <Icon width={15} height={15} type="caret-up-fill" />
                                          </Button>
                                          <Button
                                            variant="link"
                                            disabled={i === inputs.length - 1 || disabled}
                                            onClick={() => moveInputIndex(inputType, 1, i)}
                                          >
                                            <Icon width={15} height={15} type="caret-down-fill" />
                                          </Button>
                                        </Col>
                                        <Col md={3}>
                                          <Form.Label className="--required-label --bold">項目タイプ</Form.Label>
                                          <Form.Select
                                            value={type}
                                            disabled={disabled}
                                            onChange={(e) => updateInput(inputType, i, "type", e.target.value)}
                                          >
                                            <option value={""}>---</option>
                                            {SELECTABLE_SUB_FIELD_TYPES.map((fieldType, i) => {
                                              return (
                                                <option key={`option_${i}`} value={fieldType}>
                                                  {TERMS[`SUB_FIELD_TYPE_${fieldType}`]}
                                                </option>
                                              );
                                            })}
                                          </Form.Select>

                                          {(() => {
                                            const m = inputMessages.find((message) => message.place === "type");
                                            return m ? (
                                              <div className="--text-annotation mt-1 --font-s">{m.message}</div>
                                            ) : null;
                                          })()}
                                        </Col>
                                        <Col md={3}>
                                          {type && !STATIC_SUB_FIELD_TYPES.includes(type) && (
                                            <>
                                              <Form.Label className="--required-label --bold">項目名</Form.Label>
                                              <Form.Control
                                                type="text"
                                                placeholder=""
                                                disabled={disabled}
                                                value={label}
                                                onChange={(e) => updateInput(inputType, i, "label", e.target.value)}
                                              />

                                              {(() => {
                                                const m = inputMessages.find((message) => message.place === "label");
                                                return m ? (
                                                  <div className="--text-annotation mt-1 --font-s">{m.message}</div>
                                                ) : null;
                                              })()}
                                            </>
                                          )}
                                          {type && ["staticText"].includes(type) && (
                                            <>
                                              <Form.Label className="--required-label --bold">表示テキスト</Form.Label>
                                              <Form.Control
                                                type="text"
                                                disabled={disabled}
                                                placeholder=""
                                                value={typeof value === "string" ? value : ""}
                                                onChange={(e) => {
                                                  updateInput(inputType, i, "value", e.target.value);
                                                }}
                                              />
                                              {(() => {
                                                const m = inputMessages.find((message) => message.place === "value");
                                                return m ? (
                                                  <div className="--text-annotation mt-1 --font-s">{m.message}</div>
                                                ) : null;
                                              })()}
                                            </>
                                          )}
                                        </Col>
                                        <Col md={2}>
                                          {type === "checkbox" && (
                                            <>
                                              <Form.Label className="--required-label --bold">選択肢</Form.Label>
                                              <div>
                                                {`${options?.length ?? 0}選択肢`}
                                                <Button
                                                  variant={optionsEditable ? "outline-primary" : "outline-secondary"}
                                                  size="sm"
                                                  className="ms-1"
                                                  onClick={() => {
                                                    const _options =
                                                      options?.map(({ label }) => {
                                                        return { label, editable: true, hidden: false };
                                                      }) ?? [];
                                                    $optionsModal({
                                                      index: i,
                                                      options: _options,
                                                      inputType,
                                                      editable: !!optionsEditable,
                                                    });
                                                  }}
                                                >
                                                  {optionsEditable ? "編集" : "確認"}
                                                </Button>
                                              </div>
                                              {(() => {
                                                const m = inputMessages.find((message) => message.place === "options");
                                                return m ? (
                                                  <div className="--text-annotation mt-1 --font-s">{m.message}</div>
                                                ) : null;
                                              })()}
                                            </>
                                          )}
                                          {type === "options" && (
                                            <>
                                              <Form.Label
                                                className={classNames({
                                                  "--required-label": !reference,
                                                  "--bold": true,
                                                })}
                                              >
                                                {`選択肢${reference ? "（コード）" : ""}`}
                                              </Form.Label>
                                              <div>
                                                {`${options?.length ?? 0}選択肢`}
                                                <Button
                                                  variant={optionsEditable ? "outline-primary" : "outline-secondary"}
                                                  size="sm"
                                                  className="ms-1"
                                                  onClick={() => {
                                                    const _options =
                                                      options?.map(({ label }) => {
                                                        return { label, editable: true, hidden: false };
                                                      }) ?? [];
                                                    $optionsModal({
                                                      index: i,
                                                      options: _options,
                                                      inputType,
                                                      editable: !!optionsEditable && !reference,
                                                    });
                                                  }}
                                                >
                                                  {optionsEditable && !reference ? "編集" : "確認"}
                                                </Button>
                                              </div>
                                              {(() => {
                                                const m = inputMessages.find((message) => message.place === "options");
                                                return m ? (
                                                  <div className="--text-annotation mt-1 --font-s">{m.message}</div>
                                                ) : null;
                                              })()}
                                            </>
                                          )}
                                          {type === "number__calculation" && (
                                            <>
                                              <Form.Label className="--bold">計算式</Form.Label>
                                              <div>
                                                <Button
                                                  variant={disabled ? "outline-secondary" : "outline-primary"}
                                                  size="sm"
                                                  className="ms-1"
                                                  onClick={() => {
                                                    const _format = reference_format ?? "";
                                                    const format = convertCalculationFormat(
                                                      _format,
                                                      index,
                                                      inputType,
                                                      false
                                                    );
                                                    $calculationModal({
                                                      index: i,
                                                      pageIndex: index,
                                                      format,
                                                      inputType,
                                                      editable: !disabled,
                                                      validated: true,
                                                      error: validateCalculationFormat(_format, index, inputType),
                                                    });
                                                  }}
                                                >
                                                  {disabled ? "確認" : "編集"}
                                                </Button>
                                                {(() => {
                                                  const m = inputMessages.find(
                                                    (message) => message.place === "number__calculation"
                                                  );
                                                  return m ? (
                                                    <div className="--text-annotation mt-1 --font-s">{m.message}</div>
                                                  ) : null;
                                                })()}
                                              </div>
                                            </>
                                          )}
                                        </Col>
                                        <Col md={1}>
                                          {type && !NOT_INPUT_SUB_FIELD_TYPES.includes(type) && (
                                            <>
                                              <Form.Label className="--bold">必須</Form.Label>
                                              {hasRequiredConditions && <div className="--font-s">※条件付き</div>}
                                              <Form.Check
                                                type="switch"
                                                checked={!!(required || hasRequiredConditions)}
                                                disabled={!requiredEditable}
                                                onChange={() => {
                                                  updateInput(inputType, i, "required", !required);
                                                }}
                                              />
                                            </>
                                          )}
                                        </Col>
                                        <Col
                                          md={1}
                                          className={classNames({
                                            "d-flex": additional,
                                            "align-items-center": additional,
                                          })}
                                        >
                                          {additional ? (
                                            <Button
                                              variant="outline-danger"
                                              size="sm"
                                              disabled={disabled}
                                              onClick={() => deleteInput(inputType, i)}
                                            >
                                              削除
                                            </Button>
                                          ) : (
                                            <>
                                              <Form.Label className="--bold">表示</Form.Label>
                                              {(hasDisplayConditions || hasRequiredConditions) && (
                                                <div className="--font-s">※条件付き</div>
                                              )}
                                              <Form.Check
                                                type="switch"
                                                checked={display}
                                                disabled={!displayEditable}
                                                onChange={() => updateInput(inputType, i, "display", !display)}
                                              />
                                              {(() => {
                                                const m = inputMessages.find((message) => message.place === "display");
                                                return m ? (
                                                  <div className="--text-annotation mt-1 --font-s">{m.message}</div>
                                                ) : null;
                                              })()}
                                            </>
                                          )}
                                        </Col>
                                        <Col
                                          md={1}
                                          className={classNames({
                                            "d-flex": additional,
                                            "align-items-center": additional,
                                          })}
                                        >
                                          {!additional && type && !NOT_INPUT_SUB_FIELD_TYPES.includes(type) && (
                                            <>
                                              <Form.Label className="--bold">反映先</Form.Label>
                                              {(copyTargets ?? [])?.length > 0 ? (
                                                <div>
                                                  あり
                                                  <OverlayTrigger
                                                    placement="top"
                                                    overlay={(props) => (
                                                      <Popover id={`popover_${key}`} {...props} className="--pre-wrap">
                                                        <Popover.Body>
                                                          {copyTargets?.map(({ table, column }) => (
                                                            <div key={`${table}_${column}`}>
                                                              {`${sectorLabels[table]} ${sectorColumnLabels[table][column]}`}
                                                            </div>
                                                          ))}
                                                        </Popover.Body>
                                                      </Popover>
                                                    )}
                                                  >
                                                    <span>
                                                      <Icon
                                                        width={15}
                                                        height={15}
                                                        type="info-circle-fill"
                                                        className="ms-1"
                                                      />
                                                    </span>
                                                  </OverlayTrigger>
                                                  {hasInactiveCopyTargets && (
                                                    <OverlayTrigger
                                                      placement="top"
                                                      delay={{ show: 50, hide: 50 }}
                                                      overlay={(props) => (
                                                        <Tooltip id={`tooltip_${key}`} {...props}>
                                                          管理対象外のテーブルが含まれています
                                                        </Tooltip>
                                                      )}
                                                    >
                                                      <Badge pill bg="warning" className="ms-1">
                                                        !
                                                      </Badge>
                                                    </OverlayTrigger>
                                                  )}
                                                </div>
                                              ) : (
                                                <div>なし</div>
                                              )}
                                            </>
                                          )}
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>
                                  );
                                })}
                              </ListGroup>
                            )}
                            <div className="mt-2">
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => addInput(inputType)}
                                disabled={!state.isEditing}
                              >
                                {inputTypeLabel}の項目を追加
                              </Button>
                              {state.isEditing &&
                                inputType === "inputs" &&
                                (() => {
                                  const m = messages.find(
                                    (_) =>
                                      _.item === inputType &&
                                      _.pageIndex === state.activePageIndex &&
                                      _.place === "add_input_button"
                                  );
                                  return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
                                })()}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>

          <Row className="mt-4">
            <Col>
              <h3 className="Headline--section-sub mb-2">承認ステップ</h3>
              {state.isEditing && (
                <Row className="my-2">
                  <Col md={4}>他の申請書の承認フローをインポート</Col>
                  <Col md={8}>
                    <Form.Select
                      value={state.referringApplicationType}
                      onChange={(e) => {
                        const next = e.target.value;
                        if (!next) return;
                        $state({ ...state, referringApplicationType: next });
                        $activeModal("before_overwrite");
                      }}
                    >
                      <option value={"empty"}>---</option>
                      {templateSummaries
                        .filter((t) => t.is_active)
                        .map(({ application_type, name }, i) => {
                          return (
                            <option key={`${application_type}_${i}`} value={application_type}>
                              {name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={3}>
                  <ListGroup as="ol">
                    {state.steps.map((step, stepIndex) => {
                      const stepMessages = messages.filter((m) => m.item === "steps" && m.index === stepIndex);
                      return (
                        <ListGroup.Item
                          key={`step${stepIndex}`}
                          as="li"
                          className={classNames({
                            "border-success": stepIndex === state.activeStepIndex,
                            border: true,
                          })}
                        >
                          <Row>
                            <Col md={3}>
                              <Button
                                variant="link"
                                disabled={stepIndex === 0 || !state.isEditing}
                                onClick={() => moveStepIndex(-1, stepIndex)}
                              >
                                <Icon width={15} height={15} type="caret-up-fill" />
                              </Button>
                              <Button
                                variant="link"
                                disabled={stepIndex === state.steps.length - 1 || !state.isEditing}
                                onClick={() => moveStepIndex(1, stepIndex)}
                              >
                                <Icon width={15} height={15} type="caret-down-fill" />
                              </Button>
                            </Col>
                            <Col
                              md={9}
                              onClick={() => $state({ ...state, activeStepIndex: stepIndex })}
                              className="d-flex align-items-center --word-break-all"
                            >
                              {`${stepIndex + 1}. ${step.name}`}
                              {stepMessages.length > 0 && (
                                <Badge pill bg="danger" className="mx-1">
                                  !
                                </Badge>
                              )}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Col>
                <Col sm={9}>
                  {activeStep && (
                    <Card>
                      <Card.Body>
                        <Form.Label className="--required-label --bold">ステップ名</Form.Label>
                        <Row className="align-items-center mb-2">
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="ステップ名"
                              value={activeStep.name}
                              onChange={(e) => {
                                updateStep(state.activeStepIndex, "name", e.target.value);
                              }}
                              disabled={!state.isEditing}
                            />
                            {(() => {
                              const m = messages.find(
                                (_) => _.item === "steps" && _.index === state.activeStepIndex && _.place === "name"
                              );
                              return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
                            })()}
                          </Col>
                        </Row>
                        <Form.Label className="--required-label --bold">承認者の条件</Form.Label>
                        <Row className="mb-2">
                          <Col>
                            {["fixed", "related"].map((type) => {
                              return (
                                <Form.Check
                                  inline
                                  type="radio"
                                  id={`type_${type}`}
                                  label={TERMS[`APPLICATION_PROCESSOR_${type}`]}
                                  key={`type_${type}`}
                                  checked={type === activeStep.type}
                                  value={type}
                                  onChange={() => updateStep(state.activeStepIndex, "type", type)}
                                  disabled={!state.isEditing}
                                ></Form.Check>
                              );
                            })}
                            <Accordion className="mt-2">
                              {activeStep.type === "related" ? (
                                <Accordion.Item eventKey={"position"}>
                                  <Accordion.Header>
                                    {(activeStep.group.position_codes?.length ?? 0) > 0
                                      ? activeStep.group.position_codes
                                          ?.map((_) => positions.find((p) => p.positionCode === _)?.positionName ?? "")
                                          .join(", ")
                                      : "役職 : 指定しない"}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {positions.map((position) => {
                                      return (
                                        <Form.Check
                                          type="checkbox"
                                          id={`${position.id}`}
                                          label={position.positionName}
                                          key={`${position.id}`}
                                          checked={activeStep.group.position_codes?.includes(position.positionCode)}
                                          value={position.positionCode}
                                          className="my-1"
                                          onChange={() => {
                                            const isCheckedNow = activeStep.group.position_codes?.includes(
                                              position.positionCode
                                            );
                                            const next = {
                                              ...activeStep.group,
                                              position_codes: isCheckedNow
                                                ? activeStep.group.position_codes?.filter(
                                                    (_) => _ !== position.positionCode
                                                  )
                                                : [...(activeStep.group.position_codes ?? []), position.positionCode],
                                            };
                                            updateStep(state.activeStepIndex, "group", next);
                                          }}
                                          disabled={!state.isEditing}
                                        ></Form.Check>
                                      );
                                    })}
                                  </Accordion.Body>
                                </Accordion.Item>
                              ) : null}
                              {activeStep.type === "fixed" ? (
                                <Accordion.Item eventKey={"section"}>
                                  <Accordion.Header>
                                    {(activeStep.group.section_codes?.length ?? 0) > 0
                                      ? activeStep.group.section_codes
                                          ?.map((_) => sections.find((p) => p.sectionCode === _)?.name ?? "")
                                          .join(", ")
                                      : "部署 : 指定しない"}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {sections.map((section) => {
                                      return (
                                        <Form.Check
                                          type="checkbox"
                                          id={`${section.id}`}
                                          label={section.name}
                                          key={`${section.id}`}
                                          checked={activeStep.group.section_codes?.includes(section.sectionCode)}
                                          value={section.sectionCode}
                                          disabled={(activeStep.group.role_names?.length ?? 0) > 0 || !state.isEditing}
                                          className="my-1"
                                          onChange={() => {
                                            const isCheckedNow = activeStep.group.section_codes?.includes(
                                              section.sectionCode
                                            );
                                            const next = {
                                              ...activeStep.group,
                                              section_codes: isCheckedNow
                                                ? activeStep.group.section_codes?.filter(
                                                    (_) => _ !== section.sectionCode
                                                  )
                                                : [...(activeStep.group.section_codes ?? []), section.sectionCode],
                                            };
                                            updateStep(state.activeStepIndex, "group", next);
                                          }}
                                        ></Form.Check>
                                      );
                                    })}
                                  </Accordion.Body>
                                </Accordion.Item>
                              ) : null}
                              {activeStep.type === "fixed" ? (
                                <Accordion.Item eventKey={"role"}>
                                  <Accordion.Header>
                                    {(activeStep.group.role_names?.length ?? 0) > 0
                                      ? activeStep.group.role_names
                                          ?.map((_) => roles.find((r) => r.id === _)?.label ?? "")
                                          .join(", ")
                                      : "ロール : 指定しない"}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {roles.map((role) => {
                                      return (
                                        <Form.Check
                                          type="checkbox"
                                          id={`${role.id}`}
                                          label={role.label}
                                          key={`${role.id}`}
                                          checked={activeStep.group.role_names?.includes(role.id)}
                                          value={role.id}
                                          disabled={
                                            (activeStep.group.section_codes?.length ?? 0) > 0 || !state.isEditing
                                          }
                                          className="my-1"
                                          onChange={() => {
                                            const isCheckedNow = activeStep.group.role_names?.includes(role.id);
                                            const next = {
                                              ...activeStep.group,
                                              role_names: isCheckedNow
                                                ? activeStep.group.role_names?.filter((_) => _ !== role.id)
                                                : [...(activeStep.group.role_names ?? []), role.id],
                                            };
                                            updateStep(state.activeStepIndex, "group", next);
                                          }}
                                        ></Form.Check>
                                      );
                                    })}
                                  </Accordion.Body>
                                </Accordion.Item>
                              ) : null}
                            </Accordion>
                            {(() => {
                              const m = messages.find(
                                (_) => _.item === "steps" && _.index === state.activeStepIndex && _.place === "group"
                              );
                              return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
                            })()}
                          </Col>
                        </Row>
                        <Row className="mb-2 align-items-center">
                          <Col>
                            <Form.Check
                              id={`editable`}
                              type="checkbox"
                              label={"申請者に承認者の変更・追加を許可する"}
                              checked={activeStep.editable}
                              disabled={activeStep.type === "fixed" || !state.isEditing}
                              value={activeStep.editable ? "true" : "false"}
                              className="mt-1"
                              onChange={() => updateStep(state.activeStepIndex, "editable", !activeStep.editable)}
                            />
                          </Col>
                          {(() => {
                            const m = messages.find(
                              (_) => _.item === "steps" && _.index === state.activeStepIndex && _.place === "editable"
                            );
                            return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
                          })()}
                        </Row>
                        <Form.Label className="--required-label --bold">承認者が入力する項目</Form.Label>
                        <Row className="mb-2 align-items-center">
                          <Col>
                            <Accordion>
                              <Accordion.Item eventKey={"field"}>
                                <Accordion.Header>
                                  {(() => {
                                    const selected = processorInputFields
                                      .filter((_) => activeStep.inputs?.includes(_.key))
                                      .map((_) => _.label);
                                    return selected.length > 0 ? selected.join(", ") : "なし";
                                  })()}
                                </Accordion.Header>
                                <Accordion.Body>
                                  {processorInputFields.map((field) => {
                                    return (
                                      <Form.Check
                                        type="checkbox"
                                        id={`${field.key}`}
                                        label={field.label}
                                        key={`${field.key}`}
                                        checked={activeStep.inputs?.includes(field.key)}
                                        value={field.key}
                                        className="my-1"
                                        onChange={(e) => {
                                          const next = activeStep.inputs?.includes(e.target.value)
                                            ? activeStep.inputs.filter((_) => _ !== e.target.value)
                                            : [...(activeStep.inputs ?? []), e.target.value];
                                          updateStep(state.activeStepIndex, "inputs", next);
                                        }}
                                        disabled={!state.isEditing}
                                      ></Form.Check>
                                    );
                                  })}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            {(() => {
                              const m = messages.find(
                                (_) => _.item === "steps" && _.index === state.activeStepIndex && _.place === "input"
                              );
                              return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
                            })()}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              variant="outline-danger"
                              className="float-end"
                              size="sm"
                              disabled={!canDeleteStep || !state.isEditing}
                              onClick={() => deleteStep(state.activeStepIndex)}
                            >
                              削除
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )}
                </Col>
              </Row>
              <div className="mt-2">
                <Button variant="outline-primary" size="sm" onClick={() => addStep()} disabled={!state.isEditing}>
                  承認ステップを追加
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <h3 className="Headline--section-sub mb-2">データ反映タイミング</h3>
              <Form.Select
                value={state.indexToCopy}
                onChange={(e) => {
                  const nextSteps = state.steps.map((step, _i) => {
                    return {
                      ...step,
                      copy: _i === +e.target.value,
                    };
                  });
                  $state({ ...state, steps: nextSteps, indexToCopy: +e.target.value });
                }}
                disabled={!state.isEditing}
              >
                {state.steps.map((step, i) => {
                  return (
                    <option key={i} value={i}>
                      {step.name} 完了後
                    </option>
                  );
                })}
              </Form.Select>
              {(() => {
                const m = messages.find((message) => message.item === "copy");
                return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
              })()}
            </Col>
          </Row>
          {controlButtons}
        </>
      )}
      <Modal show={optionsModal.index !== -1} onHide={closeOptionsModal} size="lg" centered>
        <Modal.Body>
          <Row className="mb-1">
            <Col md={12} className="--overflow-auto">
              {optionsModal.options.map(({ label }, i) => {
                return (
                  <Row key={`editingOption${i}`}>
                    <Col md={optionsModal.editable ? 8 : 12}>
                      <Form.Control
                        type="text"
                        value={label}
                        disabled={!optionsModal.editable}
                        onChange={(e) => {
                          if (!optionsModal.editable) return;
                          const options = optionsModal.options.map((v, _i) =>
                            i === _i ? { ...v, label: e.target.value } : v
                          );
                          $optionsModal({ ...optionsModal, options });
                        }}
                      />
                      {label === "" && <div className="--font-s --text-annotation">入力してください</div>}
                      {duplicatedOptionLabels.includes(label) && (
                        <div className="--font-s --text-annotation">重複しています</div>
                      )}
                    </Col>
                    {optionsModal.editable && (
                      <>
                        <Col md={2}>
                          <Button variant="link" disabled={i === 0} onClick={() => moveOptionsIndex(-1, i)}>
                            <Icon width={15} height={15} type="caret-up-fill" />
                          </Button>
                          <Button
                            variant="link"
                            disabled={i === optionsModal.options.length - 1}
                            onClick={() => moveOptionsIndex(1, i)}
                          >
                            <Icon width={15} height={15} type="caret-down-fill" />
                          </Button>
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() =>
                              $optionsModal({
                                ...optionsModal,
                                options: optionsModal.options.filter((_, _i) => _i !== i),
                              })
                            }
                          >
                            削除
                          </Button>
                        </Col>
                      </>
                    )}
                  </Row>
                );
              })}
            </Col>
            {optionsModal.editable && displayOptionLength === 0 && (
              <div className="--font-s --text-annotation">表示する選択肢を一つ以上設定してください</div>
            )}
          </Row>
          {optionsModal.editable && (
            <div className="mt-2">
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() =>
                  $optionsModal({
                    ...optionsModal,
                    options: [...optionsModal.options, { label: "", editable: true, hidden: false }],
                  })
                }
              >
                選択肢を追加
              </Button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeOptionsModal} variant="outline-secondary">
            キャンセル
          </Button>
          {optionsModal.editable && (
            <Button
              disabled={
                optionsModal.options.length === 0 ||
                optionsModal.options.some(({ label }) => label === "") ||
                duplicatedOptionLabels.length > 0 ||
                displayOptionLength === 0
              }
              onClick={() => {
                updateInput(
                  optionsModal.inputType as "inputs" | "processor_inputs",
                  optionsModal.index,
                  "options",
                  optionsModal.options
                );
                closeOptionsModal();
              }}
            >
              設定
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={calculationModal.index !== -1} onHide={closeCalculationModal} size="lg" centered>
        <Modal.Body>
          <Row>
            <div className="--bold">計算式</div>
            {calculationModal.editable && (
              <>
                <div className="--font-s">※ 入力できる文字は、+ - * / ( ) と半角数字です。</div>
                <div className="--font-s">※ 差し込み項目を削除する場合は、項目全体を選択の上削除してください。</div>
              </>
            )}
            <Col>
              <Form.Control
                as="textarea"
                placeholder=""
                value={calculationModal.format}
                onChange={(e) => {
                  const value = e.target.value;
                  // 入力文字の正しさ（≠数式としての正しさ）を確認するため、【表示用差し込み項目】を1(仮の数値)で置換する
                  const check = value.replaceAll(/【([^【】]*)】/g, "1");
                  // 数値・四則演算子・括弧以外が含まれている場合は入力を受け付けない
                  if (!check.match(CALCULATION_FORMAT_REGEXP)) return;
                  $calculationModal({ ...calculationModal, format: value, validated: false });
                }}
                disabled={!calculationModal.editable}
                ref={calculationFormatRef}
              />
              {state.isEditing && calculationModal.editable && (
                <div>
                  {calculationModal.validated ? (
                    calculationModal.error ? (
                      <span className="--font-s --text-annotation">{calculationModal.error}</span>
                    ) : (
                      <span className="--font-s">正しい計算式です</span>
                    )
                  ) : (
                    <span className="--font-s">「設定」前に計算式を検証してください</span>
                  )}
                  <Button
                    size="sm"
                    className="my-1 float-end"
                    disabled={calculationModal.validated}
                    onClick={() => {
                      const { format, pageIndex, inputType } = calculationModal;
                      const _format = convertCalculationFormat(format, pageIndex, inputType);
                      const error = validateCalculationFormat(_format, pageIndex, inputType);
                      $calculationModal({ ...calculationModal, validated: true, error });
                    }}
                  >
                    計算式を検証
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          {calculationModal.editable && (
            <Row>
              <div className="--bold">差し込み項目</div>
              <Col>
                {numberInputsForCalculationModal.map(({ key, label }, i) => {
                  return (
                    <Button
                      key={key}
                      size="sm"
                      variant="outline-primary"
                      className="mx-1"
                      onClick={() => {
                        // カーソルの位置
                        const position = calculationFormatRef.current?.selectionStart ?? 0;
                        // カーソル以前とカーソル以降で分割し、間に追加する文字列を挿入
                        const before = calculationModal.format.substring(0, position);
                        const after = calculationModal.format.substring(position);
                        const insert = `【${label}[${i}]】`;
                        const next = `${before}${insert}${after}`;
                        $calculationModal({ ...calculationModal, format: next, validated: false });
                        // カーソル位置を差し込み項目後に設定（反映待ちのためsetTimeout）
                        const nextPosition = (before + insert).length;
                        setTimeout(() => {
                          calculationFormatRef.current?.focus();
                          calculationFormatRef.current?.setSelectionRange(nextPosition, nextPosition);
                        }, 0);
                      }}
                    >
                      {label}
                    </Button>
                  );
                })}
                {numberInputsForCalculationModal.length === 0 && "選択可能な項目なし"}
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeCalculationModal} variant="outline-secondary">
            キャンセル
          </Button>
          {calculationModal.editable && (
            <Button
              disabled={!(calculationModal.error === "" && calculationModal.validated)}
              onClick={() => {
                const { format, index, pageIndex, inputType } = calculationModal;
                updateInput(
                  inputType as "inputs" | "processor_inputs",
                  index,
                  "reference_format",
                  convertCalculationFormat(format, pageIndex, inputType)
                );
                closeCalculationModal();
              }}
            >
              設定
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={activeModal === "before_create"}
        onConfirm={() => {
          $activeModal("");
          create();
        }}
        onCancel={() => {
          $activeModal("");
        }}
        message="新しいバージョンの作成を開始します。よろしいですか？"
      />
      <ModalDialog
        show={activeModal === "before_overwrite"}
        onConfirm={() => {
          $activeModal("");
          importSteps();
        }}
        onCancel={() => {
          $activeModal("");
          $state({ ...state, referringApplicationType: "empty" });
        }}
        message="インポートして編集中の承認フローを上書きしてよろしいですか？"
      />
      <ModalDialog
        show={activeModal === "fail_import"}
        onConfirm={() => {
          $activeModal("");
        }}
        type="alert"
        message="この申請書の承認フローは設定されていないため、インポートできませんでした。"
      />
      <ModalDialog
        show={activeModal === "before_release"}
        onConfirm={() => {
          $activeModal("");
          release();
        }}
        onCancel={() => $activeModal("")}
        message="新しいバージョンをリリースします。リリース後に作成される申請書から設定が反映されます。よろしいですか？"
      />
      <ModalDialog
        show={activeModal === "before_delete"}
        onConfirm={() => {
          $activeModal("");
          discard();
        }}
        onCancel={() => {
          $activeModal("");
        }}
        type="destructiveConfirm"
        message={`${isRightAfterCopy ? "コピー" : "編集中の内容"}を削除します。よろしいですか？`}
        confirmButtonName="削除"
      />
    </Container>
  );
}

export default ApplicationConfig;
