import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar_Evaluation from "../../component/Sidebar_Evaluation";
import { Container, Row, Col, Form, Alert, Button } from "react-bootstrap";
import EvaluationProjectList from "./EvaluationProjectList";
import EvaluationSystemList from "./EvaluationSystemList";
import EvaluationProjectGroupList from "./EvaluationProjectGroupList";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { setEditTarget } from "./evaluationSheetSlice";
import { useAppDispatch } from "../../app/store";

const answerStepLengthOptions = [
  {
    value: "a",
    label: "本人だけが回答する",
  },
  {
    value: "b",
    label: "本人・直属の上司が回答する",
  },
  {
    value: "c",
    label: "本人・直属の上司・さらに上の上司が回答する",
  },
];

const stepLengthOptions = [
  {
    value: "1",
    label: "1 (例: 本人の回答のみ)",
    placeholder: "例: 本人評価",
  },
  {
    value: "2",
    label: "2 (例: 本人、直属の上司が回答)",
    placeholder: "例: １次評価",
  },
  {
    value: "3",
    label: "3 (例: 本人、直属の上司、部長が回答)",
    placeholder: "例: ２次評価",
  },
  {
    value: "4",
    label: "4",
    placeholder: "例: ３次評価",
  },
  {
    value: "5",
    label: "5",
    placeholder: "例: ４次評価",
  },
  {
    value: "6",
    label: "6以上",
    placeholder: "例: ５次評価",
  },
];

const confirmStepLengthOptions = [
  {
    value: "0",
    label: "0 (例: 管理者が結果をダウンロードするのみ)",
  },
  {
    value: "1",
    label: "1 (例: 本人が承認)",
  },
  {
    value: "2",
    label: "2 (例: 本人、直属の上司が承認)",
  },
  {
    value: "3",
    label: "3以上",
  },
];

const colorOptions = [
  {
    value: "a",
    label: "パターンA",
  },
  {
    value: "b",
    label: "パターンB",
  },
];

const settingsOptions = [
  {
    value: "use_old_layout",
    label: "現在使っている評価シート等のレイアウトを踏襲したい",
  },
  {
    value: "has_old_layout",
    label: "現在使っている評価シート等があるが、新しくレイアウトを作成しなおしたい",
  },
  {
    value: "no_old_layout",
    label: "現在使っている評価シート等はないので、新しくレイアウトを作成したい",
  },
];

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [values, $values] = useState({
    settings: "use_old_layout",
    name: "",
    stepLength: 1,
    stepNames: [] as string[],
    confirmStepLength: 0,
    behaviorPolicy_showAttributes: false,
    behaviorPolicy_openValues: false,
    behaviorPolicy_hideValues: false,
    contentPolicy_past: false,
    contentPolicy_weight: false,
    coloring: "a",
  });
  const [entered, $entered] = useState([] as string[]);

  return (
    <div className="Ev-container --editor">
      <Sidebar_Evaluation current="list" />
      <div className="Ev-edit-content bg-white">
        <Container>
          <Row className="mb-4">
            <Col>
              <h1 className="Headline--page">新規評価タイプの作成</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Alert variant="info">
                現在の評価制度（または予定している評価制度）に一番近い内容を選択し、初期状態を設定してください。どの設定も後から変更可能なので、正確でなくても構いません。
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col md={2} className="--bold pt-2">
              評価タイプ名
            </Col>
            <Col md={10}>
              <Form.Control
                type="text"
                value={values.name}
                placeholder="例: 標準の評価制度"
                onChange={(e) => {
                  $values({ ...values, name: e.target.value });
                }}
              ></Form.Control>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={2} className="--bold">
              評価フロー
            </Col>
            <Col md={10}>
              <Row className="mb-2 --align-items-center">
                <Col md={2} className="--bold">
                  回答ステップ数
                </Col>
                <Col md={10}>
                  <Form.Select
                    value={values.stepLength}
                    onChange={(e) => {
                      $values({
                        ...values,
                        stepLength: +e.target.value,
                        stepNames: values.stepNames.filter((_, i) => i < +e.target.value),
                      });
                      $entered([...entered.filter((e) => e !== "stepLength"), "stepLength"]);
                    }}
                  >
                    {stepLengthOptions.map((o, i) => {
                      return (
                        <option key={`option_${i}`} value={o.value}>
                          {o.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>

              <section className="Ev-edit__group mb-4">
                {(() => {
                  const result = [];
                  for (let i = 0; i < values.stepLength; i++) {
                    result.push(
                      <Row key={`step_${i}`} className="my-1 --align-items-center">
                        <Col md={2} className="--bold">
                          {`ステップ${i + 1}の名称`}
                        </Col>
                        <Col md={10}>
                          <Form.Control
                            type="text"
                            value={values.stepNames[i]}
                            placeholder={stepLengthOptions[i].placeholder}
                            onChange={(e) => {
                              $values({
                                ...values,
                                stepNames: values.stepNames.map((_, si) => {
                                  return si !== i ? _ : e.target.value;
                                }),
                              });
                            }}
                          ></Form.Control>
                        </Col>
                      </Row>
                    );
                  }
                  return result;
                })()}
              </section>

              <Row className="mb-2 --align-items-center">
                <Col md={2} className="--bold">
                  承認ステップ数
                </Col>
                <Col md={10}>
                  <Form.Select
                    value={values.confirmStepLength}
                    onChange={(e) => {
                      $values({ ...values, confirmStepLength: +e.target.value });
                      $entered([...entered.filter((e) => e !== "confirmStepLength"), "confirmStepLength"]);
                    }}
                  >
                    {confirmStepLengthOptions.map((o, i) => {
                      return (
                        <option key={`option_${i}`} value={o.value}>
                          {o.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={2} className="--bold">
              回答画面の方針
            </Col>
            <Col md={10}>
              <Row>
                <Col md={2} className="--bold">
                  作成方針
                </Col>
                <Col md={10}>
                  {settingsOptions.map((o, i) => {
                    return (
                      <Form.Check
                        type="radio"
                        label={o.label}
                        key={`settings_${i}`}
                        id={`settings_${i}`}
                        checked={values.settings === o.value}
                        value={o.value}
                        onChange={(e) => {
                          $values({ ...values, settings: e.target.value });
                        }}
                      />
                    );
                  })}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={2} className="--bold">
                  操作性
                </Col>
                <Col md={10}>
                  <div className="mb-2">
                    <Form.Check
                      type="checkbox"
                      id="behaviorPolicy_showAttributes"
                      label="被評価者のプロファイル（名前・部署など）を表示したい"
                      onChange={() => {
                        $values({ ...values, behaviorPolicy_showAttributes: !values.behaviorPolicy_showAttributes });
                      }}
                      checked={values.behaviorPolicy_showAttributes}
                    />
                  </div>
                  <div className="mb-2">
                    <Form.Check
                      type="checkbox"
                      id="behaviorPolicy_openValues"
                      label="上司が部下の回答を見ながら回答できるようにしたい"
                      onChange={() => {
                        $values({ ...values, behaviorPolicy_openValues: !values.behaviorPolicy_openValues });
                      }}
                      checked={values.behaviorPolicy_openValues}
                    />
                  </div>
                  <div className="mb-2">
                    <Form.Check
                      type="checkbox"
                      id="behaviorPolicy_hideValues"
                      label="上司の入力欄を部下に隠したい"
                      onChange={() => {
                        $values({ ...values, behaviorPolicy_hideValues: !values.behaviorPolicy_hideValues });
                      }}
                      checked={values.behaviorPolicy_hideValues}
                    />
                  </div>
                  <div className="mb-2">
                    <Form.Check
                      type="checkbox"
                      id="contentPolicy_past"
                      label="前期間の評価を回答時に閲覧させたい"
                      onChange={() => {
                        $values({ ...values, contentPolicy_past: !values.contentPolicy_past });
                      }}
                      checked={values.contentPolicy_past}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={2} className="--bold">
                  分析
                </Col>
                <Col md={10}>
                  <div className="mb-2">
                    <Form.Check
                      type="checkbox"
                      id="contentPolicy_weight"
                      label="重要な項目の点数を内部的に高く扱いたい"
                      onChange={() => {
                        $values({ ...values, contentPolicy_weight: !values.contentPolicy_weight });
                      }}
                      checked={values.contentPolicy_weight}
                    />
                  </div>
                  <div className="mb-2">
                    <Form.Check
                      type="checkbox"
                      id="contentPolicy_weight"
                      label=""
                      onChange={() => {
                        $values({ ...values, contentPolicy_weight: !values.contentPolicy_weight });
                      }}
                      checked={values.contentPolicy_weight}
                    />
                  </div>
                </Col>
              </Row>
              {/* <Row className="mt-4">
                <Col md={2} className="--bold pt-2">
                  カラーリング
                </Col>
                <Col md={10}>
                  <Form.Select
                    value={values.coloring}
                    onChange={(e) => {
                      $values({ ...values, coloring: e.target.value });
                    }}
                  >
                    {colorOptions.map((o, i) => {
                      return (
                        <option key={`option_${i}`} value={o.value}>
                          {o.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row> */}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={2} className="--bold"></Col>
            <Col md={10}>
              <Button variant="outline-secondary" onClick={() => navigate("/_/evaluation/list/types")} className="mx-1">
                キャンセル
              </Button>
              <Button variant="primary" onClick={() => false}>
                保存
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
