import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams, useLocation, useSearchParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  selectClientState,
  initSectorSettingStatus,
  putSectorSettingStatus,
  downloadAdhocFormatFiles,
  getPreparationFiles,
  postPreparationFile,
  putPreparationFile,
  deletePreparationFile,
  selectImportFormatFiles,
  selectRawDataFiles,
  selectActiveSectors,
  selectMasterActiveSectors,
  deleteLoadData,
  deleteAdhocCell,
  getLoadData,
} from "./clientSlice";
import { selectUserState } from "../../features/login/userSlice";
import { ThreadCommentSummary, selectComments, getParentThreadComments } from "../../features/client/threadSlice";
import Sidebar from "../../component/Sidebar";
import Icon from "../../component/Icon";
import Table from "../../component/Table";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import TagCloud from "../../component/TagCloud";
import { getQuery, toTimeLabel } from "../../app/util";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip as RTooltip,
} from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  PreparationFile,
  ThreadBoxItem,
  IMPORT_STATUSES,
  Sector,
  IMPORT_TYPES,
  IMPORT_TYPES_IN_PROGRESS,
} from "./clientValues";
import { getApiSummaries } from "../../features/permission/permissionSlice";
import ThreadBox from "../../component/ThreadBox";
import { getAllTerms } from "../../app/translate";
import classNames from "classnames";
import { scroller } from "react-scroll";
import { downloadFile } from "../profile/profileSlice";
import ModalDialog from "../../component/ModalDialog";

ChartJS.register(ArcElement, Tooltip, Legend);

function App() {
  const dispatch = useAppDispatch();
  const { sectorSettingStatus, sectorSettingStatusId, sectorUserStatus, loadId } = useAppSelector(selectClientState);
  const activeSectors = useAppSelector(selectActiveSectors);
  const masterActiveSectors = useAppSelector(selectMasterActiveSectors);
  const { user } = useAppSelector(selectUserState);
  const receivedComments = useAppSelector(selectComments);
  const rawDataFiles = useAppSelector(selectRawDataFiles);
  const importFormatFiles = useAppSelector(selectImportFormatFiles);
  const { search } = useLocation();
  const [featuredSectorId, $featuredSectorId] = useState(getQuery(search)["sector"]);
  const isProcessing = useRef(false);
  const [activeModal, $activeModal] = useState("");
  const [searchParams, $setSearchParams] = useSearchParams();
  const [sectorId, $setSectorId] = useState("");
  const [logicalName, $setLogicalName] = useState("");
  useEffect(() => {
    $featuredSectorId(searchParams.get("sector") || "");
  }, [searchParams]);
  useEffect(() => {
    if (sectorId) {
      dispatch(getLoadData({ sectorId }));
    }
  }, [sectorId]);

  const [fileUploaderState, $fileUploaderState] = useState({
    type: "",
    isOpen: false,
    remarks: "",
    tags: [] as string[],
    decodedFileData: null as DecodedFileData | null,
    activeConfirmModal: "" as "create" | "discardFile" | "discard" | "",
  });
  const [fileEditorState, $fileEditorState] = useState({
    isOpen: false,
    id: "",
    remarks: "",
    tags: [] as string[],
    activeConfirmModal: "" as "delete" | "update" | "discard" | "",
  });
  const editingFile = useMemo(() => {
    if (fileEditorState.id === "") return null;
    return [...importFormatFiles, ...rawDataFiles].find((f) => f.id === fileEditorState.id);
  }, [fileEditorState]);

  const closeFileEditModal = () => {
    $fileEditorState({ isOpen: false, id: "", remarks: "", tags: [], activeConfirmModal: "" });
  };
  const closeFileEditConfirmModal = () => {
    $fileEditorState({ ...fileEditorState, activeConfirmModal: "" });
  };
  const cancelFileEdit = () => {
    const { remarks, tags } = fileEditorState;
    if (
      remarks !== editingFile?.remarks ||
      tags.some((t) => !editingFile?.tags.includes(t)) ||
      editingFile?.tags.some((t) => !tags.includes(t))
    ) {
      $fileEditorState({ ...fileEditorState, activeConfirmModal: "discard" });
    } else {
      closeFileEditModal();
    }
  };
  const closeFileUploadModal = () => {
    $fileUploaderState({
      type: "",
      isOpen: false,
      remarks: "",
      tags: [],
      decodedFileData: null,
      activeConfirmModal: "",
    });
  };
  const closeFileUploadConfirmModal = () => {
    $fileUploaderState({ ...fileUploaderState, activeConfirmModal: "" });
  };
  const cancelFileUpload = () => {
    const { decodedFileData, remarks, tags } = fileUploaderState;
    if (decodedFileData !== null || remarks.length !== 0 || tags.length !== 0) {
      $fileUploaderState({ ...fileUploaderState, activeConfirmModal: "discard" });
    } else {
      closeFileUploadModal();
    }
  };
  const handleBeforeDestroy = (sectorId: string, logicalName: string) => {
    $activeModal("before_destroy");
    $setSectorId(sectorId);
    $setLogicalName(logicalName);
  };
  const allActiveSectors = useMemo(() => {
    return [
      {
        category: "account",
        description: "",
        displayStatus: "",
        is_view: false,
        is_summary: false,
        logical_name: "アカウント",
        master_required: false,
        sector_id: "account",
        status: "todo",
      } as Sector,
      ...masterActiveSectors,
      ...activeSectors,
    ];
  }, [activeSectors, masterActiveSectors]);

  const [threadModalState, $threadModalState] = useState({
    isOpen: false,
    title: "",
    threadBoxItem: {
      webScreen: "",
    } as ThreadBoxItem,
  });
  const closeThreadModal = () => {
    dispatch(getParentThreadComments());
    $threadModalState({
      ...threadModalState,
      title: "",
      isOpen: false,
      threadBoxItem: {
        webScreen: "",
      },
    });
    $featuredSectorId("");
    if (searchParams.get("sector")) $setSearchParams({});
  };
  const [tableNameKeyword, $tableNameKeyword] = useState("");
  const [selectedStatus, $selectedStatus] = useState([] as string[]);
  const postFile = async () => {
    const { type, decodedFileData, tags, remarks } = fileUploaderState;
    if (isProcessing.current || decodedFileData === null) return;
    isProcessing.current = true;
    await dispatch(postPreparationFile({ type, decodedFileData, tags, remarks }));
    dispatch(getPreparationFiles());
    closeFileUploadModal();
    isProcessing.current = false;
  };
  const putFile = async () => {
    const { id, tags, remarks } = fileEditorState;
    await dispatch(putPreparationFile({ id, tags, remarks }));
    dispatch(getPreparationFiles());
    closeFileEditModal();
  };
  const deleteFile = async () => {
    const id = fileEditorState.id;
    if (isProcessing.current || !id) return;
    isProcessing.current = true;
    await dispatch(deletePreparationFile({ id }));
    dispatch(getPreparationFiles());
    closeFileEditModal();
    isProcessing.current = false;
  };

  const destroyRecords = async () => {
    try {
      if (sectorId) {
        if (loadId) {
          dispatch(deleteLoadData({ loadId }));
        }
        // セルデータ削除内でセクター設定ステータスの更新を行っているため、セルデータが空の場合も削除処理を行う
        await dispatch(deleteAdhocCell({ sectorId: sectorId ?? "" }));
        // これを呼ばないとステータスの画面表示が更新されない場合がある
        await selectSettingStatus(sectorId, IMPORT_STATUSES[0]);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const TERMS = getAllTerms();
  const graphData = useMemo(() => {
    const keys = [...IMPORT_STATUSES].reverse();
    const labels = keys.map((k) => TERMS[`IMPORT_STATUS_${k}`]);
    const data = [0, 0, 0, 0, 0];
    for (const key in allActiveSectors) {
      const activeSector = allActiveSectors[key];
      if (activeSector.is_view === true || activeSector.is_summary) continue;
      switch (sectorSettingStatus[activeSector.sector_id]?.status) {
        case keys[0]:
          data[0]++;
          break;
        case keys[1]:
          data[1]++;
          break;
        case keys[2]:
          data[2]++;
          break;
        case keys[3]:
          data[3]++;
          break;
        case keys[4]:
          data[4]++;
          break;
        default:
          data[4]++;
          break;
      }
    }
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: [
            "rgba(70, 159, 118, 0.4)",
            "rgba(61, 139, 253, 0.4)",
            "rgba(61, 139, 253, 0.4)",
            "rgba(61, 139, 253, 0.4)",
            "rgba(137, 144, 151, 0.4)",
          ],
          borderColor: [
            "rgba(25, 135, 84, 1)",
            "rgba(13, 110, 253, 1)",
            "rgba(13, 110, 253, 1)",
            "rgba(13, 110, 253, 1)",
            "rgba(108, 117, 125, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
  }, [allActiveSectors, sectorSettingStatus, sectorUserStatus]);

  const graphOptions = {
    plugins: {
      legend: {
        position: "right" as "right",
        labels: {
          font: {
            size: 11,
          },
        },
      },
    },
    layout: {
      padding: {
        bottom: 80,
      },
    },
  };

  const downloadFormatFiles = async () => {
    await dispatch(downloadAdhocFormatFiles());
  };

  const selectSettingStatus = (sectorId: string, status: string) => {
    const itemName: string = `${sectorId}.status`;
    const updateData: { [key: string]: string } = { [itemName]: status };
    dispatch(
      putSectorSettingStatus({
        sectorSettingStatusId,
        updateData,
      })
    );
  };

  type ReceivedCommentsPerSector = { [sector: string]: { receivedComments: ThreadCommentSummary[] } };
  const threadsPerSector: ReceivedCommentsPerSector = useMemo(() => {
    const result = {} as ReceivedCommentsPerSector;
    for (const sector in sectorSettingStatus) {
      if (sector.indexOf("updated_at") !== -1) continue;
      result[sector] = {
        receivedComments: receivedComments.filter((c) => c.webScreen === `import/${sector}`),
      };
    }
    // account も追加する
    result["account"] = { receivedComments: receivedComments.filter((c) => c.webScreen === `import/account`) };
    return result;
  }, [sectorSettingStatus, receivedComments]);

  useEffect(() => {
    dispatch(getParentThreadComments());
    dispatch(getPreparationFiles());
    dispatch(getApiSummaries());
  }, []);
  const displaySectors = useMemo(() => {
    return allActiveSectors
      .map((s) => {
        const status = sectorSettingStatus[s.sector_id]?.status ?? IMPORT_STATUSES[0];
        s = {
          ...s,
          // ステータスを取得、表示用に変換
          status,
          displayStatus: TERMS[`IMPORT_STATUS_${status}`],
        };
        return s;
      })
      .filter((s) => {
        // 仮想テーブルまたはサマリーテーブル（XX経験年数など）は表示しない
        return s.is_view !== true && s.is_summary !== true;
      });
  }, [tableNameKeyword, allActiveSectors, sectorSettingStatus]);

  const sectorIdLabels = useMemo(() => {
    return allActiveSectors.reduce((prev, { sector_id, logical_name }) => {
      return { ...prev, [sector_id]: logical_name };
    }, {}) as { [key: string]: string };
  }, [allActiveSectors]);

  const [isAnimationDone, $isAnimationDone] = useState(false);
  useEffect(() => {
    if (!isAnimationDone && Object.keys(allActiveSectors).length > 0 && featuredSectorId) {
      // 取得したテーブル
      $isAnimationDone(true);
      scroller.scrollTo(featuredSectorId, {
        duration: 200,
        delay: 1000,
        smooth: true,
        offset: -100,
      });
    }
  }, [displaySectors]);

  useEffect(() => {
    if (displaySectors.length > 0) {
      const display = displaySectors.find((d) => d.sector_id === featuredSectorId);
      if (display) {
        $threadModalState({
          ...threadModalState,
          title: `${display.logical_name} についてのスレッド`,
          isOpen: true,
          threadBoxItem: { webScreen: `import/${featuredSectorId}` },
        });
      }
    }
  }, [featuredSectorId, displaySectors]);

  const getFileJSX = (f: PreparationFile) => {
    const displayName = `${f.name}(${toTimeLabel(f.created_at)})`;
    return (
      <article key={f.id} className="--align-items-center my-1">
        <OverlayTrigger
          placement="top"
          overlay={(props) => (
            <RTooltip id="button-tooltip" {...props}>
              {displayName}
              {f.remarks && <div className="--font-s">{f.remarks}</div>}
            </RTooltip>
          )}
        >
          <div
            className="--bullet --ellipsis --text-link __Link --no-wrap"
            onClick={() =>
              $fileEditorState({
                isOpen: true,
                id: f.id,
                remarks: f.remarks,
                tags: f.tags,
                activeConfirmModal: "",
              })
            }
          >
            {displayName}
          </div>
        </OverlayTrigger>
      </article>
    );
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"import"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">データインポート</h1>
        <Modal
          show={fileEditorState.isOpen}
          onHide={cancelFileEdit}
          className="mt-4"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>ファイル編集</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                {editingFile?.name}
                <Button
                  variant="primary"
                  className="ms-1"
                  onClick={() => {
                    if (!editingFile) return;
                    dispatch(downloadFile({ fileId: editingFile.file_id, key: editingFile.key }));
                  }}
                >
                  ダウンロード
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <h3 className="Headline--section-sub mb-2 --required-label">関連するテーブル</h3>
                <TagCloud
                  className="my-2"
                  data={displaySectors.map(({ sector_id, logical_name }) => {
                    return {
                      id: sector_id,
                      label: logical_name,
                      isSelected: fileEditorState.tags.includes(sector_id),
                    };
                  })}
                  onChange={(id) => {
                    $fileEditorState({
                      ...fileEditorState,
                      tags: fileEditorState.tags.includes(id)
                        ? fileEditorState.tags.filter((_) => _ !== id)
                        : [...fileEditorState.tags, id],
                    });
                  }}
                />
                {fileEditorState.tags.length === 0 && (
                  <span className="--text-annotation --font-s">1つ以上選択してください</span>
                )}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <h3 className="Headline--section-sub mb-2">備考</h3>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={fileEditorState.remarks}
                  onChange={(e) => {
                    $fileEditorState({
                      ...fileEditorState,
                      remarks: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col>
                <Button variant="outline-secondary" onClick={cancelFileEdit}>
                  キャンセル
                </Button>
                <Button
                  variant="danger"
                  className="mx-1"
                  onClick={() => $fileEditorState({ ...fileEditorState, activeConfirmModal: "delete" })}
                >
                  削除
                </Button>
                <Button
                  variant="primary"
                  disabled={fileEditorState.tags.length === 0}
                  onClick={() => $fileEditorState({ ...fileEditorState, activeConfirmModal: "update" })}
                >
                  更新
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
        <ModalDialog
          show={activeModal === "before_destroy"}
          onConfirm={() => {
            $activeModal("");
            destroyRecords();
          }}
          onCancel={() => {
            $activeModal("");
          }}
          type="destructiveConfirm"
          confirmButtonName="破棄"
          message={`インポート中の${logicalName}の内容をすべて破棄します。よろしいですか？`}
        />
        <ModalDialog
          show={fileEditorState.activeConfirmModal === "delete"}
          onConfirm={deleteFile}
          onCancel={closeFileEditConfirmModal}
          message="削除します。よろしいですか？"
          type="destructiveConfirm"
          confirmButtonName="削除"
        />
        <ModalDialog
          show={fileEditorState.activeConfirmModal === "update"}
          onConfirm={putFile}
          onCancel={closeFileEditConfirmModal}
          message="更新します。よろしいですか？"
        />
        <ModalDialog
          show={fileUploaderState.activeConfirmModal === "create"}
          onConfirm={postFile}
          onCancel={closeFileUploadConfirmModal}
          message="保存します。よろしいですか？"
        />
        <ModalDialog
          show={fileUploaderState.activeConfirmModal === "discardFile"}
          onConfirm={() => $fileUploaderState({ ...fileUploaderState, decodedFileData: null, activeConfirmModal: "" })}
          onCancel={closeFileUploadConfirmModal}
          message="ファイルを破棄します。よろしいですか？"
          type="destructiveConfirm"
          confirmButtonName="破棄"
        />
        <ModalDialog
          show={fileUploaderState.activeConfirmModal === "discard"}
          onConfirm={closeFileUploadModal}
          onCancel={closeFileUploadConfirmModal}
          message="入力内容を破棄します。よろしいですか？"
          type="destructiveConfirm"
          confirmButtonName="破棄"
        />
        <ModalDialog
          show={fileEditorState.activeConfirmModal === "discard"}
          onConfirm={closeFileEditModal}
          onCancel={closeFileEditConfirmModal}
          message="入力内容を破棄します。よろしいですか？"
          type="destructiveConfirm"
          confirmButtonName="破棄"
        />
        <Modal
          show={fileUploaderState.isOpen}
          onHide={closeFileUploadModal}
          className="mt-4"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>{TERMS[`FILE_TYPE_${fileUploaderState.type}`]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2">
              <Col>
                <div className="my-2">
                  <h3 className="Headline--section-sub mb-2 --required-label">添付ファイル</h3>
                  {fileUploaderState.decodedFileData === null ? (
                    <>
                      <Uploader
                        onFileLoad={(decodedFileData) => $fileUploaderState({ ...fileUploaderState, decodedFileData })}
                      />
                      <span className="--text-annotation --font-s">ファイルをアップロードしてください</span>
                    </>
                  ) : (
                    <Card>
                      <Card.Body>
                        ファイル添付済（{fileUploaderState.decodedFileData.name}）
                        <Button
                          variant="danger"
                          className="ms-1"
                          onClick={() =>
                            $fileUploaderState({ ...fileUploaderState, activeConfirmModal: "discardFile" })
                          }
                        >
                          添付済のファイルを破棄
                        </Button>
                      </Card.Body>
                    </Card>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <h3 className="Headline--section-sub mb-2 --required-label">関連するテーブル</h3>
                <TagCloud
                  className="my-2"
                  data={displaySectors.map(({ sector_id, logical_name }) => {
                    return {
                      id: sector_id,
                      label: logical_name,
                      isSelected: fileUploaderState.tags.includes(sector_id),
                    };
                  })}
                  onChange={(id) => {
                    $fileUploaderState({
                      ...fileUploaderState,
                      tags: fileUploaderState.tags.includes(id)
                        ? fileUploaderState.tags.filter((_) => _ !== id)
                        : [...fileUploaderState.tags, id],
                    });
                  }}
                />
                {fileUploaderState.tags.length === 0 && (
                  <span className="--text-annotation --font-s">1つ以上選択してください</span>
                )}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <h3 className="Headline--section-sub mb-2">備考</h3>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={fileUploaderState.remarks}
                  onChange={(e) => {
                    $fileUploaderState({
                      ...fileUploaderState,
                      remarks: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={cancelFileUpload} variant="outline-secondary">
              キャンセル
            </Button>
            <Button
              disabled={fileUploaderState.decodedFileData === null || fileUploaderState.tags.length === 0}
              onClick={() => $fileUploaderState({ ...fileUploaderState, activeConfirmModal: "create" })}
              variant="primary"
            >
              保存
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={threadModalState.isOpen}
          onHide={closeThreadModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <Row className="mb-2">
              <Col>
                <h2 className="Headline--section mb-2">{threadModalState.title}</h2>
                <div className="my-2">
                  <ThreadBox
                    threadBoxItem={threadModalState.threadBoxItem}
                    onChange={() => {
                      dispatch(initSectorSettingStatus({ sectors: allActiveSectors }));
                      dispatch(getParentThreadComments());
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeThreadModal} variant="outline-secondary">
              閉じる
            </Button>
          </Modal.Footer>
        </Modal>
        <Form className="Grouping mt-3 bg-white">
          <Container>
            <Row className="mb-2">
              <Col>
                <p>
                  企業・従業員情報を整理して各テーブルにデータとして登録していきます。{/* 詳しくは */}
                  {/* <Link to="/_/import_guide/">データインポート作業の進め方</Link>をご覧ください。 */}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h2 className="Headline--section-sub">進捗</h2>
                <div className="Doughnut">
                  <Doughnut data={graphData} options={graphOptions} />
                </div>
              </Col>
              <Col md={8}>
                <h2 className="Headline--section-sub mt-2 mb-2">1. 元データの収集</h2>
                <p className="mb-4">現在管理している企業・従業員情報を収集します。</p>
                <h2 className="Headline--section-sub mt-2 mb-2">2. アップロードファイルの作成</h2>
                <p className="mb-4">元データからアップロードファイルを作成します。</p>
                <p className="mb-4">
                  ※ファイルフォーマットは
                  <a className="--inline-link --cursor-pointer" onClick={downloadFormatFiles}>
                    こちら
                  </a>
                  から。
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 50, hide: 50 }}
                    overlay={(props) => (
                      <RTooltip id="info-tooltip" {...props}>
                        ※アカウントの登録フォーマットはアカウントのインポート画面からダウンロードできます。
                        <br />
                        ※一括登録の際はファイル項目をインポート先の項目にマッピングをするためファイルフォーマットは自由ですが、上記フォーマットを使用するとマッピングが省けます。
                      </RTooltip>
                    )}
                  >
                    <span>
                      <Icon width={16} height={16} type="info-circle-fill" />
                    </span>
                  </OverlayTrigger>
                </p>
                {/* <p className="mb-4"> */}
                {/* ※アップロードファイルの作成サポート機能は<Link to={"/_/import_support/"}>こちら</Link>から。 */}
                {/* </p> */}
                <h2 className="Headline--section-sub mt-2 mb-2">3. インポート</h2>
                <p className="mb-2">テーブル一覧のインポートリンクからデータを登録します。</p>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Row>
                  <Col>
                    <h2 className="Headline--section mb-2">テーブル一覧</h2>
                    <Card className="mb-4">
                      <Card.Body>
                        <Row>
                          <span className="--bold">表示条件</span>
                        </Row>
                        <Row className="--align-items-center mb-1">
                          <Col md={2}>ステータス：</Col>
                          <Col md={10}>
                            {IMPORT_STATUSES.map((_) => (
                              <Form.Check
                                type="checkbox"
                                key={`check-${_}`}
                                id={`check-${_}`}
                                label={TERMS[`IMPORT_STATUS_${_}`]}
                                checked={selectedStatus.includes(_)}
                                onChange={() => {
                                  if (selectedStatus.includes(_)) {
                                    $selectedStatus(selectedStatus.filter((s) => s !== _));
                                  } else {
                                    $selectedStatus([...selectedStatus, _]);
                                  }
                                }}
                                inline
                              />
                            ))}
                          </Col>
                        </Row>
                        <Row className="--align-items-center mb-1">
                          <Col md={2}>テーブル名：</Col>
                          <Col md={10}>
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={tableNameKeyword}
                              onChange={(e) => {
                                $tableNameKeyword(e.target.value);
                              }}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Row className="mb-4-auto">
                      <Col>
                        <div className="text-end">
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              $fileUploaderState({
                                ...fileUploaderState,
                                type: "raw",
                                isOpen: true,
                              });
                            }}
                          >
                            元データの保存
                          </Button>
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              $fileUploaderState({
                                ...fileUploaderState,
                                type: "import",
                                isOpen: true,
                              });
                            }}
                            className="ms-1"
                          >
                            アップロードファイルの保存
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <div className="Mapping-list">
                      <div className="Mapping-list__item">
                        <div className="Mapping-list__item-value">
                          <Row>
                            <Col sm={2} className="--bold">
                              ステータス
                            </Col>
                            <Col sm={3} className="--bold">
                              テーブル
                            </Col>
                            <Col sm={3} className="--bold">
                              概要
                            </Col>
                            <Col sm={4} className="--bold pe-4">
                              元データ・アップロードファイル
                            </Col>
                          </Row>
                        </div>
                      </div>
                      {displaySectors.map(({ sector_id, logical_name, description, displayStatus, status }) => {
                        const matchedPreparationFilesHTML = (
                          <div>
                            {rawDataFiles
                              .filter((f) => f.tags.includes(sector_id))
                              .sort((a: any, b: any) => a.created_at - b.created_at)
                              .map((f) => getFileJSX(f))}
                          </div>
                        );
                        const matchedImportFormatFilesHTML = (
                          <div>
                            {importFormatFiles
                              .filter((f) => f.tags.includes(sector_id))
                              .sort((a: any, b: any) => a.created_at - b.created_at)
                              .map((f) => getFileJSX(f))}
                          </div>
                        );

                        if (selectedStatus.length > 0 && !selectedStatus.includes(status)) {
                          // ステータスの絞り込み条件に対応しない場合表示しない
                          return null;
                        }
                        if (tableNameKeyword && !logical_name.includes(tableNameKeyword)) {
                          return null;
                        }

                        const commentLayout = (() => {
                          const receivedComments = threadsPerSector[sector_id ?? ""]?.receivedComments ?? [];
                          return (
                            <div
                              className={classNames({
                                "Mapping-list__item-thread": true,
                                "mb-1": true,
                                "--text-annotation":
                                  receivedComments[0] &&
                                  user &&
                                  receivedComments[0].author !== user?.id &&
                                  receivedComments[0].isRecentComment,
                              })}
                              onClick={() =>
                                $threadModalState({
                                  ...threadModalState,
                                  title: `${logical_name} についてのスレッド`,
                                  isOpen: true,
                                  threadBoxItem: { webScreen: `import/${sector_id}` },
                                })
                              }
                            >
                              <Icon width={18} height={18} type="chat-left-text-fill" />
                              {receivedComments?.length > 0 ? (
                                <span className="mx-1 --font-s">{receivedComments[0].time}</span>
                              ) : (
                                <span className="mx-1 --font-s">コメントなし</span>
                              )}
                            </div>
                          );
                        })();
                        return (
                          <section
                            id={sector_id}
                            key={sector_id}
                            className={classNames({
                              "Mapping-list__item": featuredSectorId !== sector_id,
                              "Mapping-list__item--featured": featuredSectorId === sector_id,
                            })}
                          >
                            <div className="Mapping-list__item-value">
                              <Row className="--align-items-center">
                                <Col md={2}>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant={(() => {
                                        switch (displayStatus) {
                                          case "開始前":
                                            return "outline-secondary";
                                          case "終了日設定中":
                                          case "履歴追加中":
                                            return "outline-primary";
                                          case "完了":
                                            return "outline-success";
                                          default:
                                            return "outline-secondary";
                                        }
                                      })()}
                                    >
                                      {displayStatus}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {IMPORT_STATUSES.map((o, ii) => (
                                        <Dropdown.Item
                                          key={`status-${ii}`}
                                          onClick={() =>
                                            status === IMPORT_STATUSES[0] || status === IMPORT_STATUSES[4]
                                              ? selectSettingStatus(sector_id, o)
                                              : handleBeforeDestroy(sector_id, logical_name)
                                          }
                                          disabled={
                                            status === IMPORT_STATUSES[0] || status === IMPORT_STATUSES[4]
                                              ? Boolean(IMPORT_TYPES.some(({ id }) => id === o))
                                              : Boolean(IMPORT_TYPES_IN_PROGRESS.some(({ id }) => id === o))
                                          }
                                        >
                                          {TERMS[`IMPORT_STATUS_${o}`]}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Col>
                                <Col md={3}>
                                  <div className="Mapping-list__item-label">
                                    <div className="Mapping-list__item-label-content">
                                      {sector_id !== "account" ? (
                                        <Link to={`/_/import/${sector_id}/`}>{logical_name}</Link>
                                      ) : (
                                        <Link to={`/_/account/`}>
                                          {logical_name}
                                          <span className="--px-1">
                                            <Icon type="external-tab" width={16} height={16} />
                                          </span>
                                        </Link>
                                      )}
                                      <div>
                                        <span>{commentLayout}</span>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={3}>{description}</Col>
                                <Col md={4}>
                                  {rawDataFiles.some((f) => f.tags.includes(sector_id)) ? (
                                    <div>
                                      <div>元データ</div>
                                      <div>{matchedPreparationFilesHTML}</div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {importFormatFiles.some((f) => f.tags.includes(sector_id)) ? (
                                    <div>
                                      <div>アップロードファイル</div>
                                      <div>{matchedImportFormatFilesHTML}</div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </section>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <h2 className="Headline--section mb-2">元データリスト</h2>
                <Row className="mt-2">
                  <Table
                    col={[
                      {
                        name: "ファイル名",
                        width: 200,
                        filterable: true,
                      },
                      {
                        name: "記載内容",
                        width: 150,
                        filterable: true,
                      },
                      {
                        name: "日時",
                        width: 150,
                      },
                      {
                        name: "備考",
                        width: 200,
                        filterable: true,
                      },
                    ]}
                    row={rawDataFiles.map((file) => {
                      return {
                        data: [
                          file.name,
                          file.tags
                            .map((tag) => sectorIdLabels[tag])
                            .filter((_) => _)
                            .join(", "),
                          toTimeLabel(file.created_at),
                          file.remarks,
                        ],
                        action: {
                          handler: () => {
                            $fileEditorState({
                              isOpen: true,
                              id: file.id,
                              remarks: file.remarks,
                              tags: file.tags,
                              activeConfirmModal: "",
                            });
                          },
                        },
                      };
                    })}
                    usePagenation={true}
                    useKeywordFilter={true}
                  />
                </Row>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
    </div>
  );
}

export default App;
