import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  ProfileField,
  ProfileSubFieldType,
  ProfileSubField,
  FieldValue,
  ProfileSubFieldDiff,
  generateProfileFieldsBundler,
  diffCollector,
  requestBodyBuilderMap,
  ValidDateMap,
  FieldValidator,
  LabelValueOption,
  generateMultipleProfileFieldsBundler,
  generateValidatorBundler,
} from "./profileFieldValues";
import {
  personal,
  personal_history,
  bonus,
  bonus_history,
  transportation_allowance,
  transportation_allowance_history,
  dependent,
  dependent_history,
  bank_account,
  bank_account_history,
  resident_tax,
  resident_tax_history,
  assignment_old,
  assignment2,
  assignment_old_history,
  assignment2_history,
  section_old_summary,
  section2_summary,
  position_old_summary,
  position2_summary,
  job,
  job_history,
  job_summary,
  grade_old,
  grade_old_history,
  grade_old_summary,
  grade2,
  grade2_history,
  grade2_summary,
  education,
  education_history,
  spouse,
  spouse_history,
  address,
  address_history,
  social_insurance,
  social_insurance_history,
  resignee,
  resignee_history,
  industrial_accident,
  industrial_accident_history,
  former_job,
  former_job_history,
  mid_career,
  mid_career_history,
  recruitment,
  recruitment_history,
  employment_insurance,
  employment_insurance_history,
  project,
  project_history,
  training,
  training_history,
  attendance,
  attendance_history,
  salary,
  salary_history,
  reward_punishment,
  reward_punishment_history,
  license,
  license_history,
  labor_contract,
  labor_contract_history,
  leave_of_absence,
  leave_of_absence_history,
  secondment,
  secondment_history,
  secondment_acceptance,
  secondment_acceptance_history,
  accessible_section,
  accessible_section_history,
  accounting_section,
  accounting_section_history,
  accounting_section_from_m_section,
  accounting_section_from_m_section_history,
  additional_information_from_m_section,
  additional_information_from_m_section_history,
  additional_information_from_m_position,
  additional_information_from_m_position_history,
  residence_with_working,
  residence_with_working_history,
  term_contract,
  term_contract_history,
  union,
  union_history,
  personal_classification,
  personal_classification_history,
  calculation_unit_price,
  calculation_unit_price_history,
  emergency_contact,
  emergency_contact_history,
  duty,
  duty_history,
  duty_summary,
  withholding_tax,
  withholding_tax_history,
} from "./subset/";
import { RegularColumn } from "../client/clientValues";

dayjs.extend(utc);

export interface Account {
  id: number;
  name: string;
  image: string;
  isActive: boolean;
  isBilling: boolean;
  isRemind: boolean;
  language: string;
  loginCode: string;
  mailAddress: string;
  mainCompanyCode: string;
  remarks: string;
  statusMessage: string;
}
export interface Member {
  id: number;
  name: string;
  image: string;
  sectionCode: number;
  sectionName: number;
  fullSectionName: number;
  positionCode: number;
  positionName: number;
  isConcurrent: boolean;
}
export interface Profile {
  memberId: number;
  snapshots: {
    validFrom: string;
    data: {
      [key: string]: {
        [subKey: string]: FieldValue;
      };
    };
  }[];
}
export interface GuestAccount {
  id: number;
  isActive: boolean;
  language: string;
  mailAddress: string;
  name: string;
  companyName: string;
  rolesInCurrentCompany: {
    id: number;
    role_name: string;
    service_id: number;
  }[];
}

// 編集形式のパターン分けのための値

export type ProfileFieldStatus = {
  [fieldPath: string]: {
    validated: boolean;
    errorMessage: string;
  };
};
export type FieldEditMode = "browse" | "update";

export const fieldCategoryGroups = [
  // TODO : 管理対象テーブルでないものは除外できるようにしたい
  {
    id: "group_company",
    label: "会社情報",
    categories: [
      { id: "personal", label: { ja: "基本" } },
      { id: "assignment2", label: { ja: "所属部署・役職" } },
      { id: "assignment_old", label: { ja: "【廃止予定】所属部署・役職" } },
      { id: "grade2", label: { ja: "等級" } },
      { id: "grade_old", label: { ja: "【廃止予定】等級" } },
      { id: "job", label: { ja: "職種" } },
      { id: "duty", label: { ja: "職務" } },
      { id: "accessible_section", label: { ja: "アクセス可能な部署" } },
    ],
  },
  {
    id: "group_personal",
    label: "個人情報",
    categories: [
      { id: "address", label: { ja: "住所" } },
      { id: "emergency_contact", label: { ja: "緊急連絡先" } },
      { id: "personal_classification", label: { ja: "本人区分" } },
      { id: "residence_with_working", label: { ja: "在留就労" } },
      { id: "union", sector_id: "profile_u_union", label: { ja: "組合" } },
      { id: "spouse", label: { ja: "配偶者" } },
      { id: "dependent", label: { ja: "家族" } },
    ],
  },
  {
    id: "group_slip_withholding_tax",
    label: "明細・源泉徴収票",
    categories: [
      { id: "attendance", label: { ja: "勤怠" } },
      { id: "salary", label: { ja: "給与" } },
      { id: "bonus", label: { ja: "賞与" } },
      { id: "withholding_tax", label: { ja: "源泉徴収情報" } },
    ],
  },
  {
    id: "group_payment_insurance",
    label: "給与・社会保険",
    categories: [
      { id: "social_insurance", label: { ja: "社会保険" } },
      { id: "employment_insurance", label: { ja: "雇用保険" } },
      { id: "industrial_accident", label: { ja: "労災保険" } },
      { id: "resident_tax", label: { ja: "住民税" } },
      { id: "transportation_allowance", label: { ja: "通勤手当" } },
      { id: "bank_account", label: { ja: "振込口座" } },
      { id: "calculation_unit_price", label: { ja: "計算単価" } },
      { id: "reward_punishment", label: { ja: "賞罰" } },
      { id: "mid_career", label: { ja: "中途入社" } },
      { id: "accounting_section", label: { ja: "経理用負担部署" } },
      { id: "accounting_section_from_m_section", label: { ja: "経理用負担部署（コードテーブル連動）" } },
      { id: "additional_information_from_m_section", label: { ja: "所属部署付加情報" } },
      { id: "additional_information_from_m_position", label: { ja: "役職付加情報" } },
    ],
  },
  {
    id: "group_contract",
    label: "雇用",
    categories: [
      { id: "recruitment", label: { ja: "採用" } },
      { id: "labor_contract", label: { ja: "労働契約" } },
      { id: "term_contract", label: { ja: "有期雇用契約" } },
      { id: "secondment", label: { ja: "出向" } },
      { id: "secondment_acceptance", label: { ja: "出向受入" } },
      { id: "leave_of_absence", label: { ja: "休職" } },
      { id: "resignee", label: { ja: "退職" } },
    ],
  },
  {
    id: "group_skill",
    label: "スキル",
    categories: [
      { id: "project", label: { ja: "プロジェクト" } },
      { id: "training", label: { ja: "教育研修" } },
      { id: "license", label: { ja: "免許資格" } },
      { id: "education", label: { ja: "学歴" } },
      { id: "former_job", label: { ja: "前職" } },
    ],
  },
];
export type TabTables = {
  tables: string[];
  isBaseDateRequired?: boolean;
};

export const tablesOfCategory = {
  personal: { tables: ["personal"] },
  address: { tables: ["address"] },
  resignee: { tables: ["resignee"] },
  salary: { tables: ["salary"] },
  bonus: { tables: ["bonus"] },
  transportation_allowance: { tables: ["transportation_allowance"] },
  bank_account: { tables: ["bank_account"] },
  resident_tax: { tables: ["resident_tax"] },
  dependent: { tables: ["dependent"] },
  assignment_old: {
    tables: ["assignment_old", "section_old_summary", "position_old_summary"],
    isBaseDateRequired: false,
  },
  assignment2: {
    tables: ["assignment2", "section2_summary", "position2_summary"],
    isBaseDateRequired: false,
  },
  job: {
    tables: ["job", "job_summary"],
    isBaseDateRequired: false,
  },
  grade_old: {
    tables: ["grade_old", "grade_old_summary"],
    isBaseDateRequired: false,
  },
  grade2: {
    tables: ["grade2", "grade2_summary"],
    isBaseDateRequired: false,
  },
  education: { tables: ["education"], isBaseDateRequired: false },
  spouse: { tables: ["spouse"] },
  social_insurance: { tables: ["social_insurance"] },
  industrial_accident: { tables: ["industrial_accident"] },
  former_job: { tables: ["former_job"] },
  mid_career: { tables: ["mid_career"] },
  recruitment: { tables: ["recruitment"] },
  employment_insurance: { tables: ["employment_insurance"] },
  project: { tables: ["project"], isBaseDateRequired: false },
  training: { tables: ["training"], isBaseDateRequired: false },
  attendance: { tables: ["attendance"] },
  reward_punishment: {
    tables: ["reward_punishment"],
    isBaseDateRequired: false,
  },
  license: { tables: ["license"], isBaseDateRequired: false },
  secondment: { tables: ["secondment"] },
  secondment_acceptance: { tables: ["secondment_acceptance"] },
  accounting_section: { tables: ["accounting_section"] },
  accounting_section_from_m_section: { tables: ["accounting_section_from_m_section"] },
  additional_information_from_m_section: { tables: ["additional_information_from_m_section"] },
  additional_information_from_m_position: { tables: ["additional_information_from_m_position"] },
  accessible_section: { tables: ["accessible_section"], isBaseDateRequired: false },
  labor_contract: { tables: ["labor_contract"] },
  leave_of_absence: { tables: ["leave_of_absence"] },
  residence_with_working: { tables: ["residence_with_working"] },
  term_contract: { tables: ["term_contract"] },
  union: { tables: ["union"], isBaseDateRequired: false },
  personal_classification: { tables: ["personal_classification"] },
  calculation_unit_price: { tables: ["calculation_unit_price"] },
  emergency_contact: { tables: ["emergency_contact"] },
  duty: {
    tables: ["duty", "duty_summary"],
    isBaseDateRequired: false,
  },
  withholding_tax: { tables: ["withholding_tax"] },
} as { [category: string]: TabTables };

export const tableCategoryMapping = (() => {
  let tableCategoryMapping = {} as {
    [key: string]: string;
  };
  Object.entries(tablesOfCategory).forEach((category) => {
    category[1].tables.map((table) => {
      tableCategoryMapping[table] = category[0];
    });
  });
  return tableCategoryMapping;
})();

const _profileSectorStaff = {
  personal,
  personal_history,
  bonus,
  bonus_history,
  transportation_allowance,
  transportation_allowance_history,
  dependent,
  dependent_history,
  bank_account,
  bank_account_history,
  resident_tax,
  resident_tax_history,
  assignment_old,
  assignment2,
  assignment_old_history,
  assignment2_history,
  section_old_summary,
  section2_summary,
  position_old_summary,
  position2_summary,
  job,
  job_history,
  job_summary,
  grade_old,
  grade_old_history,
  grade_old_summary,
  grade2,
  grade2_history,
  grade2_summary,
  education,
  education_history,
  spouse,
  spouse_history,
  address,
  address_history,
  social_insurance,
  social_insurance_history,
  resignee,
  resignee_history,
  industrial_accident,
  industrial_accident_history,
  former_job,
  former_job_history,
  mid_career,
  mid_career_history,
  recruitment,
  recruitment_history,
  employment_insurance,
  employment_insurance_history,
  project,
  project_history,
  training,
  training_history,
  attendance,
  attendance_history,
  salary,
  salary_history,
  reward_punishment,
  reward_punishment_history,
  license,
  license_history,
  labor_contract,
  labor_contract_history,
  leave_of_absence,
  leave_of_absence_history,
  secondment,
  secondment_history,
  secondment_acceptance,
  secondment_acceptance_history,
  accessible_section,
  accessible_section_history,
  accounting_section,
  accounting_section_history,
  accounting_section_from_m_section,
  accounting_section_from_m_section_history,
  additional_information_from_m_section,
  additional_information_from_m_section_history,
  additional_information_from_m_position,
  additional_information_from_m_position_history,
  residence_with_working,
  residence_with_working_history,
  term_contract,
  term_contract_history,
  union,
  union_history,
  personal_classification,
  personal_classification_history,
  calculation_unit_price,
  calculation_unit_price_history,
  emergency_contact,
  emergency_contact_history,
  duty,
  duty_history,
  duty_summary,
  withholding_tax,
  withholding_tax_history,
} as {
  [field: string]: {
    category: string;
    table: string;
    validateSubFields?: FieldValidator;
    toProfileFields?: (responseResults: { [k: string]: any }[], columns?: RegularColumn[]) => ProfileField[];
    toDiffList?: (subFields: ProfileSubField[], validDateMap: ValidDateMap) => ProfileSubFieldDiff[];
    toRequestBody?: {
      put: (body: { [field: string]: FieldValue }, recordId: number) => { [field: string]: FieldValue };
      post: (
        body: { [field: string]: FieldValue },
        login_code: string,
        valid_from: string,
        valid_to: string
      ) => { [field: string]: FieldValue };
    };
    subFields: {
      [subField: string]: {
        type?: ProfileSubFieldType;
        required?: boolean;
        editable?: boolean;
        errorMessage?: string;
        defaultValue?: FieldValue;
        rules?: [string, number][];
        options?: string[];
        labelValueOptions?: LabelValueOption[];
        tag?: string;
        tagGroupIndex?: number;
        minTagGroupsLength?: number;
        maxTagGroupsLength?: number;
      };
    };
    labelMap?: { [lang: string]: { [term: string]: string } };
    history?: boolean;
  };
};
for (const fieldName in _profileSectorStaff) {
  // 属性のデフォルトを追加
  for (const subFieldName in _profileSectorStaff[fieldName].subFields) {
    const sub = _profileSectorStaff[fieldName].subFields[subFieldName];
    sub.editable = sub.editable !== false;
    sub.required = sub.required === true;
    sub.defaultValue = null; // いずれ廃止の方向 デフォルト値は一律バックエンドで扱い、フロントからの送信値は null にする
    sub.rules = sub.rules ?? [];
    sub.labelValueOptions = sub.labelValueOptions ?? sub.options?.map((o) => ({ label: o, value: o })) ?? [];
  }

  // validateSubFields が未定義の場合、ここで定義
  _profileSectorStaff[fieldName].validateSubFields =
    _profileSectorStaff[fieldName].validateSubFields ||
    generateValidatorBundler(_profileSectorStaff[fieldName].subFields);

  // toProfileFields（ API レスポンスから ProfileField[] を返す）をここで定義
  _profileSectorStaff[fieldName].toProfileFields =
    _profileSectorStaff[fieldName].toProfileFields ||
    (fieldName.endsWith("_history") || fieldName.endsWith("_summary")
      ? generateMultipleProfileFieldsBundler(fieldName, _profileSectorStaff[fieldName], "tableHandler", "tb_")
      : generateProfileFieldsBundler(fieldName, _profileSectorStaff[fieldName]));

  // toDiffList（ProfileField[] から API リクエストボディを組み立てるための配列を返す）をここで定義
  _profileSectorStaff[fieldName].toDiffList = _profileSectorStaff[fieldName].toDiffList || diffCollector;

  // toRequestBody（メソッドごとに API リクエストボディを調整する関数のまとまり）をここで定義
  _profileSectorStaff[fieldName].toRequestBody = _profileSectorStaff[fieldName].toRequestBody || requestBodyBuilderMap;
}
const profileSectorStaff = { ..._profileSectorStaff } as {
  [field: string]: {
    category: string;
    table: string;
    validateSubFields: FieldValidator;
    toDiffList: (subFields: ProfileSubField[], validDateMap: ValidDateMap) => ProfileSubFieldDiff[];
    toRequestBody: {
      put: (body: { [field: string]: FieldValue }, recordId: number) => { [field: string]: FieldValue };
      post: (
        body: { [field: string]: FieldValue },
        login_code: string,
        valid_from: string,
        valid_to: string
      ) => { [field: string]: FieldValue };
    };
    toProfileFields: (responseResult: { [k: string]: any }[], columns?: RegularColumn[]) => ProfileField[];
    subFields: {
      [subField: string]: {
        type: ProfileSubFieldType;
        required: boolean;
        editable: boolean;
        rules: [string, number | string][];
        errorMessage?: string;
        defaultValue?: FieldValue;
        labelValueOptions?: LabelValueOption[];
      };
    };
    labelMap: { [lang: string]: { [term: string]: string } };
    history?: boolean;
  };
};
export { profileSectorStaff };

export const validateProfileField = (
  profileField: ProfileField,
  next: ProfileSubField[]
): {
  validated: boolean;
  subFields: ProfileSubField[];
} => {
  const field = profileSectorStaff[profileField.fieldName];
  if (!field)
    return {
      validated: false,
      subFields: [],
    };
  return field.validateSubFields(next);
};

export const decodeStatusMessage = (statusMessage: string) => {
  try {
    if (statusMessage) {
      const code = JSON.parse(statusMessage)["code"];
      switch (code) {
        case 1:
          return "アカウント画面で管理者が無効化しました。";
        case 2:
          return "アカウントアップロードで管理者が無効化しました。";
        case 3:
          return "ログイン失敗回数の上限を超えたため無効化されました。";
        case 4:
          return "退職から1か月以上経過したため無効化されました。";
        default:
          return "無効になった原因は不明です。";
      }
    } else {
      return "";
    }
  } catch (e) {
    return "";
  }
};

export type fieldViewData = {
  id: string;
  label: string;
  checked: boolean;
  subFields: { id: string; label: string; checked: boolean }[];
};

export const USER_TABLE_PREFIX = "profile_u_";

export type ChoiceItem = {
  label?: string;
  value: string;
  checked: boolean;
};

export type SearchItem = {
  column: string;
  label: string;
  choices: ChoiceItem[];
};

export type SearchCondition = {
  [key: string]: any;
};
export const ACCOUNT_SEARCH_LIMIT = 20;
export const PREVIEW_LIST_UPTO = 20;

export type MultiTablesDownloadPolicy =
  | "latest"
  | "by_date"
  | "matched"
  | "by_today"
  | "updated_by_today"
  | "created_before_and_updated_by_today";
export type DiffDownloadPolicy = "latest" | "matched" | "updated";

export type UploadingAccountRawData = {
  [key: string]: string | number | boolean;
};

export type UploadingAccountData = {
  disabled_service_ids: number[];
  enabled_service_ids: number[];
  id: number;
  is_active: string | number | boolean;
  is_remind: string | number | boolean;
  language: string;
  line: number;
  login_code: string;
  mail_address: string;
  main_company_code: string;
  name: string;
  password: string;
  remarks: string;
  services: string[];
};

export const accountLanguages = [
  {
    label: "日本語",
    value: "ja",
  },
  {
    label: "英語",
    value: "en",
  },
];

export const accountFieldTerms = {
  is_active: "ステータス",
  is_remind: "リマインドメール",
  language: "言語",
  login_code: "ログインID",
  mail_address: "メールアドレス",
  main_company_code: "ログイン時の企業コード",
  name: "名前",
  password: "パスワード",
  remarks: "備考",
} as {
  [key: string]: string;
};

export type PersonalData = {
  id: number;
  account_id: number;
  employment_type: string;
  enrollment_type: string;
  name: string;
};
