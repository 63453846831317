import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Modal, Card, ListGroup, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { fieldViewData, USER_TABLE_PREFIX, MultiTablesDownloadPolicy } from "../../features/profile/profileValues";
import {
  downloadMultiTableList,
  getSearchCondition,
  postSearchCondition,
  deleteSearchCondition,
  selectProfileState,
} from "../../features/profile/profileSlice";
import { userColumnChoices } from "./profileFieldValues";
import FieldListSelector from "../../features/profile/FieldListSelector";
import SnapshotTimeSelector, { onChangeOption } from "../../features/profile/SnapshotTimeSelector";
import {
  getSectors,
  selectClientState,
  selectActiveSectors,
  getPayrollSetting,
} from "../../features/client/clientSlice";
import dayjs from "dayjs";
import "react-calendar/dist/Calendar.css";
import ModalDialog from "../../component/ModalDialog";
import OrderEditor from "../../component/OrderEditor";
import { getQuery } from "../../app/util";
import { selectUserState } from "../../features/login/userSlice";
import { isPermitted } from "../permission/permissionValues";

function ProfileList() {
  const dispatch = useAppDispatch();
  const downloadType: string = "multiple";
  // クエリパラメータを変換
  const { search } = useLocation();
  const { options, tables, policy, outputFormat } = useMemo(() => {
    const { dateFrom, options, tables, policy, outputFormat } = getQuery(search);
    try {
      if (search.includes("dateTo")) {
        //差分ダウンロードにページ移動した場合、わざとエラーを出して初期値を表示
        throw new Error("requiredColumns is not supported");
      }
      return {
        dateFrom,
        options: JSON.parse(options),
        tables: JSON.parse(tables) as string[],
        policy: policy as MultiTablesDownloadPolicy,
        outputFormat: outputFormat as string,
      };
    } catch (e) {
      return {
        options: {
          dateFormat: "yyyy-MM-dd",
          enrollment_type__in: ["在籍", "休職"] as string[],
          include_updated_at: true,
        },
        tables: [] as string[],
        policy: "matched",
        outputFormat: "xlsx",
      };
    }
  }, [search]);
  const { policies } = useAppSelector(selectUserState);
  const { sectorRegularColumns, payrollSetting } = useAppSelector(selectClientState);
  const activeSectors = useAppSelector(selectActiveSectors);
  const { searchConditions } = useAppSelector(selectProfileState);
  const [state, $state] = useState({
    selectedPointDate: dayjs(),
    dateError: "",
    fieldViewSectors: tables.map((id) => {
      return { id, label: "", checked: true, subFields: [] };
    }) as fieldViewData[],
    enrollmentTypeOptions: userColumnChoices["profile_u_personal"]["enrollment_type"].map((e, i) => {
      return {
        id: `enrollment_type${i + 1}`,
        label: e,
        checked: options.enrollment_type__in?.includes(e) ?? false,
      };
    }),
    retreiveOptions: [
      {
        id: "latest",
        label: "指定日時点の最新データ",
        checked: policy === "latest",
        isFutureSelectable: true,
      },
      {
        id: "by_date",
        label: "指定日までの全データ",
        checked: policy === "by_date",
        isFutureSelectable: true,
      },
      {
        id: "matched",
        label: "指定日と開始日が一致する全データ",
        checked: policy === "matched" || !policy,
        isFutureSelectable: true,
      },
      {
        id: "by_today",
        label: "開始日が指定日から本日までの全データ",
        checked: policy === "by_today",
        isFutureSelectable: false,
      },
      {
        id: "updated_by_today",
        label: "指定日から本日までに作成・更新されたデータ",
        checked: policy === "updated_by_today",
        isFutureSelectable: false,
      },
      {
        id: "created_before_and_updated_by_today",
        label: "指定日より前に作成され、指定日から本日までに更新されたデータ",
        checked: policy === "created_before_and_updated_by_today",
        isFutureSelectable: false,
      },
    ] as {
      id: MultiTablesDownloadPolicy;
      label: string;
      checked: boolean;
      isFutureSelectable: boolean;
    }[],
    outputFormat: [
      {
        id: "xlsx",
        label: "通常の形式",
        checked: outputFormat === "xlsx" || !outputFormat,
      },
      {
        id: "xlsx_external",
        label: "給与計算システム用エクセル",
        checked: outputFormat === "xlsx_external",
      },
    ] as {
      id: string;
      label: string;
      checked: boolean;
    }[],
    dateOptions: [
      {
        id: "format1",
        label: "yyyy-MM-dd",
        checked: options.dateFormat === "yyyy-MM-dd",
      },
      {
        id: "format2",
        label: "yyyy/MM/dd",
        checked: options.dateFormat === "yyyy/MM/dd",
      },
    ],
    dataOptions: [
      {
        id: "include_updated_at",
        label: "最新更新日付を出力する",
        checked: options.include_updated_at === true,
      },
    ],
    order: [] as { label: string; id: string; fixed: boolean }[],
  });
  const [isModalActive, $isModalActive] = useState(false);
  const [searchConditionModal, $searchConditionModal] = useState({
    isShow: false,
    isConfirming: false,
    label: "",
  });
  const [searchConditionListModal, $searchConditionListModal] = useState({
    isShow: false,
    selectingId: "",
    isConfirming: false,
  });
  const selectField = (id: string, parentFieldId?: string) => {
    $state({
      ...state,
      fieldViewSectors: state.fieldViewSectors.map((sector) => {
        return {
          ...sector,
          checked: !parentFieldId && sector.id === id ? !sector.checked : sector.checked,
          subFields: sector.subFields.map((s) => {
            if (parentFieldId !== sector.id || id !== s.id) return s;
            return {
              ...s,
              checked: !s.checked,
            };
          }),
        };
      }),
      order: (() => {
        if (parentFieldId) return state.order;
        return state.order.some((_) => _.id === id)
          ? state.order.filter((_) => _.id !== id)
          : [
              ...state.order,
              {
                id,
                label: activeSectors.find((_) => _.sector_id === `${USER_TABLE_PREFIX}${id}`)?.logical_name ?? "--",
                fixed: false,
              },
            ];
      })(),
    });
  };
  const isNormalOutput = useMemo(() => {
    return state.outputFormat.find((_) => _.checked)?.id === "xlsx";
  }, [state.outputFormat]);

  const download = () => {
    dispatch(downloadMultiTableList(createConditionParams()));
    $isModalActive(true);
  };

  const createConditionParams = () => {
    return {
      dateFrom: state.selectedPointDate.format("YYYY-MM-DD"),
      policy: state.retreiveOptions.find((_) => _.checked)?.id ?? "matched",
      outputFormat: state.outputFormat.find((_) => _.checked)?.id ?? "xlsx",
      tables: state.order.map((_) => _.id),
      options: (() => {
        return {
          enrollment_type__in: state.enrollmentTypeOptions.filter((_) => _.checked).map((_) => _.label) as string[],
          ...state.dataOptions.reduce((prev, current) => {
            return { ...prev, [current.id]: current.checked };
          }, {} as { [key: string]: boolean }),
          dateFormat: state.dateOptions.find((_) => _.checked)?.label ?? "format1",
        };
      })(),
    };
  };

  const applyPreset = (json: any) => {
    const query = Object.keys(json)
      .filter((key) => ["dateFrom", "options", "tables", "policy", "outputFormat"].includes(key))
      .map((key) => `${key}=${typeof json[key] === "string" ? json[key] : JSON.stringify(json[key])}`)
      .join("&");
    window.location.href = `${window.location.origin}${window.location.pathname}?${query}`;
  };

  const deleteCondition = async () => {
    await dispatch(deleteSearchCondition({ id: searchConditionListModal.selectingId }));
    const res = await dispatch(getSearchCondition({ downloadType: downloadType }));
    $searchConditionListModal({
      ...searchConditionListModal,
      isConfirming: false,
      selectingId: "",
      isShow: res.payload.length > 0,
    });
  };

  const hideSearchConditionModal = () => {
    $searchConditionModal({ ...searchConditionModal, isShow: false, isConfirming: false });
  };

  const hideSearchConditionListModal = () => {
    $searchConditionListModal({ ...searchConditionListModal, isShow: false, isConfirming: false });
  };

  const saveSearchCondition = async () => {
    // 指定日は絞り込み条件の設定として保存しないため、保存用の条件から除外
    const { dateFrom, ...conditionParams } = createConditionParams();
    const condition = {
      label: searchConditionModal.label,
      downloadType: downloadType,
      ...conditionParams,
    };
    await dispatch(postSearchCondition(condition));
    await dispatch(getSearchCondition({ downloadType: downloadType }));
    hideSearchConditionModal();
  };

  // 表示項目を設定
  useEffect(() => {
    if (activeSectors.length > 0) {
      // 表示項目データ作成
      let fieldViewSectors = [] as fieldViewData[];
      const userDataSectors = activeSectors
        .filter((s) => s.sector_id.startsWith(USER_TABLE_PREFIX))
        .filter((_) => isPermitted(policies, _.sector_id.replace(USER_TABLE_PREFIX, "user_data_manager/"), "GET"));
      for (const i in userDataSectors) {
        const sector = userDataSectors[i];
        const sectorId = sector.sector_id;
        if (sector.is_summary) continue;
        const replacedSectorId = sectorId.replace(USER_TABLE_PREFIX, "");
        const field = {
          id: replacedSectorId,
          label: sector.logical_name,
          checked: state.fieldViewSectors.find((_) => _.id === replacedSectorId)?.checked ?? false,
          subFields: [],
        };
        fieldViewSectors = [...fieldViewSectors, field];
      }
      const order =
        state.order.length > 0
          ? state.order
          : fieldViewSectors.filter(({ checked }) => checked).map(({ id, label }) => ({ id, label, fixed: false }));
      $state({ ...state, fieldViewSectors, order });
    }
  }, [activeSectors, sectorRegularColumns]);
  useEffect(() => {
    // 未来日付の選択の許可がない選択肢で、未来日付が選択されている場合はエラーを表示
    const hasDateError =
      !state.retreiveOptions.find((_) => _.checked)?.isFutureSelectable &&
      state.selectedPointDate.isAfter(dayjs(), "day");
    $state({ ...state, dateError: hasDateError ? "本日までの日付から選択してください。" : "" });
  }, [state.selectedPointDate, state.retreiveOptions]);

  useEffect(() => {
    dispatch(getPayrollSetting());
    dispatch(getSectors());
    dispatch(getSearchCondition({ downloadType: downloadType }));
  }, []);

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="--flex">
                <Button
                  variant="outline-primary"
                  className="mx-2"
                  disabled={searchConditions.length === 0}
                  onClick={() =>
                    $searchConditionListModal({ ...searchConditionListModal, isShow: true, selectingId: "" })
                  }
                >
                  保存された絞り込み条件を使う
                </Button>
                <Button
                  variant="outline-primary"
                  className="mx-2"
                  onClick={() => $searchConditionModal({ ...searchConditionModal, label: "", isShow: true })}
                >
                  現在の絞り込み条件を保存する
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="2">
          <div className="--bold pt-md-3">指定日</div>
        </Col>
        <Col md="10">
          {state.dateError && (
            <Row className="mb-2 --align-items-center">
              <Col>
                <Alert variant="warning">{state.dateError}</Alert>
              </Col>
            </Row>
          )}
          <Row className="--align-items-center">
            <Col>
              <SnapshotTimeSelector
                selectedPointDate={state.selectedPointDate}
                onChange={({ selectedPointDate }: onChangeOption) => {
                  if (!selectedPointDate) return;
                  $state({
                    ...state,
                    selectedPointDate,
                  });
                }}
              />
            </Col>
          </Row>
          <Row className="my-1 mt-2">
            <Col>
              {state.retreiveOptions.map((option, i) => {
                return (
                  <Form.Check
                    type="radio"
                    label={`${i + 1}. ${option.label}`}
                    key={`option_m_${option.id}`}
                    id={`option_m_${option.id}`}
                    checked={option.checked}
                    value={option.id}
                    onChange={() => {
                      $state({
                        ...state,
                        retreiveOptions: state.retreiveOptions.map((o) => {
                          return { ...o, checked: o.id === option.id };
                        }),
                      });
                    }}
                  />
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      {payrollSetting?.payroll_system === "obic7" && (
        <Row className="mt-4">
          <Col md="2">
            <div className="--bold pt-md-3">出力形式</div>
          </Col>
          <Col md="10">
            {state.outputFormat.map((option, i) => {
              return (
                <Form.Check
                  type="radio"
                  label={option.label}
                  key={`outputFormat_${option.id}`}
                  id={`outputFormat_${option.id}`}
                  checked={option.checked}
                  value={option.id}
                  onChange={() => {
                    $state({
                      ...state,
                      outputFormat: state.outputFormat.map((o) => ({ ...o, checked: o.id === option.id })),
                    });
                  }}
                />
              );
            })}
          </Col>
        </Row>
      )}
      <Row className="mt-4">
        <Col md="2">
          <div className="--bold pt-md-3">対象テーブル</div>
        </Col>
        <Col md="10">
          <FieldListSelector
            fields={state.fieldViewSectors}
            onFieldSelected={selectField}
            useSubFieldsTrigger={false}
          />
        </Col>
      </Row>
      {state.order.length > 0 ? (
        <Row className="mt-4 --align-items-center">
          <Col md="2">
            <div className="--bold">出力順序</div>
          </Col>
          <Col md="10">
            <OrderEditor
              data={state.order}
              onChange={(order) => {
                $state({
                  ...state,
                  order,
                });
              }}
            />
          </Col>
        </Row>
      ) : null}
      <Row className="mt-4 --align-items-center">
        <Col md="2">
          <div className="--bold">在籍区分</div>
        </Col>
        <Col md="10">
          {state.enrollmentTypeOptions.map((option) => {
            return (
              <Form.Check
                type="checkbox"
                label={option.label}
                key={`option_m_${option.id}`}
                id={`option_m_${option.id}`}
                checked={option.checked}
                value={option.id}
                onChange={() => {
                  $state({
                    ...state,
                    enrollmentTypeOptions: state.enrollmentTypeOptions.map((o) => {
                      if (o.id !== option.id) return o;
                      return { ...o, checked: !o.checked };
                    }),
                  });
                }}
              />
            );
          })}
        </Col>
      </Row>
      <Row className="mt-4 --align-items-center">
        <Col md="2">
          <div className="--bold">出力オプション</div>
        </Col>
        <Col md="10">
          {state.dataOptions.map((option) => {
            return (
              <Form.Check
                type="checkbox"
                label={option.label}
                key={`option_multiple_${option.id}`}
                id={`option_multiple_${option.id}`}
                checked={option.checked}
                value={option.id}
                onChange={() => {
                  $state({
                    ...state,
                    dataOptions: state.dataOptions.map((o) => {
                      if (o.id !== option.id) return o;
                      return { ...o, checked: !o.checked };
                    }),
                  });
                }}
              />
            );
          })}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="2">
          <div className="--bold pt-md-3">日付の形式</div>
        </Col>
        <Col md="10">
          {state.dateOptions.map((option) => {
            return (
              <Form.Check
                type="radio"
                label={option.label}
                key={`option_multiple_${option.id}`}
                id={`option_multiple_${option.id}`}
                checked={option.checked}
                value={option.id}
                onChange={() => {
                  $state({
                    ...state,
                    dateOptions: state.dateOptions.map((o) => {
                      return { ...o, checked: o.id === option.id };
                    }),
                  });
                }}
              />
            );
          })}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Button variant="primary" disabled={state.order.length === 0 || !!state.dateError} onClick={download}>
            ダウンロード
          </Button>
        </Col>
      </Row>
      <ModalDialog
        show={isModalActive}
        onConfirm={() => $isModalActive(false)}
        message="ファイルの作成を開始しました。ダウンロード可能になったら通知されます。"
        type="alert"
      />
      <Modal show={searchConditionModal.isShow} onHide={hideSearchConditionModal} size="lg" centered>
        <Modal.Header>
          <Modal.Title>絞り込み条件の保存</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-1">
            <Col md={4}>
              <Form.Label className="--required-label --bold">ラベル</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Control
                type="text"
                value={searchConditionModal.label}
                onChange={(e) => {
                  $searchConditionModal({ ...searchConditionModal, label: e.target.value });
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hideSearchConditionModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button
            onClick={() => $searchConditionModal({ ...searchConditionModal, isConfirming: true })}
            variant="primary"
            disabled={searchConditionModal.label === ""}
          >
            保存
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={searchConditionModal.isConfirming}
        onConfirm={saveSearchCondition}
        onCancel={() => $searchConditionModal({ ...searchConditionModal, isConfirming: false })}
        message="絞り込み条件を保存します。よろしいですか？"
        type="confirm"
      />
      <Modal show={searchConditionListModal.isShow} onHide={hideSearchConditionListModal} size="lg" centered>
        <Modal.Header>
          <Modal.Title>保存された絞り込み条件を使う</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-1">
            <Col md={12}>
              <div className="--overflow-auto">
                <ListGroup as="ol">
                  {searchConditions.map((condition, i) => {
                    return (
                      <ListGroup.Item key={i}>
                        {condition.label}
                        <Button
                          variant="danger"
                          className="float-end"
                          size="sm"
                          onClick={() => {
                            $searchConditionListModal({
                              ...searchConditionListModal,
                              selectingId: condition.id,
                              isConfirming: true,
                            });
                          }}
                        >
                          削除
                        </Button>
                        <Button
                          variant="primary"
                          className="mx-2 float-end"
                          size="sm"
                          onClick={() => applyPreset(condition)}
                        >
                          適用
                        </Button>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hideSearchConditionListModal} variant="outline-secondary">
            キャンセル
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={searchConditionListModal.isConfirming}
        onConfirm={deleteCondition}
        onCancel={() => $searchConditionListModal({ ...searchConditionListModal, isConfirming: false })}
        message="絞り込み条件を削除します。よろしいですか？"
        type="destructiveConfirm"
        confirmButtonName="削除"
      />
    </Container>
  );
}

export default ProfileList;
