import { useEffect, useState, Fragment, useMemo } from "react";
import { Tab, Container, Row, Col, Button, Form, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getOperations,
  selectOperationState,
  postOperation,
  putOperation,
  deleteOperation,
} from "../../features/dashboard/operationSlice";
import { useAppSelector, useAppDispatch } from "../../app/store";
import OperationCard from "../../component/OperationCard";
import OperationEditorCard from "../../component/OperationEditorCard";
import { Operation } from "./operationValues";
import ModalDialog from "../../component/ModalDialog";

const empty = { id: "", key: "", title: "", description: "", flows: [] };
const newFlow = {
  id: "",
  key: "new",
  title: "新規業務シナリオ",
  description: "",
  flows: [
    {
      title: "通常パターン",
      tasks: [
        {
          title: "最初の作業",
          links: [],
        },
      ],
    },
  ],
};

function OperationsEditor() {
  const dispatch = useAppDispatch();
  const { operations } = useAppSelector(selectOperationState);
  const [currentOperationKey, $currentOperationKey] = useState("");
  const [activeTabKey, $activeTabKey] = useState("editor");
  const [nextValues, $nextValues] = useState(empty as Operation);
  const [isUnsavedChanges, $isUnsavedChanges] = useState(false);
  const [isValid, $isValid] = useState(true);
  const [activeModal, $activeModal] = useState("");
  const [savedType, $savedType] = useState("" as "save" | "delete" | "");

  useEffect(() => {
    dispatch(getOperations());
  }, []);

  useEffect(() => {
    const selectedOperation = operations.find((_) => _.key === currentOperationKey) ?? null;
    $nextValues(empty);
    if (currentOperationKey === "new") {
      // TODO: 当面は決まったパターンしか編集させないので使用しない
      $isValid(false);
      setTimeout(() => {
        $nextValues({
          ...newFlow,
          key: "new",
        });
      }, 300);
    } else if (selectedOperation) {
      setTimeout(() => {
        $nextValues({ ...selectedOperation });
      }, 300);
    }
  }, [currentOperationKey]);

  useEffect(() => {
    if (savedType === "") return;
    dispatch(getOperations());
    const selectedOperation = operations.find((_) => _.key === currentOperationKey) ?? null;
    if (selectedOperation) {
      if (savedType === "save" && selectedOperation.id) {
        $nextValues({ ...selectedOperation });
        $savedType("");
      } else if (savedType === "delete" && !selectedOperation.id) {
        $nextValues({ ...selectedOperation });
        $savedType("");
      }
    }
  }, [savedType]);

  const saveOperation = async () => {
    // 念のためキーの重複チェック
    if (operations.find((_) => _.key === nextValues.key && _.id !== nextValues.id)) {
      alert("キーが重複しています。");
      return;
    }

    if (nextValues.id) {
      await dispatch(
        putOperation({
          id: nextValues.id,
          key: nextValues.key,
          description: nextValues.description ? nextValues.description : "",
          title: nextValues.title,
          flows: nextValues.flows,
        })
      ).then(() => {
        $savedType("save");
        $isUnsavedChanges(false);
      });
    } else {
      dispatch(
        await postOperation({
          key: nextValues.key,
          description: nextValues.description ? nextValues.description : "",
          title: nextValues.title,
          flows: nextValues.flows,
        })
      ).then(() => {
        $savedType("save");
        $isUnsavedChanges(false);
      });
    }
  };

  const delOperation = async () => {
    if (nextValues.id && nextValues.id !== "") {
      await dispatch(deleteOperation({ id: nextValues.id })).then(() => {
        $savedType("delete");
        $isUnsavedChanges(false);
      });
    }
  };

  const operationOptions = useMemo(() => {
    return operations;
    // return [
    //   ...operations,
    //   {
    //     ...newFlow,
    //   },
    // ];
  }, [operations]);

  return (
    <Container>
      <Row className="mt-4 --align-items-center">
        <Col md={6}>業務シナリオ</Col>
        <Col md={6}>
          <Form.Select
            value={currentOperationKey}
            disabled={isUnsavedChanges}
            onChange={(e) => {
              $currentOperationKey(e.target.value);
            }}
          >
            <option value={""}>---</option>
            {operationOptions.map((operation, i) => {
              return (
                <option key={`operation_${i}`} value={operation.key}>
                  {operation.title}
                </option>
              );
            })}
          </Form.Select>
        </Col>
      </Row>
      {currentOperationKey !== "" && (
        <div className="mt-4">
          <Row>
            <Col>
              <Tab.Container
                onSelect={(key: string | null) => {
                  key && $activeTabKey(key);
                }}
                activeKey={activeTabKey}
              >
                <Nav variant="tabs">
                  <Nav.Item key="editor">
                    <Nav.Link eventKey="editor">編集</Nav.Link>
                  </Nav.Item>
                  <Nav.Item key="preview">
                    <Nav.Link eventKey="preview" disabled={!isValid}>
                      プレビュー
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Row className="pt-4">
                    <Col>
                      <div className="mb-2">
                        <Button
                          variant="outline-secondary"
                          disabled={!isUnsavedChanges && nextValues.key !== "new"}
                          onClick={() => {
                            const selectedOperation = operations.find((_) => _.key === currentOperationKey) ?? null;
                            if (selectedOperation) {
                              $nextValues({ ...selectedOperation });
                            } else if (currentOperationKey === "new") {
                              $nextValues({ ...empty });
                              $currentOperationKey("");
                            }
                            $isUnsavedChanges(false);
                            $isValid(true);
                          }}
                        >
                          変更内容を破棄する
                        </Button>
                        <Button
                          variant="primary"
                          className="mx-1 --print-none"
                          disabled={!isValid}
                          onClick={() => {
                            $activeModal("before_save");
                          }}
                        >
                          保存する
                        </Button>
                        <ModalDialog
                          show={activeModal === "before_save"}
                          onConfirm={() => {
                            $activeModal("");
                            saveOperation();
                          }}
                          onCancel={() => {
                            $activeModal("");
                          }}
                          message="保存します。よろしいですか？"
                        />
                        {nextValues.id && (
                          <Button
                            variant="danger"
                            className="mx-1 --print-none float-end"
                            onClick={() => {
                              $activeModal("before_deleting");
                            }}
                          >
                            デフォルト値に戻す
                          </Button>
                        )}
                        <ModalDialog
                          show={activeModal === "before_deleting"}
                          onConfirm={() => {
                            $activeModal("");
                            delOperation();
                          }}
                          onCancel={() => {
                            $activeModal("");
                          }}
                          message="デフォルト値に戻します。よろしいですか？"
                          type="destructiveConfirm"
                        />
                      </div>
                      {activeTabKey === "preview" ? (
                        <OperationCard data={nextValues} />
                      ) : (
                        <Fragment>
                          <OperationEditorCard
                            data={nextValues}
                            onChange={(nextValues, _isValid) => {
                              $nextValues({ ...nextValues });
                              $isUnsavedChanges(true);
                              $isValid(_isValid);
                            }}
                          />
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
}

export default OperationsEditor;
