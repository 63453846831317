import { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button, Form, Accordion, Badge } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  getDefaultOptions,
  getRegularColumns,
  getExternalOptions,
  selectClientState,
  commitExternalOptions,
  selectActiveSectors,
} from "./clientSlice";
import ModalDialog from "../../component/ModalDialog";
import { useParams } from "react-router-dom";
import { RegularColumn, Sector, ExternalOption } from "./clientValues";

function ExternalOptionEdit() {
  const { target } = useParams();
  const { sectorRegularColumns, defaultOptions, externalOptionId } = useAppSelector(selectClientState);
  const activeSectors = useAppSelector(selectActiveSectors);
  const dispatch = useAppDispatch();
  const [state, $state] = useState({
    activeModal: "",
    inputtedColumns: {} as { [columnName: string]: { [key: string]: string } },
  });

  const sectorId = useMemo(() => {
    return target ?? "";
  }, [target]);

  useEffect(() => {
    if (sectorId) {
      dispatch(getRegularColumns({ sectorId }));
      dispatch(getDefaultOptions({ sectorId }));
      dispatch(getExternalOptions());
    }
  }, [sectorId]);

  const editableOptionColumns = useMemo(() => {
    return (
      sectorRegularColumns[sectorId]?.filter(({ input_type }) => input_type === "options") ?? ([] as RegularColumn[])
    );
  }, [sectorRegularColumns, sectorId]);

  const closeModal = () => {
    $state({ ...state, activeModal: "", inputtedColumns: {} });
  };

  const matchedSector: Sector | undefined = useMemo(() => {
    const matchedSector = activeSectors.find(({ sector_id }) => sector_id === sectorId);
    return matchedSector;
  }, [sectorId, activeSectors]);

  const editableOptions = useMemo(() => {
    const editableOptions = Object.entries(defaultOptions ?? []).reduce((prev, [columnName, options]) => {
      if (editableOptionColumns.some((column) => column.id === columnName)) {
        prev[columnName] = options;
      }
      return prev;
    }, {} as { [key: string]: ExternalOption[] });
    return editableOptions;
  }, [defaultOptions, editableOptionColumns]);

  const editingOptions = useMemo(() => {
    return Object.entries(editableOptions).reduce((prev, [columnName, options]) => {
      return {
        ...prev,
        [columnName]: options.map((option) => {
          const value = state.inputtedColumns[columnName]?.[option.label] ?? option.value;
          return { ...option, value };
        }),
      };
    }, {} as { [key: string]: ExternalOption[] });
  }, [state.inputtedColumns, editableOptions]);

  return (
    <Container>
      <div className="pt-4">
        <h2 className="Headline--section mb-2">{matchedSector?.logical_name}の選択肢設定</h2>
        <Accordion defaultActiveKey="" className="mb-3">
          {Object.keys(editingOptions ?? []).map((key) => {
            const columnOptions = editingOptions[key];
            const c = editableOptionColumns?.find((e) => e.id === key);
            const hasEmptyValue = columnOptions?.some((c) => c.value === "");
            return (
              <Accordion.Item eventKey={c ? c.label : ""} key={c?.label}>
                <Accordion.Header>
                  {c?.label}
                  {hasEmptyValue && (
                    <Badge pill bg="danger" className="mx-1">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body>
                  {columnOptions?.map((o, i) => {
                    return (
                      <Row key={`${o.label}-${i}`} className="mb-2">
                        <Col>
                          <Form.Label className="--bold">{o.label}</Form.Label>
                          <Form.Control
                            type="text"
                            value={o.value}
                            onChange={(e) => {
                              $state({
                                ...state,
                                inputtedColumns: {
                                  ...state.inputtedColumns,
                                  [c ? c.id : ""]: {
                                    ...state.inputtedColumns[c ? c.id : ""],
                                    [o.label]: e.target.value,
                                  },
                                },
                              });
                            }}
                          />
                          {o.value === "" && (
                            <span className="--text-annotation mt-1 --font-s">入力してください。</span>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
        <Button
          onClick={() => $state({ ...state, activeModal: "commit" })}
          disabled={Object.keys(editingOptions).some((columnName) => {
            const options = editingOptions[columnName];
            return options.some((option) => option.value === "");
          })}
        >
          保存
        </Button>
      </div>
      <ModalDialog
        show={state.activeModal === "commit"}
        message="保存します。よろしいですか？"
        onConfirm={async () => {
          let updatedObject: any = {};
          Object.entries(editableOptions).map(([k, option]) => {
            const columnName = k;
            const result: { [key: string]: string } = {};
            option.forEach((item) => {
              result[item.label] = String(item.value);
            });
            let updateData = {} as { [key: string]: string };
            if (state.inputtedColumns && state.inputtedColumns[columnName]) {
              updateData = state.inputtedColumns[columnName];
            } else {
              updateData = result;
            }
            const mergedObject = {
              ...result, // editableOptions の値を初期値としてマージする
              ...updateData, // state.inputtedColumns の値で上書き
            };
            updatedObject = {
              ...updatedObject,
              [sectorId]: {
                ...updatedObject[sectorId],
                [columnName]: mergedObject,
              },
            };
          });
          if (updatedObject) {
            await dispatch(
              commitExternalOptions({
                options: updatedObject,
              })
            );
            dispatch(getRegularColumns({ sectorId }));
            await dispatch(getDefaultOptions({ sectorId }));
            dispatch(getExternalOptions());
          }
          closeModal();
        }}
        onCancel={() => {
          $state({ ...state, activeModal: "" });
        }}
      />
    </Container>
  );
}

export default ExternalOptionEdit;
