import { useEffect, useState, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { Container, Row, Col, Form, Button, Tabs, Tab, Modal } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getEvaluationAnsweringProject,
  selectEvaluationState,
  stage,
  apply,
  setOpenPane,
  getTodoTemplates,
  setEditTarget,
  getEvaluationProjectGroups,
  getEvaluationProject,
  getEvaluationSystem,
  getEvaluationSystems,
  selectEvaluateeList,
} from "./evaluationSheetSlice";
import { EvaluationTodoTemplate, UnitChangePayload, UnitGroup, UnitGroupTemplate } from "./EvaluationTypes";
import EvUnitGroup from "./UnitGroup";
import { selectLayoutState } from "../layout/layoutSlice";
import Table from "../../component/Table";
import Sidebar_Evaluation from "../../component/Sidebar_Evaluation";
import MemberStaticSelector from "../../features/profile/MemberStaticSelector";
import { useParams } from "react-router-dom";
import { getMembers, selectProfileState } from "../profile/profileSlice";
import { Account } from "../profile/profileValues";

function App() {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const { systems, project, projectGroups, projects, system, patterns, todos } = useAppSelector(selectEvaluationState);
  const evaluateeList = useAppSelector(selectEvaluateeList);
  const { accounts } = useAppSelector(selectProfileState);
  const [activeModal, $activeModal] = useState("");
  const [projectSwitchingModal, $projectSwitchingModal] = useState({
    show: false,
    nextProject: "",
  });
  const [activeTab, $activeTab] = useState("todo");
  const [showTodoModal, $showTodoModal] = useState(false);
  const [targets, $targets] = useState<EvaluationTodoTemplate[]>([]);
  const [targetReviewee, $targetReviewee] = useState(null as Account | null);

  const phaseNameMap = useMemo(() => {
    return (system?.phaseTemplates ?? []).reduce((prev, current) => {
      return {
        ...prev,
        [current.key]: current.label,
      };
    }, {} as { [key: string]: string });
  }, [system?.phaseTemplates]);

  const selectEvaluatee = (id: number) => {
    const reviewee = accounts.find((a) => a.id === id) ?? null;
    $targetReviewee(reviewee);
    $targets(
      todos.filter((t) => {
        return t.evaluatee.accountId === id;
      })
    );
  };

  const closeEditingModal = () => {
    $targets([]);
    $targetReviewee(null);
    $showTodoModal(false);
  };

  useEffect(() => {
    dispatch(getEvaluationProjectGroups());
    dispatch(getEvaluationSystems());
    dispatch(getMembers());
  }, []);

  const _projectId = useMemo(() => {
    return projectId ?? projects[0]?.id;
  }, [projectId, projects]);

  const currentSystem = useMemo(() => {
    if (systems.length > 0 && project) {
      return systems.find((s) => s.id === project.systemId);
    }
  }, [systems, project]);

  useEffect(() => {
    if (currentSystem) {
      dispatch(
        setEditTarget({
          systemId: currentSystem.id,
        })
      );
    }
  }, [currentSystem]);

  const currentProjectGroup = useMemo(() => {
    if (projectGroups.length > 0 && project) {
      return projectGroups.find((s) => s.id === project.groupId);
    }
  }, [projectGroups, project]);

  useEffect(() => {
    if (_projectId) {
      dispatch(
        getEvaluationProject({
          id: _projectId,
        })
      ).then((res: any) => {
        console.log(res);
      });
      dispatch(
        getTodoTemplates({
          projectId: _projectId,
        })
      );
      dispatch(setEditTarget({ projectId: _projectId }));
    }
  }, [_projectId]);

  useEffect(() => {
    if (project) {
      dispatch(
        getEvaluationSystem({
          id: project.systemId,
        })
      );
    }
  }, [project]);

  if (!project) return <div />;
  return (
    <div className="Ev-container --editor">
      <Sidebar_Evaluation current="project" />
      <main className="Ev-edit-content bg-white">
        <Row className="mb-2 --align-items-center">
          <Col md={3} className="--font-s">
            プロジェクト
          </Col>
          <Col md={5}>
            <Form.Select
              value={project?.id}
              onChange={(e) => {
                if (!window.confirm("プロジェクトを切り替えますか？")) return;
                window.location.href = `/_/evaluation/project/${e.target.value}/`;
              }}
            >
              {projects.map((p, i) => {
                return (
                  <option key={`option_${i}`} value={p.id}>
                    {p.label}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
          <Col md={4}>
            <Button variant="outline-primary">編集</Button>
            <Button variant="outline-primary" className="mx-1">
              新規作成
            </Button>
          </Col>
        </Row>
        <Tabs
          activeKey={activeTab}
          onSelect={(key) => {
            if (!key) return;
            $activeTab(key);
          }}
        >
          <Tab eventKey="info" title="基本情報">
            <Container className="py-4">
              <Row>
                <Col md={3} className="--bold">
                  評価タイプ
                </Col>
                <Col md={9}>{currentSystem?.label}</Col>
              </Row>
              <Row className="mt-2">
                <Col md={3} className="--bold">
                  実施期間
                </Col>
                <Col md={9}>{currentProjectGroup?.label}</Col>
              </Row>
              <Row className="mt-2">
                <Col md={3} className="--bold">
                  ステータス
                </Col>
                <Col md={9}>{project?.status}</Col>
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="flow" title="被評価者" className="py-4">
            <Row>
              <Col>
                {system?.phaseTemplates.length && (
                  <Table
                    col={[
                      {
                        name: "被評価者",
                      },
                      {
                        name: "パターン",
                      },
                      ...system.phaseTemplates.map((phase) => {
                        return {
                          name: phase.label,
                        };
                      }),
                    ]}
                    row={evaluateeList.map((d) => {
                      const pattern = patterns.find((p) => p.id === d.patternId)?.title ?? "";
                      const evaluatee = accounts.find((a) => a.id === d.evaluatee.accountId);
                      return {
                        data: [
                          evaluatee?.name ?? d.evaluatee.displayName ?? "",
                          pattern,
                          ...system.phaseTemplates.map((phase) => {
                            const thisPhaseTodos = d.todos.filter((_t) => _t.phase.key === phase.key);
                            return (
                              <div>
                                {thisPhaseTodos.length > 0 ? (
                                  thisPhaseTodos.map((_todo, _ti) => {
                                    const evaluator = accounts.find((a) => a.id === _todo.evaluator.accountId);
                                    const displayName = _todo.evaluator.displayName
                                      ? `${evaluator?.name}(${_todo.evaluator.displayName})`
                                      : evaluator?.name;
                                    return <div key={`todo${_todo.evaluatee.accountId}_${_ti}`}>{displayName}</div>;
                                  })
                                ) : (
                                  <div>--</div>
                                )}
                              </div>
                            );
                          }),
                        ],
                        action: {
                          handler: () => {
                            selectEvaluatee(d.evaluatee.accountId);
                            $showTodoModal(true);
                          },
                        },
                      };
                    })}
                  />
                )}
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="todo" title="TODO" className="py-4">
            <Row className="mb-2">
              <Col>
                <Button variant="outline-primary" className="mx-1">
                  ダウンロード
                </Button>
                <Button variant="outline-primary" className="mx-1">
                  アップロード
                </Button>
              </Col>
            </Row>
            <Table
              col={[
                {
                  name: "フェーズ",
                },
                {
                  name: "ステータス",
                  width: 110,
                },
                {
                  name: "被評価者",
                },
                {
                  name: "評価者",
                },
              ]}
              row={todos.map((t) => {
                const phaseLabel = system?.phaseTemplates.find((pt) => pt.key === t.phase.key)?.label ?? t.phase.key;
                const evaluator = accounts.find((a) => a.id === t.evaluator.accountId);
                const displayName =
                  (t.evaluator.displayName ? `${evaluator?.name}(${t.evaluator.displayName})` : evaluator?.name) || "";
                return {
                  data: [phaseLabel, t.status, t.evaluatee.displayName, displayName],
                  action: {
                    handler: () => {
                      selectEvaluatee(t.evaluatee.accountId);
                      $showTodoModal(true);
                    },
                  },
                };
              })}
            />
          </Tab>
        </Tabs>

        <Modal show={showTodoModal} centered size="xl" onHide={closeEditingModal}>
          <Modal.Header>評価関係の編集</Modal.Header>
          <Modal.Body>
            <Row className="mb-2">
              <Col>
                <div className="px-2">
                  <span className="--bold">被評価者</span> : {targetReviewee?.name}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2 bg-light">
                  <span className="--bold">評価者</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2 bg-light">
                  {system?.phaseTemplates.map((phase, pi) => {
                    const thisPhaseTodos = todos.filter(
                      (t) => t.phase.key === phase.key && t.evaluatee.accountId === targetReviewee?.id
                    );
                    return (
                      <Row key={`phase${pi}`} className="mb-2 --align-items-center">
                        <Col md={3} className="--bold">
                          {phase.label}
                        </Col>
                        <Col md={9}>
                          {thisPhaseTodos.length > 0 ? (
                            thisPhaseTodos.map((t, ti) => {
                              return (
                                <div key={`phase_${pi}__todo_${ti}`} className="mb-2">
                                  <MemberStaticSelector
                                    className="mb-1"
                                    accounts={accounts}
                                    selectedAccountId={t.evaluator.accountId}
                                    onSelectedAccountChange={(id) => {
                                      $targets(
                                        targets.map((_t, _ti) => {
                                          if (ti === _ti) {
                                            return {
                                              ..._t,
                                              evaluator: {
                                                ...t.evaluator,
                                                accountId: id,
                                              },
                                            };
                                          } else {
                                            return _t;
                                          }
                                        })
                                      );
                                    }}
                                  />
                                  <div className="--flex --align-items-center">
                                    <div className="mx-2 --shrink-0">表記</div>
                                    <Form.Control
                                      type="text"
                                      value={t.evaluator.displayName}
                                      onChange={(e) => {
                                        $targets(
                                          targets.map((_t, _ti) => {
                                            if (ti === _ti) {
                                              return {
                                                ..._t,
                                                evaluator: {
                                                  ..._t.evaluator,
                                                  displayName: e.target.value,
                                                },
                                              };
                                            } else {
                                              return _t;
                                            }
                                          })
                                        );
                                      }}
                                    ></Form.Control>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div>未設定</div>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex">
              <Button onClick={closeEditingModal} variant="outline-secondary" className="mx-1">
                キャンセル
              </Button>
              <Button variant="primary" onClick={closeEditingModal}>
                保存する
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </main>
    </div>
  );
}

export default App;
