import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import Table from "../../component/Table";
import { getEvaluationProjectGroups, selectEvaluationState } from "./evaluationSheetSlice";
import { Row, Col, Alert, Container, Button } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useAppDispatch();
  const { projectGroups } = useAppSelector(selectEvaluationState);
  useEffect(() => {
    dispatch(getEvaluationProjectGroups());
  }, []);
  return (
    <Container>
      <Row>
        <Col>
          <Button variant="outline-primary">新規評価年度</Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          {projectGroups.length > 0 ? (
            <Table
              col={[
                {
                  name: "評価年度名",
                  colName: "title",
                },
                {
                  name: "期間",
                },
              ]}
              row={projectGroups.map((a) => {
                return {
                  key: a.id,
                  data: [a.label, a.startDate + " 〜 " + a.endDate],
                };
              })}
              usePagenation={true}
            />
          ) : (
            <Alert variant={"info"}>該当するプロジェクト情報がありません。</Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
