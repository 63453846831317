import { useState, useMemo, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../app/store";
import { Tab, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ApplicationConfig from "../apply/ApplicationConfig";
import MasterFieldList from "../profile/MasterFieldList";
import MasterReportList from "../profile/MasterReportList";
import ApplicationStatus from "../apply/ApplicationStatus";
import Sidebar from "../../component/Sidebar";
import "react-calendar/dist/Calendar.css";
import RemindSettingList from "../todo/RemindSettingList";
import RemindSettingEdit from "../todo/RemindSettingEdit";
import ColumnRename from "../client/ColumnRename";
import ExternalColumnRename from "../client/ExternalColumnRename";
import ExternalFieldList from "../client/ExternalFieldList";
import OperationsEditor from "../dashboard/OperationsEditor";
import MyNumberConfig from "../myNumber/MyNumberConfig";
import { selectUserState, selectUserRootRoles, PolicyMap } from "../login/userSlice";
import OptionEdit from "../client/OptionEdit";
import ExternalOptionEdit from "../client/ExternalOptionEdit";
import PayrollSettingEdit from "../client/PayrollSettingEdit";
import ReportOrderEdit from "../client/ReportOrderEdit";

type TAB = {
  label: string;
  eventKey: string;
  isAvailable: (policies: PolicyMap, roles: string[]) => boolean;
};

const TABS: TAB[] = [
  {
    label: "管理項目",
    eventKey: "fields",
    isAvailable: (policies, _) => {
      return (policies.template_manager ?? []).some((_) => _ === "POST");
    },
  },
  {
    label: "システム帳票",
    eventKey: "reports",
    isAvailable: (policies, _) => {
      return (policies.template_manager ?? []).some((_) => _ === "POST");
    },
  },
  {
    label: "申請書",
    eventKey: "application",
    isAvailable: (policies, _) => {
      return (policies.template_manager ?? []).some((_) => _ === "POST");
    },
  },
  {
    label: "ToDo設定",
    eventKey: "remind_setting",
    isAvailable: (policies, _) => {
      return (policies.template_manager ?? []).some((_) => _ === "POST");
    },
  },
  {
    label: "業務シナリオ",
    eventKey: "operations",
    isAvailable: (policies, _) => {
      return (policies.template_manager ?? []).some((_) => _ === "POST");
    },
  },
  {
    label: "マイナンバー",
    eventKey: "my_number",
    isAvailable: (policies, _) => {
      return (policies.my_number_manager ?? []).some((_) => _ === "POST");
    },
  },
  {
    label: "外部システム連携",
    eventKey: "external",
    isAvailable: (policies, _) => {
      return (policies.template_manager ?? []).some((_) => _ === "POST");
    },
  },
];

const getRemindSettingPageType = (pathname: string) => {
  if (pathname.indexOf("/_/masters/remind_setting/edit") === 0) return "edit";
  else if (pathname.indexOf("/_/masters/remind_setting/create") === 0) return "create";
  else if (pathname.indexOf("/_/masters/remind_setting") === 0) return "list";
  else return "";
};
const getFieldsPageType = (pathname: string) => {
  if (pathname.indexOf("/_/masters/fields/edit") === 0) return "edit";
  else if (pathname.indexOf("/_/masters/fields/options") === 0) return "optionEdit";
  return pathname.indexOf("/_/masters/fields") === 0 ? "list" : "";
};
const getReportsPageType = (pathname: string) => {
  if (pathname.indexOf("/_/masters/reports/order") === 0) return "order";
  return pathname.indexOf("/_/masters/reports") === 0 ? "list" : "";
};
const getExternalPageType = (pathname: string) => {
  if (pathname.indexOf("/_/masters/external/edit") === 0) return "edit";
  else if (pathname.indexOf("/_/masters/external/options") === 0) return "optionEdit";
  else if (pathname.indexOf("/_/masters/payroll_setting/edit") === 0) return "settingEdit";
  return pathname.indexOf("/_/masters/external") === 0 ? "list" : "";
};

function MasterTop() {
  const location = useLocation();
  const { mode, target } = useParams();
  const { policies } = useAppSelector(selectUserState);
  const roles = useAppSelector(selectUserRootRoles);
  const tabPanes = useMemo(() => {
    const tabPanes = TABS.filter((tab) => tab.isAvailable(policies, roles));
    return tabPanes;
  }, [roles, policies]);
  const [state, $state] = useState({
    remindSettingPageType: getRemindSettingPageType(location.pathname),
    applicationType: target,
    fieldsPageType: getFieldsPageType(location.pathname),
    reportsPageType: getReportsPageType(location.pathname),
    externalPageType: getExternalPageType(location.pathname),
    activeTabKey: (() => {
      if (getRemindSettingPageType(location.pathname) !== "") return "remind_setting";
      if (getFieldsPageType(location.pathname) !== "") return "fields";
      if (getReportsPageType(location.pathname) !== "") return "reports";
      if (getExternalPageType(location.pathname) !== "") return "external";
      switch (mode) {
        case "application":
        case "remind_setting":
        case "operations":
        case "my_number":
          return mode;
        default:
          return "fields";
      }
    })(),
  });

  useEffect(() => {
    $state({
      ...state,
      remindSettingPageType: getRemindSettingPageType(location.pathname),
      fieldsPageType: getFieldsPageType(location.pathname),
      reportsPageType: getReportsPageType(location.pathname),
      externalPageType: getExternalPageType(location.pathname),
      applicationType: target,
    });
  }, [location, target]);

  const tabContent = useMemo(() => {
    if (state.activeTabKey === "remind_setting") {
      if (["edit", "create"].includes(state.remindSettingPageType)) return <RemindSettingEdit />;
      else return <RemindSettingList />;
    } else if (state.activeTabKey === "application") {
      if (state.applicationType) return <ApplicationConfig />;
      return <ApplicationStatus />;
    } else if (state.activeTabKey === "operations") {
      return <OperationsEditor />;
    } else if (state.activeTabKey === "my_number") {
      return <MyNumberConfig />;
    } else if (state.activeTabKey === "reports") {
      if (state.reportsPageType === "order") return <ReportOrderEdit />;
      else return <MasterReportList />;
    } else if (state.activeTabKey === "external") {
      if (state.externalPageType === "edit") {
        return <ExternalColumnRename />;
      } else if (state.externalPageType === "optionEdit") {
        return <ExternalOptionEdit />;
      } else if (state.externalPageType === "settingEdit") {
        return <PayrollSettingEdit />;
      } else {
        return <ExternalFieldList />;
      }
    } else {
      if (state.fieldsPageType === "edit") {
        return <ColumnRename />;
      } else if (state.fieldsPageType === "optionEdit") {
        return <OptionEdit />;
      } else {
        return <MasterFieldList />;
      }
    }
  }, [state]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"masters"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">システム設定</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Tab.Container
            onSelect={(key: string | null) => {
              let next = { ...state };
              if (key) {
                next = { ...next, activeTabKey: key };
                if (key !== "data") {
                  window.history.replaceState({}, "", `/_/masters/${key}`);
                  if (key === "remind_setting") {
                    next = { ...next, remindSettingPageType: "list" };
                  } else if (key === "application") {
                    next = { ...next, applicationType: undefined };
                  } else if (key === "fields") {
                    next = { ...next, fieldsPageType: "list" };
                  } else if (key === "reports") {
                    next = { ...next, reportsPageType: "list" };
                  } else if (key === "external") {
                    next = { ...next, externalPageType: "list" };
                  }
                } else {
                  window.history.replaceState({}, "", `/_/masters/`);
                }
              }
              $state(next);
            }}
            activeKey={state.activeTabKey}
          >
            <Nav variant="tabs">
              {tabPanes.map((t) => (
                <Nav.Item key={t.eventKey}>
                  <Nav.Link eventKey={t.eventKey}>{t.label}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <Tab.Content>{tabContent}</Tab.Content>
          </Tab.Container>
        </main>
      </div>
    </div>
  );
}

export default MasterTop;
