import Execute from "./Execute";
import {
  EvaluationLayout,
  EvaluationLayoutUsage,
  EvaluationPattern,
  EvaluationSystem,
  UnitGroupTemplate,
} from "../EvaluationTypes";
import { EvaluationHelperContext, UnitGroupSeed, UnitLayoutRow, UnitLayoutRowTemplate } from "../EvaluationTypes";
import { setUnitGroupTemplateSize } from "../evaluationValues";
import TextStoriesModule from "./TextStoriesModule";
import AnswerRowListModule from "./AnswerRowListModule";
import AnswerHeaderRowModule from "./AnswerHeaderRowModule";
import StaticUnitModule from "./StaticUnitModule";
import ComputedUnitModule from "./ComputedUnitModule";

const emptyHelperContext: EvaluationHelperContext = {
  commonSelectOptions: {},
  behaviorMapTemplate: {},
  behaviorDefinition: {},
  phaseTemplates: [],
  computingRuleSet: {},
  cssTraits: {},
};

export const getLayoutRows = ({
  pattern,
  layoutUsage,
  system,
  useSeedTemplateDefault = true,
}: {
  pattern?: EvaluationPattern;
  layoutUsage?: EvaluationLayoutUsage;
  system: EvaluationSystem;
  useSeedTemplateDefault?: boolean;
}): {
  unitLayoutRows: UnitLayoutRow[];
  context: EvaluationHelperContext;
} => {
  if (!pattern || !layoutUsage) {
    return {
      unitLayoutRows: [],
      context: { ...emptyHelperContext },
    };
  }
  const rows = useSeedTemplateDefault
    ? system.seedTemplates.map((seed) => {
        return {
          id: seed.id,
          seeds: [seed],
          preference: ["mb-4"],
        } as UnitLayoutRowTemplate;
      })
    : layoutUsage.rows.map((row) => {
        return {
          id: row.id,
          seeds: row.seeds
            .map((seed) => {
              const seedTemplate = system.seedTemplates.find((st) => st.id === seed.templateId);
              if (!seedTemplate) return null;
              return {
                id: seed.id,
                name: seedTemplate.name,
                templateId: seed.templateId,
                procedure: seedTemplate.procedure.map((step) => {
                  return { ...step, id: `${seed.id}_${step.id}` };
                }),
                preference: seed.preference,
                omittingPatternIds: seed.omittingPatternIds,
              } as UnitGroupSeed;
            })
            .filter((_) => _),
          preference: row.preference,
        } as UnitLayoutRowTemplate;
      });
  const { unitGroupTemplates, context } = rows
    .map((row) => row.seeds)
    .flat()
    .filter((seed) => {
      return !seed.omittingPatternIds.includes(pattern.id);
    })
    .reduce(
      (prev, current) => {
        const result = Execute(
          {
            context: prev.context,
            unitGroupId: `${current.id}`,
            units: [],
            subRoutineId: "", // 各 procedure.step を解釈するとき上書き
          },
          {
            procedure: current.procedure,
            pattern,
            useSeedTemplateDefault,
          }
        );

        return {
          unitGroupTemplates: [
            ...prev.unitGroupTemplates,
            {
              id: current.id,
              name: current.name,
              units: [...result.units],
              templateId: current.templateId,
              procedure: current.procedure,
              preference: current.preference,
              useSeedTemplateDefault: !!useSeedTemplateDefault,
            } as UnitGroupTemplate,
          ],
          context: result.context,
        };
      },
      {
        unitGroupTemplates: [] as UnitGroupTemplate[],
        context: {
          commonSelectOptions: { ...layoutUsage.commonSelectOptions },
          behaviorMapTemplate: { ...layoutUsage.behaviorMapTemplate },
          behaviorDefinition: { ...layoutUsage.behaviorDefinition },
          phaseTemplates: [...system.phaseTemplates],
          computingRuleSet: { ...layoutUsage.computingRuleSet },
          cssTraits: (() => {
            const cssTraits = { ...system.cssTraits };
            Object.keys(layoutUsage.cssTraits).forEach((key) => {
              cssTraits[key] = {
                label: layoutUsage.cssTraits[key].label || system.cssTraits[key]?.label || "",
                styles: { ...(cssTraits[key]?.styles || {}), ...layoutUsage.cssTraits[key].styles },
              };
            });
            return cssTraits;
          })(),
        },
      }
    );
  const unitLayoutRows = rows.map((row, i) => {
    return {
      id: row.id,
      unitGroups: row.seeds
        .map((seed) => {
          const unitGroup = unitGroupTemplates.find((ug) => ug.id === seed.id);
          return unitGroup ? setUnitGroupTemplateSize(unitGroup) : null;
        })
        .filter((_) => _) as UnitGroupTemplate[],
      preference: row.preference,
    } as UnitLayoutRow;
  });
  return {
    unitLayoutRows,
    context,
  };
};

export const subRoutineMap = {
  TextStoriesModule,
  AnswerRowListModule,
  AnswerHeaderRowModule,
  StaticUnitModule,
  ComputedUnitModule,
} as {
  [subRoutineId: string]: any;
};

export const subRoutineOptions = Object.keys(subRoutineMap).reduce(
  (prev, current) => {
    return [
      ...prev,
      {
        label: subRoutineMap[current].label,
        value: current,
      },
    ];
  },
  [] as {
    label: string;
    value: string;
  }[]
);
