import { useMemo, MouseEventHandler } from "react";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../app/store";
import "../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Icon from "./Icon";
import { selectUserState, selectUserRootRoles, PolicyMap } from "../features/login/userSlice";
import { selectLayoutState } from "../features/layout/layoutSlice";
import { selectEvaluationState } from "../features/evaluationSheet/evaluationSheetSlice";
import { serviceLabels } from "../features/permission/permissionValues";

type ROW = {
  label: string;
  id: string;
  action?: MouseEventHandler;
  link: string;
  isExternalLink?: boolean;
  icon: {
    type: string;
    width: number;
    height: number;
  };
  isAvailable: (policies: PolicyMap, roles: string[], currentRole: string) => boolean;
  sub?: {
    label: string;
    id: string;
    link: string;
  }[];
};

type Props = {
  current: string;
};

const ROWS: ROW[] = [
  {
    label: "マイダッシュボード",
    id: "dashboard",
    link: "/_/evaluation",
    icon: {
      type: "card-checklist",
      width: 28,
      height: 28,
    },
    isAvailable: () => true,
  },
  {
    label: "管理者ダッシュボード",
    id: "admin_dashboard",
    link: "/_/evaluation/admin_dashboard",
    icon: {
      type: "card-checklist",
      width: 28,
      height: 28,
    },
    isAvailable: () => true,
  },
  {
    label: "制度プロジェクト",
    id: "system_settings",
    link: "/_/evaluation/system_settings",
    icon: {
      type: "arrow-repeat",
      width: 28,
      height: 28,
    },
    sub: [
      {
        label: "ワークフロー",
        id: "workflow",
        link: "/_/evaluation/system_settings/",
      },
      {
        label: "シート・レイアウト",
        id: "layout",
        link: "/_/evaluation/layout/",
      },
      {
        label: "パターン",
        id: "pattern",
        link: "/_/evaluation/layout/",
      },
      {
        label: "スケール",
        id: "scale",
        link: "/_/evaluation/system_settings/",
      },
    ],
    isAvailable: () => true,
  },
  {
    label: "制度プロセス",
    id: "process_settings",
    link: "/_/evaluation/process_settings",
    icon: {
      type: "calendar2-week",
      width: 28,
      height: 28,
    },
    sub: [
      {
        label: "プロセス",
        id: "process",
        link: "/_/evaluation/system_settings/",
      },
      {
        label: "個人シート",
        id: "sheet",
        link: "/_/evaluation/system_settings/",
      },
      {
        label: "評価対象者リスト",
        id: "target_group",
        link: "/_/evaluation/system_settings/",
      },
    ],
    isAvailable: () => true,
  },
  {
    label: "制度",
    id: "common_settings",
    link: "/_/evaluation/common_settings",
    icon: {
      type: "tags",
      width: 28,
      height: 28,
    },
    isAvailable: () => true,
  },
];

function App({ current }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { sidebarStatus } = useAppSelector(selectLayoutState);
  const { policies, user } = useAppSelector(selectUserState);
  const { companyChoices, current_company } = user;
  // const { editTarget } = useAppSelector(selectEvaluationState);
  const roles = useAppSelector(selectUserRootRoles);

  const stories = current.split("/").filter((_) => _);
  // action を設定
  const rows = useMemo(() => {
    return ROWS.filter((row) => row.isAvailable(policies, roles, user.role));
    // return rows
    //   .filter((row) => row.id === "list" || current !== "list")
    //   .map((row) => {
    //     if (row.id === "list") return row;
    //     if (row.id === "system") {
    //       return {
    //         ...row,
    //         link:
    //           row.link +
    //           (editTarget.systemId ? `/${editTarget.systemId}` : "") +
    //           (editTarget.patternId ? `/${editTarget.patternId}` : ""),
    //       };
    //     } else if (row.id === "project") {
    //       return {
    //         ...row,
    //         link:
    //           row.link +
    //           (editTarget.projectId ? `/${editTarget.projectId}` : "") +
    //           (editTarget.patternId ? `/${editTarget.patternId}` : ""),
    //       };
    //     } else {
    //       return row;
    //     }
    //   });
  }, [roles, policies, user.role]);

  const switchableCompanies = useMemo(() => {
    const _switchableCompanies = companyChoices
      .filter((c) => c.company_id !== current_company.id)
      .filter((c) => {
        // TODO 切り替え条件
        return Object.values(c.services).some((services) => services.includes(serviceLabels["profile"].id));
      });
    return _switchableCompanies;
  }, [companyChoices]);

  return (
    <nav
      className={classNames({
        "Ev-edit-pane": sidebarStatus === "open",
        "Ev-edit-pane--folded": sidebarStatus === "folded",
      })}
    >
      <nav className="Sidebar">
        <div className="Sidebar__list --evaluation">
          {switchableCompanies.length > 0 ? (
            <Link to={"/_/switch_company/"} className="Sidebar__company-switcher">
              <div className="--font-s">操作中の企業</div>
              <div>{current_company.company_name}</div>
            </Link>
          ) : null}
          {rows.map((row) => {
            return (
              <div key={row.id}>
                <div
                  onClick={(e) => {
                    if (row.action) {
                      row.action(e);
                      return false;
                    }
                    navigate(row.link);
                  }}
                  data-row={row.id}
                  className={classNames({
                    Sidebar__row: true,
                    "Sidebar__row--with-company-choices": companyChoices.length > 1,
                    "Sidebar__row--current": stories[0] === row.id,
                  })}
                >
                  {row.icon.type && (
                    <div className="Sidebar__row-icon">
                      <Icon type={row.icon.type} width={row.icon.width} height={row.icon.height} />
                    </div>
                  )}
                  {row.label}
                  {row.isExternalLink === true ? (
                    <span className="--px-1">
                      <Icon type="external-tab" width={16} height={16} />
                    </span>
                  ) : null}
                </div>
                {stories[0] === row.id && Array.isArray(row.sub) ? (
                  <div
                    className={classNames({
                      Sidebar__row: true,
                      "Sidebar__row--with-company-choices": companyChoices.length > 1,
                      "Sidebar__row--current": true,
                      "Sidebar__row--sub": true,
                    })}
                  >
                    {row.sub.map(({ label, id, link }) => {
                      return (
                        <Link
                          to={link}
                          key={id}
                          className={classNames({
                            "Sidebar__row-sub-link": true,
                            "Sidebar__row-sub-link--current": stories[1] == id,
                          })}
                        >
                          {label}
                        </Link>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </nav>
    </nav>
  );
}

export default App;
