import { EvaluationPattern } from "../EvaluationTypes";
import { headerList_input1, headerList_input3, headerList_input4, textList } from "./procedureProps";

const attribute_groups = [
  {
    attribute_group_id: "001",
    attribute_group_name: "職種",
    attribute_values: [
      {
        attribute_name: "職種",
        attribute_value: "営業職",
      },
    ],
  },
  {
    attribute_group_id: "002",
    attribute_group_name: "等級",
    attribute_values: [
      {
        attribute_name: "等級",
        attribute_value: "1等級",
      },
      {
        attribute_name: "等級",
        attribute_value: "2等級",
      },
      {
        attribute_name: "等級",
        attribute_value: "3等級",
      },
    ],
  },
];

export const patterns = [
  {
    id: "pattern_tech_a",
    projectId: "hyoka_nakagawa",
    title: "技術職A",
    description: "技術職Aのパターンです。",
    updated_at: Date.now() - 1000 * 60 * 60 * 24 * 3,
    layoutId: "layout_tech",
    assignConditions: [],
    attributes: [
      {
        id: "description",
        value: "技術職Aの評価です。",
        label: "説明",
      },
    ],
    attribute_groups,
    user_attribute_mappings: [
      { id: "team", label: "所属", value: "u_recruitment.recruitment_type" },
      { id: "group", label: "所属グループ", value: "u_recruitment.recruitment_type" },
      { id: "joinedAtaLabel", label: "入社日", value: "u_recruitment.recruitment_type" },
      { id: "name", label: "氏名", value: "u_recruitment.recruitment_type" },
      { id: "agRank", label: "AGランク", value: "u_recruitment.recruitment_type" },
      { id: "assign", label: "担当業務分類", value: "u_recruitment.recruitment_type" },
      { id: "number", label: "従業員番号", value: "u_recruitment.recruitment_type" },
    ],
    seedData: {
      procedure_1: {
        procedure_1_step_a0: {
          textList: ["1", "2", "3", "4", "5", "6", "7", "8"].map((value) => ({ value })),
          headerList: headerList_input1,
        },
        procedure_1_step_a1: {
          textList: ["1", "2", "3", "4", "5", "6", "7", "8"].map((value) => ({ value })),
          headerList: headerList_input1,
          useCategoryTotal: {
            use: false,
          },
          useRowIndex: {
            use: true,
          },
        },
        procedure_1_step_a2: {
          unit: {
            value: "",
            gridRowSpan: 2,
            gridColumnSpan: 1,
            cssBundleKeys: ["header_cell", "flex_centering"],
          },
        },
        procedure_1_step_a3: {
          use: true,
          unit: {
            test: "A",
            conditions: [
              {
                computedKey: "$$SELF_ID",
                value: "$$RESULT",
              },
            ],
          },
        },
        procedure_1_step_a4: {
          use: true,
          unit: {
            test: "B",
            conditions: [
              {
                computedKey: "$$SELF_ID",
                value: "$$RESULT",
              },
              {
                computedKey: "",
                value: "エラーです！",
              },
            ],
          },
        },
      },
      procedure_2: {
        procedure_2_step_a10: {
          unit: { value: "" },
        },
      },
      procedure_3: {
        procedure_3_step_b0: {
          textList,
          useCategoryTotal: {
            use: true,
          },
        },
        procedure_3_step_b1: {
          textList,
          headerList: headerList_input3,
          summaryDepth: {
            value: 1,
          },
        },
        procedure_3_step_b2: {
          useCategoryTotal: {
            use: true,
          },
          useRowIndex: {
            use: false,
          },
          textList,
          headerList: headerList_input3,
          summaryDepth: {
            value: 2,
          },
        },
        procedure_3_step_b3: {
          unit: {
            value: "評価項目",
            gridRowSpan: 2,
            gridColumnSpan: 4,
            classNames: ["row-legend"],
            cssBundleKeys: ["header_cell", "flex_centering"],
          },
        },
      },
      procedure_4: {
        procedure_4_step_c0: {
          unit: {
            value: "",
          },
        },
        procedure_4_step_c1: {
          unit: {
            value: "",
          },
        },
      },
    },
  },
  {
    id: "pattern_tech_b",
    projectId: "hyoka_nakagawa",
    title: "技術職B",
    description: "技術職Bのパターンです。",
    updated_at: Date.now() - 1000 * 60 * 60 * 24 * 10,
    layoutId: "layout_tech",
    assignConditions: [],
    attributes: [
      {
        id: "description",
        value: "技術職Bの評価です。",
        label: "説明",
      },
    ],
    attribute_groups,
    user_attribute_mappings: [
      { id: "team", label: "所属", value: "u_recruitment.recruitment_type" },
      { id: "group", label: "所属グループ", value: "u_recruitment.recruitment_type" },
      { id: "joinedAtaLabel", label: "入社日", value: "u_recruitment.recruitment_type" },
      { id: "name", label: "氏名", value: "u_recruitment.recruitment_type" },
      { id: "agRank", label: "AGランク", value: "u_recruitment.recruitment_type" },
      { id: "assign", label: "担当業務分類", value: "u_recruitment.recruitment_type" },
      { id: "number", label: "従業員番号", value: "u_recruitment.recruitment_type" },
    ],
    seedData: {
      procedure_1: {
        procedure_1_step_a0: {
          textList: ["A", "B", "C", "D", "E"].map((value) => ({ value })),
          headerList: headerList_input1,
        },
        procedure_1_step_a1: {
          textList: ["A", "B", "C", "D", "E"].map((value) => ({ value })),
          headerList: headerList_input1,
          useCategoryTotal: {
            use: true,
          },
          useRowIndex: {
            use: true,
          },
        },
        procedure_1_step_a2: {
          unit: {
            value: "",
          },
        },
        procedure_1_step_a3: {
          use: true,
          unit: {
            test: "A",
            conditions: [
              {
                computedKey: "$$SELF_ID",
                value: "$$RESULT",
              },
            ],
          },
        },
        procedure_1_step_a4: {
          use: true,
          unit: {
            test: "B",
            conditions: [
              {
                computedKey: "$$SELF_ID",
                value: "$$RESULT",
              },
              {
                computedKey: "",
                value: "エラーです！",
              },
            ],
          },
        },
      },
      procedure_3: {
        procedure_3_step_b0: {
          textList,
          useCategoryTotal: {
            use: true,
          },
        },
        procedure_3_step_b1: {
          textList,
          headerList: headerList_input3,
        },
        procedure_3_step_b2: {
          useCategoryTotal: {
            use: true,
          },
          useRowIndex: {
            use: false,
          },
          textList,
          headerList: headerList_input3,
        },
        procedure_3_step_b3: {
          unit: {
            value: "評価項目",
            gridRowSpan: 2,
            gridColumnSpan: 4,
            classNames: ["row-legend"],
            cssBundleKeys: ["header_cell", "flex_centering"],
          },
        },
      },
      procedure_4: {
        procedure_4_step_c0: {
          unit: {
            value: "ATTR$$description",
          },
        },
        procedure_4_step_c1: {
          unit: {
            value: "ATTR$$name",
          },
        },
      },
    },
  },
  {
    id: "pattern_sales_a",
    projectId: "hyoka_nakagawa",
    title: "営業職",
    description: "営業職のパターンです。",
    updated_at: Date.now() - 1000 * 60 * 60 * 24 * 7,
    layoutId: "layout_sales",
    assignConditions: [],
    attributes: [
      {
        id: "description",
        value: "営業職の評価です。",
        label: "説明",
      },
    ],
    attribute_groups,
    user_attribute_mappings: [
      { id: "team", label: "所属", value: "u_recruitment.recruitment_type" },
      { id: "group", label: "所属グループ", value: "u_recruitment.recruitment_type" },
      { id: "joinedAtaLabel", label: "入社日", value: "u_recruitment.recruitment_type" },
      { id: "name", label: "氏名", value: "u_recruitment.recruitment_type" },
      { id: "agRank", label: "AGランク", value: "u_recruitment.recruitment_type" },
      { id: "assign", label: "担当業務分類", value: "u_recruitment.recruitment_type" },
      { id: "number", label: "従業員番号", value: "u_recruitment.recruitment_type" },
    ],
    seedData: {
      procedure_1: {
        procedure_1_step_a0: {
          textList: ["A", "B", "C", "D", "E"].map((value) => ({ value })),
          headerList: headerList_input1,
        },
        procedure_1_step_a1: {
          textList: ["A", "B", "C", "D", "E"].map((value) => ({ value })),
          headerList: headerList_input1,
          useCategoryTotal: {
            use: false,
          },
          useRowIndex: {
            use: true,
          },
        },
        procedure_1_step_a3: {
          use: false,
          unit: {
            test: "A",
            conditions: [
              {
                computedKey: "$$SELF_ID",
                value: "$$RESULT",
              },
            ],
          },
        },
        procedure_1_step_a4: {
          use: false,
          unit: {
            test: "B",
            conditions: [
              {
                computedKey: "$$SELF_ID",
                value: "$$RESULT",
              },
              {
                computedKey: "",
                value: "エラーです！",
              },
            ],
          },
        },
      },
      procedure_3: {
        procedure_3_step_b0: {
          textList,
          useCategoryTotal: {
            use: true,
          },
        },
        procedure_3_step_b1: {
          textList,
          headerList: headerList_input4,
        },
        procedure_3_step_b2: {
          useCategoryTotal: {
            use: true,
          },
          useRowIndex: {
            use: false,
          },
          textList,
          headerList: headerList_input4,
        },
        procedure_3_step_b3: {
          unit: {
            value: "評価項目",
            gridRowSpan: 2,
            gridColumnSpan: 4,
            classNames: ["row-legend"],
            cssBundleKeys: ["header_cell", "flex_centering"],
          },
        },
      },
    },
  },
] as EvaluationPattern[];
