import { UnitGroupSeedTemplate, UnitLayoutRowUsage } from "../EvaluationTypes";

// レイアウト共通のユニットグループ
export const dummySeedTemplates = [
  {
    id: "seed_template_1",
    projectId: "hyoka_project_nakagawa",
    procedure: [
      {
        subRoutineId: "AnswerHeaderRowModule",
        id: "step_a0",
        origin: {
          row: 1,
          column: 2,
        },
        argv: {
          headerList: [
            {
              factors: ["カテゴリ", "回答"],
              axis: "answer",
              relatedPhase: "phase_key_0",
              type: "text",
              isAnswer: true,
            },
            {
              factors: ["カテゴリ", "ウェイト"],
              axis: "weight",
              relatedPhase: "phase_key_0",
              type: "select",
              helper: "stepValues?min=0&max=100&step=5",
            },
          ],
          textList: ["1", "2", "3"].map((value) => ({ value })),
        },
      },
      {
        subRoutineId: "AnswerRowListModule",
        id: "step_a1",
        origin: {
          row: "FOLLOW$$0",
          column: 1,
        },
        argv: {
          useCategoryTotal: {
            use: false,
          },
          useRowIndex: {
            use: true,
          },
          headerList: [
            {
              factors: ["回答2"],
              axis: "answer2",
              relatedPhase: "phase_key_0",
              type: "text",
              isAnswer: true,
            },
            {
              factors: ["ウェイト"],
              axis: "weight",
              relatedPhase: "phase_key_0",
              type: "select",
              helper: "stepValues?min=0&max=100&step=5",
            },
          ],
          textList: ["1", "2", "3", "4"].map((value) => ({ value })),
        },
      },
      {
        subRoutineId: "StaticUnitModule",
        id: "step_a2",
        origin: {
          row: 1,
          column: 1,
        },
        argv: {
          unit: {
            value: "",
            gridRowSpan: 2,
            gridColumnSpan: 1,
            cssBundleKeys: ["header_cell", "flex_centering"],
          },
        },
      },
      {
        subRoutineId: "ComputedUnitModule",
        id: "step_a3",
        origin: {
          row: "FOLLOW$$1",
          column: 3,
        },
        argv: {
          use: false,
          unit: {
            test: "A",
            conditions: [
              {
                computedKey: "$$SELF_ID",
                value: "$$RESULT",
              },
            ],
          },
        },
      },
      {
        subRoutineId: "ComputedUnitModule",
        id: "step_a4",
        origin: {
          row: "FOLLOW$$1",
          column: 4,
        },
        argv: {
          use: false,
          unit: {
            test: "B",
            conditions: [
              {
                computedKey: "$$SELF_ID",
                value: "$$RESULT",
              },
              {
                computedKey: "",
                value: "エラーです！",
              },
            ],
          },
        },
      },
    ],
    omittingPatternIds: [],
    preference: [],
    name: "メインの回答欄",
  },
  {
    id: "seed_template_2",
    projectId: "hyoka_project_nakagawa",
    omittingPatternIds: [],
    name: "コメント欄",
    procedure: [
      {
        subRoutineId: "StaticUnitModule",
        id: "step_a10",
        origin: {
          row: 1,
          column: 1,
        },
        argv: {
          unit: {
            value: "横並びレイアウト",
            cssBundleKeys: ["header_cell", "flex_centering"],
          },
        },
      },
    ],
    preference: [],
  },
  {
    id: "seed_template_3",
    projectId: "hyoka_project_nakagawa",
    omittingPatternIds: [],
    name: "評価入力欄",
    procedure: [
      {
        subRoutineId: "TextStoriesModule",
        id: "step_b0",
        origin: {
          row: 3,
          column: 1,
        },
        argv: {
          textList: ["1,A", "1,B", "1,C"].map((value) => ({ value })),
          useCategoryTotal: {
            use: false,
          },
        },
      },
      {
        subRoutineId: "AnswerHeaderRowModule",
        id: "step_b1",
        origin: {
          row: 1,
          column: "FOLLOW$$0", // 直前のモジュールの右隣に配置
        },
        argv: {
          textList: ["1,A", "1,B", "1,C"].map((value) => ({ value })),
          headerList: [
            {
              factors: ["回答"],
              axis: "answer",
              relatedPhase: "phase_key_0",
              type: "text",
              isAnswer: true,
            },
          ],
        },
      },
      {
        subRoutineId: "AnswerRowListModule", // AnswerRow を規定回数くりかえす
        id: "step_b2",
        origin: {
          row: 3,
          column: "FOLLOW$$0",
        },
        argv: {
          useCategoryTotal: {
            use: false,
          },
          useRowIndex: {
            use: false,
          },
          headerList: [
            {
              factors: ["回答"],
              axis: "answer",
              relatedPhase: "phase_key_0",
              type: "text",
              isAnswer: true,
            },
          ],
          textList: ["1,A", "1,B", "1,C"].map((value) => ({ value })),
          summaryDepth: {
            value: 1,
          },
        },
      },
      {
        subRoutineId: "StaticUnitModule",
        id: "step_b3",
        origin: {
          row: 1,
          column: 1,
        },
        argv: {
          unit: {
            value: "評価項目",
            gridRowSpan: 1,
            gridColumnSpan: 2,
            classNames: ["row-legend"],
            cssBundleKeys: ["header_cell", "flex_centering"],
          },
        },
      },
    ],
    preference: [],
  },
  {
    id: "seed_template_4",
    projectId: "hyoka_project_nakagawa",
    omittingPatternIds: [],
    name: "回答者の情報ボックス",
    procedure: [
      {
        subRoutineId: "StaticUnitModule",
        id: "step_c0",
        origin: {
          row: 1,
          column: 1,
        },
        argv: {
          unit: {
            value: "ATTR$$description",
          },
        },
      },
      {
        subRoutineId: "StaticUnitModule",
        id: "step_c1",
        origin: {
          row: 2,
          column: 1,
        },
        argv: {
          unit: {
            value: "ATTR$$name",
          },
        },
      },
    ],
    preference: [],
  },
] as UnitGroupSeedTemplate[];

// パターンごとのレイアウトロー
export const dummyUnitLayoutRowUsages1 = [
  {
    id: "row_1",
    seeds: [
      {
        id: "procedure_1",
        templateId: "seed_template_1",
        omittingPatternIds: [],
        preference: ["mr-4"],
      },
      {
        id: "procedure_2",
        templateId: "seed_template_2",
        omittingPatternIds: [],
        preference: [],
      },
    ],
    preference: ["mb-8", "align-items-center"],
  },
  {
    id: "row_3",
    seeds: [
      {
        id: "procedure_3",
        templateId: "seed_template_3",
        omittingPatternIds: [],
        preference: [],
      },
    ],
    preference: [],
  },
] as UnitLayoutRowUsage[];

export const dummyUnitLayoutRowUsages2 = [
  {
    id: "row_4",
    seeds: [
      {
        id: "procedure_4",
        templateId: "seed_template_4",
        omittingPatternIds: [],
        preference: [],
      },
    ],
    preference: ["mb-4"],
  },
  {
    id: "row_1",
    seeds: [
      {
        id: "procedure_1",
        templateId: "seed_template_1",
        omittingPatternIds: [],
        preference: ["mr-4"],
      },
      {
        id: "procedure_2",
        templateId: "seed_template_2",
        omittingPatternIds: [],
        preference: [],
      },
    ],
    preference: ["mb-8", "align-items-center"],
  },
  {
    id: "row_3",
    seeds: [
      {
        id: "procedure_3",
        templateId: "seed_template_3",
        omittingPatternIds: [],
        preference: [],
      },
    ],
    preference: [],
  },
] as UnitLayoutRowUsage[];

export const dummyUnitLayoutRowUsages_sales = [
  {
    id: "row_3",
    seeds: [
      {
        id: "procedure_3",
        templateId: "seed_template_3",
        omittingPatternIds: [],
        preference: [],
      },
    ],
    preference: [],
  },
] as UnitLayoutRowUsage[];
