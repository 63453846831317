import { EvaluationSystem } from "../EvaluationTypes";
import dayjs from "dayjs";
import { dummySeedTemplates } from "./unit_groups";

export const hyoka_nakagawa: EvaluationSystem = {
  id: "hyoka_nakagawa",
  label: "サンプル評価",
  updatedAt: dayjs().add(-1, "day").unix(),
  phaseTemplates: [
    {
      key: "phase_key_0",
      label: "目標設定",
      phaseGroupKey: "phase_group_key_A",
    },
    {
      key: "phase_key_1",
      label: "目標承認",
      phaseGroupKey: "phase_group_key_A",
    },
    {
      key: "phase_key_2",
      label: "中間評価（本人）",
      phaseGroupKey: "phase_group_key_B",
    },
    {
      key: "phase_key_3",
      label: "中間評価（一次評価）",
      phaseGroupKey: "phase_group_key_B",
    },
  ],
  phaseGroupTemplates: [
    {
      key: "phase_group_key_A",
      label: "目標設定期間",
    },
    {
      key: "phase_group_key_B",
      label: "中間評価期間",
    },
    {
      key: "phase_group_key_C",
      label: "期末評価期間",
    },
  ],
  hooks: [
    {
      label: "バリデーション",
      endpoint: "endpointForValidation",
      usingPhases: ["phase_key_self"],
    },
    {
      label: "保存",
      endpoint: "endpointForChangeDone",
      usingPhases: ["phase_key_0", "phase_key_1", "phase_key_2"],
    },
  ],
  seedTemplates: dummySeedTemplates,
  cssTraits: {
    header_cell: {
      label: "列・カラム名",
      styles: {
        background: "#fff",
        color: "rgb(25, 80, 131)",
        border: "1px solid #ddd",
      },
    },
    normal_cell: {
      label: "通常のセル",
      styles: {
        border: "1px solid #ddd",
        minHeight: "40px",
      },
    },
    flex_centering: {
      label: "flex",
      styles: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
};
