import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sidebar_Evaluation from "../../component/Sidebar_Evaluation";
import Table from "../../component/Table";
import { Container, Row, Col, Button, Alert, Form, ListGroup } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { getPhases, getScales, getTodos, getWorkflows, getMethodTags, selectESState } from "./evaluationSystemSlice";
import { ESProcessMethod, ESWorkflow } from "./ESTypes";
import Icon from "../../component/Icon";
import dayjs from "dayjs";
import { getEvaluationSystem } from "../evaluationSheet/evaluationSheetSlice";
import { useNavigate } from "react-router-dom";

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getMethodTags());
    dispatch(getPhases());
    dispatch(getTodos());
    dispatch(getScales());
    dispatch(getWorkflows());
    dispatch(getEvaluationSystem({ id: "TODO" }));
  }, []);

  return (
    <div className="Ev-container --editor">
      <Sidebar_Evaluation current="common_settings" />
      <div className="Ev-edit-content">
        <h2 className="Headline--page">制度設定</h2>
        <Container className="bg-white mt-3 p-4 px-2 px-md-4">
          <Row className="mb-4 --align-items-center">
            <Col md={8}>
              <h3 className="Headline--section">制度を選択</h3>
            </Col>
            <Col md={4}>
              <div className="--flex --content-end"></div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
