import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../app/store";
import Sidebar from "../../component/Sidebar";
import { Container, Row, Col, Alert, Tab, Nav, Form, Accordion } from "react-bootstrap";
import AccountList from "./AccountList";
import AccountRegister from "./AccountRegister";
import AccountUpload from "./AccountUpload";
import AccountEditGuest from "./AccountEditGuest";
import AccountRoleList from "./AccountRoleList";
import { selectUserState } from "../login/userSlice";
import AccountNotAssignedList from "./AccountNotAssignedList";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

type TAB = {
  label: string;
  eventKey: string;
  isAvailable?: (role: string) => boolean;
};

const TABS: TAB[] = [
  {
    label: "一覧",
    eventKey: "list",
  },
  {
    label: "新規登録",
    eventKey: "register",
  },
  {
    label: "アップロード",
    eventKey: "upload",
    isAvailable: (role) => role === "admin",
  },
  {
    label: "ゲスト",
    eventKey: "guest",
    isAvailable: (role) => role === "admin",
  },
  {
    label: "権限",
    eventKey: "permission",
    isAvailable: (role) => role === "admin",
  },
  {
    label: "配属なしのアカウント",
    eventKey: "not_assigned",
  },
];

function App() {
  const navigate = useNavigate();
  const { field } = useParams();
  const { user } = useAppSelector(selectUserState);
  const [activeTabKey, $activeTabKey] = useState(field || "list");

  useEffect(() => {
    $activeTabKey(field || "list");
  }, [field]);

  const tabContent = useMemo(() => {
    if (activeTabKey === "list") {
      return <AccountList />;
    } else if (activeTabKey === "register") {
      return <AccountRegister />;
    } else if (activeTabKey === "upload") {
      return <AccountUpload />;
    } else if (activeTabKey === "guest") {
      return <AccountEditGuest />;
    } else if (activeTabKey === "permission") {
      return <AccountRoleList />;
    } else if (activeTabKey === "not_assigned") {
      return <AccountNotAssignedList />;
    } else {
      return <div>{activeTabKey}</div>;
    }
  }, [activeTabKey]);

  const tabPanes = useMemo(() => {
    return TABS.filter((t) => !t.isAvailable || t.isAvailable(user.role));
  }, [user.role]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"account"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">アカウント</h1>
        <div className="Grouping mt-3 bg-white">
          <Container>
            <Tab.Container
              onSelect={(key: string | null) => {
                $activeTabKey(key ?? "list");
                if (key && key !== "list") {
                  navigate(`/_/account/${key}`, { replace: true });
                } else {
                  navigate(`/_/account/`, { replace: true });
                }
              }}
              activeKey={activeTabKey}
            >
              <Nav variant="tabs">
                {tabPanes.map((t) => (
                  <Nav.Item key={t.eventKey}>
                    <Nav.Link eventKey={t.eventKey}>{t.label}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content>
                <div className="mt-4">{tabContent}</div>
              </Tab.Content>
            </Tab.Container>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default App;
