import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { call } from "../../app/api";
import columnTypeChoicesJson from "../../column_type_choices.json";
import dayjs from "dayjs";

const SLICE_NAME = "statistics";

export type StatsItem = {
  id: string;
  label: string;
  subLabel: string;
  stats: {
    ageMap: number[];
    averageAge: number;
    averageJoinedYears: number;
    genderMap: number[];
    size: number;
  };
};

type SectionStory = {
  codes: string[];
  floor: number;
  childrenCodes: string[];
};
interface StatisticsState {
  initialized: boolean;
  insufficientData: boolean;
  statsMap: {
    [type: string]: { label: string; value: StatsItem[] };
  };
  sectionStories: {
    [code: string]: SectionStory;
  };
}

export const getStatistics = createAsyncThunk(
  SLICE_NAME + "/getStatistics",
  async ({ showRecessMember, showSecondedMember }: { showRecessMember: boolean; showSecondedMember: boolean }) => {
    const base_date = dayjs().format("YYYY-MM-DD");

    // マスター
    const masterRes = await Promise.all([
      call("get", "master_data_manager/position", { doReload: false })({ base_date }),
      call("get", "master_data_manager/section", { doReload: false })({
        base_date,
      }),
      call("get", "template_manager/profile_option", { doReload: false })({
        sector: "profile_u_personal",
      }),
    ]);
    const [positions, sections, employment_types] = masterRes.map((r, ri) => {
      if (ri === 0 || ri === 1) return r.data.result;
      return r.data.result.length > 0
        ? r.data.result[0].employment_type.filter((_: any) => !_.hidden)
        : columnTypeChoicesJson.employment;
    });
    if (positions.length * sections.length * employment_types.length === 0) {
      throw new Error("insufficient data");
    }

    // 部署ごとに子部署の部署コードをまとめる
    const sectionStories = sections.reduce(
      (prev: any, current: any) => {
        let next = { ...prev };
        let parent: any = current;
        let floor = 1;
        while (true) {
          parent = parent.parent_section_code
            ? sections.find((s: any) => s.section_code === parent.parent_section_code) ?? null
            : null;
          if (!parent) break;
          next = {
            ...next,
            [parent.section_code]: {
              codes: [...next[parent.section_code].codes, current.section_code],
              childrenCodes:
                floor === 1
                  ? [...next[parent.section_code].childrenCodes, current.section_code]
                  : next[parent.section_code].childrenCodes,
              floor: next[parent.section_code].floor,
            },
          };
          floor++;
        }
        return {
          ...next,
          [current.section_code]: { ...next[current.section_code], floor },
        };
      },
      sections.reduce(
        (prev: any, current: any) => {
          return { ...prev, [current.section_code]: { codes: [current.section_code], childrenCodes: [], floor: 1 } };
        },
        {} as {
          [section_code: string]: SectionStory;
        }
      )
    );

    // ユーザー
    const res = await Promise.all([
      call(
        "get",
        "user_data_manager/personal"
      )({
        base_date,
      }),
      call(
        "get",
        "user_data_manager/assignment2"
      )({
        base_date,
      }),
      call("get", "user_data_manager/position2_summary")(),
    ]);
    const [_userPersonals, userAssignments, userPositions] = res.map((r) => r.data.result);
    const userPersonals = _userPersonals.filter((p: any) => {
      if (p.enrollment_type === "休職") return showRecessMember;
      else if (p.enrollment_type === "出向") return showSecondedMember;
      else return true;
    });
    const getStats = ({ personals }: { personals: any[] }) => {
      const round = (n: number) => Math.round(n * 10) / 10;
      const { ageMap, totalAge, totalJoinedYears, genderMap } = personals.reduce(
        (prev: any, current: any) => {
          return {
            ageMap: [
              prev.ageMap[0] + (current.age <= 29 ? 1 : 0),
              prev.ageMap[1] + (current.age > 29 && current.age <= 39 ? 1 : 0),
              prev.ageMap[2] + (current.age > 39 && current.age <= 49 ? 1 : 0),
              prev.ageMap[3] + (current.age > 49 && current.age <= 59 ? 1 : 0),
              prev.ageMap[4] + (current.age >= 60 ? 1 : 0),
            ],
            totalAge: prev.totalAge + current.age,
            totalJoinedYears: prev.totalJoinedYears + current.joined_years,
            genderMap: [
              prev.genderMap[0] + (current.gender_type === "男性" ? 1 : 0),
              prev.genderMap[1] + (current.gender_type === "女性" ? 1 : 0),
            ],
          };
        },
        {
          ageMap: [0, 0, 0, 0, 0],
          totalAge: 0,
          totalJoinedYears: 0,
          genderMap: [0, 0],
        }
      );
      return {
        size: personals.length,
        ageMap,
        averageAge: personals.length > 0 ? round(totalAge / personals.length) : 0,
        averageJoinedYears: personals.length > 0 ? round(totalJoinedYears / personals.length) : 0,
        genderMap,
      };
    };

    // 軸ごとにデータを整理

    const positionStats = positions
      .sort((a: any, b: any) => a.order - b.order)
      .map((p: any) => {
        const matched = userPositions.filter((up: any) => up.position_code === p.position_code);
        const personals = matched
          .map((m: any) => {
            return userPersonals.find((up: any) => up.account_id === m.account_id);
          })
          .filter((_: any) => _);

        return {
          id: p.id,
          label: p.position_name,
          subLabel: p.position_code,
          stats: getStats({ personals }),
        };
      });
    const sectionStats = sections
      .sort((a: any, b: any) => a.order - b.order)
      .map((p: any) => {
        const matched = userAssignments
          .filter((up: any) => !up.is_concurrent)
          .filter((up: any) => {
            return sectionStories[p.section_code].codes.some((_: any) => _ === up.section_code);
          });
        const personals = matched
          .map((m: any) => {
            return userPersonals.find((up: any) => up.account_id === m.account_id);
          })
          .filter((_: any) => _);
        return {
          id: p.id,
          label: p.section_name,
          subLabel: p.section_code,
          stats: getStats({ personals }),
        };
      });
    const employmentTypeStats = employment_types.map((p: any, i: number) => {
      const personals = userPersonals.filter((_: any) => _).filter((up: any) => up.employment_type === p.label);
      return {
        id: "employment_type" + i,
        label: p.label,
        subLabel: "",
        stats: getStats({ personals }),
      };
    });

    const genderStats = ["男性", "女性"].map((gender_type, i) => {
      const personals = userPersonals.filter((up: any) => up.gender_type === gender_type);
      return {
        id: "gender_type" + i,
        label: gender_type,
        subLabel: "",
        stats: getStats({ personals }),
      };
    });
    return {
      statsMap: {
        positionStats: {
          label: "役職",
          value: positionStats,
        },
        sectionStats: {
          label: "部署",
          value: sectionStats,
        },
        employmentTypeStats: {
          label: "雇用区分",
          value: employmentTypeStats,
        },
        genderStats: {
          label: "性別",
          value: genderStats,
        },
      },
      sectionStories,
    };
  }
);

/*
  state の初期状態
*/
const initialState: StatisticsState = {
  initialized: false,
  insufficientData: false,
  statsMap: {
    positionStats: {
      label: "役職",
      value: [],
    },
    sectionStats: {
      label: "部署",
      value: [],
    },
    employmentTypeStats: {
      label: "雇用区分",
      value: [],
    },
    genderStats: {
      label: "性別",
      value: [],
    },
  },
  sectionStories: {},
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatistics.pending, (state, action) => {
      state.insufficientData = false;
    });
    builder.addCase(getStatistics.fulfilled, (state, action) => {
      state.statsMap = action.payload.statsMap;
      state.sectionStories = action.payload.sectionStories;
      state.initialized = true;
    });
    builder.addCase(getStatistics.rejected, (state, action) => {
      state.insufficientData = true;
      state.initialized = true;
    });
  },
});

// export const {  } = slice.actions;

export const selectStatisticsState = (state: RootState) => {
  return state.statistics as StatisticsState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
