import { UnitTemplate } from "../EvaluationTypes";
import { EvaluationModuleCommonProps, EvaluationHelperProduct } from "../EvaluationTypes";

const seedDataTypes = {
  unit: {
    description: "パラメータ",
    data: {
      type: "object",
      schema: {
        unit: {
          type: "unit",
          label: "パラメータ",
          props: {
            value: {
              type: "text",
              label: "表示テキスト",
            },
            gridRowSpan: {
              type: "number",
              label: "行数",
            },
            gridColumnSpan: {
              type: "number",
              label: "列数",
            },
            classNames: {
              type: "text",
              label: "class(カンマ区切り)",
            },
            cssBundleKeys: {
              type: "text",
              label: "CSS Bundle Key(カンマ区切り)",
            },
          },
        },
      },
    },
  },
};
const StaticUnitModule = (
  common: EvaluationModuleCommonProps,
  props: {
    origin: {
      row: number;
      column: number;
    };
    unit: {
      value: string;
      id?: string;
      gridRowSpan?: number;
      gridColumnSpan?: number;
      classNames?: string[];
      cssBundleKeys?: string[];
      style?: React.CSSProperties;
    };
  }
): EvaluationHelperProduct => {
  const units = [] as UnitTemplate[];
  const gridRow = props.origin.row ?? 1;
  const gridColumn = props.origin.column ?? 1;
  const gridRowSpan = props.unit.gridRowSpan ?? 1;
  const gridColumnSpan = props.unit.gridColumnSpan ?? 1;
  units.push({
    id: `${common.unitGroupId}__${common.subRoutineId}__${gridRow}__${gridColumn}`,
    value: props.unit.value,
    type: "static",
    unitGroupId: common.unitGroupId,
    subRoutineId: common.subRoutineId,
    gridRow,
    gridColumn,
    gridRowSpan,
    gridColumnSpan,
    style: {
      ...(props.unit.style ?? {}),
      ...(props.unit.cssBundleKeys ?? []).reduce((prev, current) => {
        const snippets = common.context.cssTraits[current];
        return {
          ...prev,
          ...(snippets?.styles ?? {}),
        };
      }, {} as React.CSSProperties),
    },
  });
  return {
    units,
    origin: { row: props.origin.row, column: props.origin.column },
    occupied: { row: props.origin.row + 1, column: props.origin.column + 1 },
    context: common.context,
    meta: {},
  };
};

const sampleArgv = {
  unit: {
    value: "",
  },
};

export default {
  label: "固定ユニット",
  run: StaticUnitModule,
  seedDataTypes,
  sampleArgv,
};
