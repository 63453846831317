import jwt_decode from "jwt-decode";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { API_ORIGIN_ASSESSMENT, call } from "../../app/api";
import { User } from "../../features/login/userSlice";

const SLICE_NAME = "token";

interface tokenState {
  idToken: string;
  idTokenErrorMessage: string;
  refreshToken: string;
  newPasswordErrorMessage: string;
  newPasswordErrorHint: number;
}

/*
  state の初期状態
*/
const initialState: tokenState = {
  idToken: "",
  idTokenErrorMessage: "",
  refreshToken: "",
  newPasswordErrorMessage: "",
  newPasswordErrorHint: 0,
};

type idTokenOption = {
  companyCode: string;
  loginCode: string;
  password: string;
};

export type decodedToken = {
  current_user: User;
  exp: number;
  anonymous: boolean;
};

export const getIdToken = createAsyncThunk(SLICE_NAME + "/getIdToken", async (idTokenOption: idTokenOption) => {
  /*
        - id_token の取得
      */
  const result = await new Promise<{
    token: string;
    refresh_token: string;
  }>(async (resolve, reject) => {
    try {
      const { data, status } = await call("post", "authorizer/login", {
        origin: API_ORIGIN_ASSESSMENT,
        doAuth: false,
        doReload: false,
      })({
        login_code: idTokenOption.loginCode,
        company_code: idTokenOption.companyCode,
        password: idTokenOption.password,
        role: ["admin", "user"],
      });
      if (status !== 200) {
        reject("例外的なエラーが発生しました。管理者にお問い合わせください。");
      }

      const { token, refresh_token } = data;
      resolve({
        token,
        refresh_token,
      });
    } catch (e: any) {
      if (e.response.status === 403) {
        reject("ログインできませんでした。ID、パスワードをご確認ください。");
      } else if (e.response.status === 406) {
        reject("許可されていないアドレスからのアクセスです。");
      } else if (e.response.status === 410) {
        reject("規定のログイン失敗回数に達したため、アカウントが使用不可に設定されました。");
      } else if (e.response.status === 412) {
        reject("アカウントの設定に不備があるため、ログインできませんでした。管理者にお問い合わせください。");
      } else if (e.response.status === 500) {
        reject("ただ今メンテナンス中です。");
      } else {
        reject("例外的なエラーが発生しました。管理者にお問い合わせください。");
      }
    }
  });

  return {
    idToken: result.token,
    refreshToken: result.refresh_token,
  };
});

export const switchCompany = createAsyncThunk(
  SLICE_NAME + "/switchCompany",
  async (options: { companyCode: string; role: string; isProfile: boolean; isServiceSwitch: boolean }) => {
    /*
        - id_token の取得
      */
    const result = await new Promise<{
      token: string;
      refresh_token: string;
    }>(async (resolve, reject) => {
      try {
        const { data, status } = await call("post", "authorizer/switch", {
          origin: API_ORIGIN_ASSESSMENT,
          doAuth: false,
          doReload: false,
        })({
          company_code: options.companyCode,
          role: options.role,
          is_profile: options.isProfile,
          is_service_switch: options.isServiceSwitch,
        });
        if (status !== 200) {
          reject("error");
        }

        const { token, refresh_token } = data;
        resolve({
          token,
          refresh_token,
        });
      } catch (e) {
        reject("error");
      }
    });

    return {
      idToken: result.token,
      refreshToken: result.refresh_token,
    };
  }
);

export const reloadToken = createAsyncThunk(SLICE_NAME + "/reloadToken", async () => {
  const res = await call("get", "authorizer/reload", {
    origin: API_ORIGIN_ASSESSMENT,
    doAuth: false,
    doReload: false,
  })();
  if (res.data.status >= 400) throw new Error("failed in reload");
  const { token } = res.data;
  return { idToken: token };
});

export const selectIsIdTokenExpired = (state: RootState) => {
  try {
    const { exp }: decodedToken = jwt_decode(state.token.idToken);
    return Date.now() > exp * 1000;
  } catch (e) {
    return true;
  }
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    truncate: (state) => {
      state.idToken = initialState.idToken;
    },
    importIdToken: (state, action) => {
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
    },
    resetIdTokenErrorMessage: (state) => {
      state.idTokenErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIdToken.fulfilled, (state, action) => {
      state.idToken = action.payload.idToken;
      state.idTokenErrorMessage = "";
      state.refreshToken = action.payload.refreshToken;
      // 企業切替の際に一時的に保持していた企業コードを削除
      localStorage.removeItem("temporary_company_code");
      localStorage.removeItem("prev_temporary_company_code");
    });
    builder.addCase(getIdToken.rejected, (state, action) => {
      state.idToken = initialState.idToken;
      state.idTokenErrorMessage = action.error.message ?? "";
      state.refreshToken = initialState.refreshToken;
    });
    builder.addCase(switchCompany.fulfilled, (state, action) => {
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
      localStorage.setItem("jwt", action.payload.idToken);
    });
    builder.addCase(switchCompany.rejected, (state) => {
      state.idToken = initialState.idToken;
      state.refreshToken = initialState.refreshToken;
    });
    builder.addCase(reloadToken.fulfilled, (state, action) => {
      state.idToken = action.payload.idToken;
      localStorage.setItem("jwt", action.payload.idToken);
    });
    builder.addCase(reloadToken.rejected, (state) => {
      state.idToken = initialState.idToken;
      localStorage.removeItem("jwt");
    });
  },
});

export const { truncate, importIdToken, resetIdTokenErrorMessage } = slice.actions;

export const selectTokenState = (state: RootState) => {
  return state.token as tokenState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
