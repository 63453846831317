import { useMemo } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector } from "../../app/store";
import { selectMyNumberState } from "./myNumberSlice";

type Props = {
  id: string;
  value: string;
  name: string;
  status: string;
  status_reason?: string;
  handler?: (options: {
    id: string;
    name: string;
    action: "register" | "review" | "browse" | "list_logs" | "revert_deletable";
  }) => any;
  className?: string;
  canReview?: boolean;
  isOld?: boolean;
};
function MyNumberRow({ id, value, name, status, status_reason, handler, canReview, className, isOld }: Props) {
  const substitute = useMemo(() => {
    if (value) return value;
    else if (status === "unregistered") return "未登録";
    else if (status === "todo") return "登録中";
    else if (status === "reviewing") return "************（承認待ち）";
    else if (status === "deletable") return "削除予定のため閲覧不可";
    else return "************";
  }, [status, value]);

  const { myNumberValue } = useAppSelector(selectMyNumberState);

  return (
    <Row className={`${className} --align-items-center`}>
      <Col md="3">
        <div>{name}</div>
      </Col>
      <Col md="4">
        <Form.Control type="text" disabled={true} isInvalid={status === "rejected"} value={value || substitute} />
        {status_reason && <Form.Control.Feedback type="invalid">{status_reason}</Form.Control.Feedback>}
      </Col>
      <Col md="5" className="--flex mt-2 mt-md-0">
        {isOld ? null : (
          <>
            {status === "done" && handler && (
              <>
                <Button
                  onClick={() => {
                    handler({ id, action: "browse", name });
                  }}
                  variant="outline-primary"
                  disabled={!!myNumberValue}
                  className="mx-1"
                >
                  表示
                </Button>
                {canReview && (
                  <Button
                    onClick={() => handler({ id, action: "review", name })}
                    variant="outline-primary"
                    className="mx-1"
                  >
                    詳細
                  </Button>
                )}
              </>
            )}
            {(status === "unregistered" || status === "todo") && handler && (
              <Button
                onClick={() => handler({ id, action: "register", name })}
                variant="outline-primary"
                className="mx-1"
              >
                登録
              </Button>
            )}
            {status === "reviewing" &&
              handler &&
              (canReview ? (
                <Button
                  onClick={() => handler({ id, action: "review", name })}
                  variant="outline-primary"
                  className="mx-1"
                >
                  確認
                </Button>
              ) : (
                <Button
                  onClick={() => handler({ id, action: "browse", name })}
                  variant="outline-primary"
                  className="mx-1"
                >
                  表示
                </Button>
              ))}
            {status === "rejected" && !canReview && handler && (
              <Button
                onClick={() => handler({ id, action: "register", name })}
                variant="outline-primary"
                className="mx-1"
              >
                再登録
              </Button>
            )}
            {status === "deletable" && (
              <>
                <Button variant="outline-primary" disabled className="mx-1">
                  表示
                </Button>
                {canReview && handler && (
                  <Button
                    onClick={() => handler({ id, action: "revert_deletable", name })}
                    variant="outline-primary"
                    className="mx-1"
                  >
                    削除予定を取消
                  </Button>
                )}
              </>
            )}
          </>
        )}
        {handler && !["unregistered", "todo"].includes(status) && (
          <Button
            onClick={() => {
              handler && handler({ id, action: "list_logs", name });
            }}
            variant="outline-secondary"
            className="mx-1"
          >
            操作ログ
          </Button>
        )}
      </Col>
    </Row>
  );
}

export default MyNumberRow;
