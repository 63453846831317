import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form, Modal, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  getSectors,
  initSectorStatus,
  selectClientState,
  getPayrollSetting,
  downloadExternalOptions,
  postPayrollSetting,
  putPayrollSetting,
} from "./clientSlice";
import { SectorStatus, PayrollSetting, PAYROLL_SYSTEMS, KOT_USAGE_SATUSES } from "./clientValues";
import ModalDialog from "../../component/ModalDialog";
import { getAllTerms } from "../../app/translate";

function ExternalFieldList() {
  const TERMS = getAllTerms();
  const dispatch = useAppDispatch();
  const { sectorUserStatus, payrollSetting } = useAppSelector(selectClientState);

  const [state, $state] = useState({
    sectors: [] as SectorStatus[],
    activeModal: "",
    kotToken: "",
    kotStatus: "",
  });

  useEffect(() => {
    dispatch(initSectorStatus());
    dispatch(getSectors());
    dispatch(getPayrollSetting());
  }, [dispatch]);

  useEffect(() => {
    $state({ ...state, sectors: [...sectorUserStatus], kotStatus: payrollSetting.kot_status || "not_in_use" });
  }, [sectorUserStatus, payrollSetting]);

  const savePayrollSetting = async () => {
    let setting = {} as PayrollSetting;
    setting.payroll_system = payrollSetting.payroll_system;
    setting.kot_token = state.kotToken;
    setting.kot_status = state.kotStatus;
    if (payrollSetting.id) {
      setting.id = payrollSetting.id;
      await dispatch(putPayrollSetting(setting));
    } else {
      await dispatch(postPayrollSetting(setting));
    }
    dispatch(getPayrollSetting());
    $state({ ...state, activeModal: "", kotToken: "" });
  };

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Link to={"/_/masters/payroll_setting/edit/"}>
            <Button className="mx-1" variant="outline-primary">
              給与計算システム連携の設定
            </Button>
          </Link>
          <Button
            className="mx-1"
            variant="outline-primary"
            onClick={() => $state({ ...state, activeModal: "to_form" })}
          >
            KING OF TIME連携の設定
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          {payrollSetting?.payroll_system && (
            <div>
              ※従業員情報（一覧）で差分ダウンロードできる給与計算システム用ファイルは「
              {PAYROLL_SYSTEMS[payrollSetting.payroll_system as keyof typeof PAYROLL_SYSTEMS]}
              」のフォーマットでダウンロードされます。
            </div>
          )}
          {payrollSetting?.kot_token && (
            <div>
              ※KING OF TIMEとアカウントのデータ連携をしています（ステータス：
              {TERMS[`KOT_USAGE_STATUS_${payrollSetting.kot_status || "not_in_use"}`]}）
            </div>
          )}
          <Button
            className="mr-2 float-end"
            variant="outline-primary"
            disabled={!payrollSetting?.payroll_system}
            onClick={() => {
              dispatch(downloadExternalOptions());
            }}
          >
            選択肢一括ダウンロード
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Row className="mb-3">
            <Col>
              <Row className="mb-3">
                <Col>
                  <Row>
                    <div className="Table">
                      <div className="Table__header">
                        <div className="Table__header-inner">
                          <div className="Table__header-col" style={{ width: "250px" }}>
                            {"テーブル"}
                          </div>
                          <div className="Table__header-col" style={{ width: "160px" }}>
                            {"アクション"}
                          </div>
                        </div>
                      </div>
                      {state.sectors
                        .filter((sector) => sector.active)
                        .map((s, i) => {
                          return (
                            <div className="Table__row" key={`sector-${s.key}`}>
                              <div className="Table__row-inner">
                                <div className="Table__col" style={{ width: "250px" }}>
                                  {s.label}
                                </div>
                                <div className="Table__col" style={{ width: "320px" }}>
                                  {payrollSetting?.payroll_system ? (
                                    <Link className="me-1" to={`/_/masters/external/edit/${s.key}`}>
                                      項目名のマッピング
                                    </Link>
                                  ) : (
                                    <span className="me-1">項目名のマッピング</span>
                                  )}
                                  {payrollSetting?.payroll_system ? (
                                    <Link to={`/_/masters/external/options/${s.key}`}>選択肢のマッピング</Link>
                                  ) : (
                                    <span>選択肢のマッピング</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        show={state.activeModal === "to_form"}
        onHide={() => {
          window.history.back();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Fragment>
            <strong>KING OF TIME連携の設定</strong>
            <Card className="my-2">
              <Card.Body>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong>トークン</strong>
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          id="kotToken"
                          value={state.kotToken}
                          onChange={(e) => {
                            $state({ ...state, kotToken: e.target.value });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <div className="--font-s --text-secondary">
                          ※空で保存するとKING OF TIMEとの連携が解除されます。
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: "middle" }}>
                        <div style={{ marginBottom: "10px" }}></div>
                        <strong style={{ marginRight: "20px" }}>ステータス</strong>
                      </td>
                      <td>
                        <div style={{ marginBottom: "10px" }}></div>
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          {KOT_USAGE_SATUSES.map((status) => (
                            <Form.Check
                              type="radio"
                              id={`status_${status}`}
                              label={TERMS[`KOT_USAGE_STATUS_${status}`]}
                              value={status}
                              checked={state.kotStatus === status}
                              onChange={(e) => {
                                $state({ ...state, kotStatus: e.target.value });
                              }}
                              key={status}
                            />
                          ))}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              $state({ ...state, activeModal: "" });
            }}
            variant="outline-secondary"
          >
            キャンセル
          </Button>
          <Button
            onClick={() => {
              $state({ ...state, activeModal: "commit" });
            }}
            variant="primary"
          >
            保存
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={state.activeModal === "commit"}
        message={
          !state.kotToken ? "KING OF TIMEとの連携が解除されますが、よろしいですか？" : "保存します。よろしいですか？"
        }
        onConfirm={async () => {
          savePayrollSetting();
        }}
        onCancel={() => {
          $state({ ...state, activeModal: "", kotToken: "" });
        }}
      />
    </Container>
  );
}

export default ExternalFieldList;
